import {useMutation, useQuery} from '@apollo/client'
import {
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  Skeleton,
  Typography
} from '@mui/material'
import {isNil} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AddDiscountToTourItemsMutation,
  AddDiscountToTourItemsMutationVariables,
  Currency,
  DiscountApplication,
  DiscountDisplayMode,
  EnabledDiscountFieldsFragment,
  ErrorMessages,
  SellingChannel,
  TourTimeSlotEnabledDiscountsQuery,
  TourTimeSlotEnabledDiscountsQueryVariables
} from '../../__generated__/schema'
import {useEcommerceErrorHandlers} from '../hooks/ecommerceErrorHandlers'
import {useFormatDiscountValue} from '../hooks/formatting'
import {useMutationAssistanceHooks} from '../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../utils'
import {
  ADD_DISCOUNT_TO_TOUR_ITEMS,
  TOUR_TIME_SLOT_ENABLED_DISCOUNTS
} from './graphql'
import {useDiscountUsageLimitPerOrderHelper} from './utils'

interface IAddTourItemDiscountChipProps {
  tourTimeSlotId: number
  isMobile: boolean
  currency: Currency
  cartId: number
  tourItemId: number
  onIHaveDiscountCodeClick: () => void
  onCustomerDiscountClick: (
    discount: (EnabledDiscountFieldsFragment & {tourItemId: number}) | null
  ) => void
}

export const AddTourItemDiscountChip: React.FC<IAddTourItemDiscountChipProps> =
  ({
    tourTimeSlotId,
    isMobile,
    currency,
    cartId,
    tourItemId,
    onIHaveDiscountCodeClick,
    onCustomerDiscountClick
  }: IAddTourItemDiscountChipProps) => {
    const {t} = useTranslation()
    const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
    const {invalidCartStateErrorHandler} = useEcommerceErrorHandlers()
    const formatDiscountValue = useFormatDiscountValue(currency)
    const discountUsageLimitPerOrderHelper =
      useDiscountUsageLimitPerOrderHelper()
    const {data, loading} = useQuery<
      TourTimeSlotEnabledDiscountsQuery,
      TourTimeSlotEnabledDiscountsQueryVariables
    >(TOUR_TIME_SLOT_ENABLED_DISCOUNTS, {
      fetchPolicy: 'network-only',
      variables: {
        id: tourTimeSlotId,
        discountsFilter: {
          channel: SellingChannel.ECommerce,
          displayModes: [DiscountDisplayMode.Displayed]
        }
      },
      onError: (error) => {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidCartState
          )
        ) {
          invalidCartStateErrorHandler({error})
        } else {
          defaultErrorHandler(error, {
            title: t('Error while loading discounts')
          })
        }
      }
    })
    const [addDiscountToTourItems] = useMutation<
      AddDiscountToTourItemsMutation,
      AddDiscountToTourItemsMutationVariables
    >(ADD_DISCOUNT_TO_TOUR_ITEMS)
    const addDiscountToTourItem = useCallback(
      async (discountId: number) => {
        try {
          setShowBackdrop(true)
          await addDiscountToTourItems({
            variables: {cartId, itemIds: [tourItemId], discountId}
          })
        } catch (error) {
          if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.InvalidCartState
            )
          ) {
            invalidCartStateErrorHandler({error})
          } else if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.DiscountUsageLimitPerOrderExceeded
            )
          ) {
            defaultErrorHandler(error, {
              title: t('Discount redemption limit exceeded'),
              description: t(
                'We’re sorry, but the discount you are trying to apply has reached its usage limit for this order. Each order can only use this discount a certain number of times, and you have already applied the maximum allowed usage. Please review your order to ensure the discount is applied correctly or reduce the number of times you’re using the discount.'
              )
            })
          } else {
            defaultErrorHandler(error, {
              title: t('Unable to add discount'),
              description: error.graphQLErrors[0].message
            })
          }
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addDiscountToTourItems,
        cartId,
        defaultErrorHandler,
        invalidCartStateErrorHandler,
        setShowBackdrop,
        t,
        tourItemId
      ]
    )
    const handleDiscountClick = useCallback(
      (discount: EnabledDiscountFieldsFragment) => async () => {
        if (discount.application === DiscountApplication.Customer) {
          onCustomerDiscountClick({...discount, tourItemId})
        } else if (discount.application === DiscountApplication.Code) {
          onIHaveDiscountCodeClick()
        } else {
          await addDiscountToTourItem(discount.id)
        }
      },
      [
        addDiscountToTourItem,
        onCustomerDiscountClick,
        onIHaveDiscountCodeClick,
        tourItemId
      ]
    )
    const width = isMobile ? undefined : 320
    return loading ? (
      <Skeleton sx={{mx: 2}} width={width} height={56} />
    ) : (data?.tourTimeSlot.enabledDiscounts || []).length > 0 ? (
      <MenuList
        disablePadding
        sx={{
          width,
          '& .MuiMenuItem-root': {
            whiteSpace: 'normal'
          }
        }}
      >
        <MenuItem
          sx={{minHeight: 56, py: 1}}
          onClick={onIHaveDiscountCodeClick}
        >
          <ListItemText
            primary={t('I have discount code')}
            primaryTypographyProps={{variant: 'body2'}}
          />
        </MenuItem>
        {data?.tourTimeSlot.enabledDiscounts.map((discount) => {
          const {numberOfUsageLeft, canBeUsed} =
            discountUsageLimitPerOrderHelper({
              selectedDiscount: discount,
              tourTimeSlotId
            })
          return (
            <MenuItem
              disabled={!canBeUsed}
              key={discount.id}
              sx={{py: 1, minHeight: 56}}
              onClick={handleDiscountClick(discount)}
            >
              <ListItemText
                primary={discount.name}
                primaryTypographyProps={{variant: 'body2'}}
                secondary={[
                  formatDiscountValue({
                    value: discount.value,
                    type: discount.type
                  }),
                  !isNil(discount.maxUsageLimitPerOrder) &&
                    t('Can be redeemed {{count}} times per order.', {
                      count: numberOfUsageLeft
                    })
                ]
                  .filter(Boolean)
                  .join(' • ')}
                secondaryTypographyProps={{variant: 'caption'}}
              />
            </MenuItem>
          )
        })}
      </MenuList>
    ) : (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: 56,
          width,
          px: 2
        }}
      >
        <Typography variant="body2">
          {t('No available discounts were found')}
        </Typography>
      </Box>
    )
  }
