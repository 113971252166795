export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: number;
  /** Integers that will have a value greater than 0. */
  PositiveInt: number;
  /** A string that cannot be passed as an empty value */
  NonEmptyString: any;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: any;
  /** Private RSA key scalar type */
  PrivateRSAKey: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  Url: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: Object;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: number;
  /** Floats that will have a value greater than 0. */
  PositiveFloat: number;
  /** Represents NULL values */
  Void: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** Floats that will have a value of 0 or less. */
  NonPositiveFloat: any;
  /** Integers that will have a value of 0 or less. */
  NonPositiveInt: any;
};








export type Translated = {
  __typename?: 'Translated';
  en?: Maybe<Scalars['String']>;
  sk?: Maybe<Scalars['String']>;
  cs?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
};

export type TranslatedInput = {
  en?: Maybe<Scalars['String']>;
  sk?: Maybe<Scalars['String']>;
  cs?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
};

export type ECommerceTranslated = {
  __typename?: 'ECommerceTranslated';
  en?: Maybe<Scalars['String']>;
  sk?: Maybe<Scalars['String']>;
  cs?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['String']>;
};

export type ECommerceTranslatedInput = {
  en?: Maybe<Scalars['String']>;
  sk?: Maybe<Scalars['String']>;
  cs?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['String']>;
};

export enum ExistingCountryCode {
  Aw = 'AW',
  Af = 'AF',
  Ao = 'AO',
  Ai = 'AI',
  Al = 'AL',
  Ad = 'AD',
  Ae = 'AE',
  Ar = 'AR',
  Am = 'AM',
  As = 'AS',
  Ag = 'AG',
  Au = 'AU',
  At = 'AT',
  Az = 'AZ',
  Bi = 'BI',
  Be = 'BE',
  Bj = 'BJ',
  Bf = 'BF',
  Bd = 'BD',
  Bg = 'BG',
  Bh = 'BH',
  Bs = 'BS',
  Ba = 'BA',
  By = 'BY',
  Bz = 'BZ',
  Bm = 'BM',
  Bo = 'BO',
  Br = 'BR',
  Bb = 'BB',
  Bn = 'BN',
  Bt = 'BT',
  Bw = 'BW',
  Cf = 'CF',
  Ca = 'CA',
  Ci = 'CI',
  Cm = 'CM',
  Cd = 'CD',
  Cg = 'CG',
  Ck = 'CK',
  Co = 'CO',
  Km = 'KM',
  Cv = 'CV',
  Cr = 'CR',
  Cu = 'CU',
  Ky = 'KY',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dm = 'DM',
  Dk = 'DK',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Eg = 'EG',
  Er = 'ER',
  Eh = 'EH',
  Es = 'ES',
  Ee = 'EE',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fr = 'FR',
  Fo = 'FO',
  Fm = 'FM',
  Ga = 'GA',
  Gb = 'GB',
  Ge = 'GE',
  Gh = 'GH',
  Gi = 'GI',
  Gn = 'GN',
  Gp = 'GP',
  Gm = 'GM',
  Gw = 'GW',
  Gq = 'GQ',
  Gr = 'GR',
  Gd = 'GD',
  Gl = 'GL',
  Gt = 'GT',
  Gf = 'GF',
  Gu = 'GU',
  Gy = 'GY',
  Hk = 'HK',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Ch = 'CH',
  Cl = 'CL',
  Cn = 'CN',
  Id = 'ID',
  In = 'IN',
  Ie = 'IE',
  Ir = 'IR',
  Iq = 'IQ',
  Is = 'IS',
  Il = 'IL',
  It = 'IT',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Kz = 'KZ',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Kn = 'KN',
  Kr = 'KR',
  Kw = 'KW',
  La = 'LA',
  Lb = 'LB',
  Lr = 'LR',
  Ly = 'LY',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Mo = 'MO',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Mg = 'MG',
  Mv = 'MV',
  Mx = 'MX',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mt = 'MT',
  Mm = 'MM',
  Me = 'ME',
  Mn = 'MN',
  Mp = 'MP',
  Mz = 'MZ',
  Mr = 'MR',
  Ms = 'MS',
  Mq = 'MQ',
  Mu = 'MU',
  Mw = 'MW',
  My = 'MY',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nu = 'NU',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nz = 'NZ',
  Om = 'OM',
  Pk = 'PK',
  Pa = 'PA',
  Pn = 'PN',
  Pe = 'PE',
  Ph = 'PH',
  Pw = 'PW',
  Ps = 'PS',
  Pg = 'PG',
  Pl = 'PL',
  Pr = 'PR',
  Kp = 'KP',
  Pt = 'PT',
  Py = 'PY',
  Pf = 'PF',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sd = 'SD',
  Sn = 'SN',
  Sg = 'SG',
  Sh = 'SH',
  Sj = 'SJ',
  Sb = 'SB',
  Sl = 'SL',
  Sv = 'SV',
  Sm = 'SM',
  So = 'SO',
  Pm = 'PM',
  Rs = 'RS',
  Ss = 'SS',
  St = 'ST',
  Sr = 'SR',
  Sk = 'SK',
  Si = 'SI',
  Se = 'SE',
  Sz = 'SZ',
  Sc = 'SC',
  Sy = 'SY',
  Tc = 'TC',
  Td = 'TD',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tm = 'TM',
  To = 'TO',
  Tt = 'TT',
  Tn = 'TN',
  Tr = 'TR',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ug = 'UG',
  Ua = 'UA',
  Uy = 'UY',
  Us = 'US',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export enum CountryCode {
  Aw = 'AW',
  Af = 'AF',
  Ao = 'AO',
  Ai = 'AI',
  Al = 'AL',
  Ad = 'AD',
  An = 'AN',
  Ae = 'AE',
  Ar = 'AR',
  Am = 'AM',
  As = 'AS',
  Ag = 'AG',
  Au = 'AU',
  At = 'AT',
  Az = 'AZ',
  Bi = 'BI',
  Be = 'BE',
  Bj = 'BJ',
  Bf = 'BF',
  Bd = 'BD',
  Bg = 'BG',
  Bh = 'BH',
  Bs = 'BS',
  Ba = 'BA',
  By = 'BY',
  Bz = 'BZ',
  Bm = 'BM',
  Bo = 'BO',
  Br = 'BR',
  Bb = 'BB',
  Bn = 'BN',
  Bt = 'BT',
  Bw = 'BW',
  Cf = 'CF',
  Ca = 'CA',
  Ci = 'CI',
  Cm = 'CM',
  Cd = 'CD',
  Cg = 'CG',
  Ck = 'CK',
  Co = 'CO',
  Km = 'KM',
  Cv = 'CV',
  Cr = 'CR',
  Cs = 'CS',
  Cu = 'CU',
  Ky = 'KY',
  Cy = 'CY',
  Cz = 'CZ',
  Dd = 'DD',
  De = 'DE',
  Dj = 'DJ',
  Dm = 'DM',
  Dk = 'DK',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Eg = 'EG',
  Er = 'ER',
  Eh = 'EH',
  Es = 'ES',
  Ee = 'EE',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fr = 'FR',
  Fo = 'FO',
  Fm = 'FM',
  Ga = 'GA',
  Gb = 'GB',
  Ge = 'GE',
  Gh = 'GH',
  Gi = 'GI',
  Gn = 'GN',
  Gp = 'GP',
  Gm = 'GM',
  Gw = 'GW',
  Gq = 'GQ',
  Gr = 'GR',
  Gd = 'GD',
  Gl = 'GL',
  Gt = 'GT',
  Gf = 'GF',
  Gu = 'GU',
  Gy = 'GY',
  Hk = 'HK',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Ch = 'CH',
  Cl = 'CL',
  Cn = 'CN',
  Id = 'ID',
  In = 'IN',
  Ie = 'IE',
  Ir = 'IR',
  Iq = 'IQ',
  Is = 'IS',
  Il = 'IL',
  It = 'IT',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Kz = 'KZ',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Kn = 'KN',
  Kr = 'KR',
  Kw = 'KW',
  La = 'LA',
  Lb = 'LB',
  Lr = 'LR',
  Ly = 'LY',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Mo = 'MO',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Mg = 'MG',
  Mv = 'MV',
  Mx = 'MX',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mt = 'MT',
  Mm = 'MM',
  Me = 'ME',
  Mn = 'MN',
  Mp = 'MP',
  Mz = 'MZ',
  Mr = 'MR',
  Ms = 'MS',
  Mq = 'MQ',
  Mu = 'MU',
  Mw = 'MW',
  My = 'MY',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nu = 'NU',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nz = 'NZ',
  Om = 'OM',
  Pk = 'PK',
  Pa = 'PA',
  Pn = 'PN',
  Pe = 'PE',
  Ph = 'PH',
  Pw = 'PW',
  Ps = 'PS',
  Pg = 'PG',
  Pl = 'PL',
  Pr = 'PR',
  Kp = 'KP',
  Pt = 'PT',
  Py = 'PY',
  Pf = 'PF',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sd = 'SD',
  Sn = 'SN',
  Sg = 'SG',
  Sh = 'SH',
  Sj = 'SJ',
  Sb = 'SB',
  Sl = 'SL',
  Sv = 'SV',
  Sm = 'SM',
  So = 'SO',
  Pm = 'PM',
  Rs = 'RS',
  Ss = 'SS',
  St = 'ST',
  Su = 'SU',
  Sr = 'SR',
  Sk = 'SK',
  Si = 'SI',
  Se = 'SE',
  Sz = 'SZ',
  Sc = 'SC',
  Sy = 'SY',
  Tc = 'TC',
  Td = 'TD',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tm = 'TM',
  To = 'TO',
  Tt = 'TT',
  Tn = 'TN',
  Tr = 'TR',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ug = 'UG',
  Ua = 'UA',
  Uy = 'UY',
  Us = 'US',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yu = 'YU',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

/** Locale is a language, that is used for localizing Attendio app for current user and/or client. */
export enum LocaleCode {
  En = 'en',
  Sk = 'sk',
  Cs = 'cs',
  Hu = 'hu'
}

export enum ECommerceLocaleCode {
  En = 'en',
  Sk = 'sk',
  Cs = 'cs',
  Hu = 'hu',
  De = 'de',
  Pl = 'pl'
}

/** Language code is used for determining a language, that is used in a Show by actors. */
export enum LanguageCode {
  Aar = 'aar',
  Abk = 'abk',
  Ace = 'ace',
  Ach = 'ach',
  Ada = 'ada',
  Ady = 'ady',
  Afa = 'afa',
  Afh = 'afh',
  Afr = 'afr',
  Ain = 'ain',
  Aka = 'aka',
  Akk = 'akk',
  Ale = 'ale',
  Alg = 'alg',
  Alt = 'alt',
  Amh = 'amh',
  Ang = 'ang',
  Anp = 'anp',
  Apa = 'apa',
  Ara = 'ara',
  Arc = 'arc',
  Arg = 'arg',
  Arn = 'arn',
  Arp = 'arp',
  Art = 'art',
  Arw = 'arw',
  Asm = 'asm',
  Ast = 'ast',
  Ath = 'ath',
  Aus = 'aus',
  Ava = 'ava',
  Ave = 'ave',
  Awa = 'awa',
  Aym = 'aym',
  Aze = 'aze',
  Bad = 'bad',
  Bai = 'bai',
  Bak = 'bak',
  Bal = 'bal',
  Bam = 'bam',
  Ban = 'ban',
  Bas = 'bas',
  Bat = 'bat',
  Bej = 'bej',
  Bel = 'bel',
  Bem = 'bem',
  Ben = 'ben',
  Ber = 'ber',
  Bho = 'bho',
  Bih = 'bih',
  Bik = 'bik',
  Bin = 'bin',
  Bis = 'bis',
  Bla = 'bla',
  Bnt = 'bnt',
  Bod = 'bod',
  Bos = 'bos',
  Bra = 'bra',
  Bre = 'bre',
  Btk = 'btk',
  Bua = 'bua',
  Bug = 'bug',
  Bul = 'bul',
  Byn = 'byn',
  Cad = 'cad',
  Cai = 'cai',
  Car = 'car',
  Cat = 'cat',
  Cau = 'cau',
  Ceb = 'ceb',
  Cel = 'cel',
  Ces = 'ces',
  Cha = 'cha',
  Chb = 'chb',
  Che = 'che',
  Chk = 'chk',
  Chm = 'chm',
  Chn = 'chn',
  Cho = 'cho',
  Chp = 'chp',
  Chr = 'chr',
  Chu = 'chu',
  Chv = 'chv',
  Chy = 'chy',
  Cmc = 'cmc',
  Cnr = 'cnr',
  Cop = 'cop',
  Cor = 'cor',
  Cos = 'cos',
  Cre = 'cre',
  Crh = 'crh',
  Crp = 'crp',
  Csb = 'csb',
  Cus = 'cus',
  Cym = 'cym',
  Dak = 'dak',
  Dan = 'dan',
  Dar = 'dar',
  Day = 'day',
  Del = 'del',
  Den = 'den',
  Deu = 'deu',
  Dgr = 'dgr',
  Din = 'din',
  Div = 'div',
  Doi = 'doi',
  Dra = 'dra',
  Dsb = 'dsb',
  Dua = 'dua',
  Dum = 'dum',
  Dyu = 'dyu',
  Dzo = 'dzo',
  Efi = 'efi',
  Egy = 'egy',
  Eka = 'eka',
  Ell = 'ell',
  Elx = 'elx',
  Eng = 'eng',
  Enm = 'enm',
  Epo = 'epo',
  Est = 'est',
  Eus = 'eus',
  Ewe = 'ewe',
  Ewo = 'ewo',
  Fan = 'fan',
  Fao = 'fao',
  Fas = 'fas',
  Fat = 'fat',
  Fij = 'fij',
  Fil = 'fil',
  Fin = 'fin',
  Fiu = 'fiu',
  Fon = 'fon',
  Fra = 'fra',
  Frm = 'frm',
  Fro = 'fro',
  Frr = 'frr',
  Frs = 'frs',
  Fry = 'fry',
  Ful = 'ful',
  Fur = 'fur',
  Gaa = 'gaa',
  Gay = 'gay',
  Gba = 'gba',
  Gem = 'gem',
  Gez = 'gez',
  Gil = 'gil',
  Gla = 'gla',
  Gle = 'gle',
  Glg = 'glg',
  Glv = 'glv',
  Gmh = 'gmh',
  Goh = 'goh',
  Gon = 'gon',
  Gor = 'gor',
  Got = 'got',
  Grb = 'grb',
  Grc = 'grc',
  Grn = 'grn',
  Gsw = 'gsw',
  Guj = 'guj',
  Gwi = 'gwi',
  Hai = 'hai',
  Hat = 'hat',
  Hau = 'hau',
  Haw = 'haw',
  Heb = 'heb',
  Her = 'her',
  Hil = 'hil',
  Him = 'him',
  Hin = 'hin',
  Hit = 'hit',
  Hmn = 'hmn',
  Hmo = 'hmo',
  Hrv = 'hrv',
  Hsb = 'hsb',
  Hun = 'hun',
  Hup = 'hup',
  Hye = 'hye',
  Iba = 'iba',
  Ibo = 'ibo',
  Ido = 'ido',
  Iii = 'iii',
  Ijo = 'ijo',
  Iku = 'iku',
  Ile = 'ile',
  Ilo = 'ilo',
  Ina = 'ina',
  Inc = 'inc',
  Ind = 'ind',
  Ine = 'ine',
  Inh = 'inh',
  Ipk = 'ipk',
  Ira = 'ira',
  Iro = 'iro',
  Isl = 'isl',
  Ita = 'ita',
  Jav = 'jav',
  Jbo = 'jbo',
  Jpn = 'jpn',
  Jpr = 'jpr',
  Jrb = 'jrb',
  Kaa = 'kaa',
  Kab = 'kab',
  Kac = 'kac',
  Kal = 'kal',
  Kam = 'kam',
  Kan = 'kan',
  Kar = 'kar',
  Kas = 'kas',
  Kat = 'kat',
  Kau = 'kau',
  Kaw = 'kaw',
  Kaz = 'kaz',
  Kbd = 'kbd',
  Kha = 'kha',
  Khi = 'khi',
  Khm = 'khm',
  Kho = 'kho',
  Kik = 'kik',
  Kin = 'kin',
  Kir = 'kir',
  Kmb = 'kmb',
  Kok = 'kok',
  Kom = 'kom',
  Kon = 'kon',
  Kor = 'kor',
  Kos = 'kos',
  Kpe = 'kpe',
  Krc = 'krc',
  Krl = 'krl',
  Kro = 'kro',
  Kru = 'kru',
  Kua = 'kua',
  Kum = 'kum',
  Kur = 'kur',
  Kut = 'kut',
  Lad = 'lad',
  Lah = 'lah',
  Lam = 'lam',
  Lao = 'lao',
  Lat = 'lat',
  Lav = 'lav',
  Lez = 'lez',
  Lim = 'lim',
  Lin = 'lin',
  Lit = 'lit',
  Lol = 'lol',
  Loz = 'loz',
  Ltz = 'ltz',
  Lua = 'lua',
  Lub = 'lub',
  Lug = 'lug',
  Lui = 'lui',
  Lun = 'lun',
  Luo = 'luo',
  Lus = 'lus',
  Mad = 'mad',
  Mag = 'mag',
  Mah = 'mah',
  Mai = 'mai',
  Mak = 'mak',
  Mal = 'mal',
  Man = 'man',
  Map = 'map',
  Mar = 'mar',
  Mas = 'mas',
  Mdf = 'mdf',
  Mdr = 'mdr',
  Men = 'men',
  Mga = 'mga',
  Mic = 'mic',
  Min = 'min',
  Mis = 'mis',
  Mkd = 'mkd',
  Mkh = 'mkh',
  Mlg = 'mlg',
  Mlt = 'mlt',
  Mnc = 'mnc',
  Mni = 'mni',
  Mno = 'mno',
  Moh = 'moh',
  Mon = 'mon',
  Mos = 'mos',
  Mri = 'mri',
  Msa = 'msa',
  Mul = 'mul',
  Mun = 'mun',
  Mus = 'mus',
  Mwl = 'mwl',
  Mwr = 'mwr',
  Mya = 'mya',
  Myn = 'myn',
  Myv = 'myv',
  Nah = 'nah',
  Nai = 'nai',
  Nap = 'nap',
  Nau = 'nau',
  Nav = 'nav',
  Nbl = 'nbl',
  Nde = 'nde',
  Ndo = 'ndo',
  Nds = 'nds',
  Nep = 'nep',
  Nia = 'nia',
  Nic = 'nic',
  Niu = 'niu',
  Nld = 'nld',
  Nno = 'nno',
  Nob = 'nob',
  Nog = 'nog',
  Non = 'non',
  Nor = 'nor',
  Nqo = 'nqo',
  Nso = 'nso',
  Nub = 'nub',
  Nwc = 'nwc',
  Nya = 'nya',
  Nym = 'nym',
  Nyn = 'nyn',
  Nyo = 'nyo',
  Nzi = 'nzi',
  Oci = 'oci',
  Oji = 'oji',
  Ori = 'ori',
  Orm = 'orm',
  Osa = 'osa',
  Oss = 'oss',
  Ota = 'ota',
  Oto = 'oto',
  Paa = 'paa',
  Pag = 'pag',
  Pal = 'pal',
  Pam = 'pam',
  Pan = 'pan',
  Pap = 'pap',
  Pau = 'pau',
  Peo = 'peo',
  Phi = 'phi',
  Phn = 'phn',
  Pli = 'pli',
  Pol = 'pol',
  Pon = 'pon',
  Por = 'por',
  Pra = 'pra',
  Pro = 'pro',
  Pus = 'pus',
  Que = 'que',
  Raj = 'raj',
  Rap = 'rap',
  Rar = 'rar',
  Roa = 'roa',
  Roh = 'roh',
  Rom = 'rom',
  Ron = 'ron',
  Run = 'run',
  Rup = 'rup',
  Rus = 'rus',
  Sad = 'sad',
  Sag = 'sag',
  Sah = 'sah',
  Sai = 'sai',
  Sam = 'sam',
  San = 'san',
  Sas = 'sas',
  Sat = 'sat',
  Scn = 'scn',
  Sco = 'sco',
  Sel = 'sel',
  Sem = 'sem',
  Sga = 'sga',
  Sgn = 'sgn',
  Shn = 'shn',
  Sid = 'sid',
  Sin = 'sin',
  Sio = 'sio',
  Sit = 'sit',
  Sla = 'sla',
  Slk = 'slk',
  Slv = 'slv',
  Sma = 'sma',
  Sme = 'sme',
  Smi = 'smi',
  Smj = 'smj',
  Smn = 'smn',
  Smo = 'smo',
  Sms = 'sms',
  Sna = 'sna',
  Snd = 'snd',
  Snk = 'snk',
  Sog = 'sog',
  Som = 'som',
  Son = 'son',
  Sot = 'sot',
  Spa = 'spa',
  Sqi = 'sqi',
  Srd = 'srd',
  Srn = 'srn',
  Srp = 'srp',
  Srr = 'srr',
  Ssa = 'ssa',
  Ssw = 'ssw',
  Suk = 'suk',
  Sun = 'sun',
  Sus = 'sus',
  Sux = 'sux',
  Swa = 'swa',
  Swe = 'swe',
  Syc = 'syc',
  Syr = 'syr',
  Tah = 'tah',
  Tai = 'tai',
  Tam = 'tam',
  Tat = 'tat',
  Tel = 'tel',
  Tem = 'tem',
  Ter = 'ter',
  Tet = 'tet',
  Tgk = 'tgk',
  Tgl = 'tgl',
  Tha = 'tha',
  Tig = 'tig',
  Tir = 'tir',
  Tiv = 'tiv',
  Tkl = 'tkl',
  Tlh = 'tlh',
  Tli = 'tli',
  Tmh = 'tmh',
  Tog = 'tog',
  Ton = 'ton',
  Tpi = 'tpi',
  Tsi = 'tsi',
  Tsn = 'tsn',
  Tso = 'tso',
  Tuk = 'tuk',
  Tum = 'tum',
  Tup = 'tup',
  Tur = 'tur',
  Tut = 'tut',
  Tvl = 'tvl',
  Twi = 'twi',
  Tyv = 'tyv',
  Udm = 'udm',
  Uga = 'uga',
  Uig = 'uig',
  Ukr = 'ukr',
  Umb = 'umb',
  Und = 'und',
  Urd = 'urd',
  Uzb = 'uzb',
  Vai = 'vai',
  Ven = 'ven',
  Vie = 'vie',
  Vol = 'vol',
  Vot = 'vot',
  Wak = 'wak',
  Wal = 'wal',
  War = 'war',
  Was = 'was',
  Wen = 'wen',
  Wln = 'wln',
  Wol = 'wol',
  Xal = 'xal',
  Xho = 'xho',
  Yao = 'yao',
  Yap = 'yap',
  Yid = 'yid',
  Yor = 'yor',
  Ypk = 'ypk',
  Zap = 'zap',
  Zbl = 'zbl',
  Zen = 'zen',
  Zgh = 'zgh',
  Zha = 'zha',
  Zho = 'zho',
  Znd = 'znd',
  Zul = 'zul',
  Zun = 'zun',
  Zxx = 'zxx',
  Zza = 'zza'
}


export enum ShowAgeClassificationCode {
  SkNotRestricted = 'sk_not_restricted',
  SkOver2 = 'sk_over2',
  SkOver3 = 'sk_over3',
  SkOver4 = 'sk_over4',
  SkOver5 = 'sk_over5',
  SkOver6 = 'sk_over6',
  SkOver7 = 'sk_over7',
  SkOver8 = 'sk_over8',
  SkOver9 = 'sk_over9',
  SkOver10 = 'sk_over10',
  SkOver11 = 'sk_over11',
  SkOver12 = 'sk_over12',
  SkOver13 = 'sk_over13',
  SkOver14 = 'sk_over14',
  SkOver15 = 'sk_over15',
  SkOver16 = 'sk_over16',
  SkOver17 = 'sk_over17',
  SkOver18 = 'sk_over18',
  SkUnsuitableUnder7 = 'sk_unsuitable_under7',
  SkUnsuitableUnder12 = 'sk_unsuitable_under12',
  SkUnsuitableUnder15 = 'sk_unsuitable_under15',
  SkUnsuitableUnder18 = 'sk_unsuitable_under18',
  SkSuitableUnder12 = 'sk_suitable_under12',
  SkSuitableForAll = 'sk_suitable_for_all',
  CzNotRestricted = 'cz_not_restricted',
  CzOver2 = 'cz_over2',
  CzOver3 = 'cz_over3',
  CzOver4 = 'cz_over4',
  CzOver5 = 'cz_over5',
  CzOver6 = 'cz_over6',
  CzOver7 = 'cz_over7',
  CzOver8 = 'cz_over8',
  CzOver9 = 'cz_over9',
  CzOver10 = 'cz_over10',
  CzOver11 = 'cz_over11',
  CzOver12 = 'cz_over12',
  CzOver13 = 'cz_over13',
  CzOver14 = 'cz_over14',
  CzOver15 = 'cz_over15',
  CzOver16 = 'cz_over16',
  CzOver17 = 'cz_over17',
  CzOver18 = 'cz_over18'
}

export enum ShowCrewRoleCode {
  Actor = 'actor',
  Director = 'director',
  Writer = 'writer',
  Producer = 'producer',
  Author = 'author',
  Direction = 'direction',
  Dramaturgy = 'dramaturgy',
  Script = 'script',
  Music = 'music',
  Costumes = 'costumes',
  Rehearsal = 'rehearsal',
  Choreography = 'choreography',
  DirectorialCollaboration = 'directorial_collaboration',
  Text = 'text',
  SourceMaterial = 'source_material',
  Creator = 'creator',
  CollectiveOfCreators = 'collective_of_creators',
  CreativeTeam = 'creative_team',
  StagingAdaptation = 'staging_adaptation',
  IdeaConcept = 'idea_concept',
  Research = 'research',
  Adaptation = 'adaptation',
  Translation = 'translation',
  RehearsalAssistance = 'rehearsal_assistance',
  Dramatization = 'dramatization',
  DramaturgyAssistance = 'dramaturgy_assistance',
  DirectorialAssistance = 'directorial_assistance',
  Concept = 'concept',
  TechnicalCoordination = 'technical_coordination',
  TechnicalCrew = 'technical_crew',
  ProductionSupervision = 'production_supervision',
  ChoreographyAssistance = 'choreography_assistance',
  MovementCollaboration = 'movement_collaboration',
  RehearsalPianistCoach = 'rehearsal_pianist_coach',
  AdaptationRevision = 'adaptation_revision',
  VerseAdaptation = 'verse_adaptation',
  Lyrics = 'lyrics',
  MusicSelection = 'music_selection',
  OrchestralArrangement = 'orchestral_arrangement',
  MusicalRehearsal = 'musical_rehearsal',
  MusicalArrangements = 'musical_arrangements',
  MusicPostProduction = 'music_post_production',
  Sound = 'sound',
  SoundDesign = 'sound_design',
  Orchestration = 'orchestration',
  MusicArrangement = 'music_arrangement',
  Libretto = 'libretto',
  LibrettoAdaptation = 'libretto_adaptation',
  VocalAssistance = 'vocal_assistance',
  Voice = 'voice',
  VocalPreparation = 'vocal_preparation',
  Singing = 'singing',
  EnsembleSinging = 'ensemble_singing',
  Choir = 'choir',
  Conducting = 'conducting',
  ChoirMaster = 'choir_master',
  Concertmaster = 'concertmaster',
  PianoAccompaniment = 'piano_accompaniment',
  Visuals = 'visuals',
  SetDesign = 'set_design',
  Puppets = 'puppets',
  Scene = 'scene',
  ScenicDesign = 'scenic_design',
  StageDesign = 'stage_design',
  ScenicCollaboration = 'scenic_collaboration',
  SetDecoration = 'set_decoration',
  SetConstruction = 'set_construction',
  Projection = 'projection',
  VideoProduction = 'video_production',
  VideoArt = 'video_art',
  Video = 'video',
  VideoProcessing = 'video_processing',
  VideoDesign = 'video_design',
  Animation = 'animation',
  SpecialEffects = 'special_effects',
  CostumeDesign = 'costume_design',
  CostumeMaking = 'costume_making',
  Wardrobe = 'wardrobe',
  Hairstyles = 'hairstyles',
  Hats = 'hats',
  Masks = 'masks',
  LightingDesign = 'lighting_design',
  Lights = 'lights',
  Props = 'props',
  ArtisticCollaboration = 'artistic_collaboration',
  Graphics = 'graphics',
  Illustration = 'illustration',
  Photography = 'photography',
  Stage = 'stage',
  StageManagement = 'stage_management',
  TechnicalDirection = 'technical_direction',
  LanguageCollaboration = 'language_collaboration',
  LanguageAdaptation = 'language_adaptation',
  EducationalCollaboration = 'educational_collaboration',
  ExpertCollaboration = 'expert_collaboration',
  Interpretation = 'interpretation',
  Subtitles = 'subtitles',
  Camera = 'camera',
  Drone = 'drone',
  Guest = 'guest',
  Soloist = 'soloist',
  TextFollower = 'text_follower',
  Supervision = 'supervision',
  Production = 'production',
  CoProduction = 'co_production',
  InterpretationPerformance = 'interpretation_performance',
  Management = 'management',
  Logistics = 'logistics',
  ProductionManager = 'production_manager',
  LicensingCoordinator = 'licensing_coordinator',
  License = 'license'
}

export enum ShowFormatCode {
  Analog_16mm = 'analog_16mm',
  Analog_35mm = 'analog_35mm',
  Analog_70mm = 'analog_70mm',
  Digital_2d = 'digital_2d',
  Digital_2d_4d = 'digital_2d_4d',
  Digital_2d_4k = 'digital_2d_4k',
  Digital_3d = 'digital_3d',
  Digital_3d_4d = 'digital_3d_4d',
  DigitalBd = 'digital_bd',
  DigitalDvd = 'digital_dvd',
  Digital_2dHfr = 'digital_2d_hfr',
  Digital_3dHfr = 'digital_3d_hfr',
  LiveStream = 'live_stream',
  Record = 'record',
  Live = 'live'
}

export enum ShowGenreCode {
  Family = 'family',
  Kids = 'kids',
  Miscellaneous = 'miscellaneous',
  FineArt = 'fine_art',
  Painting = 'painting',
  Drawing = 'drawing',
  Mosaic = 'mosaic',
  Calligraphy = 'calligraphy',
  Photography = 'photography',
  Architecture = 'architecture',
  Pottery = 'pottery',
  Sculpture = 'sculpture',
  Illusions = 'illusions',
  Multimedia = 'multimedia',
  Nature = 'nature',
  Flora = 'flora',
  Fauna = 'fauna',
  ContemporaryArt = 'contemporary_art',
  Science = 'science',
  Toys = 'toys',
  Prehistory = 'prehistory',
  Antiquity = 'antiquity',
  MiddleAges = 'middle_ages',
  ModernTimes = 'modern_times',
  Fashion = 'fashion',
  StandupComedy = 'standup_comedy',
  FashionShow = 'fashion_show',
  Action = 'action',
  Animation = 'animation',
  Adult = 'adult',
  Adventure = 'adventure',
  Biography = 'biography',
  Crime = 'crime',
  Documentary = 'documentary',
  Fantasy = 'fantasy',
  Filmnoir = 'filmnoir',
  History = 'history',
  Horror = 'horror',
  Musical = 'musical',
  Music = 'music',
  Mystery = 'mystery',
  Romance = 'romance',
  Scify = 'scify',
  Short = 'short',
  Sport = 'sport',
  Talkshow = 'talkshow',
  Thriller = 'thriller',
  War = 'war',
  Western = 'western',
  Disaster = 'disaster',
  Erotic = 'erotic',
  RoadMovie = 'road_movie',
  SilentFilm = 'silent_film',
  Psychological = 'psychological',
  BlackHumor = 'black_humor',
  Parody = 'parody',
  Politics = 'politics',
  Dance = 'dance',
  Comedy = 'comedy',
  Drama = 'drama',
  Tragedy = 'tragedy',
  Melodrama = 'melodrama',
  Satire = 'satire',
  Opera = 'opera',
  Ballet = 'ballet',
  Cabaret = 'cabaret',
  Farce = 'farce',
  Operetta = 'operetta',
  Grotesque = 'grotesque',
  Tragicomedy = 'tragicomedy',
  Experimental = 'experimental',
  Modern = 'modern',
  Variety = 'variety',
  Pantomime = 'pantomime',
  Folklore = 'folklore',
  Puppetry = 'puppetry',
  Circus = 'circus',
  Magic = 'magic',
  Show = 'show',
  Gala = 'gala',
  Ball = 'ball',
  Prom = 'prom',
  TravestyShow = 'travesty_show',
  Tasting = 'tasting',
  Striptease = 'striptease',
  Party = 'party',
  Ceremony = 'ceremony',
  Carnival = 'carnival',
  Discoteque = 'discoteque',
  Decade_20s = 'decade_20s',
  Decade_30s = 'decade_30s',
  Decade_40s = 'decade_40s',
  Decade_50s = 'decade_50s',
  Decade_60s = 'decade_60s',
  Decade_70s = 'decade_70s',
  Decade_80s = 'decade_80s',
  Decade_90s = 'decade_90s',
  BeautyContest = 'beauty_contest',
  Blues = 'blues',
  Country = 'country',
  Disco = 'disco',
  BrassBand = 'brass_band',
  Jazz = 'jazz',
  ElectricBoogie = 'electric_boogie',
  ElectronicMusic = 'electronic_music',
  Techno = 'techno',
  Trance = 'trance',
  Folk = 'folk',
  Funk = 'funk',
  Metal = 'metal',
  Rock = 'rock',
  RockAndRoll = 'rock_and_roll',
  Punk = 'punk',
  Pop = 'pop',
  Rap = 'rap',
  HipHop = 'hip_hop',
  Soul = 'soul',
  Swing = 'swing',
  RhythmAndBlues = 'rhythm_and_blues',
  Reggae = 'reggae',
  House = 'house',
  Instrumental = 'instrumental',
  ClassicalMusic = 'classical_music',
  ChamberMusic = 'chamber_music',
  WorldMusic = 'world_music',
  ACappella = 'a_cappella',
  Alternative = 'alternative',
  ChansonFrancaise = 'chanson_francaise',
  Medieval = 'medieval',
  Renaissance = 'renaissance',
  NewAge = 'new_age',
  Beatbox = 'beatbox',
  Sing = 'sing',
  ChoirSinging = 'choir_singing',
  Religious = 'religious',
  Hockey = 'hockey',
  Football = 'football',
  Volleyball = 'volleyball',
  Basketball = 'basketball',
  Badminton = 'badminton',
  Biathlon = 'biathlon',
  BodyBuilding = 'body_building',
  Boxing = 'boxing',
  Curling = 'curling',
  Cycling = 'cycling',
  Esports = 'esports',
  FieldHockey = 'field_hockey',
  Fitness = 'fitness',
  Floorball = 'floorball',
  Golf = 'golf',
  Gymnastics = 'gymnastics',
  Handball = 'handball',
  IceSkating = 'ice_skating',
  MartialArts = 'martial_arts',
  Racing = 'racing',
  Motosport = 'motosport',
  Skiing = 'skiing',
  SkiJumping = 'ski_jumping',
  Squash = 'squash',
  Swimming = 'swimming',
  TableTennis = 'table_tennis',
  Tennis = 'tennis',
  Athletics = 'athletics',
  Waterpolo = 'waterpolo',
  Food = 'food',
  Streetfood = 'streetfood',
  Gastronomy = 'gastronomy',
  Theatre = 'theatre',
  Film = 'film',
  Wine = 'wine',
  Tradeshow = 'tradeshow',
  Fair = 'fair',
  Drinks = 'drinks',
  Beer = 'beer',
  Literature = 'literature',
  JobFair = 'job_fair',
  Conference = 'conference',
  Business = 'business',
  Finance = 'finance',
  Accounting = 'accounting',
  Communication = 'communication',
  PersonalDevelopment = 'personal_development',
  Marketing = 'marketing',
  Design = 'design',
  Lifestyle = 'lifestyle',
  Yoga = 'yoga',
  Traveling = 'traveling',
  Wellness = 'wellness',
  Spa = 'spa',
  Tourism = 'tourism',
  Relax = 'relax',
  Rafting = 'rafting',
  EscapeRoom = 'escape_room',
  Sightseeing = 'sightseeing',
  Tour = 'tour',
  Flight = 'flight',
  Cruise = 'cruise',
  Educational = 'educational',
  Ride = 'ride',
  Adrenaline = 'adrenaline',
  Paintball = 'paintball',
  Lasertag = 'lasertag',
  Learning = 'learning',
  Massage = 'massage'
}

export enum ShowSoundMixCode {
  Stereo20 = 'stereo20',
  Stereo21 = 'stereo21',
  Surround51 = 'surround51',
  Surround71 = 'surround71',
  DolbyAtmos = 'dolby_atmos'
}

export enum ShowTypeCode {
  Movie = 'movie',
  Theatre = 'theatre',
  Music = 'music',
  Education = 'education',
  Exhibition = 'exhibition',
  Festival = 'festival',
  Entertainment = 'entertainment',
  Sport = 'sport',
  Experience = 'experience'
}

export enum ShowVersionCode {
  Original = 'original',
  DubbingSlk = 'dubbing_slk',
  DubbingCes = 'dubbing_ces',
  DubbingEng = 'dubbing_eng',
  DubbingHun = 'dubbing_hun',
  DubbingDeu = 'dubbing_deu',
  DubbingPol = 'dubbing_pol',
  SubtitlesSlk = 'subtitles_slk',
  SubtitlesCes = 'subtitles_ces',
  SubtitlesEng = 'subtitles_eng',
  SubtitlesHun = 'subtitles_hun',
  SubtitlesDeu = 'subtitles_deu',
  SubtitlesPol = 'subtitles_pol',
  SubtitlesCesEng = 'subtitles_ces_eng',
  SubtitlesCesDeu = 'subtitles_ces_deu',
  SubtitlesSlkEng = 'subtitles_slk_eng',
  SubtitlesSlkDeu = 'subtitles_slk_deu',
  SubtitlesSlkHun = 'subtitles_slk_hun',
  Ukr = 'ukr',
  Rue = 'rue',
  Slk = 'slk',
  Ces = 'ces',
  Eng = 'eng',
  Rus = 'rus',
  Deu = 'deu',
  Hun = 'hun',
  Pol = 'pol'
}

export type ShowDistribution = {
  __typename?: 'ShowDistribution';
  formatCode?: Maybe<ShowFormatCode>;
  soundMixCode?: Maybe<ShowSoundMixCode>;
  versionCode?: Maybe<ShowVersionCode>;
};

export type ShowDistributionInput = {
  formatCode?: Maybe<ShowFormatCode>;
  soundMixCode?: Maybe<ShowSoundMixCode>;
  versionCode?: Maybe<ShowVersionCode>;
};

export type ShowTranslation = {
  __typename?: 'ShowTranslation';
  showId: Scalars['Int'];
  localeCode: LocaleCode;
  title: Scalars['String'];
  tagline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ShowTranslationInput = {
  title: Scalars['String'];
  tagline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  localeCode: LocaleCode;
};

export type ShowCrewPerson = {
  __typename?: 'ShowCrewPerson';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ShowCrewPersonInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ShowCrewByRole = {
  __typename?: 'ShowCrewByRole';
  crewRoleCode: ShowCrewRoleCode;
  persons: Array<ShowCrewPerson>;
};

export type ShowCrewByRoleInput = {
  crewRoleCode: ShowCrewRoleCode;
  persons: Array<ShowCrewPersonInput>;
};

export type PaginationInput = {
  offset?: Maybe<Scalars['NonNegativeInt']>;
  limit?: Maybe<Scalars['PositiveInt']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  hasMore: Scalars['Boolean'];
  offset: Scalars['NonNegativeInt'];
  limit: Scalars['PositiveInt'];
  totalRowsCount: Scalars['NonNegativeInt'];
};

export type ShowsFilterInput = {
  hasText?: Maybe<Scalars['String']>;
  typeCode?: Maybe<ShowTypeCode>;
  genreCode?: Maybe<ShowGenreCode>;
  countryCode?: Maybe<CountryCode>;
  fromGlobalReleaseDate?: Maybe<Scalars['Date']>;
  toGlobalReleaseDate?: Maybe<Scalars['Date']>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
  businessPartnerIds?: Maybe<Array<Scalars['PositiveInt']>>;
  ids?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type LibraryShowInput = {
  originalTitle?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  productionYear?: Maybe<Scalars['Int']>;
  globalReleaseDate?: Maybe<Scalars['Date']>;
  typeCode: ShowTypeCode;
  ageClassificationCodes: Array<ShowAgeClassificationCode>;
  countryCodes: Array<CountryCode>;
  distributions: Array<ShowDistributionInput>;
  genreCodes: Array<ShowGenreCode>;
  languageCodes: Array<LanguageCode>;
  contentDescriptorCodes?: Maybe<Array<ShowContentDescriptorCode>>;
};

export type ClientShowInput = {
  originalTitle?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  productionYear?: Maybe<Scalars['Int']>;
  globalReleaseDate?: Maybe<Scalars['Date']>;
  typeCode: ShowTypeCode;
  ageClassificationCodes: Array<ShowAgeClassificationCode>;
  countryCodes: Array<CountryCode>;
  distributions: Array<ShowDistributionInput>;
  genreCodes: Array<ShowGenreCode>;
  languageCodes: Array<LanguageCode>;
  contentDescriptorCodes?: Maybe<Array<ShowContentDescriptorCode>>;
  videos?: Maybe<Array<AddShowVideoInput>>;
};

export type UpdateClientShowInput = {
  originalTitle?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  productionYear?: Maybe<Scalars['Int']>;
  globalReleaseDate?: Maybe<Scalars['Date']>;
  typeCode: ShowTypeCode;
  ageClassificationCodes: Array<ShowAgeClassificationCode>;
  countryCodes: Array<CountryCode>;
  distributions: Array<ShowDistributionInput>;
  genreCodes: Array<ShowGenreCode>;
  languageCodes: Array<LanguageCode>;
  contentDescriptorCodes?: Maybe<Array<ShowContentDescriptorCode>>;
  videos?: Maybe<Array<AddShowVideoInput>>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
};

export type LibraryShow = {
  __typename?: 'LibraryShow';
  id: Scalars['Int'];
  originalTitle?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  productionYear?: Maybe<Scalars['Int']>;
  globalReleaseDate?: Maybe<Scalars['Date']>;
  typeCode: ShowTypeCode;
  ageClassificationCodes: Array<ShowAgeClassificationCode>;
  countryCodes: Array<CountryCode>;
  distributions: Array<ShowDistribution>;
  genreCodes: Array<ShowGenreCode>;
  languageCodes: Array<LanguageCode>;
  contentDescriptorCodes?: Maybe<Array<ShowContentDescriptorCode>>;
  crew: Array<ShowCrewByRole>;
  translations: Array<ShowTranslation>;
  image?: Maybe<LibraryShowImage>;
  /** If type is omitted, it returns all images, otherwise it will return images with the specific type */
  images: Array<LibraryShowImage>;
  videos: Array<ShowVideo>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type LibraryShowImageArgs = {
  id: Scalars['Int'];
};


export type LibraryShowImagesArgs = {
  type?: Maybe<Array<ShowImageType>>;
};

export type LibraryShows = {
  __typename?: 'LibraryShows';
  items: Array<LibraryShow>;
  pagination: Pagination;
};

export enum ShowImageType {
  Poster = 'poster',
  Banner = 'banner',
  Photo = 'photo',
  Cover = 'cover',
  Backdrop = 'backdrop'
}

export enum ShowImageThumbnailSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export enum ThumbnailType {
  Poster = 'poster',
  Photo = 'photo'
}

export type ShowImageThumbnail = {
  __typename?: 'ShowImageThumbnail';
  id: Scalars['Int'];
  size: ShowImageThumbnailSize;
  key: Scalars['String'];
  type?: Maybe<ThumbnailType>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export enum LibraryMediaState {
  Active = 'active',
  Deleted = 'deleted'
}

export type LibraryShowImage = {
  __typename?: 'LibraryShowImage';
  id: Scalars['Int'];
  type: ShowImageType;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
  url: Scalars['String'];
  hash: Scalars['String'];
  /** clientId is used to determine whether image is private. If so, clientId is filled, otherwise it's null. */
  clientId?: Maybe<Scalars['Int']>;
  state: LibraryMediaState;
  thumbnail?: Maybe<ShowImageThumbnail>;
  thumbnails: Array<ShowImageThumbnail>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Refers to User ID */
  createdBy: Scalars['String'];
  /** Refers to User ID */
  updatedBy: Scalars['String'];
};


export type LibraryShowImageThumbnailArgs = {
  id: Scalars['Int'];
};


export type LibraryShowImageThumbnailsArgs = {
  size?: Maybe<Array<ShowImageThumbnailSize>>;
};

/** @deprecated - please use ErrorCodes2 */
export enum ErrorCodes {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotImplemented = 'NOT_IMPLEMENTED',
  MissingShowTypeValidations = 'MISSING_SHOW_TYPE_VALIDATIONS',
  InvalidAgeClassificationCodes = 'INVALID_AGE_CLASSIFICATION_CODES',
  InvalidGenreCodes = 'INVALID_GENRE_CODES',
  InvalidShowFormatCode = 'INVALID_SHOW_FORMAT_CODE',
  InvalidSoundMixCode = 'INVALID_SOUND_MIX_CODE',
  InvalidVersionCode = 'INVALID_VERSION_CODE',
  EmptyDistribution = 'EMPTY_DISTRIBUTION',
  MissingEffectiveClient = 'MISSING_EFFECTIVE_CLIENT',
  ClientAlreadyHasCustomVersionOfShow = 'CLIENT_ALREADY_HAS_CUSTOM_VERSION_OF_SHOW',
  InvalidIban = 'INVALID_IBAN',
  InvalidSwift = 'INVALID_SWIFT',
  BadUserInput = 'BAD_USER_INPUT'
}

export enum ErrorMessages {
  NotAuthenticated = 'NOT_AUTHENTICATED',
  MissingPermission = 'MISSING_PERMISSION',
  MissingEffectiveClient = 'MISSING_EFFECTIVE_CLIENT',
  UnwantedEffectiveClient = 'UNWANTED_EFFECTIVE_CLIENT',
  NotImplemented = 'NOT_IMPLEMENTED',
  NotUniqueEventPricingStartsAt = 'NOT_UNIQUE_EVENT_PRICING_STARTS_AT',
  CantModifyLastEventPricingFromThePast = 'CANT_MODIFY_LAST_EVENT_PRICING_FROM_THE_PAST',
  EventPricingStartsAtInThePast = 'EVENT_PRICING_STARTS_AT_IN_THE_PAST',
  AtLeastOneSellingChannelRequired = 'AT_LEAST_ONE_SELLING_CHANNEL_REQUIRED',
  AtLeastOneDivisionIdRequired = 'AT_LEAST_ONE_DIVISION_ID_REQUIRED',
  OnlyActiveDivisionsOfClientAreAllowed = 'ONLY_ACTIVE_DIVISIONS_OF_CLIENT_ARE_ALLOWED',
  UnableToUpdateDeletedDiscount = 'UNABLE_TO_UPDATE_DELETED_DISCOUNT',
  InvalidDiscountId = 'INVALID_DISCOUNT_ID',
  InvalidConditionValue = 'INVALID_CONDITION_VALUE',
  InvalidConditionOperator = 'INVALID_CONDITION_OPERATOR',
  ConditionRuleDoesntMatchApplicationRuleType = 'CONDITION_RULE_DOESNT_MATCH_APPLICATION_RULE_TYPE',
  InvalidApplicationRuleId = 'INVALID_APPLICATION_RULE_ID',
  EventPricingNotFound = 'EVENT_PRICING_NOT_FOUND',
  DiscountCodeIsNotUnique = 'DISCOUNT_CODE_IS_NOT_UNIQUE',
  ActivationDateIsInThePast = 'ACTIVATION_DATE_IS_IN_THE_PAST',
  InvalidActivationDate = 'INVALID_ACTIVATION_DATE',
  ExpirationDateIsInThePast = 'EXPIRATION_DATE_IS_IN_THE_PAST',
  InvalidExpirationDate = 'INVALID_EXPIRATION_DATE',
  ActivationDateIsAfterExpirationDate = 'ACTIVATION_DATE_IS_AFTER_EXPIRATION_DATE',
  InvalidDiscountCodeName = 'INVALID_DISCOUNT_CODE_NAME',
  InvalidDiscountCodeId = 'INVALID_DISCOUNT_CODE_ID',
  InvalidCartState = 'INVALID_CART_STATE',
  EventSeatNotAvailable = 'EVENT_SEAT_NOT_AVAILABLE',
  NotEnoughZoneSeatsInSuitableState = 'NOT_ENOUGH_ZONE_SEATS_IN_SUITABLE_STATE',
  MaximumCountExceeded = 'MAXIMUM_COUNT_EXCEEDED',
  PaymentMethodGroupInUseCantBeDeleted = 'PAYMENT_METHOD_GROUP_IN_USE_CANT_BE_DELETED',
  InvalidPaymentMethodGroupId = 'INVALID_PAYMENT_METHOD_GROUP_ID',
  InvalidCartSellingChannel = 'INVALID_CART_SELLING_CHANNEL',
  InvalidCountry = 'INVALID_COUNTRY',
  InvalidMailingAddress = 'INVALID_MAILING_ADDRESS',
  InvalidInvoiceAddress = 'INVALID_INVOICE_ADDRESS',
  InvalidEventId = 'INVALID_EVENT_ID',
  InvalidReservationEndWebDuration = 'INVALID_RESERVATION_END_WEB_DURATION',
  InvalidReservationEndCashdeskDuration = 'INVALID_RESERVATION_END_CASHDESK_DURATION',
  EventHasNoValidCheckoutOptions = 'EVENT_HAS_NO_VALID_CHECKOUT_OPTIONS',
  PaymentIntentHasInvalidPaymentMethod = 'PAYMENT_INTENT_HAS_INVALID_PAYMENT_METHOD',
  InvalidPaymentIntentAmountValue = 'INVALID_PAYMENT_INTENT_AMOUNT_VALUE',
  InvalidPaymentIntentsTotalPrice = 'INVALID_PAYMENT_INTENTS_TOTAL_PRICE',
  SplitItemsDontBelongToReservation = 'SPLIT_ITEMS_DONT_BELONG_TO_RESERVATION',
  SplitContainsAllReservationItems = 'SPLIT_CONTAINS_ALL_RESERVATION_ITEMS',
  InvalidEventState = 'INVALID_EVENT_STATE',
  UnsuitableCartCheckoutOptions = 'UNSUITABLE_CART_CHECKOUT_OPTIONS',
  MissingPaymentServiceProviderConfig = 'MISSING_PAYMENT_SERVICE_PROVIDER_CONFIG',
  MissingGopayPaymentServiceProviderConfig = 'MISSING_GOPAY_PAYMENT_SERVICE_PROVIDER_CONFIG',
  InvalidGopayCredentials = 'INVALID_GOPAY_CREDENTIALS',
  CartExpired = 'CART_EXPIRED',
  ItemsBelongToMultipleEvents = 'ITEMS_BELONG_TO_MULTIPLE_EVENTS',
  ItemPricingDisablesDiscount = 'ITEM_PRICING_DISABLES_DISCOUNT',
  DiscountNotFound = 'DISCOUNT_NOT_FOUND',
  ApplicationRuleNotFound = 'APPLICATION_RULE_NOT_FOUND',
  EventDoesntComplyWithDiscountRules = 'EVENT_DOESNT_COMPLY_WITH_DISCOUNT_RULES',
  DiscountCodeNotFound = 'DISCOUNT_CODE_NOT_FOUND',
  DiscountCodeHasExceededUsageLimit = 'DISCOUNT_CODE_HAS_EXCEEDED_USAGE_LIMIT',
  DiscountCodeUsageLimitPerOrderExceeded = 'DISCOUNT_CODE_USAGE_LIMIT_PER_ORDER_EXCEEDED',
  DiscountNotAllowedForSellingChannel = 'DISCOUNT_NOT_ALLOWED_FOR_SELLING_CHANNEL',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  ItemBelongsToReservation = 'ITEM_BELONGS_TO_RESERVATION',
  ReservationIsBeingProcessed = 'RESERVATION_IS_BEING_PROCESSED',
  ReservationHasBeenAlreadyPaid = 'RESERVATION_HAS_BEEN_ALREADY_PAID',
  ReservationExpired = 'RESERVATION_EXPIRED',
  ReservationDeleted = 'RESERVATION_DELETED',
  ReservationIsNotActive = 'RESERVATION_IS_NOT_ACTIVE',
  InvalidReservationState = 'INVALID_RESERVATION_STATE',
  PaymentMethodNotFound = 'PAYMENT_METHOD_NOT_FOUND',
  CartNotFound = 'CART_NOT_FOUND',
  EventNotFound = 'EVENT_NOT_FOUND',
  EventSeatNotFound = 'EVENT_SEAT_NOT_FOUND',
  ActivePricingNotFound = 'ACTIVE_PRICING_NOT_FOUND',
  MissingCartId = 'MISSING_CART_ID',
  DiscountForItemNotFound = 'DISCOUNT_FOR_ITEM_NOT_FOUND',
  NotEnoughItems = 'NOT_ENOUGH_ITEMS',
  CartAndEventDoNotBelongToSameClient = 'CART_AND_EVENT_DO_NOT_BELONG_TO_SAME_CLIENT',
  InvalidPriceValue = 'INVALID_PRICE_VALUE',
  DiscountCodeIsInactive = 'DISCOUNT_CODE_IS_INACTIVE',
  DiscountCodeIsInvalid = 'DISCOUNT_CODE_IS_INVALID',
  ItemIsAlreadyDiscounted = 'ITEM_IS_ALREADY_DISCOUNTED',
  TicketItemNotFound = 'TICKET_ITEM_NOT_FOUND',
  ReservationNotFound = 'RESERVATION_NOT_FOUND',
  ReservationIsInvalid = 'RESERVATION_IS_INVALID',
  InvalidEventSeatStateTransition = 'INVALID_EVENT_SEAT_STATE_TRANSITION',
  InvalidApiSeatState = 'INVALID_API_SEAT_STATE',
  ClaimNotFound = 'CLAIM_NOT_FOUND',
  DivisionNotFound = 'DIVISION_NOT_FOUND',
  MissingItemIds = 'MISSING_ITEM_IDS',
  ItemAlreadyBelongsToClaim = 'ITEM_ALREADY_BELONGS_TO_CLAIM',
  ItemDoesNotBelongToSale = 'ITEM_DOES_NOT_BELONG_TO_SALE',
  ItemsDoNotBelongToSameSale = 'ITEMS_DO_NOT_BELONG_TO_SAME_SALE',
  ItemsHaveDifferentTypes = 'ITEMS_HAVE_DIFFERENT_TYPES',
  ReasonCanNotBeEmpty = 'REASON_CAN_NOT_BE_EMPTY',
  ItemsDoNotBelongToClient = 'ITEMS_DO_NOT_BELONG_TO_CLIENT',
  ItemNotFound = 'ITEM_NOT_FOUND',
  ItemNotFoundDueToFilterEventIds = 'ITEM_NOT_FOUND_DUE_TO_FILTER_EVENT_IDS',
  ItemNotFoundDueToFilterTourIds = 'ITEM_NOT_FOUND_DUE_TO_FILTER_TOUR_IDS',
  ItemNotFoundDueToFilterTourTimeSlotIds = 'ITEM_NOT_FOUND_DUE_TO_FILTER_TOUR_TIME_SLOT_IDS',
  ItemNotFoundDueToFilterVenueIds = 'ITEM_NOT_FOUND_DUE_TO_FILTER_VENUE_IDS',
  ItemNotFoundDueToFilterArguments = 'ITEM_NOT_FOUND_DUE_TO_FILTER_ARGUMENTS',
  InvalidClaimState = 'INVALID_CLAIM_STATE',
  SaleNotFound = 'SALE_NOT_FOUND',
  DiscountCodeUsageLimitWasReached = 'DISCOUNT_CODE_USAGE_LIMIT_WAS_REACHED',
  DiscountCodeExpired = 'DISCOUNT_CODE_EXPIRED',
  InvalidClaimStateTransition = 'INVALID_CLAIM_STATE_TRANSITION',
  ClaimHasNoItems = 'CLAIM_HAS_NO_ITEMS',
  LeadDataInputIsEmpty = 'LEAD_DATA_INPUT_IS_EMPTY',
  LeadDataInputRequiredFieldIsMissing = 'LEAD_DATA_INPUT_REQUIRED_FIELD_IS_MISSING',
  LeadDataInputUnavailableFieldIsPresent = 'LEAD_DATA_INPUT_UNAVAILABLE_FIELD_IS_PRESENT',
  LeadNotFound = 'LEAD_NOT_FOUND',
  MaxTicketsPerOrderExceeded = 'MAX_TICKETS_PER_ORDER_EXCEEDED',
  MaxTicketsPerOrderExceededDueSeatsAddedWithGroup = 'MAX_TICKETS_PER_ORDER_EXCEEDED_DUE_SEATS_ADDED_WITH_GROUP',
  PaymentIntentNotFound = 'PAYMENT_INTENT_NOT_FOUND',
  PaymentServiceProviderConfigNotFound = 'PAYMENT_SERVICE_PROVIDER_CONFIG_NOT_FOUND',
  UserNotFound = 'USER_NOT_FOUND',
  ClientNotFound = 'CLIENT_NOT_FOUND',
  PassCodeNotFound = 'PASS_CODE_NOT_FOUND',
  EventNotPublished = 'EVENT_NOT_PUBLISHED',
  CantDeleteUsedRole = 'CANT_DELETE_USED_ROLE',
  CantDeleteLastRoleOfAdminType = 'CANT_DELETE_LAST_ROLE_OF_ADMIN_TYPE',
  RoleNotFound = 'ROLE_NOT_FOUND',
  CantCreateRoleForThisClient = 'CANT_CREATE_ROLE_FOR_THIS_CLIENT',
  InvalidRoleType = 'INVALID_ROLE_TYPE',
  EitherCodeOrIdIsRequired = 'EITHER_CODE_OR_ID_IS_REQUIRED',
  CantUpdateRoleForThisClient = 'CANT_UPDATE_ROLE_FOR_THIS_CLIENT',
  CantUpdateClientOnUsedRole = 'CANT_UPDATE_CLIENT_ON_USED_ROLE',
  RoleIsDeprecated = 'ROLE_IS_DEPRECATED',
  TermsOfServiceUrlIsTooLong = 'TERMS_OF_SERVICE_URL_IS_TOO_LONG',
  MarketingInformationUrlIsTooLong = 'MARKETING_INFORMATION_URL_IS_TOO_LONG',
  InvalidTermsOfServiceUrl = 'INVALID_TERMS_OF_SERVICE_URL',
  InvalidMarketingInformationUrl = 'INVALID_MARKETING_INFORMATION_URL',
  RequestMadeUnauthorizedUser = 'REQUEST_MADE_UNAUTHORIZED_USER',
  InvalidPassCodeCheckState = 'INVALID_PASS_CODE_CHECK_STATE',
  InvalidTicketItemCheckStatus = 'INVALID_TICKET_ITEM_CHECK_STATUS',
  SendingEmailFailed = 'SENDING_EMAIL_FAILED',
  InvalidUserState = 'INVALID_USER_STATE',
  FailedToGenerateAnonymizedUsername = 'FAILED_TO_GENERATE_ANONYMIZED_USERNAME',
  ReservationsDoNotBelongToSameCart = 'RESERVATIONS_DO_NOT_BELONG_TO_SAME_CART',
  DeliverToCannotBeEmpty = 'DELIVER_TO_CANNOT_BE_EMPTY',
  InvalidEmailAddress = 'INVALID_EMAIL_ADDRESS',
  ReservationIdsCannotBeEmptyArray = 'RESERVATION_IDS_CANNOT_BE_EMPTY_ARRAY',
  VenueNotFound = 'VENUE_NOT_FOUND',
  SalesDoNotBelongToSameCart = 'SALES_DO_NOT_BELONG_TO_SAME_CART',
  SaleInputsCannotBeEmpty = 'SALE_INPUTS_CANNOT_BE_EMPTY',
  AuditoriumNotFound = 'AUDITORIUM_NOT_FOUND',
  AuditoriumLayoutNotFound = 'AUDITORIUM_LAYOUT_NOT_FOUND',
  AuditoriumLayoutDefaultSeatsStatesInvalid = 'AUDITORIUM_LAYOUT_DEFAULT_SEATS_STATES_INVALID',
  InvalidVatRate = 'INVALID_VAT_RATE',
  VatRatesNotFound = 'VAT_RATES_NOT_FOUND',
  RefundIntentInputsAreEmpty = 'REFUND_INTENT_INPUTS_ARE_EMPTY',
  RefundIntentInputHasInvalidPaymentMethod = 'REFUND_INTENT_INPUT_HAS_INVALID_PAYMENT_METHOD',
  PaymentMethodCannotBeUsedForRefunds = 'PAYMENT_METHOD_CANNOT_BE_USED_FOR_REFUNDS',
  InvalidRefundIntentInputAmountValue = 'INVALID_REFUND_INTENT_INPUT_AMOUNT_VALUE',
  InvalidRefundIntentInputsTotalPrice = 'INVALID_REFUND_INTENT_INPUTS_TOTAL_PRICE',
  RefundIntentInputDenominationDoesNotEqualPaymentMethodValue = 'REFUND_INTENT_INPUT_DENOMINATION_DOES_NOT_EQUAL_PAYMENT_METHOD_VALUE',
  PaymentIntentInputDenominationDoesNotEqualPaymentMethodValue = 'PAYMENT_INTENT_INPUT_DENOMINATION_DOES_NOT_EQUAL_PAYMENT_METHOD_VALUE',
  MissingPaymentIntentInputDenomination = 'MISSING_PAYMENT_INTENT_INPUT_DENOMINATION',
  MissingPaymentIntentInputCount = 'MISSING_PAYMENT_INTENT_INPUT_COUNT',
  MissingRefundIntentInputDenomination = 'MISSING_REFUND_INTENT_INPUT_DENOMINATION',
  MissingRefundIntentInputCount = 'MISSING_REFUND_INTENT_INPUT_COUNT',
  ShowNotFound = 'SHOW_NOT_FOUND',
  InvalidCrewRoleCodes = 'INVALID_CREW_ROLE_CODES',
  TemplateNotFound = 'TEMPLATE_NOT_FOUND',
  InvalidTemplateTypeInput = 'INVALID_TEMPLATE_TYPE_INPUT',
  TemplateVersionNotFound = 'TEMPLATE_VERSION_NOT_FOUND',
  TemplateAlreadyAssigned = 'TEMPLATE_ALREADY_ASSIGNED',
  TemplateAssignmentNotFound = 'TEMPLATE_ASSIGNMENT_NOT_FOUND',
  UnknownGopayOperationResult = 'UNKNOWN_GOPAY_OPERATION_RESULT',
  AuditoriumLayoutPricingNotFound = 'AUDITORIUM_LAYOUT_PRICING_NOT_FOUND',
  InvalidAuditoriumLayoutPricingInput = 'INVALID_AUDITORIUM_LAYOUT_PRICING_INPUT',
  InvalidEventSaleAndReservationInput = 'INVALID_EVENT_SALE_AND_RESERVATION_INPUT',
  MaxNumberOfOccupiedSeatsHasBeenReached = 'MAX_NUMBER_OF_OCCUPIED_SEATS_HAS_BEEN_REACHED',
  EventAlreadyDeleted = 'EVENT_ALREADY_DELETED',
  CantDeleteEventWithItems = 'CANT_DELETE_EVENT_WITH_ITEMS',
  ImageNotFound = 'IMAGE_NOT_FOUND',
  PoorImageQuality = 'POOR_IMAGE_QUALITY',
  InvalidImagePosition = 'INVALID_IMAGE_POSITION',
  TooManyPaymentMethodsUsed = 'TOO_MANY_PAYMENT_METHODS_USED',
  MissingPaymentIntentInputs = 'MISSING_PAYMENT_INTENT_INPUTS',
  InvalidProductTypeId = 'INVALID_PRODUCT_TYPE_ID',
  ProductTypeNotFound = 'PRODUCT_TYPE_NOT_FOUND',
  InputIsEmpty = 'INPUT_IS_EMPTY',
  InvalidProductAvailabilityInputs = 'INVALID_PRODUCT_AVAILABILITY_INPUTS',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  DuplicateProductBarcode = 'DUPLICATE_PRODUCT_BARCODE',
  PriceLookupCodeAlreadyExists = 'PRICE_LOOKUP_CODE_ALREADY_EXISTS',
  InvalidProductPricingInput = 'INVALID_PRODUCT_PRICING_INPUT',
  ProductBarcodeNotFound = 'PRODUCT_BARCODE_NOT_FOUND',
  ProductIsNotAvailable = 'PRODUCT_IS_NOT_AVAILABLE',
  ProductHasNoActivePricing = 'PRODUCT_HAS_NO_ACTIVE_PRICING',
  InvalidDivisionState = 'INVALID_DIVISION_STATE',
  MessageNotFound = 'MESSAGE_NOT_FOUND',
  InvalidReplyTo = 'INVALID_REPLY_TO',
  LeadIdsArrayIsEmpty = 'LEAD_IDS_ARRAY_IS_EMPTY',
  LeadsNotFound = 'LEADS_NOT_FOUND',
  LeadsDontHaveValidContactData = 'LEADS_DONT_HAVE_VALID_CONTACT_DATA',
  MissingSubject = 'MISSING_SUBJECT',
  VoucherCampaignNotFound = 'VOUCHER_CAMPAIGN_NOT_FOUND',
  MaximumNumberOfVouchersCountHasBeenReached = 'MAXIMUM_NUMBER_OF_VOUCHERS_COUNT_HAS_BEEN_REACHED',
  ForbiddenToCreateVouchersForCampaign = 'FORBIDDEN_TO_CREATE_VOUCHERS_FOR_CAMPAIGN',
  UnexpectedVoucherInitialBalance = 'UNEXPECTED_VOUCHER_INITIAL_BALANCE',
  InvalidVoucherActivationDate = 'INVALID_VOUCHER_ACTIVATION_DATE',
  InvalidVoucherCredentials = 'INVALID_VOUCHER_CREDENTIALS',
  InvalidVoucherExpirationDate = 'INVALID_VOUCHER_EXPIRATION_DATE',
  InvalidLeadsFilterInput = 'INVALID_LEADS_FILTER_INPUT',
  VoucherNotFound = 'VOUCHER_NOT_FOUND',
  RetailPaymentMethodNotFound = 'RETAIL_PAYMENT_METHOD_NOT_FOUND',
  EcommercePaymentMethodNotFound = 'ECOMMERCE_PAYMENT_METHOD_NOT_FOUND',
  InvalidStatnaPokladnicaCredentials = 'INVALID_STATNA_POKLADNICA_CREDENTIALS',
  InvalidVoucherState = 'INVALID_VOUCHER_STATE',
  InvalidPaymentServiceProviderConfigInput = 'INVALID_PAYMENT_SERVICE_PROVIDER_CONFIG_INPUT',
  InvalidVoucherCampaignState = 'INVALID_VOUCHER_CAMPAIGN_STATE',
  InvalidCustomerPassword = 'INVALID_CUSTOMER_PASSWORD',
  CustomerAlreadyExists = 'CUSTOMER_ALREADY_EXISTS',
  MissingVoucherId = 'MISSING_VOUCHER_ID',
  VoucherHasInsufficientFunds = 'VOUCHER_HAS_INSUFFICIENT_FUNDS',
  CustomerNotFound = 'CUSTOMER_NOT_FOUND',
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidToken = 'INVALID_TOKEN',
  MissingVoucherPinCode = 'MISSING_VOUCHER_PIN_CODE',
  DuplicateVouchers = 'DUPLICATE_VOUCHERS',
  CustomerGroupDoNotBelongToClient = 'CUSTOMER_GROUP_DO_NOT_BELONG_TO_CLIENT',
  CustomerGroupNotFound = 'CUSTOMER_GROUP_NOT_FOUND',
  CantDeleteCustomerGroupWithCustomers = 'CANT_DELETE_CUSTOMER_GROUP_WITH_CUSTOMERS',
  ProductPricingNotFound = 'PRODUCT_PRICING_NOT_FOUND',
  UnableToDeleteEndedProductPricing = 'UNABLE_TO_DELETE_ENDED_PRODUCT_PRICING',
  UnableToDeleteActiveProductPricing = 'UNABLE_TO_DELETE_ACTIVE_PRODUCT_PRICING',
  AdmissionTypeNotFound = 'ADMISSION_TYPE_NOT_FOUND',
  TourNotFound = 'TOUR_NOT_FOUND',
  InvalidAdmissionTypeState = 'INVALID_ADMISSION_TYPE_STATE',
  InvalidTourState = 'INVALID_TOUR_STATE',
  TourTimeSlotNotFound = 'TOUR_TIME_SLOT_NOT_FOUND',
  TourTimeSlotInvalidState = 'TOUR_TIME_SLOT_INVALID_STATE',
  EcommerceOrderAttendeesLimitPerTourTimeSlotExceeded = 'ECOMMERCE_ORDER_ATTENDEES_LIMIT_PER_TOUR_TIME_SLOT_EXCEEDED',
  EcommerceAttendeesLimitPerTourTimeSlotExceeded = 'ECOMMERCE_ATTENDEES_LIMIT_PER_TOUR_TIME_SLOT_EXCEEDED',
  RetailAttendeesLimitPerTourTimeSlotExceeded = 'RETAIL_ATTENDEES_LIMIT_PER_TOUR_TIME_SLOT_EXCEEDED',
  AdmissionTypeAssignmentLimitPerTimeSlotExceeded = 'ADMISSION_TYPE_ASSIGNMENT_LIMIT_PER_TIME_SLOT_EXCEEDED',
  PaymentInputHasInvalidPaymentMethod = 'PAYMENT_INPUT_HAS_INVALID_PAYMENT_METHOD',
  MissingPaymentInputDenomination = 'MISSING_PAYMENT_INPUT_DENOMINATION',
  MissingPaymentInputCount = 'MISSING_PAYMENT_INPUT_COUNT',
  PaymentInputDenominationDoesNotEqualPaymentMethodValue = 'PAYMENT_INPUT_DENOMINATION_DOES_NOT_EQUAL_PAYMENT_METHOD_VALUE',
  InvalidPaymentInputAmountValue = 'INVALID_PAYMENT_INPUT_AMOUNT_VALUE',
  MissingPaymentInputs = 'MISSING_PAYMENT_INPUTS',
  AdmissionRateNotFound = 'ADMISSION_RATE_NOT_FOUND',
  InvalidAdmissionRateState = 'INVALID_ADMISSION_RATE_STATE',
  DuplicateAdmissionTypeIds = 'DUPLICATE_ADMISSION_TYPE_IDS',
  AdmissionRateAssignmentNotFound = 'ADMISSION_RATE_ASSIGNMENT_NOT_FOUND',
  DuplicateAdmissionRateAssignmentIds = 'DUPLICATE_ADMISSION_RATE_ASSIGNMENT_IDS',
  AdmissionRateAssignmentAlreadyExists = 'ADMISSION_RATE_ASSIGNMENT_ALREADY_EXISTS',
  AdmissionAssignmentNotFound = 'ADMISSION_ASSIGNMENT_NOT_FOUND',
  NoPaymentsFoundToBeIncludedInReport = 'NO_PAYMENTS_FOUND_TO_BE_INCLUDED_IN_REPORT',
  PaymentReportNotFound = 'PAYMENT_REPORT_NOT_FOUND',
  MissingStatnaPokladnicaPaymentServiceProviderConfig = 'MISSING_STATNA_POKLADNICA_PAYMENT_SERVICE_PROVIDER_CONFIG',
  InvalidPaymentServiceProviderConfig = 'INVALID_PAYMENT_SERVICE_PROVIDER_CONFIG',
  UnknownStatnaPokladnicaPaymentResult = 'UNKNOWN_STATNA_POKLADNICA_PAYMENT_RESULT',
  AdmissionRateHasNoTypeAssignments = 'ADMISSION_RATE_HAS_NO_TYPE_ASSIGNMENTS',
  InvalidTourTimeSlotSaleDataInput = 'INVALID_TOUR_TIME_SLOT_SALE_DATA_INPUT',
  TourTimeSlotHasNoValidCheckoutOptions = 'TOUR_TIME_SLOT_HAS_NO_VALID_CHECKOUT_OPTIONS',
  IncrementLowerThanAdmissionTypeStartingQuantity = 'INCREMENT_LOWER_THAN_ADMISSION_TYPE_STARTING_QUANTITY',
  InvalidTourTimeSlotState = 'INVALID_TOUR_TIME_SLOT_STATE',
  InvalidTourItemStateTransition = 'INVALID_TOUR_ITEM_STATE_TRANSITION',
  NumberOfRemainingTourItemsForGivenSlotIsLowerThanStartingQuantity = 'NUMBER_OF_REMAINING_TOUR_ITEMS_FOR_GIVEN_SLOT_IS_LOWER_THAN_STARTING_QUANTITY',
  AllowedWeekdaysAreEmpty = 'ALLOWED_WEEKDAYS_ARE_EMPTY',
  DestinationDateToIsBeforeDestinationDateFrom = 'DESTINATION_DATE_TO_IS_BEFORE_DESTINATION_DATE_FROM',
  DestinationDateFromIsBeforeSourceDate = 'DESTINATION_DATE_FROM_IS_BEFORE_SOURCE_DATE',
  DestinationDateFromIsInThePast = 'DESTINATION_DATE_FROM_IS_IN_THE_PAST',
  ReservationHasNoItems = 'RESERVATION_HAS_NO_ITEMS',
  CreditVoucherReasonIsInvalid = 'CREDIT_VOUCHER_REASON_IS_INVALID',
  VoucherCampaignRechargeLimitReached = 'VOUCHER_CAMPAIGN_RECHARGE_LIMIT_REACHED',
  VoucherMaxTopUpsCountReached = 'VOUCHER_MAX_TOP_UPS_COUNT_REACHED',
  EmptyGrantedResources = 'EMPTY_GRANTED_RESOURCES',
  ApiGrantNotFound = 'API_GRANT_NOT_FOUND',
  MonthIsTheCurrentMonth = 'MONTH_IS_THE_CURRENT_MONTH',
  MonthIsInTheFuture = 'MONTH_IS_IN_THE_FUTURE',
  YearIsInTheFuture = 'YEAR_IS_IN_THE_FUTURE',
  UsernameAlreadyTaken = 'USERNAME_ALREADY_TAKEN',
  WarehouseNotFound = 'WAREHOUSE_NOT_FOUND',
  ProductGroupNotFound = 'PRODUCT_GROUP_NOT_FOUND',
  InvalidProductState = 'INVALID_PRODUCT_STATE',
  ProductAlreadyAssignedToProductGroup = 'PRODUCT_ALREADY_ASSIGNED_TO_PRODUCT_GROUP',
  ProductOrderAlreadyTakenInProductGroup = 'PRODUCT_ORDER_ALREADY_TAKEN_IN_PRODUCT_GROUP',
  InvalidProductGroupAvailabilityInputs = 'INVALID_PRODUCT_GROUP_AVAILABILITY_INPUTS',
  MissingProductIds = 'MISSING_PRODUCT_IDS',
  DuplicateProductIds = 'DUPLICATE_PRODUCT_IDS',
  InvalidProductIds = 'INVALID_PRODUCT_IDS',
  MissingProductGroupIds = 'MISSING_PRODUCT_GROUP_IDS',
  DuplicateProductGroupIds = 'DUPLICATE_PRODUCT_GROUP_IDS',
  InvalidProductGroupIds = 'INVALID_PRODUCT_GROUP_IDS',
  WarehouseDocumentNotFound = 'WAREHOUSE_DOCUMENT_NOT_FOUND',
  ImageUploadFailed = 'IMAGE_UPLOAD_FAILED',
  InvalidImageDimensions = 'INVALID_IMAGE_DIMENSIONS',
  ShowVideoAlreadyExists = 'SHOW_VIDEO_ALREADY_EXISTS',
  BusinessPartnerNotFound = 'BUSINESS_PARTNER_NOT_FOUND',
  CompanyNameCannotBeNull = 'COMPANY_NAME_CANNOT_BE_NULL',
  TypesCannotBeEmptyOrNull = 'TYPES_CANNOT_BE_EMPTY_OR_NULL',
  StateCannotBeNull = 'STATE_CANNOT_BE_NULL',
  ProductAlreadyExistsInWarehouse = 'PRODUCT_ALREADY_EXISTS_IN_WAREHOUSE',
  WarehouseProductNotFound = 'WAREHOUSE_PRODUCT_NOT_FOUND',
  InvalidWarehouseDocumentState = 'INVALID_WAREHOUSE_DOCUMENT_STATE',
  WarehouseDocumentItemNotFound = 'WAREHOUSE_DOCUMENT_ITEM_NOT_FOUND',
  LoyaltyIdAlreadyTaken = 'LOYALTY_ID_ALREADY_TAKEN',
  CustomerLoyaltyScoreNotFound = 'CUSTOMER_LOYALTY_SCORE_NOT_FOUND',
  MarketingLabelNotFound = 'MARKETING_LABEL_NOT_FOUND',
  EventCategoryNotFound = 'EVENT_CATEGORY_NOT_FOUND',
  CostCenterNotFound = 'COST_CENTER_NOT_FOUND',
  ReportDateTimeToIsBeforeReportDateTimeFrom = 'REPORT_DATE_TIME_TO_IS_BEFORE_REPORT_DATE_TIME_FROM',
  ReportDateTimeFromIsInTheFuture = 'REPORT_DATE_TIME_FROM_IS_IN_THE_FUTURE',
  CashierNotFound = 'CASHIER_NOT_FOUND',
  RequestToGitlabFailed = 'REQUEST_TO_GITLAB_FAILED',
  GitlabTokenIsInvalid = 'GITLAB_TOKEN_IS_INVALID',
  WebsiteNotFound = 'WEBSITE_NOT_FOUND',
  SlugIsAlreadyUsed = 'SLUG_IS_ALREADY_USED',
  WebsitePageNotFound = 'WEBSITE_PAGE_NOT_FOUND',
  LocaleCodesMismatch = 'LOCALE_CODES_MISMATCH',
  ProjectFullPathCannotBeNull = 'PROJECT_FULL_PATH_CANNOT_BE_NULL',
  ProductionBranchCannotBeNull = 'PRODUCTION_BRANCH_CANNOT_BE_NULL',
  NameCannotBeNull = 'NAME_CANNOT_BE_NULL',
  EnabledLocaleCodesCannotBeEmptyOrNull = 'ENABLED_LOCALE_CODES_CANNOT_BE_EMPTY_OR_NULL',
  TitleCannotBeNull = 'TITLE_CANNOT_BE_NULL',
  SlugCannotBeNull = 'SLUG_CANNOT_BE_NULL',
  WebsiteHeaderNotFound = 'WEBSITE_HEADER_NOT_FOUND',
  UnsupportedCharacterInSlug = 'UNSUPPORTED_CHARACTER_IN_SLUG',
  WebsitePageWithTypeCannotBeDeleted = 'WEBSITE_PAGE_WITH_TYPE_CANNOT_BE_DELETED',
  InvalidUpdateWebsiteHeaderInput = 'INVALID_UPDATE_WEBSITE_HEADER_INPUT',
  ItemIdsCanNotBeEmpty = 'ITEM_IDS_CAN_NOT_BE_EMPTY',
  ItemsBelongToMultipleTourTimeSlots = 'ITEMS_BELONG_TO_MULTIPLE_TOUR_TIME_SLOTS',
  AdmissionTypeAssignmentHasDisabledDiscounts = 'ADMISSION_TYPE_ASSIGNMENT_HAS_DISABLED_DISCOUNTS',
  TourTimeSlotDoesNotComplyWithDiscountRules = 'TOUR_TIME_SLOT_DOES_NOT_COMPLY_WITH_DISCOUNT_RULES',
  WebsiteFooterNotFound = 'WEBSITE_FOOTER_NOT_FOUND',
  InvalidUpdateWebsiteCommonSettingsInput = 'INVALID_UPDATE_WEBSITE_COMMON_SETTINGS_INPUT',
  AccessZoneNotFound = 'ACCESS_ZONE_NOT_FOUND',
  AccessZoneAlreadyAssigned = 'ACCESS_ZONE_ALREADY_ASSIGNED',
  InvalidCustomerLoyaltyId = 'INVALID_CUSTOMER_LOYALTY_ID',
  CustomerIsNotActive = 'CUSTOMER_IS_NOT_ACTIVE',
  CustomerLoyaltyLevelExpired = 'CUSTOMER_LOYALTY_LEVEL_EXPIRED',
  PassCodesCanNotBeEmpty = 'PASS_CODES_CAN_NOT_BE_EMPTY',
  CanNotChangeModeOfProductWithStock = 'CAN_NOT_CHANGE_MODE_OF_PRODUCT_WITH_STOCK',
  WarehouseProductOutOfStock = 'WAREHOUSE_PRODUCT_OUT_OF_STOCK',
  CanNotIssueEmptyWarehouseDocument = 'CAN_NOT_ISSUE_EMPTY_WAREHOUSE_DOCUMENT',
  MissingWarehouseId = 'MISSING_WAREHOUSE_ID',
  BelowWarehouseProductStockLevel = 'BELOW_WAREHOUSE_PRODUCT_STOCK_LEVEL',
  MinStockLevelMustBeLowerThanOptimalStockLevel = 'MIN_STOCK_LEVEL_MUST_BE_LOWER_THAN_OPTIMAL_STOCK_LEVEL',
  WarehouseProductStockIsNegative = 'WAREHOUSE_PRODUCT_STOCK_IS_NEGATIVE',
  IsNegativeStockEnabledCannotBeNull = 'IS_NEGATIVE_STOCK_ENABLED_CANNOT_BE_NULL',
  PaymentNotFound = 'PAYMENT_NOT_FOUND',
  InvalidProductMode = 'INVALID_PRODUCT_MODE',
  AdmissionTypeAssignmentNotFound = 'ADMISSION_TYPE_ASSIGNMENT_NOT_FOUND',
  InventoryCheckedTillIsInFuture = 'INVENTORY_CHECKED_TILL_IS_IN_FUTURE',
  InventoryCheckNotFound = 'INVENTORY_CHECK_NOT_FOUND',
  CanNotDeleteCompletedFullInventoryCheck = 'CAN_NOT_DELETE_COMPLETED_FULL_INVENTORY_CHECK',
  AtLeastOneStockLevelIsRequired = 'AT_LEAST_ONE_STOCK_LEVEL_IS_REQUIRED',
  InventoryCheckProductNotFound = 'INVENTORY_CHECK_PRODUCT_NOT_FOUND',
  InvalidInventoryCheckState = 'INVALID_INVENTORY_CHECK_STATE',
  CannotCompleteEmptyInventoryCheck = 'CANNOT_COMPLETE_EMPTY_INVENTORY_CHECK',
  MissingRealStock = 'MISSING_REAL_STOCK',
  InvalidBusinessPartnerFees = 'INVALID_BUSINESS_PARTNER_FEES',
  OnlyOneFilterAllowed = 'ONLY_ONE_FILTER_ALLOWED',
  UnsupportedStatisticsType = 'UNSUPPORTED_STATISTICS_TYPE',
  MissingBesteronPaymentServiceProviderConfig = 'MISSING_BESTERON_PAYMENT_SERVICE_PROVIDER_CONFIG',
  MissingBesteronPaymentInstruments = 'MISSING_BESTERON_PAYMENT_INSTRUMENTS',
  MissingEmailAddress = 'MISSING_EMAIL_ADDRESS',
  ShowHasEvents = 'SHOW_HAS_EVENTS',
  UnknownBesteronRefundTransactionStatus = 'UNKNOWN_BESTERON_REFUND_TRANSACTION_STATUS',
  ShowHasTours = 'SHOW_HAS_TOURS',
  RefundIntentNotFound = 'REFUND_INTENT_NOT_FOUND',
  MissingVenueIds = 'MISSING_VENUE_IDS',
  MissingAuditoriumIds = 'MISSING_AUDITORIUM_IDS',
  InvalidUrl = 'INVALID_URL',
  InvalidPaymentIntentState = 'INVALID_PAYMENT_INTENT_STATE',
  DiscountUsageLimitPerOrderExceeded = 'DISCOUNT_USAGE_LIMIT_PER_ORDER_EXCEEDED',
  WebsiteHasNoChangesToPublish = 'WEBSITE_HAS_NO_CHANGES_TO_PUBLISH',
  InvalidAuditoriumLayoutState = 'INVALID_AUDITORIUM_LAYOUT_STATE',
  InvalidSeatGroupsFormat = 'INVALID_SEAT_GROUPS_FORMAT',
  InvalidDefaultSeatStatesFormat = 'INVALID_DEFAULT_SEAT_STATES_FORMAT',
  DiscountDisplayModeCannotBeNull = 'DISCOUNT_DISPLAY_MODE_CANNOT_BE_NULL',
  DiscountCodeUsedOnItem = 'DISCOUNT_CODE_USED_ON_ITEM',
  DiscountCodeRedemptionIntentNotFound = 'DISCOUNT_CODE_REDEMPTION_INTENT_NOT_FOUND'
}

export enum ErrorCodes2 {
  NoAvailableZoneSeats = 'NO_AVAILABLE_ZONE_SEATS'
}

/** Check [blog post](https://blog.logrocket.com/handling-graphql-errors-like-a-champ-with-unions-and-interfaces/) for explanation, how and why to use it. */
export type ErrorResult = {
  __typename?: 'ErrorResult';
  errorCode: ErrorCodes2;
};

export enum AuditoriumState {
  Active = 'active',
  Archived = 'archived'
}

export enum SellingChannel {
  /** Sales on point of sale */
  Retail = 'retail',
  /** Online sales */
  ECommerce = 'eCommerce'
}

export enum WeekDay {
  Sun = 'sun',
  Mon = 'mon',
  Tue = 'tue',
  Wed = 'wed',
  Thu = 'thu',
  Fri = 'fri',
  Sat = 'sat'
}

export enum DiscountCodeState {
  Inactive = 'inactive',
  Active = 'active',
  Invalid = 'invalid'
}

export enum ShowContentDescriptorCode {
  SkViolence = 'sk_violence',
  SkDiscrimination = 'sk_discrimination',
  SkFear = 'sk_fear',
  SkAddiction = 'sk_addiction',
  SkSex = 'sk_sex',
  SkVulgarisms = 'sk_vulgarisms',
  SkNudity = 'sk_nudity'
}

export enum ShowVideoSource {
  Youtube = 'youtube',
  Vimeo = 'vimeo'
}

export type ShowVideo = {
  __typename?: 'ShowVideo';
  source: ShowVideoSource;
  url: Scalars['Url'];
  sourceVideoId: Scalars['NonEmptyString'];
};

export type AddShowVideoInput = {
  source: ShowVideoSource;
  sourceVideoId: Scalars['NonEmptyString'];
};

export type UpdateShowVideoInput = {
  source: ShowVideoSource;
  sourceVideoId: Scalars['NonEmptyString'];
};

export type Query = {
  __typename?: 'Query';
  /** Currently we support only clients from specific countries. Find list of supported codes for those countries in 'clientCountryCodes' query */
  clientCountryCodes: Array<CountryCode>;
  countryVatRates: Array<Scalars['NonNegativeFloat']>;
  vatRates: VatRate;
  supportEmail: Scalars['EmailAddress'];
  apiGrants: PaginatedApiGrants;
  apiGrant: ApiGrant;
  client: Client;
  clients: Array<Client>;
  division: Division;
  divisions: Array<Division>;
  clientFiles: Array<ClientFile>;
  me?: Maybe<User>;
  we?: Maybe<Client>;
  user: User;
  users: Array<User>;
  role: Role;
  availableRoles: Array<Role>;
  roles: Array<Role>;
  permissions: Array<Permission>;
  permissionRelations: Array<PermissionRelation>;
  libraryShow: LibraryShow;
  clientShow: ClientShow;
  clientShows: ClientShows;
  libraryShows: LibraryShows;
  mixedShows: MixedShows;
  showAgeClassificationCodes: Array<ShowAgeClassificationCode>;
  showCrewRoleCodes: Array<ShowCrewRoleCode>;
  showFormatCodes: Array<ShowFormatCode>;
  showGenreCodes: Array<ShowGenreCode>;
  showSoundMixCodes: Array<ShowSoundMixCode>;
  showTypes: Array<ShowType>;
  showVersionCodes: Array<ShowVersionCode>;
  mixedShowImages?: Maybe<Array<MixedShowImage>>;
  showContentDescriptorCodes: Array<ShowContentDescriptorCode>;
  venue: Venue;
  venues: Array<Venue>;
  venuesSupportUser: Array<Venue>;
  auditorium: Auditorium;
  auditoriums: Array<Auditorium>;
  auditoriumsSupportUser: Array<Auditorium>;
  auditoriumLayout: AuditoriumLayout;
  auditoriumLayouts: Array<AuditoriumLayout>;
  auditoriumLayoutsSupportUser: Array<AuditoriumLayout>;
  auditoriumLayoutPricing: AuditoriumLayoutPricing;
  auditoriumLayoutPricings: Array<AuditoriumLayoutPricing>;
  ticketType: TicketType;
  ticketTypes: Array<TicketType>;
  websites: PaginatedWebsites;
  website: Website;
  websitePages: Array<WebsitePage>;
  websitePage: WebsitePage;
  websiteHeaders: Array<WebsiteHeader>;
  websiteHeader?: Maybe<WebsiteHeader>;
  websiteFooters: Array<WebsiteFooter>;
  websiteFooter: WebsiteFooter;
  websiteCommonSettings: WebsiteCommonSettings;
  event: Event;
  events: Array<Event>;
  paginatedEvents: PaginatedEventsSuccessResult;
  paginatedEventsOnEcommerce: PaginatedEventsSuccessResult;
  costCenter: CostCenter;
  costCenters: Array<CostCenter>;
  eventCategory: EventCategory;
  eventCategories: Array<EventCategory>;
  marketingLabel: MarketingLabel;
  marketingLabels: Array<MarketingLabel>;
  discount: Discount;
  discounts: PaginatedDiscountsSuccessResult;
  applicationRule: ApplicationRule;
  discountCodes: Array<DiscountCode>;
  discountCode: DiscountCode;
  cart: Cart;
  /**
   * Fetching list of carts provides better performance and filter of results.
   * On the other hand NarrowCart contains only limited subset of Cart fields.
   */
  carts: PaginatedCartsSuccessResult;
  eventSeats: Scalars['JSON'];
  checkDiscountCodeForCart: DiscountCode;
  latestEventSeatTicketItem: TicketItem;
  cartSummary: Cart;
  item: Item;
  itemByPassCode: ItemWithPassCode;
  cartsSupportUser: PaginatedCartsSuccessResult;
  cartSupportUser: Cart;
  tourItems: PaginatedTourItems;
  checkDiscountCodeForTourItems: DiscountCode;
  eCommercePaymentMethods: Array<PaymentMethod>;
  retailPaymentMethods: Array<PaymentMethod>;
  retailPaymentMethod: PaymentMethod;
  paymentMethodGroups: Array<PaymentMethodGroup>;
  isGoPayConfigInputValid: Scalars['Boolean'];
  payments: PaymentsResult;
  isStatnaPokladnicaConfigInputValid: Scalars['Boolean'];
  paymentReports: PaginatedPaymentReportsSuccessResult;
  paymentReport: PaymentReport;
  availableInputDataForDailyCashierReports: Array<AvailableInputDataForDailyCashierReports>;
  availableInputDataForMonthlyECommerceReports: Array<PaymentPeriodWithoutMonthlyReport>;
  availableInputDataForMonthlyReports: Array<PaymentPeriodWithoutMonthlyReport>;
  paymentReportView: PaymentReportView;
  reservation: Reservation;
  reservations: PaginatedReservationsSuccessResult;
  sale: Sale;
  sales: PaginatedSalesSuccessResult;
  checkDiscountCodeForReservation: DiscountCode;
  saleByItemPassCode: Sale;
  leads: Array<Lead>;
  message: Message;
  messages: PaginatedMessagesSuccessResult;
  claims: PaginatedClaims;
  claim: Claim;
  passCodeChecks: PaginatedPassCodeChecks;
  /** filter is not implemented */
  templates: Array<Template>;
  template: Template;
  admissionTypes: PaginatedAdmissionTypesSuccessResult;
  admissionType: AdmissionType;
  tour: Tour;
  tours: PaginatedToursSuccessResult;
  admissionRate: AdmissionRate;
  admissionRates: PaginatedAdmissionRatesSuccessResult;
  tourTimeSlot: TourTimeSlot;
  tourTimeSlots: PaginatedTourTimeSlots;
  accessZone: AccessZone;
  accessZones: PaginatedAccessZones;
  eventsStatistics: PaginatedEventsStatistics;
  eventStatistics: EventStatistics;
  aggregatedEventStatisticsByItemPrice: AggregatedEventStatisticsByItemPrice;
  productsStatistics: PaginatedProductsStatistics;
  tourTimeSlotsStatistics: PaginatedTourTimeSlotsStatistics;
  admissionTypesStatistics: PaginatedAdmissionTypesStatistics;
  showsStatistics: PaginatedShowsStatistics;
  discountsStatistics: PaginatedDiscountsStatistics;
  productTypes: Array<ProductType>;
  productType: ProductType;
  products: PaginatedProductsSuccessResult;
  product: Product;
  warehouses: PaginatedWarehousesSuccessResult;
  warehouse: Warehouse;
  productGroups: Array<ProductGroup>;
  productGroup: ProductGroup;
  warehouseDocuments: PaginatedWarehouseDocumentsSuccessResult;
  warehouseDocument: WarehouseDocument;
  businessPartner: BusinessPartner;
  businessPartners: PaginatedBusinessPartnersSuccessResult;
  warehouseProducts: PaginatedWarehouseProductsSuccessResult;
  warehouseDocumentItems: PaginatedWarehouseDocumentItemsSuccessResult;
  warehouseProduct: WarehouseProduct;
  inventoryChecks: PaginatedInventoryChecksSuccessResult;
  inventoryCheck: InventoryCheck;
  voucherCampaigns: PaginatedVoucherCampaignsSuccessResult;
  voucherCampaign: VoucherCampaign;
  voucherByCodeOnRetailChannel: Voucher;
  voucherByCodeOnECommerceChannel: Voucher;
  vouchers: PaginatedVouchersSuccessResult;
  voucher: Voucher;
  voucherTransactionIntents: PaginatedVoucherTransactionIntentsSuccessResult;
  isCustomerSignedIn: Scalars['Boolean'];
  signedInCustomer: Customer;
  customers: PaginatedCustomersSuccessResult;
  customer: Customer;
  customerGroups: Array<CustomerGroup>;
  customerGroup: CustomerGroup;
};


export type QueryCountryVatRatesArgs = {
  countryCode: ExistingCountryCode;
};


export type QueryApiGrantsArgs = {
  filter?: Maybe<ApiGrantsFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryApiGrantArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryClientArgs = {
  id: Scalars['Int'];
};


export type QueryDivisionArgs = {
  id: Scalars['Int'];
};


export type QueryDivisionsArgs = {
  filter?: Maybe<DivisionFilter>;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUsersArgs = {
  filter?: Maybe<UsersFilter>;
};


export type QueryRoleArgs = {
  id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
};


export type QueryLibraryShowArgs = {
  id: Scalars['Int'];
};


export type QueryClientShowArgs = {
  id: Scalars['Int'];
};


export type QueryClientShowsArgs = {
  filter?: Maybe<ShowsFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryLibraryShowsArgs = {
  filter?: Maybe<ShowsFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryMixedShowsArgs = {
  filter: ShowsFilterInput;
  paginationInput: PaginationInput;
};


export type QueryMixedShowImagesArgs = {
  clientShowId: Scalars['Int'];
  filter: MixedShowImagesFilterInput;
};


export type QueryVenueArgs = {
  id: Scalars['Int'];
};


export type QueryVenuesArgs = {
  filter?: Maybe<VenuesFilterInput>;
};


export type QueryVenuesSupportUserArgs = {
  clientId?: Maybe<Scalars['Int']>;
};


export type QueryAuditoriumArgs = {
  id: Scalars['Int'];
};


export type QueryAuditoriumsArgs = {
  filter?: Maybe<AuditoriumsFilterInput>;
};


export type QueryAuditoriumsSupportUserArgs = {
  venueId: Scalars['Int'];
};


export type QueryAuditoriumLayoutArgs = {
  id: Scalars['Int'];
};


export type QueryAuditoriumLayoutsArgs = {
  auditoriumId: Scalars['Int'];
};


export type QueryAuditoriumLayoutsSupportUserArgs = {
  auditoriumId: Scalars['Int'];
};


export type QueryAuditoriumLayoutPricingArgs = {
  id: Scalars['Int'];
};


export type QueryAuditoriumLayoutPricingsArgs = {
  auditoriumLayoutId: Scalars['Int'];
};


export type QueryTicketTypeArgs = {
  id: Scalars['Int'];
};


export type QueryTicketTypesArgs = {
  auditoriumLayoutPricingId: Scalars['Int'];
};


export type QueryWebsitesArgs = {
  filter?: Maybe<WebsitesFilter>;
  paginationInput: PaginationInput;
};


export type QueryWebsiteArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryWebsitePagesArgs = {
  websiteId: Scalars['PositiveInt'];
  filter?: Maybe<WebsitesPageFilter>;
};


export type QueryWebsitePageArgs = {
  ID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
};


export type QueryWebsiteHeadersArgs = {
  websiteId: Scalars['PositiveInt'];
  filter?: Maybe<WebsiteHeadersFilterInput>;
};


export type QueryWebsiteHeaderArgs = {
  headerID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
};


export type QueryWebsiteFootersArgs = {
  websiteId: Scalars['PositiveInt'];
  filter?: Maybe<WebsiteFootersFilterInput>;
};


export type QueryWebsiteFooterArgs = {
  footerID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
};


export type QueryWebsiteCommonSettingsArgs = {
  websiteId: Scalars['PositiveInt'];
};


export type QueryEventArgs = {
  id: Scalars['Int'];
};


export type QueryEventsArgs = {
  filter: EventsFilterInput;
};


export type QueryPaginatedEventsArgs = {
  filter: EventsFilterInput;
  paginationInput: PaginationInput;
};


export type QueryPaginatedEventsOnEcommerceArgs = {
  filter: EventsFilterInput;
  paginationInput: PaginationInput;
  clientId: Scalars['Int'];
};


export type QueryCostCenterArgs = {
  id: Scalars['Int'];
};


export type QueryCostCentersArgs = {
  filter?: Maybe<CostCentersFilterInput>;
};


export type QueryEventCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryEventCategoriesArgs = {
  filter?: Maybe<EventCategoriesFilterInput>;
};


export type QueryMarketingLabelArgs = {
  id: Scalars['Int'];
};


export type QueryMarketingLabelsArgs = {
  filter?: Maybe<MarketingLabelsFilterInput>;
};


export type QueryDiscountArgs = {
  id: Scalars['Int'];
};


export type QueryDiscountsArgs = {
  filter?: Maybe<DiscountsFilterInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryApplicationRuleArgs = {
  id: Scalars['Int'];
};


export type QueryDiscountCodesArgs = {
  discountId: Scalars['Int'];
  filter?: Maybe<DiscountCodesFilterInput>;
};


export type QueryDiscountCodeArgs = {
  id: Scalars['Int'];
};


export type QueryCartArgs = {
  cartId: Scalars['Int'];
};


export type QueryCartsArgs = {
  filter?: Maybe<CartsFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryEventSeatsArgs = {
  eventId: Scalars['Int'];
  myCartId?: Maybe<Scalars['Int']>;
  myReservationIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryCheckDiscountCodeForCartArgs = {
  cartId: Scalars['Int'];
  eventId: Scalars['Int'];
  codeName: Scalars['String'];
};


export type QueryLatestEventSeatTicketItemArgs = {
  eventSeatId: Scalars['Int'];
};


export type QueryCartSummaryArgs = {
  uuid: Scalars['String'];
  hash: Scalars['String'];
};


export type QueryItemArgs = {
  id: Scalars['Int'];
};


export type QueryItemByPassCodeArgs = {
  passCode: Scalars['NonEmptyString'];
  itemFilter?: Maybe<ItemFilter>;
};


export type QueryCartsSupportUserArgs = {
  filter?: Maybe<CartsSupportUserFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryCartSupportUserArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryTourItemsArgs = {
  filter?: Maybe<TourItemsFilter>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryCheckDiscountCodeForTourItemsArgs = {
  cartId: Scalars['PositiveInt'];
  tourTimeSlotId: Scalars['PositiveInt'];
  discountCodeName: Scalars['NonEmptyString'];
};


export type QueryECommercePaymentMethodsArgs = {
  state?: Maybe<PaymentMethodState>;
};


export type QueryRetailPaymentMethodsArgs = {
  state?: Maybe<PaymentMethodState>;
};


export type QueryRetailPaymentMethodArgs = {
  id: Scalars['Int'];
};


export type QueryIsGoPayConfigInputValidArgs = {
  input: GoPayConfigInput;
};


export type QueryPaymentsArgs = {
  filter?: Maybe<PaymentsFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryIsStatnaPokladnicaConfigInputValidArgs = {
  input: StatnaPokladnicaConfigInput;
};


export type QueryPaymentReportsArgs = {
  filter?: Maybe<PaymentReportsFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryPaymentReportArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryAvailableInputDataForDailyCashierReportsArgs = {
  cashierUserId?: Maybe<Scalars['PositiveInt']>;
};


export type QueryPaymentReportViewArgs = {
  filter: PaymentReportViewFilterInput;
};


export type QueryReservationArgs = {
  id: Scalars['Int'];
};


export type QueryReservationsArgs = {
  filter?: Maybe<ReservationsFilterInput>;
  paginationInput: PaginationInput;
};


export type QuerySaleArgs = {
  id: Scalars['Int'];
};


export type QuerySalesArgs = {
  filter?: Maybe<SaleFilterInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryCheckDiscountCodeForReservationArgs = {
  reservationId: Scalars['Int'];
  codeName: Scalars['String'];
};


export type QuerySaleByItemPassCodeArgs = {
  passCode: Scalars['NonEmptyString'];
};


export type QueryLeadsArgs = {
  filter: LeadsFilterInput;
};


export type QueryMessageArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryMessagesArgs = {
  filter?: Maybe<MessagesFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryClaimsArgs = {
  filter?: Maybe<ClaimsFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryClaimArgs = {
  id: Scalars['Int'];
};


export type QueryPassCodeChecksArgs = {
  paginationInput: PaginationInput;
  filter?: Maybe<PassCodeChecksFilter>;
};


export type QueryTemplatesArgs = {
  filter?: Maybe<TemplatesFilterInput>;
};


export type QueryTemplateArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryAdmissionTypesArgs = {
  filter?: Maybe<AdmissionTypesFilter>;
  paginationInput: PaginationInput;
};


export type QueryAdmissionTypeArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryTourArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryToursArgs = {
  filter?: Maybe<ToursFilter>;
  paginationInput: PaginationInput;
};


export type QueryAdmissionRateArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryAdmissionRatesArgs = {
  filter?: Maybe<AdmissionRatesFilter>;
  paginationInput: PaginationInput;
};


export type QueryTourTimeSlotArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryTourTimeSlotsArgs = {
  filter?: Maybe<TourTimeSlotsFilter>;
  paginationInput: PaginationInput;
};


export type QueryAccessZoneArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryAccessZonesArgs = {
  filter?: Maybe<AccessZonesFilter>;
  paginationInput: PaginationInput;
};


export type QueryEventsStatisticsArgs = {
  paginationInput: PaginationInput;
  filter?: Maybe<EventsStatisticsFilterInput>;
};


export type QueryEventStatisticsArgs = {
  eventId: Scalars['PositiveInt'];
};


export type QueryAggregatedEventStatisticsByItemPriceArgs = {
  eventId: Scalars['PositiveInt'];
};


export type QueryProductsStatisticsArgs = {
  paginationInput: PaginationInput;
  filter?: Maybe<ProductsStatisticsFilterInput>;
};


export type QueryTourTimeSlotsStatisticsArgs = {
  paginationInput: PaginationInput;
  filter?: Maybe<TourTimeSlotsStatisticsFilterInput>;
};


export type QueryAdmissionTypesStatisticsArgs = {
  paginationInput: PaginationInput;
  filter?: Maybe<AdmissionTypesStatisticsFilterInput>;
};


export type QueryShowsStatisticsArgs = {
  paginationInput: PaginationInput;
  filter?: Maybe<ShowsStatisticsFilterInput>;
};


export type QueryDiscountsStatisticsArgs = {
  paginationInput: PaginationInput;
  filter?: Maybe<DiscountsStatisticsFilterInput>;
};


export type QueryProductTypesArgs = {
  state?: Maybe<ProductTypeState>;
};


export type QueryProductTypeArgs = {
  id: Scalars['Int'];
};


export type QueryProductsArgs = {
  filter?: Maybe<ProductsFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryProductArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryWarehousesArgs = {
  filter?: Maybe<WarehousesFilterInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryWarehouseArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryProductGroupsArgs = {
  filter?: Maybe<ProductGroupsFilterInput>;
  productsFilter?: Maybe<ProductGroupProductsFilterInput>;
};


export type QueryProductGroupArgs = {
  id: Scalars['PositiveInt'];
  productsFilter?: Maybe<ProductGroupProductsFilterInput>;
};


export type QueryWarehouseDocumentsArgs = {
  filter?: Maybe<WarehouseDocumentsFilterInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryWarehouseDocumentArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryBusinessPartnerArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryBusinessPartnersArgs = {
  filter?: Maybe<BusinessPartnersFilterInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryWarehouseProductsArgs = {
  filter?: Maybe<WarehouseProductsFilterInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryWarehouseDocumentItemsArgs = {
  filter?: Maybe<WarehouseDocumentItemsFilterInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryWarehouseProductArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryInventoryChecksArgs = {
  filter?: Maybe<InventoryChecksFilterInput>;
  paginationInput?: Maybe<PaginationInput>;
};


export type QueryInventoryCheckArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryVoucherCampaignsArgs = {
  filter?: Maybe<VoucherCampaignsFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryVoucherCampaignArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryVoucherByCodeOnRetailChannelArgs = {
  code: Scalars['NonEmptyString'];
};


export type QueryVoucherByCodeOnECommerceChannelArgs = {
  code: Scalars['NonEmptyString'];
  pinCode: Scalars['NonEmptyString'];
  cartId: Scalars['PositiveInt'];
};


export type QueryVouchersArgs = {
  filter?: Maybe<VouchersFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryVoucherArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryVoucherTransactionIntentsArgs = {
  paginationInput: PaginationInput;
  filter?: Maybe<VoucherTransactionIntentsFilterInput>;
};


export type QueryCustomersArgs = {
  filter?: Maybe<CustomersFilterInput>;
  paginationInput: PaginationInput;
};


export type QueryCustomerArgs = {
  id: Scalars['PositiveInt'];
};


export type QueryCustomerGroupArgs = {
  id: Scalars['PositiveInt'];
};











export type Address = {
  __typename?: 'Address';
  complex: Scalars['String'];
  street: Scalars['String'];
  town: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type AddressInput = {
  complex: Scalars['String'];
  street: Scalars['String'];
  town: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type FilerImage = {
  __typename?: 'FilerImage';
  id: Scalars['Int'];
  folderId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  url: Scalars['String'];
  sha1: Scalars['String'];
  uploadedAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type VatRate = {
  __typename?: 'VatRate';
  SK: Array<Scalars['NonNegativeFloat']>;
  CZ: Array<Scalars['NonNegativeFloat']>;
  US: Array<Scalars['NonNegativeFloat']>;
};

/** List of currencies based on ISO 4217 standard https://en.wikipedia.org/wiki/ISO_4217 */
export enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

export enum Timezone {
  Gmt = 'GMT',
  Cet = 'CET',
  Eet = 'EET'
}

export enum ResolverType {
  Query = 'Query',
  Mutation = 'Mutation'
}

export type EcommerceIdentifiers = {
  uuid: Scalars['String'];
  hash: Scalars['String'];
};

export enum ApiResource {
  Shows = 'shows',
  Events = 'events',
  Tours = 'tours',
  TourTimeSlots = 'tourTimeSlots',
  Venues = 'venues',
  CheckPassCode = 'checkPassCode'
}

export enum ApiGrantState {
  Active = 'active',
  Inactive = 'inactive',
  Expired = 'expired'
}

export type ApiContact = {
  __typename?: 'ApiContact';
  email: Scalars['EmailAddress'];
  phone?: Maybe<Scalars['NonEmptyString']>;
  name: Scalars['NonEmptyString'];
  role?: Maybe<Scalars['NonEmptyString']>;
};

export type ApiGrant = {
  __typename?: 'ApiGrant';
  id: Scalars['PositiveInt'];
  name: Scalars['NonEmptyString'];
  description: Scalars['NonEmptyString'];
  grantedResources: Array<ApiResource>;
  /** No clients in list of clientsOwningResources means, the grant grants access to resources of all clients */
  clientsOwningResources: Array<Client>;
  maxRequestsPerMinute?: Maybe<Scalars['PositiveInt']>;
  maxRequestsPerHour?: Maybe<Scalars['PositiveInt']>;
  state: ApiGrantState;
  technicalContact: ApiContact;
  businessContact: ApiContact;
  expiresAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  creatorId: Scalars['PositiveInt'];
  creator: User;
};

export type ApiContactInput = {
  email: Scalars['EmailAddress'];
  phone?: Maybe<Scalars['NonEmptyString']>;
  name: Scalars['NonEmptyString'];
  role?: Maybe<Scalars['NonEmptyString']>;
};

export type ApiGrantInput = {
  name: Scalars['NonEmptyString'];
  description: Scalars['NonEmptyString'];
  grantedResources: Array<ApiResource>;
  /** The grant will grant data of clients with an id in clientIds list. If clientIds list is empty, the grant will grant access to resources of all clients. */
  clientIds?: Maybe<Array<Scalars['PositiveInt']>>;
  maxRequestsPerMinute?: Maybe<Scalars['PositiveInt']>;
  maxRequestsPerHour?: Maybe<Scalars['PositiveInt']>;
  state?: Maybe<ApiGrantState>;
  technicalContact: ApiContactInput;
  businessContact: ApiContactInput;
  expiresAt?: Maybe<Scalars['DateTime']>;
  creatorId?: Maybe<Scalars['PositiveInt']>;
};

export type CreateApiGrantResult = {
  __typename?: 'CreateApiGrantResult';
  apiGrant: ApiGrant;
  /** @deprecated bearer token should be not visible to support */
  bearerToken: Scalars['NonEmptyString'];
};

export type UpdateApiGrantInput = {
  name: Scalars['NonEmptyString'];
  description: Scalars['NonEmptyString'];
  grantedResources: Array<ApiResource>;
  state: ApiGrantState;
  expiresAt: Scalars['DateTime'];
  maxRequestsPerMinute?: Maybe<Scalars['PositiveInt']>;
  maxRequestsPerHour?: Maybe<Scalars['PositiveInt']>;
  technicalContact: ApiContactInput;
  businessContact: ApiContactInput;
  creatorId?: Maybe<Scalars['PositiveInt']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createApiGrant: CreateApiGrantResult;
  updateApiGrant: ApiGrant;
  assignClientsToApiGrant: ApiGrant;
  createClient: Client;
  updateClient: Client;
  updateCompany: Client;
  switchClient?: Maybe<Client>;
  deactivateClient: Client;
  activateClient: Client;
  createDivision: Division;
  updateDivision: Division;
  deactivateDivision: Division;
  activateDivision: Division;
  deleteDivision: Division;
  updateLeadOptions: Array<LeadOption>;
  /**
   * Uploaded file can be downloaded from digital ocean s3 server from link
   *
   * https://uploadtest1.fra1.digitaloceanspaces.com/<infrastructure>/client/<clientId>/<filepath>
   */
  uploadClientFile: ClientFile;
  /**
   * Mutation deletes file located on url
   *
   * https://uploadtest1.fra1.digitaloceanspaces.com/<infrastructure>/client/<clientId>/<filepath>
   */
  deleteClientFile?: Maybe<Scalars['Void']>;
  login: User;
  loginLead: LoginLeadResponse;
  logout: Scalars['Boolean'];
  revokeInvitation: User;
  resendInvitation: Scalars['Boolean'];
  inviteUsers: Array<InvitationResult>;
  acceptInvitation: User;
  createUser: User;
  updateMe: User;
  updateUser: User;
  changePassword: Scalars['Boolean'];
  disableUser: User;
  enableUser: User;
  anonymizeUser: User;
  forgotPassword: Scalars['Boolean'];
  recoverPassword: Scalars['Boolean'];
  verifyUserPassword: Scalars['Boolean'];
  verifyEmailToken?: Maybe<User>;
  createRole: Role;
  updateRole: Role;
  deleteRole: Role;
  createLibraryShow: LibraryShow;
  updateLibraryShow: LibraryShow;
  createClientShow: ClientShow;
  updateClientShow: ClientShow;
  upsertLibraryShowTranslations: Array<ShowTranslation>;
  upsertClientShowTranslations: Array<ShowTranslation>;
  uploadLibraryImage: LibraryShowImage;
  uploadClientShowImage: ClientShowImage;
  assignLibraryImagesToClientShow: Array<ClientShowImage>;
  deleteClientShowImage: ClientShowImage;
  upsertLibraryShowCrew: Array<ShowCrewByRole>;
  upsertClientShowCrew: Array<ShowCrewByRole>;
  updateClientShowImageOrderPosition: ClientShowImage;
  removeImageFromLibraryShow: LibraryShow;
  deleteLibraryImage: LibraryShowImage;
  activateLibraryImage: LibraryShowImage;
  addClientShowVideo: ClientShow;
  updateClientShowVideos: ClientShow;
  addLibraryShowVideo: LibraryShow;
  updateLibraryShowVideos: LibraryShow;
  deleteClientShow: Scalars['PositiveInt'];
  createVenue: Venue;
  updateVenue: Venue;
  deleteVenue: Venue;
  createAuditorium: Auditorium;
  updateAuditorium: Auditorium;
  deleteAuditorium: Auditorium;
  createAuditoriumLayout: AuditoriumLayout;
  updateAuditoriumLayout: AuditoriumLayout;
  deleteAuditoriumLayout: AuditoriumLayout;
  activateAuditoriumLayout: AuditoriumLayout;
  archiveAuditoriumLayout: AuditoriumLayout;
  copyAuditoriumLayout: AuditoriumLayout;
  duplicateAuditoriumLayoutSupportUser: AuditoriumLayout;
  createAuditoriumLayoutPricing: AuditoriumLayoutPricing;
  updateAuditoriumLayoutPricing: AuditoriumLayoutPricing;
  deleteAuditoriumLayoutPricing: AuditoriumLayoutPricing;
  activateAuditoriumLayoutPricing: AuditoriumLayoutPricing;
  archiveAuditoriumLayoutPricing: AuditoriumLayoutPricing;
  createTicketType: TicketType;
  updateTicketType: TicketType;
  deleteTicketType: TicketType;
  updateVenuesOrder: Array<Venue>;
  updateAuditoriumsOrder: Array<Auditorium>;
  createWebsite: Website;
  updateWebsite: Website;
  createWebsitePage: WebsitePage;
  deleteWebsite: Website;
  deleteWebsitePage?: Maybe<Scalars['Void']>;
  updateWebsitePage: WebsitePage;
  updateWebsitePageBlocks: WebsitePage;
  discardWebsitePagesChanges: Website;
  publishWebsitePagesChanges: Website;
  createWebsiteHeader: WebsiteHeader;
  deleteWebsiteHeader?: Maybe<Scalars['Void']>;
  updateWebsiteHeader: WebsiteHeader;
  createWebsiteFooter: WebsiteFooter;
  deleteWebsiteFooter?: Maybe<Scalars['Void']>;
  updateWebsiteFooter: WebsiteFooter;
  updateWebsiteCommonSettings: WebsiteCommonSettings;
  createEvent: Event;
  moveEvent: Event;
  resizeEvent: Event;
  updateGeneralEventData: Event;
  updateExtendedEventData: Event;
  updateSalesEventData: Event;
  copyEvents: Array<Event>;
  publishEvent: Event;
  publishEvents: Array<Event>;
  cancelEvent: Event;
  cancelEvents: Array<Event>;
  deleteEvent: Scalars['Int'];
  deleteEvents: Array<Scalars['Int']>;
  updateAllowedDiscountsSellingChannelsForEvent: Event;
  createEventPricing: EventPricing;
  updateEventPricing: EventPricing;
  deleteEventPricing: Scalars['Int'];
  copyEvent: Event;
  createCostCenter: CostCenter;
  updateCostCenter: CostCenter;
  createEventCategory: EventCategory;
  updateEventCategory: EventCategory;
  createMarketingLabel: MarketingLabel;
  updateMarketingLabel: MarketingLabel;
  createDiscount: Discount;
  deleteDiscount: Discount;
  updateDiscount: Discount;
  copyDiscount: Discount;
  createApplicationRule: ApplicationRule;
  deleteApplicationRule: ApplicationRule;
  createCondition: Condition;
  updateCondition: Condition;
  deleteCondition: Condition;
  /** If you won't specify name, the backend will autogenerate code name for the created discount code */
  createDiscountCode: DiscountCode;
  createDiscountCodes: Array<DiscountCode>;
  invalidateDiscountCodes: Array<DiscountCode>;
  addSeatingTicketItemToCart: Cart;
  createCart: Cart;
  discardCart: Cart;
  removeTicketItemFromCart: Cart;
  incrementZoneTicketItemQuantity: IncrementZoneTicketItemQuantityResult;
  decrementZoneTicketItemQuantity: Cart;
  removeAllTicketItemsFromCartForEvent: Cart;
  removeAllProductItemsFromCart: Cart;
  /**
   * Parameter sendEmail should always be set to true when making purchase on E-Commerce
   * Parameter customerId should be used only on Retail to identify Customer
   * Parameter localeCode should always be set when making purchase on E-Commerce
   */
  checkout: Cart;
  /**
   * Parameter sendEmail should always be set to true when making reservations on E-Commerce
   * Parameter customerId should be used only on Retail to identify Customer
   * Parameter localeCode should always be set when making reservation on E-Commerce
   */
  reserve: Cart;
  postponeCartExpiration: Cart;
  /** Add a discount to specific items of event */
  addDiscountToCartTicketItems: Cart;
  /**
   * Event seats are grouped by state to available and inCart and added to or removed from cart based on cartId.
   * When all event seats have state available and no cartId has been supplied, new cart will be created.
   * When removing inCart seats from cart, cartId must be provided, otherwise those are ignored.
   * Null will be returned in case that all eventSeats have inCart state and no cartId has been provided.
   * Null will be also returned in case empty array of seat's uuids has been provided without cartId.
   * Cart will be returned in case empty array of seat's uuids has been provided with cartId.
   */
  updateSeatingTicketItemsQuantityInCart?: Maybe<Cart>;
  removeDiscountFromItem: Cart;
  /**
   * Add a discount on event for the items that are not specified.
   * The discount is automatically added to the specified number of items that meet the conditions for adding this discount.
   */
  incrementDiscountQuantityForCartTicketItems: Cart;
  decrementDiscountQuantityFromCartTicketItems: Cart;
  /** Parameter warehouseId must be provided for products in 'warehouseProduct' mode. */
  incrementProductItemQuantity: Cart;
  decrementProductItemQuantity: Cart;
  /** Parameter warehouseId must be provided for products in 'warehouseProduct' mode. */
  incrementProductItemQuantityWithPriceLookupCode: Cart;
  /** Parameter warehouseId must be provided for products in 'warehouseProduct' mode. */
  incrementProductItemQuantityWithBarcode: Cart;
  incrementTourItemQuantity: Cart;
  decrementTourItemQuantity: Cart;
  removeTourItemFromCart: Cart;
  removeAllTourItemsFromCartForTimeSlot: Cart;
  addDiscountToTourItems: Cart;
  /** Only Available, Hidden, Disabled and SelectedByMe states are valid. */
  changeEventSeatsAvailability: Array<RawEventSeat>;
  /**
   * Only Available, Hidden, Disabled and SelectedByMe states are valid.
   * If "count" is not defined, states of all seats equal to "fromState" are changed to "toState"
   */
  changeEventZoneSeatsAvailability: Array<RawEventSeat>;
  createPaymentMethodGroup: PaymentMethodGroup;
  editPaymentMethodGroup: PaymentMethodGroup;
  deletePaymentMethodGroup: PaymentMethodGroup;
  createRetailPaymentMethod: PaymentMethod;
  createECommercePaymentMethod: PaymentMethod;
  deleteRetailPaymentMethod: PaymentMethod;
  deleteECommercePaymentMethod: PaymentMethod;
  updatePaymentMethod: PaymentMethod;
  createCashDeskDeposit: Payment;
  createCashDeskWithdrawal: Payment;
  createDailyCashierPaymentReport: PaymentReport;
  notifyUserReturnedFromPaymentGateway?: Maybe<Scalars['Void']>;
  createPaymentsCsvLink: Scalars['NonEmptyString'];
  createMonthlyECommercePaymentReport: PaymentReport;
  createMonthlyPaymentReport: PaymentReport;
  addSeatingTicketItemToReservation: Reservation;
  addZoneTicketItemToReservation: AddZoneTicketItemToReservationResult;
  updateReservation: Reservation;
  /** Moves some of the reservation items to a cart with cartId or to a newly created cart. Updates reservation */
  splitReservation: Cart;
  addReservationToCart: Cart;
  removeTicketItemsFromReservation: Reservation;
  removeZoneTicketItemsFromReservation: Reservation;
  removeTourItemFromReservation: Reservation;
  /** Decrement count of items with admissionTypeAssignmentId */
  decrementTourItemQuantityInReservation: Reservation;
  /** Increment count of items with admissionTypeAssignmentId */
  incrementTourItemQuantityInReservation: Reservation;
  cancelReservation: Reservation;
  removeReservationFromCart: Cart;
  addDiscountToReservationTicketItems: Reservation;
  removeDiscountFromReservationTicketItem: Reservation;
  incrementDiscountQuantityForReservationTicketItems: Reservation;
  decrementDiscountQuantityFromReservationTicketItems: Reservation;
  sendReservationConfirmationEmail: Scalars['Boolean'];
  sendSaleConfirmationEmail: Scalars['Boolean'];
  updateReservationLead: Lead;
  sendMessage: Message;
  createClaim: Claim;
  addItemsToClaim: Claim;
  removeItemsFromClaim: Claim;
  deleteClaim: Claim;
  approveClaim: Claim;
  updateClaimReason: Claim;
  upsertClaimLead: Claim;
  deleteClaimLead: Claim;
  refundClaimOnRetailChannel: Claim;
  refundClaimOnECommerceChannel: Claim;
  refundClaimByInHouseVoucher: RefundClaimByInHouseVoucherResponse;
  checkPassCodeIn: PassCodeCheck;
  checkPassCodeOut: PassCodeCheck;
  updatePassCodeCheck: PassCodeCheck;
  checkPassCodes: Array<PassCodeCheck>;
  createTemplate: Template;
  editTemplateVersionCode: TemplateVersion;
  assignTemplateToClient: Client;
  removeTemplateFromClient: Client;
  updateTemplate: Template;
  createTour: Tour;
  updateTour: Tour;
  createAdmissionType: AdmissionType;
  updateDraftAdmissionType: AdmissionType;
  updateAdmissionType: AdmissionType;
  deleteDraftAdmissionType: Scalars['PositiveInt'];
  /** Only tours in draft state can be deleted. Tours in other states will throw TourNotFound error. */
  deleteTour: Scalars['PositiveInt'];
  createAdmissionRate: AdmissionRate;
  updateAdmissionRate: AdmissionRate;
  deleteAdmissionRate: Scalars['PositiveInt'];
  updateDraftAdmissionRateAssignments: AdmissionRate;
  updateAdmissionRateAssignments: AdmissionRate;
  createTourTimeSlot: TourTimeSlot;
  deleteTourTimeSlot: Scalars['PositiveInt'];
  deleteTourTimeSlots: Array<Scalars['PositiveInt']>;
  updateTourTimeSlotSaleData: TourTimeSlot;
  updateTourTimeSlots: Array<TourTimeSlot>;
  copyTourTimeSlots: Array<TourTimeSlot>;
  copyAdmissionRate: AdmissionRate;
  createAccessZone: AccessZone;
  updateAccessZone: AccessZone;
  assignAccessZoneToAdmissionRateType: AdmissionRate;
  removeAccessZoneFromAdmissionRateType: AdmissionRate;
  createProductsStatisticsCsvLink: Scalars['NonEmptyString'];
  createEventsStatisticsCsvLink: Scalars['NonEmptyString'];
  createProductType: ProductType;
  updateProductType: ProductType;
  createProduct: Product;
  updateProduct: Product;
  upsertProductAvailability: Product;
  createProductBarcode: ProductBarcode;
  createProductPricing: Product;
  deleteProductCoverImage: Product;
  deleteProductBarcode: ProductBarcode;
  deleteProductPricing: ProductPricing;
  createWarehouse: Warehouse;
  updateWarehouse: Warehouse;
  createProductGroup: ProductGroup;
  updateProductGroup: ProductGroup;
  assignProductGroupToProduct: Product;
  assignProductToProductGroup: ProductGroup;
  removeProductFromProductGroup: ProductGroup;
  removeProductGroupFromProduct: Product;
  upsertProductGroupAvailability: ProductGroup;
  updateProductsOrderInProductGroup: ProductGroup;
  updateProductGroupsOrder: Array<ProductGroup>;
  createWarehouseDocument: WarehouseDocument;
  updateDraftWarehouseDocument: WarehouseDocument;
  uploadProductCoverImage: Product;
  createBusinessPartner: BusinessPartner;
  updateBusinessPartner: BusinessPartner;
  createWarehouseProduct: WarehouseProduct;
  addItemToWarehouseDocument: WarehouseDocument;
  removeItemFromWarehouseDocument: WarehouseDocument;
  deleteDraftWarehouseDocument: Scalars['PositiveInt'];
  issueWarehouseDocument: WarehouseDocument;
  updateWarehouseProduct: WarehouseProduct;
  createInventoryCheck: InventoryCheck;
  deleteInventoryCheck: Scalars['PositiveInt'];
  updateInventoryCheckProduct: InventoryCheck;
  updateInventoryCheck: InventoryCheck;
  completeInventoryCheck: InventoryCheck;
  createVoucherCampaign: VoucherCampaign;
  createVouchers: Array<Voucher>;
  changeVoucherState: Voucher;
  changeVoucherCampaignState: VoucherCampaign;
  creditVoucher: Voucher;
  signUpCustomer: Customer;
  forgotCustomerPassword: Scalars['Boolean'];
  signInCustomer: Customer;
  signOutCustomer: Scalars['Boolean'];
  recoverCustomerPassword: Scalars['Boolean'];
  changePasswordForSignedInCustomer: Customer;
  updateSignedInCustomer: Customer;
  createCustomerGroup: CustomerGroup;
  updateCustomerGroup: CustomerGroup;
  updateCustomer: Customer;
  deleteCustomerGroup: CustomerGroup;
  createCustomer: Customer;
  exportStatisticsFromDatagridAsExcelFile: Scalars['String'];
  exportStatisticsFromDatagridAsCsvFile: Scalars['String'];
  importDiscountCodesForClient87: Scalars['Boolean'];
};


export type MutationCreateApiGrantArgs = {
  input: ApiGrantInput;
};


export type MutationUpdateApiGrantArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateApiGrantInput;
};


export type MutationAssignClientsToApiGrantArgs = {
  id: Scalars['PositiveInt'];
  clientIds: Array<Scalars['PositiveInt']>;
};


export type MutationCreateClientArgs = {
  data: ClientInput;
};


export type MutationUpdateClientArgs = {
  id: Scalars['Int'];
  data: ClientInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
};


export type MutationSwitchClientArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationDeactivateClientArgs = {
  id: Scalars['Int'];
  reason: ClientDeactivateReason;
};


export type MutationActivateClientArgs = {
  id: Scalars['Int'];
};


export type MutationCreateDivisionArgs = {
  data: DivisionInput;
};


export type MutationUpdateDivisionArgs = {
  id: Scalars['Int'];
  data: DivisionInput;
};


export type MutationDeactivateDivisionArgs = {
  id: Scalars['Int'];
};


export type MutationActivateDivisionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDivisionArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateLeadOptionsArgs = {
  options: Array<LeadOptionInput>;
};


export type MutationUploadClientFileArgs = {
  filepath: Scalars['NonEmptyString'];
  file: Scalars['Upload'];
};


export type MutationDeleteClientFileArgs = {
  filepath: Scalars['NonEmptyString'];
};


export type MutationLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRevokeInvitationArgs = {
  id: Scalars['Int'];
};


export type MutationResendInvitationArgs = {
  id: Scalars['Int'];
};


export type MutationInviteUsersArgs = {
  emails: Array<Scalars['String']>;
  roleIds: Array<Scalars['Int']>;
};


export type MutationAcceptInvitationArgs = {
  token: Scalars['String'];
  data: InvitedUser;
};


export type MutationCreateUserArgs = {
  data: UserInput;
  password: Scalars['String'];
  roleIds: Array<Scalars['Int']>;
  relations: PermissionRelationsInput;
};


export type MutationUpdateMeArgs = {
  data: UserInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  data: UserInput;
  roleIds?: Maybe<Array<Scalars['Int']>>;
  relations?: Maybe<PermissionRelationsInput>;
};


export type MutationChangePasswordArgs = {
  id: Scalars['Int'];
  password: Scalars['String'];
  sendEmail: Scalars['Boolean'];
};


export type MutationDisableUserArgs = {
  id: Scalars['Int'];
};


export type MutationEnableUserArgs = {
  id: Scalars['Int'];
};


export type MutationAnonymizeUserArgs = {
  id: Scalars['Int'];
};


export type MutationForgotPasswordArgs = {
  username: Scalars['String'];
};


export type MutationRecoverPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyUserPasswordArgs = {
  id: Scalars['Int'];
  password: Scalars['String'];
};


export type MutationVerifyEmailTokenArgs = {
  token: Scalars['String'];
};


export type MutationCreateRoleArgs = {
  type?: Maybe<RoleType>;
  data: RoleInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['Int'];
  data: RoleInput;
  isDeprecated?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteRoleArgs = {
  id: Scalars['Int'];
};


export type MutationCreateLibraryShowArgs = {
  translation: ShowTranslationInput;
  data: LibraryShowInput;
};


export type MutationUpdateLibraryShowArgs = {
  id: Scalars['Int'];
  translation: ShowTranslationInput;
  data: LibraryShowInput;
};


export type MutationCreateClientShowArgs = {
  data: ClientShowInput;
  translation: ShowTranslationInput;
  showsDbId: Scalars['Int'];
};


export type MutationUpdateClientShowArgs = {
  translation: ShowTranslationInput;
  data: UpdateClientShowInput;
  id: Scalars['Int'];
};


export type MutationUpsertLibraryShowTranslationsArgs = {
  id: Scalars['Int'];
  data: Array<ShowTranslationInput>;
};


export type MutationUpsertClientShowTranslationsArgs = {
  id: Scalars['Int'];
  data: Array<ShowTranslationInput>;
};


export type MutationUploadLibraryImageArgs = {
  showId: Scalars['Int'];
  data: LibraryImageUploadInput;
  file: Scalars['Upload'];
};


export type MutationUploadClientShowImageArgs = {
  isPrivate: Scalars['Boolean'];
  data: ClientShowImageUploadInput;
  file: Scalars['Upload'];
};


export type MutationAssignLibraryImagesToClientShowArgs = {
  libraryImageIds: Array<Scalars['Int']>;
  showId: Scalars['Int'];
};


export type MutationDeleteClientShowImageArgs = {
  id: Scalars['Int'];
};


export type MutationUpsertLibraryShowCrewArgs = {
  id: Scalars['Int'];
  data: Array<ShowCrewByRoleInput>;
};


export type MutationUpsertClientShowCrewArgs = {
  id: Scalars['Int'];
  data: Array<ShowCrewByRoleInput>;
};


export type MutationUpdateClientShowImageOrderPositionArgs = {
  id: Scalars['PositiveInt'];
  newIndexPosition: Scalars['Int'];
};


export type MutationRemoveImageFromLibraryShowArgs = {
  showId: Scalars['PositiveInt'];
  imageId: Scalars['PositiveInt'];
};


export type MutationDeleteLibraryImageArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationActivateLibraryImageArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationAddClientShowVideoArgs = {
  id: Scalars['PositiveInt'];
  input: AddShowVideoInput;
};


export type MutationUpdateClientShowVideosArgs = {
  id: Scalars['PositiveInt'];
  input: Array<UpdateShowVideoInput>;
};


export type MutationAddLibraryShowVideoArgs = {
  id: Scalars['PositiveInt'];
  input: AddShowVideoInput;
};


export type MutationUpdateLibraryShowVideosArgs = {
  id: Scalars['PositiveInt'];
  input: Array<UpdateShowVideoInput>;
};


export type MutationDeleteClientShowArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationCreateVenueArgs = {
  data: VenueInput;
};


export type MutationUpdateVenueArgs = {
  id: Scalars['Int'];
  data: VenueInput;
};


export type MutationDeleteVenueArgs = {
  id: Scalars['Int'];
};


export type MutationCreateAuditoriumArgs = {
  data: AuditoriumInput;
};


export type MutationUpdateAuditoriumArgs = {
  id: Scalars['Int'];
  data: AuditoriumInput;
};


export type MutationDeleteAuditoriumArgs = {
  id: Scalars['Int'];
};


export type MutationCreateAuditoriumLayoutArgs = {
  auditoriumId: Scalars['Int'];
  type: AuditoriumLayoutType;
  data: AuditoriumLayoutInput;
};


export type MutationUpdateAuditoriumLayoutArgs = {
  id: Scalars['Int'];
  data: AuditoriumLayoutInput;
  seatGroups?: Maybe<Scalars['JSON']>;
};


export type MutationDeleteAuditoriumLayoutArgs = {
  id: Scalars['Int'];
};


export type MutationActivateAuditoriumLayoutArgs = {
  id: Scalars['Int'];
};


export type MutationArchiveAuditoriumLayoutArgs = {
  id: Scalars['Int'];
};


export type MutationCopyAuditoriumLayoutArgs = {
  auditoriumId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationDuplicateAuditoriumLayoutSupportUserArgs = {
  auditoriumId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationCreateAuditoriumLayoutPricingArgs = {
  auditoriumLayoutId: Scalars['Int'];
  data: AuditoriumLayoutPricingInput;
};


export type MutationUpdateAuditoriumLayoutPricingArgs = {
  id: Scalars['Int'];
  data: AuditoriumLayoutPricingInput;
};


export type MutationDeleteAuditoriumLayoutPricingArgs = {
  id: Scalars['Int'];
};


export type MutationActivateAuditoriumLayoutPricingArgs = {
  id: Scalars['Int'];
};


export type MutationArchiveAuditoriumLayoutPricingArgs = {
  id: Scalars['Int'];
};


export type MutationCreateTicketTypeArgs = {
  auditoriumLayoutPricingId: Scalars['Int'];
  data: TicketTypeInput;
};


export type MutationUpdateTicketTypeArgs = {
  id: Scalars['Int'];
  data: TicketTypeInput;
};


export type MutationDeleteTicketTypeArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateVenuesOrderArgs = {
  ids: Array<Scalars['PositiveInt']>;
};


export type MutationUpdateAuditoriumsOrderArgs = {
  venueId: Scalars['PositiveInt'];
  auditoriumIds: Array<Scalars['PositiveInt']>;
};


export type MutationCreateWebsiteArgs = {
  input: WebsiteInput;
};


export type MutationUpdateWebsiteArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateWebsiteInput;
};


export type MutationCreateWebsitePageArgs = {
  input: WebsitePageInput;
  localeCode: LocaleCode;
  websiteId: Scalars['PositiveInt'];
};


export type MutationDeleteWebsiteArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationDeleteWebsitePageArgs = {
  pageID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
};


export type MutationUpdateWebsitePageArgs = {
  pageID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
  input: UpdateWebsitePageInput;
};


export type MutationUpdateWebsitePageBlocksArgs = {
  pageID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
  blocks: Scalars['JSON'];
};


export type MutationDiscardWebsitePagesChangesArgs = {
  websiteId: Scalars['PositiveInt'];
};


export type MutationPublishWebsitePagesChangesArgs = {
  websiteId: Scalars['PositiveInt'];
};


export type MutationCreateWebsiteHeaderArgs = {
  websiteId: Scalars['PositiveInt'];
  localeCode: LocaleCode;
  input: CreateWebsiteHeaderInput;
};


export type MutationDeleteWebsiteHeaderArgs = {
  headerID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
};


export type MutationUpdateWebsiteHeaderArgs = {
  headerID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
  input: UpdateWebsiteHeaderInput;
};


export type MutationCreateWebsiteFooterArgs = {
  websiteId: Scalars['PositiveInt'];
  localeCode: LocaleCode;
  input: CreateWebsiteFooterInput;
};


export type MutationDeleteWebsiteFooterArgs = {
  footerID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
};


export type MutationUpdateWebsiteFooterArgs = {
  footerID: Scalars['NonEmptyString'];
  websiteId: Scalars['PositiveInt'];
  input: UpdateWebsiteFooterInput;
};


export type MutationUpdateWebsiteCommonSettingsArgs = {
  websiteId: Scalars['PositiveInt'];
  input: UpdateWebsiteCommonSettingsInput;
};


export type MutationCreateEventArgs = {
  data: CreateEventInput;
};


export type MutationMoveEventArgs = {
  id: Scalars['Int'];
  startsAt: Scalars['DateTime'];
};


export type MutationResizeEventArgs = {
  id: Scalars['Int'];
  duration: Scalars['PositiveInt'];
};


export type MutationUpdateGeneralEventDataArgs = {
  id: Scalars['Int'];
  data: GeneralEventDataInput;
};


export type MutationUpdateExtendedEventDataArgs = {
  id: Scalars['Int'];
  data: ExtendedEventDataInput;
};


export type MutationUpdateSalesEventDataArgs = {
  id: Scalars['Int'];
  data: EventSaleAndReservationInput;
};


export type MutationCopyEventsArgs = {
  auditoriumId: Scalars['Int'];
  ids: Array<Scalars['Int']>;
  daysOffset: Scalars['Int'];
};


export type MutationPublishEventArgs = {
  id: Scalars['Int'];
};


export type MutationPublishEventsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationCancelEventArgs = {
  id: Scalars['Int'];
};


export type MutationCancelEventsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteEventArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteEventsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationUpdateAllowedDiscountsSellingChannelsForEventArgs = {
  id: Scalars['Int'];
  sellingChannels: Array<SellingChannel>;
};


export type MutationCreateEventPricingArgs = {
  eventId: Scalars['Int'];
  data: EventPricingInput;
};


export type MutationUpdateEventPricingArgs = {
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  data: EventPricingInput;
};


export type MutationDeleteEventPricingArgs = {
  id: Scalars['Int'];
  eventId: Scalars['Int'];
};


export type MutationCopyEventArgs = {
  eventId: Scalars['PositiveInt'];
  destinationDateTime: Scalars['DateTime'];
  state?: Maybe<EventState>;
};


export type MutationCreateCostCenterArgs = {
  data: CostCenterInput;
};


export type MutationUpdateCostCenterArgs = {
  id: Scalars['Int'];
  data: CostCenterInput;
};


export type MutationCreateEventCategoryArgs = {
  data: EventCategoryInput;
};


export type MutationUpdateEventCategoryArgs = {
  id: Scalars['Int'];
  data: EventCategoryInput;
};


export type MutationCreateMarketingLabelArgs = {
  data: MarketingLabelInput;
};


export type MutationUpdateMarketingLabelArgs = {
  id: Scalars['Int'];
  data: MarketingLabelInput;
};


export type MutationCreateDiscountArgs = {
  input: DiscountInput;
};


export type MutationDeleteDiscountArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateDiscountArgs = {
  input: UpdateDiscountInput;
};


export type MutationCopyDiscountArgs = {
  id: Scalars['Int'];
  input: DiscountInput;
};


export type MutationCreateApplicationRuleArgs = {
  input: CreateApplicationRuleInput;
};


export type MutationDeleteApplicationRuleArgs = {
  id: Scalars['Int'];
};


export type MutationCreateConditionArgs = {
  input: CreateConditionInput;
};


export type MutationUpdateConditionArgs = {
  input: UpdateConditionInput;
};


export type MutationDeleteConditionArgs = {
  id: Scalars['Int'];
};


export type MutationCreateDiscountCodeArgs = {
  input: DiscountCodeInput;
  name?: Maybe<Scalars['String']>;
};


export type MutationCreateDiscountCodesArgs = {
  input: DiscountCodeInput;
  count: Scalars['PositiveInt'];
};


export type MutationInvalidateDiscountCodesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationAddSeatingTicketItemToCartArgs = {
  cartId?: Maybe<Scalars['Int']>;
  eventId: Scalars['Int'];
  eventSeatUUID: Scalars['String'];
};


export type MutationDiscardCartArgs = {
  cartId: Scalars['Int'];
};


export type MutationRemoveTicketItemFromCartArgs = {
  itemId: Scalars['Int'];
  cartId: Scalars['Int'];
};


export type MutationIncrementZoneTicketItemQuantityArgs = {
  eventId: Scalars['Int'];
  eventSeatUUID: Scalars['String'];
  cartId?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['NonNegativeInt']>;
};


export type MutationDecrementZoneTicketItemQuantityArgs = {
  eventId: Scalars['Int'];
  cartId: Scalars['Int'];
  eventSeatUUID: Scalars['String'];
  decrement?: Maybe<Scalars['NonNegativeInt']>;
};


export type MutationRemoveAllTicketItemsFromCartForEventArgs = {
  cartId: Scalars['Int'];
  eventId: Scalars['Int'];
};


export type MutationRemoveAllProductItemsFromCartArgs = {
  cartId: Scalars['Int'];
};


export type MutationCheckoutArgs = {
  input?: Maybe<LeadDataInput>;
  paymentIntentInputs?: Maybe<Array<PaymentIntentInput>>;
  cartId: Scalars['Int'];
  customerId?: Maybe<Scalars['PositiveInt']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  localeCode?: Maybe<ECommerceLocaleCode>;
};


export type MutationReserveArgs = {
  input?: Maybe<LeadDataInput>;
  cartId: Scalars['Int'];
  customerId?: Maybe<Scalars['PositiveInt']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  localeCode?: Maybe<ECommerceLocaleCode>;
};


export type MutationPostponeCartExpirationArgs = {
  cartId: Scalars['Int'];
};


export type MutationAddDiscountToCartTicketItemsArgs = {
  cartId: Scalars['Int'];
  itemIds: Array<Scalars['Int']>;
  discountId: Scalars['Int'];
  discountCodeId?: Maybe<Scalars['Int']>;
  customerLoyaltyId?: Maybe<Scalars['NonEmptyString']>;
};


export type MutationUpdateSeatingTicketItemsQuantityInCartArgs = {
  cartId?: Maybe<Scalars['Int']>;
  eventId: Scalars['Int'];
  eventSeatUUIDs: Array<Scalars['String']>;
};


export type MutationRemoveDiscountFromItemArgs = {
  discountId: Scalars['Int'];
  cartId: Scalars['Int'];
  itemId: Scalars['Int'];
};


export type MutationIncrementDiscountQuantityForCartTicketItemsArgs = {
  cartId: Scalars['Int'];
  eventId: Scalars['Int'];
  discountId: Scalars['Int'];
  discountCodeId?: Maybe<Scalars['Int']>;
  customerLoyaltyId?: Maybe<Scalars['NonEmptyString']>;
  increment?: Maybe<Scalars['NonNegativeInt']>;
};


export type MutationDecrementDiscountQuantityFromCartTicketItemsArgs = {
  cartId: Scalars['Int'];
  eventId: Scalars['Int'];
  discountId: Scalars['Int'];
  discountCodeId?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['NonNegativeInt']>;
};


export type MutationIncrementProductItemQuantityArgs = {
  cartId?: Maybe<Scalars['PositiveInt']>;
  warehouseId?: Maybe<Scalars['PositiveInt']>;
  productId: Scalars['PositiveInt'];
  divisionId: Scalars['PositiveInt'];
  increment: Scalars['PositiveInt'];
};


export type MutationDecrementProductItemQuantityArgs = {
  cartId: Scalars['PositiveInt'];
  productItemId: Scalars['PositiveInt'];
  decrement: Scalars['PositiveInt'];
};


export type MutationIncrementProductItemQuantityWithPriceLookupCodeArgs = {
  cartId?: Maybe<Scalars['PositiveInt']>;
  warehouseId?: Maybe<Scalars['PositiveInt']>;
  priceLookupCode: Scalars['PositiveInt'];
  divisionId: Scalars['PositiveInt'];
  increment: Scalars['PositiveInt'];
};


export type MutationIncrementProductItemQuantityWithBarcodeArgs = {
  cartId?: Maybe<Scalars['PositiveInt']>;
  warehouseId?: Maybe<Scalars['PositiveInt']>;
  barcode: Scalars['NonEmptyString'];
  divisionId: Scalars['PositiveInt'];
  increment: Scalars['PositiveInt'];
};


export type MutationIncrementTourItemQuantityArgs = {
  cartId?: Maybe<Scalars['PositiveInt']>;
  tourTimeSlotId: Scalars['PositiveInt'];
  admissionTypeAssignmentId: Scalars['PositiveInt'];
  increment: Scalars['PositiveInt'];
};


export type MutationDecrementTourItemQuantityArgs = {
  cartId: Scalars['PositiveInt'];
  tourTimeSlotId: Scalars['PositiveInt'];
  admissionTypeAssignmentId: Scalars['PositiveInt'];
  decrement: Scalars['PositiveInt'];
};


export type MutationRemoveTourItemFromCartArgs = {
  itemId: Scalars['PositiveInt'];
  cartId: Scalars['PositiveInt'];
};


export type MutationRemoveAllTourItemsFromCartForTimeSlotArgs = {
  cartId: Scalars['PositiveInt'];
  tourTimeSlotId: Scalars['PositiveInt'];
};


export type MutationAddDiscountToTourItemsArgs = {
  cartId: Scalars['PositiveInt'];
  itemIds: Array<Scalars['PositiveInt']>;
  discountId: Scalars['PositiveInt'];
  discountCodeId?: Maybe<Scalars['PositiveInt']>;
  customerLoyaltyId?: Maybe<Scalars['NonEmptyString']>;
};


export type MutationChangeEventSeatsAvailabilityArgs = {
  eventId: Scalars['PositiveInt'];
  uuids: Array<Scalars['NonEmptyString']>;
  state?: Maybe<ApiSeatState>;
};


export type MutationChangeEventZoneSeatsAvailabilityArgs = {
  eventId: Scalars['PositiveInt'];
  uuid: Scalars['NonEmptyString'];
  count?: Maybe<Scalars['PositiveInt']>;
  fromState: ApiSeatState;
  toState: ApiSeatState;
};


export type MutationCreatePaymentMethodGroupArgs = {
  name: Scalars['String'];
};


export type MutationEditPaymentMethodGroupArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationDeletePaymentMethodGroupArgs = {
  id: Scalars['Int'];
};


export type MutationCreateRetailPaymentMethodArgs = {
  input: RetailPaymentMethodInput;
  paymentServiceProviderConfigInput?: Maybe<PaymentServiceProviderConfigInput>;
};


export type MutationCreateECommercePaymentMethodArgs = {
  input: EcommercePaymentMethodInput;
  paymentServiceProviderConfigInput?: Maybe<PaymentServiceProviderConfigInput>;
};


export type MutationDeleteRetailPaymentMethodArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteECommercePaymentMethodArgs = {
  id: Scalars['Int'];
};


export type MutationUpdatePaymentMethodArgs = {
  id: Scalars['Int'];
  input: UpdatePaymentMethodInput;
};


export type MutationCreateCashDeskDepositArgs = {
  note?: Maybe<Scalars['NonEmptyString']>;
  paymentInputs: Array<PaymentInput>;
};


export type MutationCreateCashDeskWithdrawalArgs = {
  note?: Maybe<Scalars['NonEmptyString']>;
  paymentInputs: Array<PaymentInput>;
};


export type MutationCreateDailyCashierPaymentReportArgs = {
  input: CreateDailyCashierPaymentReportInput;
};


export type MutationNotifyUserReturnedFromPaymentGatewayArgs = {
  paymentServiceProvider: PaymentServiceProvider;
  uuid: Scalars['String'];
  hash: Scalars['String'];
  paymentIntentId: Scalars['PositiveInt'];
};


export type MutationCreatePaymentsCsvLinkArgs = {
  filter?: Maybe<PaymentsFilterInput>;
  localeCode?: Maybe<LocaleCode>;
  showSalesData?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateMonthlyECommercePaymentReportArgs = {
  input: CreateMonthlyECommercePaymentReportInput;
};


export type MutationCreateMonthlyPaymentReportArgs = {
  input: CreateMonthlyPaymentReportInput;
};


export type MutationAddSeatingTicketItemToReservationArgs = {
  id: Scalars['Int'];
  eventSeatUUID: Scalars['String'];
};


export type MutationAddZoneTicketItemToReservationArgs = {
  id: Scalars['Int'];
  eventSeatUUID: Scalars['String'];
  count: Scalars['NonNegativeInt'];
};


export type MutationUpdateReservationArgs = {
  id: Scalars['Int'];
  expireAt: Scalars['DateTime'];
  leadDataInput: LeadDataInput;
};


export type MutationSplitReservationArgs = {
  reservationId: Scalars['Int'];
  cartId?: Maybe<Scalars['Int']>;
  itemIds: Array<Scalars['Int']>;
};


export type MutationAddReservationToCartArgs = {
  reservationId: Scalars['Int'];
  cartId?: Maybe<Scalars['Int']>;
  reservationEcommerceIdentifiers?: Maybe<EcommerceIdentifiers>;
};


export type MutationRemoveTicketItemsFromReservationArgs = {
  itemIds: Array<Scalars['Int']>;
  reservationId: Scalars['Int'];
};


export type MutationRemoveZoneTicketItemsFromReservationArgs = {
  reservationId: Scalars['Int'];
  eventSeatUUID: Scalars['String'];
  count?: Maybe<Scalars['NonNegativeInt']>;
};


export type MutationRemoveTourItemFromReservationArgs = {
  itemId: Scalars['PositiveInt'];
  reservationId: Scalars['PositiveInt'];
};


export type MutationDecrementTourItemQuantityInReservationArgs = {
  reservationId: Scalars['PositiveInt'];
  admissionTypeAssignmentId: Scalars['PositiveInt'];
  decrement: Scalars['PositiveInt'];
};


export type MutationIncrementTourItemQuantityInReservationArgs = {
  reservationId: Scalars['PositiveInt'];
  admissionTypeAssignmentId: Scalars['PositiveInt'];
  increment: Scalars['PositiveInt'];
};


export type MutationCancelReservationArgs = {
  reservationId: Scalars['Int'];
  reservationEcommerceIdentifiers?: Maybe<EcommerceIdentifiers>;
};


export type MutationRemoveReservationFromCartArgs = {
  cartId: Scalars['Int'];
  reservationId: Scalars['Int'];
  reservationEcommerceIdentifiers?: Maybe<EcommerceIdentifiers>;
};


export type MutationAddDiscountToReservationTicketItemsArgs = {
  reservationId: Scalars['Int'];
  itemIds: Array<Scalars['Int']>;
  discountId: Scalars['Int'];
  discountCodeId?: Maybe<Scalars['Int']>;
  customerLoyaltyId?: Maybe<Scalars['NonEmptyString']>;
};


export type MutationRemoveDiscountFromReservationTicketItemArgs = {
  discountId: Scalars['Int'];
  reservationId: Scalars['Int'];
  itemId: Scalars['Int'];
};


export type MutationIncrementDiscountQuantityForReservationTicketItemsArgs = {
  reservationId: Scalars['Int'];
  discountId: Scalars['Int'];
  discountCodeId?: Maybe<Scalars['Int']>;
  customerLoyaltyId?: Maybe<Scalars['NonEmptyString']>;
  increment?: Maybe<Scalars['NonNegativeInt']>;
};


export type MutationDecrementDiscountQuantityFromReservationTicketItemsArgs = {
  reservationId: Scalars['Int'];
  discountId: Scalars['Int'];
  discountCodeId?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['NonNegativeInt']>;
};


export type MutationSendReservationConfirmationEmailArgs = {
  cartUUID: Scalars['String'];
  cartHash: Scalars['String'];
  reservationIds: Array<Scalars['Int']>;
  deliverTo: Array<Scalars['String']>;
  customEmailTemplateData?: Maybe<CustomEmailTemplateDataInput>;
};


export type MutationSendSaleConfirmationEmailArgs = {
  cartUUID: Scalars['String'];
  cartHash: Scalars['String'];
  saleInputs: Array<SaleInput>;
  deliverTo: Array<Scalars['String']>;
  customEmailTemplateData?: Maybe<CustomEmailTemplateDataInput>;
};


export type MutationUpdateReservationLeadArgs = {
  reservationId: Scalars['Int'];
  input: LeadDataInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationCreateClaimArgs = {
  reason: Scalars['String'];
  itemIds: Array<Scalars['Int']>;
};


export type MutationAddItemsToClaimArgs = {
  claimId: Scalars['Int'];
  itemIds: Array<Scalars['Int']>;
};


export type MutationRemoveItemsFromClaimArgs = {
  claimId: Scalars['Int'];
  itemIds: Array<Scalars['Int']>;
};


export type MutationDeleteClaimArgs = {
  id: Scalars['Int'];
};


export type MutationApproveClaimArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateClaimReasonArgs = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};


export type MutationUpsertClaimLeadArgs = {
  id: Scalars['Int'];
  input: LeadDataInput;
};


export type MutationDeleteClaimLeadArgs = {
  claimId: Scalars['Int'];
  leadId: Scalars['Int'];
};


export type MutationRefundClaimOnRetailChannelArgs = {
  id: Scalars['Int'];
  refundIntentInputs: Array<RefundIntentInput>;
};


export type MutationRefundClaimOnECommerceChannelArgs = {
  id: Scalars['Int'];
  refundIntentInputs: Array<RefundIntentInput>;
};


export type MutationRefundClaimByInHouseVoucherArgs = {
  id: Scalars['PositiveInt'];
  input: VoucherInput;
};


export type MutationCheckPassCodeInArgs = {
  passCode: Scalars['String'];
  inputType: PassCodeCheckInputType;
  discountAuthorizationMode: DiscountAuthorizationMode;
  itemFilter?: Maybe<ItemFilter>;
};


export type MutationCheckPassCodeOutArgs = {
  passCode: Scalars['String'];
  inputType: PassCodeCheckInputType;
  itemFilter?: Maybe<ItemFilter>;
};


export type MutationUpdatePassCodeCheckArgs = {
  id: Scalars['PositiveInt'];
  authorizeDiscount: Scalars['Boolean'];
};


export type MutationCheckPassCodesArgs = {
  itemFilter?: Maybe<ItemFilter>;
  passCodes: Array<Scalars['NonEmptyString']>;
  direction: PassCodeCheckDirection;
  inputType: PassCodeCheckInputType;
  discountAuthorizationMode: DiscountAuthorizationMode;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationEditTemplateVersionCodeArgs = {
  id: Scalars['PositiveInt'];
  code: Scalars['String'];
};


export type MutationAssignTemplateToClientArgs = {
  templateId: Scalars['PositiveInt'];
  clientId: Scalars['PositiveInt'];
};


export type MutationRemoveTemplateFromClientArgs = {
  templateId: Scalars['PositiveInt'];
  clientId: Scalars['PositiveInt'];
};


export type MutationUpdateTemplateArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateTemplateInput;
};


export type MutationCreateTourArgs = {
  input: TourInput;
};


export type MutationUpdateTourArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateTourInput;
};


export type MutationCreateAdmissionTypeArgs = {
  input: AdmissionTypeInput;
};


export type MutationUpdateDraftAdmissionTypeArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateDraftAdmissionTypeInput;
};


export type MutationUpdateAdmissionTypeArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateAdmissionTypeInput;
};


export type MutationDeleteDraftAdmissionTypeArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationDeleteTourArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationCreateAdmissionRateArgs = {
  input: AdmissionRateInput;
};


export type MutationUpdateAdmissionRateArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateAdmissionRateInput;
};


export type MutationDeleteAdmissionRateArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationUpdateDraftAdmissionRateAssignmentsArgs = {
  admissionRateId: Scalars['PositiveInt'];
  assignmentInputs: Array<DraftAdmissionRateAssignmentsInput>;
};


export type MutationUpdateAdmissionRateAssignmentsArgs = {
  admissionRateId: Scalars['PositiveInt'];
  assignmentInputs: Array<AdmissionRateAssignmentsInput>;
};


export type MutationCreateTourTimeSlotArgs = {
  input: TourTimeSlotInput;
};


export type MutationDeleteTourTimeSlotArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationDeleteTourTimeSlotsArgs = {
  ids: Array<Scalars['PositiveInt']>;
};


export type MutationUpdateTourTimeSlotSaleDataArgs = {
  id: Scalars['PositiveInt'];
  input: TourTimeSlotSaleDataInput;
};


export type MutationUpdateTourTimeSlotsArgs = {
  ids: Array<Scalars['PositiveInt']>;
  input: UpdateTourTimeSlotsInput;
};


export type MutationCopyTourTimeSlotsArgs = {
  input: CopyTourTimeSlotsInput;
};


export type MutationCopyAdmissionRateArgs = {
  input: CopyAdmissionRateInput;
};


export type MutationCreateAccessZoneArgs = {
  input: AccessZoneInput;
};


export type MutationUpdateAccessZoneArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateAccessZoneInput;
};


export type MutationAssignAccessZoneToAdmissionRateTypeArgs = {
  admissionTypeToRateId: Scalars['PositiveInt'];
  accessZoneIds: Array<Scalars['PositiveInt']>;
};


export type MutationRemoveAccessZoneFromAdmissionRateTypeArgs = {
  admissionTypeToRateId: Scalars['PositiveInt'];
  accessZoneIds: Array<Scalars['PositiveInt']>;
};


export type MutationCreateProductsStatisticsCsvLinkArgs = {
  filter?: Maybe<ProductsStatisticsFilterInput>;
  localeCode?: Maybe<LocaleCode>;
};


export type MutationCreateEventsStatisticsCsvLinkArgs = {
  filter?: Maybe<EventsStatisticsFilterInput>;
  localeCode?: Maybe<LocaleCode>;
};


export type MutationCreateProductTypeArgs = {
  input: CreateProductTypeInput;
};


export type MutationUpdateProductTypeArgs = {
  id: Scalars['Int'];
  input: UpdateProductTypeInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateProductInput;
};


export type MutationUpsertProductAvailabilityArgs = {
  id: Scalars['PositiveInt'];
  productAvailabilityInputs: Array<ProductAvailabilityInput>;
};


export type MutationCreateProductBarcodeArgs = {
  input: CreateProductBarcodeInput;
};


export type MutationCreateProductPricingArgs = {
  id: Scalars['PositiveInt'];
  input: ProductPricingInput;
};


export type MutationDeleteProductCoverImageArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationDeleteProductBarcodeArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationDeleteProductPricingArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationCreateWarehouseArgs = {
  input: CreateWarehouseInput;
};


export type MutationUpdateWarehouseArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateWarehouseInput;
};


export type MutationCreateProductGroupArgs = {
  input: CreateProductGroupInput;
};


export type MutationUpdateProductGroupArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateProductGroupInput;
};


export type MutationAssignProductGroupToProductArgs = {
  productId: Scalars['PositiveInt'];
  productGroupId: Scalars['PositiveInt'];
};


export type MutationAssignProductToProductGroupArgs = {
  productId: Scalars['PositiveInt'];
  productGroupId: Scalars['PositiveInt'];
};


export type MutationRemoveProductFromProductGroupArgs = {
  productId: Scalars['PositiveInt'];
  productGroupId: Scalars['PositiveInt'];
};


export type MutationRemoveProductGroupFromProductArgs = {
  productId: Scalars['PositiveInt'];
  productGroupId: Scalars['PositiveInt'];
};


export type MutationUpsertProductGroupAvailabilityArgs = {
  id: Scalars['PositiveInt'];
  productGroupAvailabilityInputs: Array<ProductGroupAvailabilityInput>;
};


export type MutationUpdateProductsOrderInProductGroupArgs = {
  productGroupId: Scalars['PositiveInt'];
  productIds: Array<Scalars['PositiveInt']>;
};


export type MutationUpdateProductGroupsOrderArgs = {
  ids: Array<Scalars['PositiveInt']>;
};


export type MutationCreateWarehouseDocumentArgs = {
  input: CreateWarehouseDocumentInput;
};


export type MutationUpdateDraftWarehouseDocumentArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateDraftWarehouseDocumentInput;
};


export type MutationUploadProductCoverImageArgs = {
  id: Scalars['PositiveInt'];
  image: Scalars['Upload'];
};


export type MutationCreateBusinessPartnerArgs = {
  input: CreateBusinessPartnerInput;
};


export type MutationUpdateBusinessPartnerArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateBusinessPartnerInput;
};


export type MutationCreateWarehouseProductArgs = {
  input: CreateWarehouseProductInput;
};


export type MutationAddItemToWarehouseDocumentArgs = {
  input: AddItemToWarehouseDocumentInput;
};


export type MutationRemoveItemFromWarehouseDocumentArgs = {
  itemId: Scalars['PositiveInt'];
  documentId: Scalars['PositiveInt'];
};


export type MutationDeleteDraftWarehouseDocumentArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationIssueWarehouseDocumentArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationUpdateWarehouseProductArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateWarehouseProductInput;
};


export type MutationCreateInventoryCheckArgs = {
  input: CreateInventoryCheckInput;
};


export type MutationDeleteInventoryCheckArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationUpdateInventoryCheckProductArgs = {
  input: UpdateInventoryCheckProductInput;
};


export type MutationUpdateInventoryCheckArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateInventoryCheckInput;
};


export type MutationCompleteInventoryCheckArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationCreateVoucherCampaignArgs = {
  input: CreateVoucherCampaignInput;
};


export type MutationCreateVouchersArgs = {
  input: CreateVouchersInput;
};


export type MutationChangeVoucherStateArgs = {
  id: Scalars['PositiveInt'];
  state: VoucherState;
};


export type MutationChangeVoucherCampaignStateArgs = {
  id: Scalars['PositiveInt'];
  state: VoucherCampaignState;
};


export type MutationCreditVoucherArgs = {
  id: Scalars['PositiveInt'];
  input: CreditVoucherInput;
};


export type MutationSignUpCustomerArgs = {
  input: CustomerInput;
};


export type MutationForgotCustomerPasswordArgs = {
  username: Scalars['NonEmptyString'];
  clientId: Scalars['PositiveInt'];
};


export type MutationSignInCustomerArgs = {
  username: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
  clientId: Scalars['PositiveInt'];
};


export type MutationRecoverCustomerPasswordArgs = {
  token: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
};


export type MutationChangePasswordForSignedInCustomerArgs = {
  password: Scalars['NonEmptyString'];
};


export type MutationUpdateSignedInCustomerArgs = {
  input: UpdateSignedInCustomerInput;
};


export type MutationCreateCustomerGroupArgs = {
  input: CreateCustomerGroupInput;
};


export type MutationUpdateCustomerGroupArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateCustomerGroupInput;
};


export type MutationUpdateCustomerArgs = {
  id: Scalars['PositiveInt'];
  input: UpdateCustomerInput;
};


export type MutationDeleteCustomerGroupArgs = {
  id: Scalars['PositiveInt'];
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationExportStatisticsFromDatagridAsExcelFileArgs = {
  dataGridTableData: DataGridTableData;
  statisticsType: DataExportStatisticsType;
  filter?: Maybe<DataExportStatisticsFilterInput>;
  localeCode?: Maybe<LocaleCode>;
  additionalHeaderInformation?: Maybe<Array<Scalars['NonEmptyString']>>;
};


export type MutationExportStatisticsFromDatagridAsCsvFileArgs = {
  dataGridTableData: DataGridTableData;
  statisticsType: DataExportStatisticsType;
  filter?: Maybe<DataExportStatisticsFilterInput>;
  localeCode?: Maybe<LocaleCode>;
  additionalHeaderInformation?: Maybe<Array<Scalars['NonEmptyString']>>;
  delimiter?: Maybe<Scalars['NonEmptyString']>;
};


export type MutationImportDiscountCodesForClient87Args = {
  createdDateFrom?: Maybe<Scalars['Date']>;
  createdDateTo?: Maybe<Scalars['Date']>;
  updatedDateFrom?: Maybe<Scalars['DateTime']>;
  updatedDateTo?: Maybe<Scalars['DateTime']>;
  clientId: Scalars['PositiveInt'];
  discountId: Scalars['PositiveInt'];
};

export type ApiGrantsFilterInput = {
  state?: Maybe<ApiGrantState>;
  clientId?: Maybe<Scalars['PositiveInt']>;
};

export type PaginatedApiGrants = {
  __typename?: 'PaginatedApiGrants';
  items: Array<ApiGrant>;
  pagination: Pagination;
};

export enum LeadOptionType {
  Retail = 'retail',
  ECommerce = 'eCommerce',
  RetailReservation = 'retailReservation',
  ECommerceReservation = 'eCommerceReservation'
}

export enum LeadInputStatus {
  Unavailable = 'unavailable',
  Optional = 'optional',
  Recommended = 'recommended',
  Required = 'required'
}

export enum LeadField {
  Name = 'name',
  Phone = 'phone',
  Email = 'email',
  Note = 'note',
  InternalNote = 'internalNote',
  CompanyName = 'companyName',
  CompanyIdNumber = 'companyIdNumber',
  VatId = 'VATId',
  TaxId = 'TAXId',
  BillingAddressStreet = 'billingAddressStreet',
  BillingAddressTown = 'billingAddressTown',
  BillingPostalCode = 'billingPostalCode',
  BillingAddressCountry = 'billingAddressCountry',
  DeliveryAddressee = 'deliveryAddressee',
  DeliveryAddressStreet = 'deliveryAddressStreet',
  DeliveryAddressTown = 'deliveryAddressTown',
  DeliveryPostalCode = 'deliveryPostalCode',
  DeliveryAddressCountry = 'deliveryAddressCountry',
  IsPrivacyPolicyConsentGranted = 'isPrivacyPolicyConsentGranted'
}

export type LeadOptionInput = {
  id: Scalars['Int'];
  helperText?: Maybe<Scalars['String']>;
  inputStatus?: Maybe<LeadInputStatus>;
};

export type ClientFile = {
  __typename?: 'ClientFile';
  url: Scalars['NonEmptyString'];
  fileName: Scalars['NonEmptyString'];
};

export type DivisionFilter = {
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type ClientInput = {
  name: Scalars['String'];
  type: ClientType;
  countryCode: ExistingCountryCode;
  currency: Currency;
  localeCodes: Array<LocaleCode>;
  companyIdNumber: Scalars['String'];
  VATRegistered: ClientVatRegistered;
  TAXId?: Maybe<Scalars['String']>;
  VATId?: Maybe<Scalars['String']>;
  ticketDefaultVatRate?: Maybe<Scalars['NonNegativeFloat']>;
  legalAddress: AddressInput;
  mailingAddress?: Maybe<Scalars['String']>;
  invoiceAddress?: Maybe<Scalars['String']>;
  dealerId: Scalars['Int'];
  termsOfServiceUrl?: Maybe<Scalars['String']>;
  marketingInformationUrl?: Maybe<Scalars['String']>;
  eCommerceUrl?: Maybe<Scalars['Url']>;
};

export type CompanyInput = {
  name: Scalars['String'];
  countryCode: ExistingCountryCode;
  companyIdNumber: Scalars['String'];
  TAXId?: Maybe<Scalars['String']>;
  VATId?: Maybe<Scalars['String']>;
  ticketDefaultVatRate?: Maybe<Scalars['NonNegativeFloat']>;
  legalAddress: AddressInput;
  mailingAddress?: Maybe<Scalars['String']>;
  invoiceAddress?: Maybe<Scalars['String']>;
  termsOfServiceUrl?: Maybe<Scalars['String']>;
  marketingInformationUrl?: Maybe<Scalars['String']>;
  eCommerceUrl?: Maybe<Scalars['Url']>;
};

export type DivisionInput = {
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  type: DivisionType;
  address: AddressInput;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  IBAN: Scalars['String'];
  SWIFT: Scalars['String'];
  invoiceFreq: DivisionInvoiceFreq;
  specificSymbol: Scalars['PositiveInt'];
  serviceTime?: Maybe<Scalars['NonNegativeInt']>;
  website?: Maybe<Scalars['NonEmptyString']>;
  onlinePurchaseActive: Scalars['Boolean'];
  onlineReservationActive: Scalars['Boolean'];
  posPurchaseActive: Scalars['Boolean'];
  posReservationActive: Scalars['Boolean'];
  gateOpensType: DivisionGateOpens;
  gateOpensValue: Scalars['NonNegativeInt'];
  gateClosesType: DivisionGateCloses;
  gateClosesValue: Scalars['NonNegativeInt'];
  checkingOption: DivisionCheckingOption;
  salesEndCashDeskValue: Scalars['NonNegativeInt'];
  salesEndCashDeskType: DivisionPosSalesEnd;
  salesEndWebValue: Scalars['NonNegativeInt'];
  salesEndWebType: DivisionOnlineSalesEnd;
  reservationEndCashDeskValue: Scalars['NonNegativeInt'];
  reservationEndCashDeskType: DivisionPosReservationEnd;
  reservationEndWebValue: Scalars['NonNegativeInt'];
  reservationEndWebType: DivisionOnlineReservationEnd;
  onlineCreateReservationEndValue: Scalars['NonNegativeInt'];
  onlineCreateReservationEndType: DivisionCreateOnlineReservationEnd;
  posCreateReservationEndValue: Scalars['NonNegativeInt'];
  posCreateReservationEndType: DivisionCreatePosReservationEnd;
};

export type LeadOption = {
  __typename?: 'LeadOption';
  id: Scalars['Int'];
  type: LeadOptionType;
  inputStatus: LeadInputStatus;
  field: LeadField;
  helperText?: Maybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['Int'];
  name: Scalars['String'];
  type: ClientType;
  countryCode: ExistingCountryCode;
  currency: Currency;
  localeCodes: Array<LocaleCode>;
  state: ClientState;
  deactivateReason?: Maybe<ClientDeactivateReason>;
  companyIdNumber: Scalars['String'];
  VATRegistered: ClientVatRegistered;
  TAXId?: Maybe<Scalars['String']>;
  VATId?: Maybe<Scalars['String']>;
  ticketDefaultVatRate?: Maybe<Scalars['NonNegativeFloat']>;
  legalAddress: Address;
  mailingAddress?: Maybe<Scalars['String']>;
  invoiceAddress?: Maybe<Scalars['String']>;
  dealerId: Scalars['Int'];
  dealer?: Maybe<User>;
  users: Array<User>;
  divisions: Array<Division>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  retailLeadOptions: Array<LeadOption>;
  eCommerceLeadOptions: Array<LeadOption>;
  retailReservationLeadOptions: Array<LeadOption>;
  eCommerceReservationLeadOptions: Array<LeadOption>;
  eCommercePaymentMethods: Array<PaymentMethod>;
  termsOfServiceUrl?: Maybe<Scalars['String']>;
  marketingInformationUrl?: Maybe<Scalars['String']>;
  appProviderB2CTermsOfServiceUrl: Scalars['String'];
  templateAssignments: Array<TemplateAssignment>;
  eCommerceUrl?: Maybe<Scalars['Url']>;
  websites: Array<Website>;
};


export type ClientECommercePaymentMethodsArgs = {
  state?: Maybe<PaymentMethodState>;
};

export enum ClientType {
  Distributor = 'distributor',
  Operator = 'operator'
}

export enum ClientState {
  Potential = 'potential',
  Active = 'active',
  Inactive = 'inactive'
}

export enum ClientVatRegistered {
  Full = 'full',
  Part = 'part',
  None = 'none'
}

export enum ClientDeactivateReason {
  Reason = 'reason',
  Other = 'other'
}

export type Division = {
  __typename?: 'Division';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  client: Client;
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  state: DivisionState;
  type: DivisionType;
  address: Address;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  IBAN: Scalars['String'];
  SWIFT: Scalars['String'];
  invoiceFreq: DivisionInvoiceFreq;
  specificSymbol: Scalars['Int'];
  serviceTime?: Maybe<Scalars['NonNegativeInt']>;
  website?: Maybe<Scalars['NonEmptyString']>;
  onlinePurchaseActive: Scalars['Boolean'];
  onlineReservationActive: Scalars['Boolean'];
  posPurchaseActive: Scalars['Boolean'];
  posReservationActive: Scalars['Boolean'];
  gateOpensType: DivisionGateOpens;
  gateOpensValue: Scalars['Int'];
  gateClosesType: DivisionGateCloses;
  gateClosesValue: Scalars['Int'];
  checkingOption: DivisionCheckingOption;
  salesEndCashDeskValue: Scalars['Int'];
  salesEndCashDeskType: DivisionPosSalesEnd;
  salesEndWebValue: Scalars['Int'];
  salesEndWebType: DivisionOnlineSalesEnd;
  reservationEndCashDeskValue: Scalars['Int'];
  reservationEndCashDeskType: DivisionPosReservationEnd;
  reservationEndWebValue: Scalars['Int'];
  reservationEndWebType: DivisionOnlineReservationEnd;
  onlineCreateReservationEndValue: Scalars['Int'];
  onlineCreateReservationEndType: DivisionCreateOnlineReservationEnd;
  posCreateReservationEndValue: Scalars['Int'];
  posCreateReservationEndType: DivisionCreatePosReservationEnd;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum DivisionState {
  Draft = 'draft',
  Active = 'active',
  Inactive = 'inactive'
}

export enum DivisionType {
  Cinema = 'cinema',
  Multiplex = 'multiplex',
  HouseOfCulture = 'houseOfCulture',
  Theatre = 'theatre',
  Museum = 'museum',
  Gallery = 'gallery',
  Amphitheatre = 'amphitheatre',
  Festival = 'festival',
  AmusementPark = 'amusementPark',
  SportsGrounds = 'sportsGrounds',
  Agency = 'agency',
  Other = 'other'
}

export enum DivisionInvoiceFreq {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  ByEvent = 'byEvent'
}

export enum DivisionTimeOffsetOptions {
  EventStart = 'eventStart',
  BeforeStart = 'beforeStart',
  AfterStart = 'afterStart',
  DayBeforeStart = 'dayBeforeStart',
  DayOfEvent = 'dayOfEvent',
  Duration = 'duration',
  BeforeEnd = 'beforeEnd',
  AfterEnd = 'afterEnd'
}

export enum DivisionOnlineSalesEnd {
  EventStart = 'eventStart',
  BeforeStart = 'beforeStart',
  AfterStart = 'afterStart',
  DayBeforeStart = 'dayBeforeStart'
}

export enum DivisionPosSalesEnd {
  EventStart = 'eventStart',
  BeforeStart = 'beforeStart',
  AfterStart = 'afterStart',
  DayBeforeStart = 'dayBeforeStart',
  DayOfEvent = 'dayOfEvent'
}

export enum DivisionOnlineReservationEnd {
  EventStart = 'eventStart',
  BeforeStart = 'beforeStart',
  AfterStart = 'afterStart',
  DayBeforeStart = 'dayBeforeStart',
  Duration = 'duration'
}

export enum DivisionPosReservationEnd {
  EventStart = 'eventStart',
  BeforeStart = 'beforeStart',
  AfterStart = 'afterStart',
  DayBeforeStart = 'dayBeforeStart',
  Duration = 'duration'
}

export enum DivisionCreateOnlineReservationEnd {
  BeforeStart = 'beforeStart',
  AfterStart = 'afterStart',
  DayBeforeStart = 'dayBeforeStart'
}

export enum DivisionCreatePosReservationEnd {
  EventStart = 'eventStart',
  BeforeStart = 'beforeStart',
  AfterStart = 'afterStart',
  DayBeforeStart = 'dayBeforeStart'
}

export enum DivisionGateOpens {
  BeforeStart = 'beforeStart',
  DayBeforeStart = 'dayBeforeStart'
}

export enum DivisionGateCloses {
  BeforeStart = 'beforeStart',
  AfterStart = 'afterStart',
  DayOfEvent = 'dayOfEvent',
  AfterEnd = 'afterEnd',
  BeforeEnd = 'beforeEnd'
}

export enum DivisionCheckingOption {
  Entrance = 'entrance',
  EntranceAndExit = 'entranceAndExit'
}

export type UsersFilter = {
  userIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type LoginLeadResponse = {
  __typename?: 'LoginLeadResponse';
  lead: User;
  /** Encoded cookie for safari browsers. (Safari browsers doesn't store cookies. Cookie needs to be stored explicitly) */
  eSid: Scalars['String'];
};

export type UserInput = {
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Title>;
  degree?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobilPhoneNumber?: Maybe<Scalars['String']>;
  localeCode: LocaleCode;
  timezone: Timezone;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  clientId?: Maybe<Scalars['Int']>;
  client?: Maybe<Client>;
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Title>;
  degree?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobilPhoneNumber?: Maybe<Scalars['String']>;
  localeCode: LocaleCode;
  timezone: Timezone;
  state: UserState;
  lastLoggedIn?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  roleIds: Array<Scalars['Int']>;
  roles: Array<Role>;
  eventManagedDivisionIds: Array<Scalars['Int']>;
  eventManagedVenueIds: Array<Scalars['Int']>;
  permissionCodes: Array<Scalars['String']>;
  permissions: Array<Permission>;
  isLead: Scalars['Boolean'];
};

export type RoleInput = {
  names: TranslatedInput;
  descriptions: TranslatedInput;
  permissionCodes: Array<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  isDeprecated?: Maybe<Scalars['Boolean']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int'];
  type: RoleType;
  code?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  client?: Maybe<Client>;
  names: Translated;
  descriptions: Translated;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  updatedByName: Scalars['String'];
  permissionCodes: Array<Scalars['String']>;
  permissions: Array<Permission>;
  relationCodes?: Maybe<Array<PermissionRelationCode>>;
  relations?: Maybe<Array<PermissionRelation>>;
  users: Array<User>;
  isDeprecated: Scalars['Boolean'];
};

export type Permission = {
  __typename?: 'Permission';
  code: PermissionCode;
  names: Translated;
  descriptions: Translated;
  type?: Maybe<RoleType>;
  requires?: Maybe<Array<PermissionCode>>;
  relationCodes: Array<PermissionRelationCode>;
  relations: Array<PermissionRelation>;
};

export type PermissionRelation = {
  __typename?: 'PermissionRelation';
  code: PermissionRelationCode;
  names: Translated;
  descriptions: Translated;
};

export type PermissionRelationsInput = {
  eventManagedDivisionIds: Array<Scalars['Int']>;
  eventManagedVenueIds: Array<Scalars['Int']>;
};

export enum RoleType {
  Admin = 'admin',
  Client = 'client',
  Public = 'public'
}

export enum PermissionCode {
  ReadApiGrants = 'READ_API_GRANTS',
  ReadApiGrant = 'READ_API_GRANT',
  ReadClients = 'READ_CLIENTS',
  ReadDivision = 'READ_DIVISION',
  ReadClientFiles = 'READ_CLIENT_FILES',
  ReadUser = 'READ_USER',
  ReadUsers = 'READ_USERS',
  ReadRole = 'READ_ROLE',
  ReadRoles = 'READ_ROLES',
  ReadAvailableRoles = 'READ_AVAILABLE_ROLES',
  ReadPermissions = 'READ_PERMISSIONS',
  ReadPermissionRelations = 'READ_PERMISSION_RELATIONS',
  ReadLibraryShow = 'READ_LIBRARY_SHOW',
  ReadClientShow = 'READ_CLIENT_SHOW',
  ReadLibraryShows = 'READ_LIBRARY_SHOWS',
  ReadClientShows = 'READ_CLIENT_SHOWS',
  ReadMixedShows = 'READ_MIXED_SHOWS',
  ReadMixedShowImages = 'READ_MIXED_SHOW_IMAGES',
  ReadVenue = 'READ_VENUE',
  ReadVenues = 'READ_VENUES',
  ReadVenuesSupportUser = 'READ_VENUES_SUPPORT_USER',
  ReadAuditorium = 'READ_AUDITORIUM',
  ReadAuditoriums = 'READ_AUDITORIUMS',
  ReadAuditoriumsSupportUser = 'READ_AUDITORIUMS_SUPPORT_USER',
  ReadAuditoriumLayout = 'READ_AUDITORIUM_LAYOUT',
  ReadAuditoriumLayouts = 'READ_AUDITORIUM_LAYOUTS',
  ReadAuditoriumLayoutsSupportUser = 'READ_AUDITORIUM_LAYOUTS_SUPPORT_USER',
  ReadAuditoriumLayoutPricing = 'READ_AUDITORIUM_LAYOUT_PRICING',
  ReadAuditoriumLayoutPricings = 'READ_AUDITORIUM_LAYOUT_PRICINGS',
  ReadTicketType = 'READ_TICKET_TYPE',
  ReadTicketTypes = 'READ_TICKET_TYPES',
  ReadWebsites = 'READ_WEBSITES',
  ReadWebsite = 'READ_WEBSITE',
  ReadWebsitePages = 'READ_WEBSITE_PAGES',
  ReadWebsitePage = 'READ_WEBSITE_PAGE',
  ReadWebsiteHeaders = 'READ_WEBSITE_HEADERS',
  ReadWebsiteHeader = 'READ_WEBSITE_HEADER',
  ReadWebsiteFooters = 'READ_WEBSITE_FOOTERS',
  ReadWebsiteFooter = 'READ_WEBSITE_FOOTER',
  ReadWebsiteCommonSettings = 'READ_WEBSITE_COMMON_SETTINGS',
  ReadEvent = 'READ_EVENT',
  ReadEvents = 'READ_EVENTS',
  ReadPaginatedEvents = 'READ_PAGINATED_EVENTS',
  ReadPaginatedEventsOnEcommerce = 'READ_PAGINATED_EVENTS_ON_ECOMMERCE',
  ReadApplicationRule = 'READ_APPLICATION_RULE',
  ReadDiscount = 'READ_DISCOUNT',
  ReadDiscounts = 'READ_DISCOUNTS',
  ReadDiscountCodes = 'READ_DISCOUNT_CODES',
  ReadDiscountCode = 'READ_DISCOUNT_CODE',
  ReadCostCenter = 'READ_COST_CENTER',
  ReadCostCenters = 'READ_COST_CENTERS',
  ReadEventCategory = 'READ_EVENT_CATEGORY',
  ReadEventCategories = 'READ_EVENT_CATEGORIES',
  ReadMarketingLabel = 'READ_MARKETING_LABEL',
  ReadMarketingLabels = 'READ_MARKETING_LABELS',
  ReadCart = 'READ_CART',
  ReadCarts = 'READ_CARTS',
  ReadCartSupportUser = 'READ_CART_SUPPORT_USER',
  ReadCartsSupportUser = 'READ_CARTS_SUPPORT_USER',
  ReadEventSeats = 'READ_EVENT_SEATS',
  ReadLatestEventSeatTicketItem = 'READ_LATEST_EVENT_SEAT_TICKET_ITEM',
  ReadItemByPassCode = 'READ_ITEM_BY_PASS_CODE',
  ReadItem = 'READ_ITEM',
  ReadCheckDiscountCodeForCart = 'READ_CHECK_DISCOUNT_CODE_FOR_CART',
  ReadCheckDiscountCodeForTourItems = 'READ_CHECK_DISCOUNT_CODE_FOR_TOUR_ITEMS',
  ReadTourItems = 'READ_TOUR_ITEMS',
  ReadECommercePaymentMethods = 'READ_E_COMMERCE_PAYMENT_METHODS',
  ReadRetailPaymentMethods = 'READ_RETAIL_PAYMENT_METHODS',
  ReadRetailPaymentMethod = 'READ_RETAIL_PAYMENT_METHOD',
  ReadPaymentMethodGroups = 'READ_PAYMENT_METHOD_GROUPS',
  ReadPayments = 'READ_PAYMENTS',
  ReadPaymentReports = 'READ_PAYMENT_REPORTS',
  ReadAvailableInputDataForDailyCashierReports = 'READ_AVAILABLE_INPUT_DATA_FOR_DAILY_CASHIER_REPORTS',
  ReadPaymentReport = 'READ_PAYMENT_REPORT',
  ReadAvailableInputDataForMonthlyECommerceReports = 'READ_AVAILABLE_INPUT_DATA_FOR_MONTHLY_E_COMMERCE_REPORTS',
  ReadAvailableInputDataForMonthlyReports = 'READ_AVAILABLE_INPUT_DATA_FOR_MONTHLY_REPORTS',
  ReadPaymentReportView = 'READ_PAYMENT_REPORT_VIEW',
  ReadPassCodeChecks = 'READ_PASS_CODE_CHECKS',
  ReadReservation = 'READ_RESERVATION',
  ReadReservations = 'READ_RESERVATIONS',
  ReadSale = 'READ_SALE',
  ReadSaleByItemPassCode = 'READ_SALE_BY_ITEM_PASS_CODE',
  ReadSales = 'READ_SALES',
  ReadCheckDiscountCodeForReservation = 'READ_CHECK_DISCOUNT_CODE_FOR_RESERVATION',
  ReadLeads = 'READ_LEADS',
  ReadMessage = 'READ_MESSAGE',
  ReadMessages = 'READ_MESSAGES',
  ReadClaims = 'READ_CLAIMS',
  ReadClaim = 'READ_CLAIM',
  ReadTemplates = 'READ_TEMPLATES',
  ReadTemplate = 'READ_TEMPLATE',
  ReadAdmissionTypes = 'READ_ADMISSION_TYPES',
  ReadAdmissionType = 'READ_ADMISSION_TYPE',
  ReadAdmissionRate = 'READ_ADMISSION_RATE',
  ReadAdmissionRates = 'READ_ADMISSION_RATES',
  ReadTourTimeSlot = 'READ_TOUR_TIME_SLOT',
  ReadTourTimeSlots = 'READ_TOUR_TIME_SLOTS',
  ReadAccessZone = 'READ_ACCESS_ZONE',
  ReadAccessZones = 'READ_ACCESS_ZONES',
  ReadTours = 'READ_TOURS',
  ReadTour = 'READ_TOUR',
  ReadEventsStatistics = 'READ_EVENTS_STATISTICS',
  ReadEventStatistics = 'READ_EVENT_STATISTICS',
  ReadAggregatedEventStatisticsByItemPrice = 'READ_AGGREGATED_EVENT_STATISTICS_BY_ITEM_PRICE',
  ReadProductsStatistics = 'READ_PRODUCTS_STATISTICS',
  ReadTourTimeSlotsStatistics = 'READ_TOUR_TIME_SLOTS_STATISTICS',
  ReadAdmissionTypesStatistics = 'READ_ADMISSION_TYPES_STATISTICS',
  ReadShowsStatistics = 'READ_SHOWS_STATISTICS',
  ReadDiscountsStatistics = 'READ_DISCOUNTS_STATISTICS',
  ReadProductTypes = 'READ_PRODUCT_TYPES',
  ReadProductType = 'READ_PRODUCT_TYPE',
  ReadProducts = 'READ_PRODUCTS',
  ReadProduct = 'READ_PRODUCT',
  ReadWarehouses = 'READ_WAREHOUSES',
  ReadWarehouse = 'READ_WAREHOUSE',
  ReadProductGroups = 'READ_PRODUCT_GROUPS',
  ReadProductGroup = 'READ_PRODUCT_GROUP',
  ReadWarehouseDocuments = 'READ_WAREHOUSE_DOCUMENTS',
  ReadWarehouseProducts = 'READ_WAREHOUSE_PRODUCTS',
  ReadWarehouseDocument = 'READ_WAREHOUSE_DOCUMENT',
  ReadBusinessPartner = 'READ_BUSINESS_PARTNER',
  ReadBusinessPartners = 'READ_BUSINESS_PARTNERS',
  ReadWarehouseDocumentItems = 'READ_WAREHOUSE_DOCUMENT_ITEMS',
  ReadWarehouseProduct = 'READ_WAREHOUSE_PRODUCT',
  ReadInventoryChecks = 'READ_INVENTORY_CHECKS',
  ReadInventoryCheck = 'READ_INVENTORY_CHECK',
  ReadVoucherByCodeOnRetailChannel = 'READ_VOUCHER_BY_CODE_ON_RETAIL_CHANNEL',
  ReadVoucherCampaigns = 'READ_VOUCHER_CAMPAIGNS',
  ReadVoucherCampaign = 'READ_VOUCHER_CAMPAIGN',
  ReadVouchers = 'READ_VOUCHERS',
  ReadVoucher = 'READ_VOUCHER',
  ReadVoucherTransactionIntents = 'READ_VOUCHER_TRANSACTION_INTENTS',
  ReadSignedInCustomer = 'READ_SIGNED_IN_CUSTOMER',
  ReadCustomers = 'READ_CUSTOMERS',
  ReadCustomerGroups = 'READ_CUSTOMER_GROUPS',
  ReadCustomerGroup = 'READ_CUSTOMER_GROUP',
  ReadCustomer = 'READ_CUSTOMER',
  CreateApiGrant = 'CREATE_API_GRANT',
  UpdateApiGrant = 'UPDATE_API_GRANT',
  AssignClientsToApiGrant = 'ASSIGN_CLIENTS_TO_API_GRANT',
  CreateClient = 'CREATE_CLIENT',
  UpdateClient = 'UPDATE_CLIENT',
  SwitchClient = 'SWITCH_CLIENT',
  DeactivateClient = 'DEACTIVATE_CLIENT',
  ActivateClient = 'ACTIVATE_CLIENT',
  UpdateLeadOptions = 'UPDATE_LEAD_OPTIONS',
  UpdateCompany = 'UPDATE_COMPANY',
  UploadClientFile = 'UPLOAD_CLIENT_FILE',
  DeleteClientFile = 'DELETE_CLIENT_FILE',
  CreateDivision = 'CREATE_DIVISION',
  UpdateDivision = 'UPDATE_DIVISION',
  DeactivateDivision = 'DEACTIVATE_DIVISION',
  ActivateDivision = 'ACTIVATE_DIVISION',
  DeleteDivision = 'DELETE_DIVISION',
  RevokeInvitation = 'REVOKE_INVITATION',
  ResendInvitation = 'RESEND_INVITATION',
  InviteUsers = 'INVITE_USERS',
  CreateUser = 'CREATE_USER',
  DisableUser = 'DISABLE_USER',
  EnableUser = 'ENABLE_USER',
  AnonymizeUser = 'ANONYMIZE_USER',
  CreateRole = 'CREATE_ROLE',
  UpdateRole = 'UPDATE_ROLE',
  DeleteRole = 'DELETE_ROLE',
  CreateLibraryShow = 'CREATE_LIBRARY_SHOW',
  UpdateLibraryShow = 'UPDATE_LIBRARY_SHOW',
  CreateClientShow = 'CREATE_CLIENT_SHOW',
  UpdateClientShow = 'UPDATE_CLIENT_SHOW',
  UpsertLibraryShowTranslations = 'UPSERT_LIBRARY_SHOW_TRANSLATIONS',
  UpsertClientShowTranslations = 'UPSERT_CLIENT_SHOW_TRANSLATIONS',
  UploadLibraryImage = 'UPLOAD_LIBRARY_IMAGE',
  UploadClientShowImage = 'UPLOAD_CLIENT_SHOW_IMAGE',
  AssignLibraryImagesToClientShow = 'ASSIGN_LIBRARY_IMAGES_TO_CLIENT_SHOW',
  DeleteClientShowImage = 'DELETE_CLIENT_SHOW_IMAGE',
  UpsertLibraryShowCrew = 'UPSERT_LIBRARY_SHOW_CREW',
  UpsertClientShowCrew = 'UPSERT_CLIENT_SHOW_CREW',
  RemoveImageFromLibraryShow = 'REMOVE_IMAGE_FROM_LIBRARY_SHOW',
  DeleteLibraryImage = 'DELETE_LIBRARY_IMAGE',
  ActivateLibraryImage = 'ACTIVATE_LIBRARY_IMAGE',
  AddClientShowVideo = 'ADD_CLIENT_SHOW_VIDEO',
  UpdateClientShowVideos = 'UPDATE_CLIENT_SHOW_VIDEOS',
  AddLibraryShowVideo = 'ADD_LIBRARY_SHOW_VIDEO',
  UpdateLibraryShowVideos = 'UPDATE_LIBRARY_SHOW_VIDEOS',
  DeleteClientShow = 'DELETE_CLIENT_SHOW',
  CreateVenue = 'CREATE_VENUE',
  UpdateVenue = 'UPDATE_VENUE',
  DeleteVenue = 'DELETE_VENUE',
  UpdateVenuesOrder = 'UPDATE_VENUES_ORDER',
  CreateAuditorium = 'CREATE_AUDITORIUM',
  UpdateAuditorium = 'UPDATE_AUDITORIUM',
  DeleteAuditorium = 'DELETE_AUDITORIUM',
  UpdateAuditoriumsOrder = 'UPDATE_AUDITORIUMS_ORDER',
  CreateAuditoriumLayout = 'CREATE_AUDITORIUM_LAYOUT',
  UpdateAuditoriumLayout = 'UPDATE_AUDITORIUM_LAYOUT',
  DeleteAuditoriumLayout = 'DELETE_AUDITORIUM_LAYOUT',
  ActivateAuditoriumLayout = 'ACTIVATE_AUDITORIUM_LAYOUT',
  ArchiveAuditoriumLayout = 'ARCHIVE_AUDITORIUM_LAYOUT',
  CopyAuditoriumLayout = 'COPY_AUDITORIUM_LAYOUT',
  DuplicateAuditoriumLayoutSupportUser = 'DUPLICATE_AUDITORIUM_LAYOUT_SUPPORT_USER',
  CreateAuditoriumLayoutPricing = 'CREATE_AUDITORIUM_LAYOUT_PRICING',
  UpdateAuditoriumLayoutPricing = 'UPDATE_AUDITORIUM_LAYOUT_PRICING',
  DeleteAuditoriumLayoutPricing = 'DELETE_AUDITORIUM_LAYOUT_PRICING',
  ActivateAuditoriumLayoutPricing = 'ACTIVATE_AUDITORIUM_LAYOUT_PRICING',
  ArchiveAuditoriumLayoutPricing = 'ARCHIVE_AUDITORIUM_LAYOUT_PRICING',
  CreateTicketType = 'CREATE_TICKET_TYPE',
  UpdateTicketType = 'UPDATE_TICKET_TYPE',
  DeleteTicketType = 'DELETE_TICKET_TYPE',
  CreateWebsite = 'CREATE_WEBSITE',
  UpdateWebsite = 'UPDATE_WEBSITE',
  DeleteWebsite = 'DELETE_WEBSITE',
  CreateWebsitePage = 'CREATE_WEBSITE_PAGE',
  UpdateWebsitePage = 'UPDATE_WEBSITE_PAGE',
  UpdateWebsitePageBlocks = 'UPDATE_WEBSITE_PAGE_BLOCKS',
  DeleteWebsitePage = 'DELETE_WEBSITE_PAGE',
  DiscardWebsitePagesChanges = 'DISCARD_WEBSITE_PAGES_CHANGES',
  PublishWebsitePagesChanges = 'PUBLISH_WEBSITE_PAGES_CHANGES',
  CreateWebsiteHeader = 'CREATE_WEBSITE_HEADER',
  DeleteWebsiteHeader = 'DELETE_WEBSITE_HEADER',
  UpdateWebsiteHeader = 'UPDATE_WEBSITE_HEADER',
  CreateWebsiteFooter = 'CREATE_WEBSITE_FOOTER',
  DeleteWebsiteFooter = 'DELETE_WEBSITE_FOOTER',
  UpdateWebsiteFooter = 'UPDATE_WEBSITE_FOOTER',
  UpdateWebsiteCommonSettings = 'UPDATE_WEBSITE_COMMON_SETTINGS',
  PublishEvent = 'PUBLISH_EVENT',
  PublishEvents = 'PUBLISH_EVENTS',
  CancelEvent = 'CANCEL_EVENT',
  CancelEvents = 'CANCEL_EVENTS',
  DeleteEvent = 'DELETE_EVENT',
  DeleteEvents = 'DELETE_EVENTS',
  CreateEvent = 'CREATE_EVENT',
  MoveEvent = 'MOVE_EVENT',
  ResizeEvent = 'RESIZE_EVENT',
  UpdateGeneralEventData = 'UPDATE_GENERAL_EVENT_DATA',
  UpdateSalesEventData = 'UPDATE_SALES_EVENT_DATA',
  UpdateExtendedEventData = 'UPDATE_EXTENDED_EVENT_DATA',
  UpdateAllowedDiscountsSellingChannelsForEvent = 'UPDATE_ALLOWED_DISCOUNTS_SELLING_CHANNELS_FOR_EVENT',
  CopyEvents = 'COPY_EVENTS',
  CopyEvent = 'COPY_EVENT',
  CreateEventPricing = 'CREATE_EVENT_PRICING',
  UpdateEventPricing = 'UPDATE_EVENT_PRICING',
  DeleteEventPricing = 'DELETE_EVENT_PRICING',
  CreateDiscount = 'CREATE_DISCOUNT',
  UpdateDiscount = 'UPDATE_DISCOUNT',
  DeleteDiscount = 'DELETE_DISCOUNT',
  CopyDiscount = 'COPY_DISCOUNT',
  CreateApplicationRule = 'CREATE_APPLICATION_RULE',
  DeleteApplicationRule = 'DELETE_APPLICATION_RULE',
  CreateCondition = 'CREATE_CONDITION',
  UpdateCondition = 'UPDATE_CONDITION',
  DeleteCondition = 'DELETE_CONDITION',
  CreateDiscountCode = 'CREATE_DISCOUNT_CODE',
  CreateDiscountCodes = 'CREATE_DISCOUNT_CODES',
  InvalidateDiscountCodes = 'INVALIDATE_DISCOUNT_CODES',
  CreateCostCenter = 'CREATE_COST_CENTER',
  UpdateCostCenter = 'UPDATE_COST_CENTER',
  CreateEventCategory = 'CREATE_EVENT_CATEGORY',
  UpdateEventCategory = 'UPDATE_EVENT_CATEGORY',
  CreateMarketingLabel = 'CREATE_MARKETING_LABEL',
  UpdateMarketingLabel = 'UPDATE_MARKETING_LABEL',
  CreateCart = 'CREATE_CART',
  AddSeatingTicketItemToCart = 'ADD_SEATING_TICKET_ITEM_TO_CART',
  UpdateSeatingTicketItemsQuantityInCart = 'UPDATE_SEATING_TICKET_ITEMS_QUANTITY_IN_CART',
  DiscardCart = 'DISCARD_CART',
  RemoveTicketItemFromCart = 'REMOVE_TICKET_ITEM_FROM_CART',
  RemoveAllTicketItemsFromCartForEvent = 'REMOVE_ALL_TICKET_ITEMS_FROM_CART_FOR_EVENT',
  RemoveAllProductItemsFromCart = 'REMOVE_ALL_PRODUCT_ITEMS_FROM_CART',
  DecrementZoneTicketItemQuantity = 'DECREMENT_ZONE_TICKET_ITEM_QUANTITY',
  IncrementZoneTicketItemQuantity = 'INCREMENT_ZONE_TICKET_ITEM_QUANTITY',
  Checkout = 'CHECKOUT',
  Reserve = 'RESERVE',
  PostponeCartExpiration = 'POSTPONE_CART_EXPIRATION',
  ChangeEventSeatsAvailability = 'CHANGE_EVENT_SEATS_AVAILABILITY',
  ChangeEventZoneSeatsAvailability = 'CHANGE_EVENT_ZONE_SEATS_AVAILABILITY',
  AddDiscountToCartTicketItems = 'ADD_DISCOUNT_TO_CART_TICKET_ITEMS',
  IncrementDiscountQuantityForCartTicketItems = 'INCREMENT_DISCOUNT_QUANTITY_FOR_CART_TICKET_ITEMS',
  DecrementDiscountQuantityFromCartTicketItems = 'DECREMENT_DISCOUNT_QUANTITY_FROM_CART_TICKET_ITEMS',
  RemoveDiscountFromItem = 'REMOVE_DISCOUNT_FROM_ITEM',
  AddDiscountToTourItems = 'ADD_DISCOUNT_TO_TOUR_ITEMS',
  IncrementProductItemQuantity = 'INCREMENT_PRODUCT_ITEM_QUANTITY',
  IncrementProductItemQuantityWithPriceLookupCode = 'INCREMENT_PRODUCT_ITEM_QUANTITY_WITH_PRICE_LOOKUP_CODE',
  IncrementProductItemQuantityWithBarcode = 'INCREMENT_PRODUCT_ITEM_QUANTITY_WITH_BARCODE',
  DecrementProductItemQuantity = 'DECREMENT_PRODUCT_ITEM_QUANTITY',
  IncrementTourItemQuantity = 'INCREMENT_TOUR_ITEM_QUANTITY',
  DecrementTourItemQuantity = 'DECREMENT_TOUR_ITEM_QUANTITY',
  RemoveTourItemFromCart = 'REMOVE_TOUR_ITEM_FROM_CART',
  RemoveAllTourItemsFromCartForTimeSlot = 'REMOVE_ALL_TOUR_ITEMS_FROM_CART_FOR_TIME_SLOT',
  CreatePaymentMethodGroup = 'CREATE_PAYMENT_METHOD_GROUP',
  EditPaymentMethodGroup = 'EDIT_PAYMENT_METHOD_GROUP',
  DeletePaymentMethodGroup = 'DELETE_PAYMENT_METHOD_GROUP',
  CreateRetailPaymentMethod = 'CREATE_RETAIL_PAYMENT_METHOD',
  CreateECommercePaymentMethod = 'CREATE_E_COMMERCE_PAYMENT_METHOD',
  DeleteRetailPaymentMethod = 'DELETE_RETAIL_PAYMENT_METHOD',
  DeleteECommercePaymentMethod = 'DELETE_E_COMMERCE_PAYMENT_METHOD',
  UpdatePaymentMethod = 'UPDATE_PAYMENT_METHOD',
  CreatePaymentsCsvLink = 'CREATE_PAYMENTS_CSV_LINK',
  CreateCashDeskDeposit = 'CREATE_CASH_DESK_DEPOSIT',
  CreateCashDeskWithdrawal = 'CREATE_CASH_DESK_WITHDRAWAL',
  CreateDailyCashierPaymentReport = 'CREATE_DAILY_CASHIER_PAYMENT_REPORT',
  CreateMonthlyECommercePaymentReport = 'CREATE_MONTHLY_E_COMMERCE_PAYMENT_REPORT',
  CreateMonthlyPaymentReport = 'CREATE_MONTHLY_PAYMENT_REPORT',
  CheckPassCodeIn = 'CHECK_PASS_CODE_IN',
  CheckPassCodeOut = 'CHECK_PASS_CODE_OUT',
  UpdatePassCodeCheck = 'UPDATE_PASS_CODE_CHECK',
  CheckPassCodes = 'CHECK_PASS_CODES',
  UpdateReservation = 'UPDATE_RESERVATION',
  AddSeatingTicketItemToReservation = 'ADD_SEATING_TICKET_ITEM_TO_RESERVATION',
  AddZoneTicketItemToReservation = 'ADD_ZONE_TICKET_ITEM_TO_RESERVATION',
  SplitReservation = 'SPLIT_RESERVATION',
  AddReservationToCart = 'ADD_RESERVATION_TO_CART',
  RemoveTicketItemsFromReservation = 'REMOVE_TICKET_ITEMS_FROM_RESERVATION',
  RemoveZoneTicketItemsFromReservation = 'REMOVE_ZONE_TICKET_ITEMS_FROM_RESERVATION',
  RemoveTourItemFromReservation = 'REMOVE_TOUR_ITEM_FROM_RESERVATION',
  DecrementTourItemQuantityInReservation = 'DECREMENT_TOUR_ITEM_QUANTITY_IN_RESERVATION',
  IncrementTourItemQuantityInReservation = 'INCREMENT_TOUR_ITEM_QUANTITY_IN_RESERVATION',
  CancelReservation = 'CANCEL_RESERVATION',
  RemoveReservationFromCart = 'REMOVE_RESERVATION_FROM_CART',
  SendReservationConfirmationEmail = 'SEND_RESERVATION_CONFIRMATION_EMAIL',
  SendSaleConfirmationEmail = 'SEND_SALE_CONFIRMATION_EMAIL',
  AddDiscountToReservationTicketItems = 'ADD_DISCOUNT_TO_RESERVATION_TICKET_ITEMS',
  IncrementDiscountQuantityForReservationTicketItems = 'INCREMENT_DISCOUNT_QUANTITY_FOR_RESERVATION_TICKET_ITEMS',
  DecrementDiscountQuantityFromReservationTicketItems = 'DECREMENT_DISCOUNT_QUANTITY_FROM_RESERVATION_TICKET_ITEMS',
  RemoveDiscountFromReservationTicketItem = 'REMOVE_DISCOUNT_FROM_RESERVATION_TICKET_ITEM',
  UpdateReservationLead = 'UPDATE_RESERVATION_LEAD',
  SendMessage = 'SEND_MESSAGE',
  CreateClaim = 'CREATE_CLAIM',
  AddItemsToClaim = 'ADD_ITEMS_TO_CLAIM',
  RemoveItemsFromClaim = 'REMOVE_ITEMS_FROM_CLAIM',
  DeleteClaim = 'DELETE_CLAIM',
  ApproveClaim = 'APPROVE_CLAIM',
  UpdateClaimReason = 'UPDATE_CLAIM_REASON',
  UpsertClaimLead = 'UPSERT_CLAIM_LEAD',
  DeleteClaimLead = 'DELETE_CLAIM_LEAD',
  RefundClaimOnRetailChannel = 'REFUND_CLAIM_ON_RETAIL_CHANNEL',
  RefundClaimOnECommerceChannel = 'REFUND_CLAIM_ON_E_COMMERCE_CHANNEL',
  RefundClaimByInHouseVoucher = 'REFUND_CLAIM_BY_IN_HOUSE_VOUCHER',
  CreateTemplate = 'CREATE_TEMPLATE',
  EditTemplateVersionCode = 'EDIT_TEMPLATE_VERSION_CODE',
  AssignTemplateToClient = 'ASSIGN_TEMPLATE_TO_CLIENT',
  RemoveTemplateFromClient = 'REMOVE_TEMPLATE_FROM_CLIENT',
  UpdateTemplate = 'UPDATE_TEMPLATE',
  CreateAdmissionType = 'CREATE_ADMISSION_TYPE',
  UpdateDraftAdmissionType = 'UPDATE_DRAFT_ADMISSION_TYPE',
  UpdateAdmissionType = 'UPDATE_ADMISSION_TYPE',
  DeleteDraftAdmissionType = 'DELETE_DRAFT_ADMISSION_TYPE',
  CreateAdmissionRate = 'CREATE_ADMISSION_RATE',
  UpdateAdmissionRate = 'UPDATE_ADMISSION_RATE',
  DeleteAdmissionRate = 'DELETE_ADMISSION_RATE',
  UpdateDraftAdmissionRateAssignments = 'UPDATE_DRAFT_ADMISSION_RATE_ASSIGNMENTS',
  UpdateAdmissionRateAssignments = 'UPDATE_ADMISSION_RATE_ASSIGNMENTS',
  CopyAdmissionRate = 'COPY_ADMISSION_RATE',
  AssignAccessZoneToAdmissionRateType = 'ASSIGN_ACCESS_ZONE_TO_ADMISSION_RATE_TYPE',
  RemoveAccessZoneFromAdmissionRateType = 'REMOVE_ACCESS_ZONE_FROM_ADMISSION_RATE_TYPE',
  CreateTourTimeSlot = 'CREATE_TOUR_TIME_SLOT',
  DeleteTourTimeSlot = 'DELETE_TOUR_TIME_SLOT',
  DeleteTourTimeSlots = 'DELETE_TOUR_TIME_SLOTS',
  UpdateTourTimeSlotSaleData = 'UPDATE_TOUR_TIME_SLOT_SALE_DATA',
  UpdateTourTimeSlots = 'UPDATE_TOUR_TIME_SLOTS',
  CopyTourTimeSlots = 'COPY_TOUR_TIME_SLOTS',
  CreateAccessZone = 'CREATE_ACCESS_ZONE',
  UpdateAccessZone = 'UPDATE_ACCESS_ZONE',
  CreateTour = 'CREATE_TOUR',
  UpdateTour = 'UPDATE_TOUR',
  DeleteTour = 'DELETE_TOUR',
  CreateProductsStatisticsCsvLink = 'CREATE_PRODUCTS_STATISTICS_CSV_LINK',
  CreateEventsStatisticsCsvLink = 'CREATE_EVENTS_STATISTICS_CSV_LINK',
  CreateProductType = 'CREATE_PRODUCT_TYPE',
  CreateProduct = 'CREATE_PRODUCT',
  UpdateProduct = 'UPDATE_PRODUCT',
  DeleteProductCoverImage = 'DELETE_PRODUCT_COVER_IMAGE',
  UpdateProductType = 'UPDATE_PRODUCT_TYPE',
  UpsertProductAvailability = 'UPSERT_PRODUCT_AVAILABILITY',
  CreateProductBarcode = 'CREATE_PRODUCT_BARCODE',
  CreateProductPricing = 'CREATE_PRODUCT_PRICING',
  DeleteProductBarcode = 'DELETE_PRODUCT_BARCODE',
  DeleteProductPricing = 'DELETE_PRODUCT_PRICING',
  CreateWarehouse = 'CREATE_WAREHOUSE',
  UpdateWarehouse = 'UPDATE_WAREHOUSE',
  CreateProductGroup = 'CREATE_PRODUCT_GROUP',
  UpdateProductGroup = 'UPDATE_PRODUCT_GROUP',
  AssignProductGroupToProduct = 'ASSIGN_PRODUCT_GROUP_TO_PRODUCT',
  AssignProductToProductGroup = 'ASSIGN_PRODUCT_TO_PRODUCT_GROUP',
  RemoveProductFromProductGroup = 'REMOVE_PRODUCT_FROM_PRODUCT_GROUP',
  RemoveProductGroupFromProduct = 'REMOVE_PRODUCT_GROUP_FROM_PRODUCT',
  UpsertProductGroupAvailability = 'UPSERT_PRODUCT_GROUP_AVAILABILITY',
  UpdateProductsOrderInProductGroup = 'UPDATE_PRODUCTS_ORDER_IN_PRODUCT_GROUP',
  UpdateProductGroupsOrder = 'UPDATE_PRODUCT_GROUPS_ORDER',
  CreateWarehouseDocument = 'CREATE_WAREHOUSE_DOCUMENT',
  UpdateDraftWarehouseDocument = 'UPDATE_DRAFT_WAREHOUSE_DOCUMENT',
  UploadProductCoverImage = 'UPLOAD_PRODUCT_COVER_IMAGE',
  CreateBusinessPartner = 'CREATE_BUSINESS_PARTNER',
  UpdateBusinessPartner = 'UPDATE_BUSINESS_PARTNER',
  CreateWarehouseProduct = 'CREATE_WAREHOUSE_PRODUCT',
  AddItemToWarehouseDocument = 'ADD_ITEM_TO_WAREHOUSE_DOCUMENT',
  RemoveItemFromWarehouseDocument = 'REMOVE_ITEM_FROM_WAREHOUSE_DOCUMENT',
  DeleteDraftWarehouseDocument = 'DELETE_DRAFT_WAREHOUSE_DOCUMENT',
  IssueWarehouseDocument = 'ISSUE_WAREHOUSE_DOCUMENT',
  UpdateWarehouseProduct = 'UPDATE_WAREHOUSE_PRODUCT',
  CreateInventoryCheck = 'CREATE_INVENTORY_CHECK',
  DeleteInventoryCheck = 'DELETE_INVENTORY_CHECK',
  UpdateInventoryCheckProduct = 'UPDATE_INVENTORY_CHECK_PRODUCT',
  UpdateInventoryCheck = 'UPDATE_INVENTORY_CHECK',
  CompleteInventoryCheck = 'COMPLETE_INVENTORY_CHECK',
  CreateVoucherCampaign = 'CREATE_VOUCHER_CAMPAIGN',
  CreateVouchers = 'CREATE_VOUCHERS',
  ChangeVoucherCampaignState = 'CHANGE_VOUCHER_CAMPAIGN_STATE',
  ChangeVoucherState = 'CHANGE_VOUCHER_STATE',
  CreditVoucher = 'CREDIT_VOUCHER',
  SignUpCustomer = 'SIGN_UP_CUSTOMER',
  SignInCustomer = 'SIGN_IN_CUSTOMER',
  ChangePasswordForSignedInCustomer = 'CHANGE_PASSWORD_FOR_SIGNED_IN_CUSTOMER',
  UpdateSignedInCustomer = 'UPDATE_SIGNED_IN_CUSTOMER',
  CreateCustomerGroup = 'CREATE_CUSTOMER_GROUP',
  UpdateCustomerGroup = 'UPDATE_CUSTOMER_GROUP',
  UpdateCustomer = 'UPDATE_CUSTOMER',
  DeleteCustomerGroup = 'DELETE_CUSTOMER_GROUP',
  CreateCustomer = 'CREATE_CUSTOMER',
  ExportStatisticsFromDatagridAsExcelFile = 'EXPORT_STATISTICS_FROM_DATAGRID_AS_EXCEL_FILE',
  ExportStatisticsFromDatagridAsCsvFile = 'EXPORT_STATISTICS_FROM_DATAGRID_AS_CSV_FILE',
  ManageRoles = 'MANAGE_ROLES',
  UpdateUser = 'UPDATE_USER',
  ChangePassword = 'CHANGE_PASSWORD',
  ManageUsers = 'MANAGE_USERS',
  ManageDivisions = 'MANAGE_DIVISIONS',
  EditDivisionInvoiceInfo = 'EDIT_DIVISION_INVOICE_INFO',
  ManageEvents = 'MANAGE_EVENTS',
  ManageEventCategories = 'MANAGE_EVENT_CATEGORIES',
  ManageThirdPartyPurchaseUrl = 'MANAGE_THIRD_PARTY_PURCHASE_URL',
  ManageMessages = 'MANAGE_MESSAGES',
  ManagePaymentMethods = 'MANAGE_PAYMENT_METHODS',
  ReadDailyCashierReports = 'READ_DAILY_CASHIER_REPORTS',
  ReadDailyCashierReport = 'READ_DAILY_CASHIER_REPORT',
  ReadMonthlyEcommerceReports = 'READ_MONTHLY_ECOMMERCE_REPORTS',
  ReadMonthlyEcommerceReport = 'READ_MONTHLY_ECOMMERCE_REPORT',
  ReadAvailableDailyCashierReportsInputDataForAll = 'READ_AVAILABLE_DAILY_CASHIER_REPORTS_INPUT_DATA_FOR_ALL',
  ReadMonthlyReports = 'READ_MONTHLY_REPORTS',
  ReadMonthlyReport = 'READ_MONTHLY_REPORT',
  ReadPaymentReportViewPage = 'READ_PAYMENT_REPORT_VIEW_PAGE',
  ReadPaymentsForSupport = 'READ_PAYMENTS_FOR_SUPPORT',
  ManageProducts = 'MANAGE_PRODUCTS',
  ManageProductTypes = 'MANAGE_PRODUCT_TYPES',
  ManageWarehouses = 'MANAGE_WAREHOUSES',
  ManageProductGroups = 'MANAGE_PRODUCT_GROUPS',
  ManageBusinessPartners = 'MANAGE_BUSINESS_PARTNERS',
  ManageWarehouseDocuments = 'MANAGE_WAREHOUSE_DOCUMENTS',
  ManageInventoryChecks = 'MANAGE_INVENTORY_CHECKS',
  ChangeDeviceSettings = 'CHANGE_DEVICE_SETTINGS',
  AccessCashCounter = 'ACCESS_CASH_COUNTER',
  CustomerDisplay = 'CUSTOMER_DISPLAY',
  FeatureFlagEf_448 = 'FEATURE_FLAG_EF_448',
  ManageVenues = 'MANAGE_VENUES',
  FeatureFlagEntradio_3899 = 'FEATURE_FLAG_ENTRADIO_3899',
  FeatureFlagEntradio_3910 = 'FEATURE_FLAG_ENTRADIO_3910',
  ManageVoucherCampaigns = 'MANAGE_VOUCHER_CAMPAIGNS',
  ManageVouchers = 'MANAGE_VOUCHERS',
  ReadVoucherPinCode = 'READ_VOUCHER_PIN_CODE',
  ManageClientShows = 'MANAGE_CLIENT_SHOWS',
  ManageTemplates = 'MANAGE_TEMPLATES',
  ManageAdmissionTypes = 'MANAGE_ADMISSION_TYPES',
  ManageTours = 'MANAGE_TOURS',
  ManageAdmissionRates = 'MANAGE_ADMISSION_RATES',
  ManageTimeSlots = 'MANAGE_TIME_SLOTS',
  CreateTourTimeSlotInCashDesk = 'CREATE_TOUR_TIME_SLOT_IN_CASH_DESK',
  ManageAccessZones = 'MANAGE_ACCESS_ZONES',
  ManageCostCenters = 'MANAGE_COST_CENTERS',
  ManageMarketingLabels = 'MANAGE_MARKETING_LABELS',
  ManageCustomers = 'MANAGE_CUSTOMERS',
  ManageCustomerGroups = 'MANAGE_CUSTOMER_GROUPS',
  FeatureFlagEntradio_3099 = 'FEATURE_FLAG_ENTRADIO_3099',
  ManageWebsites = 'MANAGE_WEBSITES',
  ManageWebsite = 'MANAGE_WEBSITE',
  WebsiteInfoForSupportUser = 'WEBSITE_INFO_FOR_SUPPORT_USER',
  UpdateWebsiteDesign = 'UPDATE_WEBSITE_DESIGN',
  UpdateWebsiteEcommerceSettings = 'UPDATE_WEBSITE_ECOMMERCE_SETTINGS',
  UpdateWebsiteMetadata = 'UPDATE_WEBSITE_METADATA',
  UpdateWebsiteScripts = 'UPDATE_WEBSITE_SCRIPTS',
  FeatureFlagEntradio_3306 = 'FEATURE_FLAG_ENTRADIO_3306'
}

export enum PermissionRelationCode {
  EventManagedDivisions = 'EVENT_MANAGED_DIVISIONS',
  EventManagedVenues = 'EVENT_MANAGED_VENUES'
}

export type InvitedUser = {
  username: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Title>;
  degree?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobilPhoneNumber?: Maybe<Scalars['String']>;
  localeCode: LocaleCode;
  timezone: Timezone;
};

export type InvitationResult = {
  __typename?: 'InvitationResult';
  email: Scalars['String'];
  user?: Maybe<User>;
};

export enum Title {
  Mr = 'Mr',
  Mrs = 'Mrs'
}

export enum UserState {
  Invited = 'invited',
  Active = 'active',
  Disabled = 'disabled',
  Anonymized = 'anonymized'
}

export type ClientShow = {
  __typename?: 'ClientShow';
  id: Scalars['Int'];
  originalTitle?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  productionYear?: Maybe<Scalars['Int']>;
  globalReleaseDate?: Maybe<Scalars['Date']>;
  typeCode: ShowTypeCode;
  ageClassificationCodes: Array<ShowAgeClassificationCode>;
  countryCodes: Array<CountryCode>;
  distributions: Array<ShowDistribution>;
  genreCodes: Array<ShowGenreCode>;
  languageCodes: Array<LanguageCode>;
  crew: Array<ShowCrewByRole>;
  translations: Array<ShowTranslation>;
  image?: Maybe<ClientShowImage>;
  images: Array<ClientShowImage>;
  primaryImage?: Maybe<ClientShowImage>;
  allPrimaryImages?: Maybe<Array<ClientShowImage>>;
  showsDbId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  videos: Array<ShowVideo>;
  contentDescriptorCodes?: Maybe<Array<ShowContentDescriptorCode>>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
  businessPartner?: Maybe<BusinessPartner>;
};


export type ClientShowImageArgs = {
  id: Scalars['Int'];
};


export type ClientShowImagesArgs = {
  type?: Maybe<Array<ShowImageType>>;
};


export type ClientShowPrimaryImageArgs = {
  type: ShowImageType;
};

export type ClientShows = {
  __typename?: 'ClientShows';
  items: Array<ClientShow>;
  pagination: Pagination;
};

export type MixedShow = LibraryShow | ClientShow;

export type MixedShows = {
  __typename?: 'MixedShows';
  items: Array<MixedShow>;
  pagination: Pagination;
};

export type ClientShowImage = {
  __typename?: 'ClientShowImage';
  id: Scalars['Int'];
  type: ShowImageType;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
  url: Scalars['String'];
  libraryImageId: Scalars['Int'];
  showId: Scalars['Int'];
  thumbnail?: Maybe<ShowImageThumbnail>;
  thumbnails: Array<ShowImageThumbnail>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  order: Scalars['NonNegativeFloat'];
};


export type ClientShowImageThumbnailArgs = {
  id: Scalars['Int'];
};


export type ClientShowImageThumbnailsArgs = {
  size?: Maybe<Array<ShowImageThumbnailSize>>;
};

export type ClientShowImageUploadInput = {
  showId: Scalars['Int'];
  type: ShowImageType;
};

export type LibraryImageUploadInput = {
  type: ShowImageType;
  state?: Maybe<LibraryMediaState>;
};

export type MixedShowImagesFilterInput = {
  type: Array<ShowImageType>;
  state?: Maybe<LibraryMediaState>;
};

export type MixedShowImage = ClientShowImage | LibraryShowImage;

export type ShowType = {
  __typename?: 'ShowType';
  code: ShowTypeCode;
  ageClassificationCodes: Array<ShowAgeClassificationCode>;
  crewRoleCodes: Array<ShowCrewRoleCode>;
  formatCodes: Array<ShowFormatCode>;
  genreCodes: Array<ShowGenreCode>;
  soundMixCodes: Array<ShowSoundMixCode>;
  versionCodes: Array<ShowVersionCode>;
};

export enum TreatGroupSeatsOptions {
  /** Each seat from group can be added to cart separately */
  Separately = 'separately',
  /** Group is treated as a unit. E.g. if a user tries to add a seat from the group into the cart, all seats of the group are added to the cart. */
  AsGroups = 'asGroups'
}

export type VenueInput = {
  name: Scalars['String'];
  secondaryName?: Maybe<Scalars['String']>;
  address: AddressInput;
};

export type Venue = {
  __typename?: 'Venue';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  secondaryName?: Maybe<Scalars['String']>;
  address: Address;
  auditoriums: Array<Auditorium>;
  order?: Maybe<Scalars['PositiveInt']>;
};

export type AuditoriumInput = {
  venueId: Scalars['Int'];
  name: Scalars['String'];
  shortcut: Scalars['String'];
  state: AuditoriumState;
  defaultDivisionId?: Maybe<Scalars['PositiveInt']>;
};

export type Auditorium = {
  __typename?: 'Auditorium';
  id: Scalars['Int'];
  name: Scalars['String'];
  shortcut: Scalars['String'];
  auditoriumLayouts: Array<AuditoriumLayout>;
  state: AuditoriumState;
  venue: Venue;
  defaultDivision?: Maybe<Division>;
  order?: Maybe<Scalars['PositiveInt']>;
};

export enum AuditoriumLayoutState {
  Draft = 'draft',
  Active = 'active',
  Archived = 'archived'
}

export enum AuditoriumLayoutPricingState {
  Draft = 'draft',
  Active = 'active',
  Archived = 'archived'
}

export enum AuditoriumLayoutType {
  /** Zones standing with no seats */
  ZoneFloor = 'zoneFloor',
  /** Currently NA */
  WithoutSeats = 'withoutSeats',
  /** Mixed seats with zone standing */
  SeatingFloorPlan = 'seatingFloorPlan'
}

export type DefaultSeatStatesInput = {
  seats: Scalars['JSON'];
  disabledCountsInZones: Scalars['JSON'];
  hiddenCountsInZones: Scalars['JSON'];
};

export type AuditoriumLayoutInput = {
  name: Scalars['String'];
  layout: Scalars['JSONObject'];
  defaultSeatStates?: Maybe<DefaultSeatStatesInput>;
};

export type DefaultSeatStates = {
  __typename?: 'DefaultSeatStates';
  seats: Scalars['JSON'];
  disabledCountsInZones: Scalars['JSON'];
  hiddenCountsInZones: Scalars['JSON'];
};

export type AuditoriumLayout = {
  __typename?: 'AuditoriumLayout';
  id: Scalars['Int'];
  name: Scalars['String'];
  capacity: Scalars['Int'];
  type: AuditoriumLayoutType;
  status: AuditoriumLayoutState;
  layout: Scalars['JSONObject'];
  auditoriumLayoutPricings: Array<AuditoriumLayoutPricing>;
  auditorium: Auditorium;
  defaultSeatStates: DefaultSeatStates;
  seatGroups: Scalars['JSON'];
};

export type AuditoriumLayoutPricingInput = {
  name: Scalars['String'];
  maxTicketsPerOrder: Scalars['Int'];
  pricing: Scalars['JSONObject'];
  /**
   * This sets the maximum number of occupied seats compared on Retail
   * with number of occupied seats on both selling channels
   */
  maxNumberOfOccupiedSeatsOnRetail: Scalars['NonNegativeInt'];
  /**
   * This sets the maximum number of occupied seats compared on Ecommerce
   * with number of occupied seats on both selling channels
   */
  maxNumberOfOccupiedSeatsOnEcommerce: Scalars['NonNegativeInt'];
};

export type AuditoriumLayoutPricing = {
  __typename?: 'AuditoriumLayoutPricing';
  id: Scalars['Int'];
  name: Scalars['String'];
  maxTicketsPerOrder: Scalars['Int'];
  status: AuditoriumLayoutPricingState;
  pricing: Scalars['JSONObject'];
  ticketTypes: Array<TicketType>;
  auditoriumLayout: AuditoriumLayout;
  maxNumberOfOccupiedSeatsOnRetail: Scalars['NonNegativeInt'];
  maxNumberOfOccupiedSeatsOnEcommerce: Scalars['NonNegativeInt'];
};

export type TicketTypeInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['NonNegativeFloat']>;
  color: Scalars['String'];
  areDiscountsEnabled: Scalars['Boolean'];
};

export type TicketType = {
  __typename?: 'TicketType';
  id: Scalars['Int'];
  auditoriumLayoutPricingId: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['NonNegativeFloat']>;
  color: Scalars['String'];
  areDiscountsEnabled: Scalars['Boolean'];
};

export type AuditoriumsFilterInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  state?: Maybe<AuditoriumState>;
  venueIds?: Maybe<Array<Scalars['PositiveInt']>>;
  auditoriumIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type VenuesFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  venueIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type Website = {
  __typename?: 'Website';
  id: Scalars['PositiveInt'];
  clientId: Scalars['PositiveInt'];
  client: Client;
  projectFullPath: Scalars['NonEmptyString'];
  productionBranch: Scalars['NonEmptyString'];
  previewUrl?: Maybe<Scalars['NonEmptyString']>;
  name: Scalars['NonEmptyString'];
  primaryDomain?: Maybe<Scalars['NonEmptyString']>;
  state: WebsiteState;
  enabledLocaleCodes: Array<LocaleCode>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  pages?: Maybe<Scalars['JSON']>;
  pagesUpdatedAt?: Maybe<Scalars['DateTime']>;
  pagesUpdatedBy?: Maybe<User>;
};

export type WebsiteInput = {
  clientId: Scalars['PositiveInt'];
  projectFullPath: Scalars['NonEmptyString'];
  productionBranch: Scalars['NonEmptyString'];
  previewUrl?: Maybe<Scalars['NonEmptyString']>;
  name: Scalars['NonEmptyString'];
  state?: Maybe<WebsiteState>;
  /** Must include client's locale codes */
  enabledLocaleCodes: Array<LocaleCode>;
  primaryDomain?: Maybe<Scalars['NonEmptyString']>;
};

export type OpenGraphMetadataInput = {
  title?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  images?: Maybe<Scalars['Url']>;
};

export type RobotsMetadataInput = {
  index?: Maybe<Scalars['Boolean']>;
  follow?: Maybe<Scalars['Boolean']>;
};

export type MetadataInput = {
  title?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  openGraph?: Maybe<OpenGraphMetadataInput>;
  robots?: Maybe<RobotsMetadataInput>;
};

export type OpenGraphMetadata = {
  __typename?: 'OpenGraphMetadata';
  title?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  images?: Maybe<Scalars['Url']>;
};

export type RobotsMetadata = {
  __typename?: 'RobotsMetadata';
  index?: Maybe<Scalars['Boolean']>;
  follow?: Maybe<Scalars['Boolean']>;
};

export type CommonSettingsOpenGraphMetadata = {
  __typename?: 'CommonSettingsOpenGraphMetadata';
  title: Scalars['NonEmptyString'];
  description: Scalars['NonEmptyString'];
  images?: Maybe<Scalars['Url']>;
};

export type CommonSettingsRobotsMetadata = {
  __typename?: 'CommonSettingsRobotsMetadata';
  index: Scalars['Boolean'];
  follow: Scalars['Boolean'];
};

export type Metadata = {
  __typename?: 'Metadata';
  title?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  icons?: Maybe<Scalars['NonEmptyString']>;
  openGraph?: Maybe<OpenGraphMetadata>;
  robots?: Maybe<RobotsMetadata>;
};

export type WebsitePageInput = {
  title: Scalars['NonEmptyString'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['NonEmptyString']>;
  state: WebsitePageState;
  metadata?: Maybe<MetadataInput>;
  blocks?: Maybe<Scalars['JSON']>;
};

export type UpdateWebsitePageInput = {
  title?: Maybe<Scalars['NonEmptyString']>;
  slug?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  state?: Maybe<WebsitePageState>;
  metadata?: Maybe<MetadataInput>;
};

export type HeaderPhabletUpInput = {
  width: Scalars['NonNegativeInt'];
  height: Scalars['NonNegativeInt'];
};

export type HeaderLogoInput = {
  src: Scalars['NonEmptyString'];
  alt?: Maybe<Scalars['NonEmptyString']>;
  width: Scalars['NonNegativeInt'];
  height: Scalars['NonNegativeInt'];
  phabletUp?: Maybe<HeaderPhabletUpInput>;
};

export type CreateWebsiteHeaderInput = {
  logo: HeaderLogoInput;
  items: Scalars['JSON'];
};

export type UpdateHeaderPhabletUpInput = {
  width?: Maybe<Scalars['NonNegativeInt']>;
  height?: Maybe<Scalars['NonNegativeInt']>;
};

export type UpdateHeaderLogoInput = {
  src?: Maybe<Scalars['NonEmptyString']>;
  alt?: Maybe<Scalars['NonEmptyString']>;
  width?: Maybe<Scalars['NonNegativeInt']>;
  height?: Maybe<Scalars['NonNegativeInt']>;
  phabletUp?: Maybe<UpdateHeaderPhabletUpInput>;
};

export type UpdateWebsiteHeaderInput = {
  logo?: Maybe<UpdateHeaderLogoInput>;
  items?: Maybe<Scalars['JSON']>;
};

export type FooterItemInput = {
  label: Scalars['NonEmptyString'];
  typographyVariant?: Maybe<TypographyVariant>;
  link?: Maybe<Scalars['NonEmptyString']>;
  linkTarget?: Maybe<Scalars['NonEmptyString']>;
  icon?: Maybe<TypifiedIconModel>;
};

export type FooterItemGroupInput = {
  items: Array<FooterItemInput>;
  alignment: FooterItemAlignment;
};

export type CreateWebsiteFooterInput = {
  itemGroups: Array<FooterItemGroupInput>;
};

export type UpdateWebsiteFooterInput = {
  itemGroups: Array<FooterItemGroupInput>;
};

export enum ECommerceTheme {
  RubyRed = 'rubyRed',
  CrimsonRed = 'crimsonRed',
  OrangeRed = 'orangeRed',
  Coral = 'coral',
  DarkOrange = 'darkOrange',
  BronzeBrown = 'bronzeBrown',
  LimeGreen = 'limeGreen',
  ForestGreen = 'forestGreen',
  SeaGreen = 'seaGreen',
  EmeraldGreen = 'emeraldGreen',
  Teal = 'teal',
  SteelBlue = 'steelBlue',
  DodgerBlue = 'dodgerBlue',
  EletricIndigo = 'eletricIndigo',
  NavyBlue = 'navyBlue',
  DeepPurple = 'deepPurple',
  Purple = 'purple',
  Amethyst = 'amethyst',
  DarkGray = 'darkGray'
}

export enum ECommerceThemeBackgroundColor {
  Transparent = 'transparent',
  Grey = 'grey'
}

export enum CommonSettingsScriptLocation {
  Head = 'head',
  Body = 'body'
}

export type CommonSettingsECommerceQueryParamsInput = {
  theme?: Maybe<ECommerceTheme>;
  showCartButton?: Maybe<Scalars['Boolean']>;
  backgroundColor?: Maybe<ECommerceThemeBackgroundColor>;
  gatewayButtonTarget?: Maybe<Scalars['NonEmptyString']>;
  eventOverviewEnabled?: Maybe<Scalars['Boolean']>;
  eventButtonTarget?: Maybe<Scalars['NonEmptyString']>;
};

export type CommonSettingsShowDetailSlugInput = {
  en?: Maybe<Scalars['NonEmptyString']>;
  sk?: Maybe<Scalars['NonEmptyString']>;
  cs?: Maybe<Scalars['NonEmptyString']>;
  hu?: Maybe<Scalars['NonEmptyString']>;
};

export type CommonSettingsMetadataInput = {
  metadataBase?: Maybe<Scalars['Url']>;
  title?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  icons?: Maybe<Scalars['NonEmptyString']>;
  openGraph?: Maybe<OpenGraphMetadataInput>;
  robots?: Maybe<RobotsMetadataInput>;
};

export type CommonSettingsClientInput = {
  eCommerceCartUrl?: Maybe<Scalars['Url']>;
};

export type CommonSettingsScriptInput = {
  id?: Maybe<Scalars['NonEmptyString']>;
  content?: Maybe<Scalars['NonEmptyString']>;
  location?: Maybe<CommonSettingsScriptLocation>;
  src?: Maybe<Scalars['NonEmptyString']>;
};

export type UpdateWebsiteCommonSettingsInput = {
  eCommerceQueryParams?: Maybe<CommonSettingsECommerceQueryParamsInput>;
  showDetailSlug?: Maybe<CommonSettingsShowDetailSlugInput>;
  metadata?: Maybe<CommonSettingsMetadataInput>;
  client?: Maybe<CommonSettingsClientInput>;
  scripts?: Maybe<Array<CommonSettingsScriptInput>>;
};

export type CommonSettingsECommerceQueryParams = {
  __typename?: 'CommonSettingsECommerceQueryParams';
  theme?: Maybe<ECommerceTheme>;
  showCartButton?: Maybe<Scalars['Boolean']>;
  backgroundColor?: Maybe<ECommerceThemeBackgroundColor>;
  gatewayButtonTarget?: Maybe<Scalars['NonEmptyString']>;
  eventOverviewEnabled?: Maybe<Scalars['Boolean']>;
  eventButtonTarget?: Maybe<Scalars['NonEmptyString']>;
};

export type CommonSettingShowDetailSlug = {
  __typename?: 'CommonSettingShowDetailSlug';
  en: Scalars['NonEmptyString'];
  sk: Scalars['NonEmptyString'];
  cs: Scalars['NonEmptyString'];
  hu: Scalars['NonEmptyString'];
};

export type CommonSettingsMetadata = {
  __typename?: 'CommonSettingsMetadata';
  metadataBase: Scalars['Url'];
  title: Scalars['NonEmptyString'];
  description: Scalars['NonEmptyString'];
  icons: Scalars['NonEmptyString'];
  openGraph: CommonSettingsOpenGraphMetadata;
  robots: CommonSettingsRobotsMetadata;
};

export type CommonSettingsClient = {
  __typename?: 'CommonSettingsClient';
  eCommerceCartUrl: Scalars['Url'];
};

export type CommonSettingsScript = {
  __typename?: 'CommonSettingsScript';
  id: Scalars['NonEmptyString'];
  content?: Maybe<Scalars['NonEmptyString']>;
  location: CommonSettingsScriptLocation;
  src?: Maybe<Scalars['NonEmptyString']>;
};

export type WebsiteCommonSettings = {
  __typename?: 'WebsiteCommonSettings';
  eCommerceQueryParams?: Maybe<CommonSettingsECommerceQueryParams>;
  showDetailSlug: CommonSettingShowDetailSlug;
  metadata: CommonSettingsMetadata;
  client: CommonSettingsClient;
  scripts?: Maybe<Array<CommonSettingsScript>>;
};

export type WebsitesFilter = {
  clientId?: Maybe<Scalars['PositiveInt']>;
  hasText?: Maybe<Scalars['NonEmptyString']>;
  states?: Maybe<Array<WebsiteState>>;
  enabledLocaleCodes?: Maybe<Array<LocaleCode>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  createdByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
  updatedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type PaginatedWebsites = {
  __typename?: 'PaginatedWebsites';
  items: Array<Website>;
  pagination: Pagination;
};

export enum WebsitePageState {
  Draft = 'draft',
  Archived = 'archived',
  Published = 'published'
}

export enum WebsiteState {
  Draft = 'draft',
  Published = 'published',
  Deleted = 'deleted'
}

export enum WebsitePageType {
  Homepage = 'homepage',
  ShowDetail = 'showDetail',
  EventDetail = 'eventDetail',
  ArticleDetail = 'articleDetail',
  SignIn = 'signIn',
  SignUp = 'signUp',
  Profile = 'profile',
  Cart = 'cart',
  PrivacyPolicy = 'privacyPolicy',
  TermsAndConditions = 'termsAndConditions',
  Sitemap = 'sitemap',
  SearchResults = 'searchResults',
  PageNotFound = 'pageNotFound'
}

export type WebsitesPageFilter = {
  state?: Maybe<WebsitePageState>;
  localeCode?: Maybe<LocaleCode>;
  hasText?: Maybe<Scalars['NonEmptyString']>;
};

export type WebsitePage = {
  __typename?: 'WebsitePage';
  ID: Scalars['NonEmptyString'];
  slug: Scalars['String'];
  state: WebsitePageState;
  title: Scalars['NonEmptyString'];
  localeCode: LocaleCode;
  description?: Maybe<Scalars['NonEmptyString']>;
  type?: Maybe<WebsitePageType>;
  metadata?: Maybe<Metadata>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  blocks?: Maybe<Scalars['JSON']>;
};

export type HeaderPhabletUp = {
  __typename?: 'HeaderPhabletUp';
  width: Scalars['NonNegativeInt'];
  height: Scalars['NonNegativeInt'];
};

export type HeaderLinkItem = {
  __typename?: 'HeaderLinkItem';
  label: Scalars['NonEmptyString'];
  href: Scalars['NonEmptyString'];
  target?: Maybe<Scalars['NonEmptyString']>;
};

export type HeaderItemsGroup = {
  __typename?: 'HeaderItemsGroup';
  label: Scalars['NonEmptyString'];
  items: Array<HeaderItem>;
};

export type HeaderItem = HeaderLinkItem | HeaderItemsGroup;

export type HeaderLogo = {
  __typename?: 'HeaderLogo';
  src: Scalars['NonEmptyString'];
  alt?: Maybe<Scalars['NonEmptyString']>;
  width: Scalars['NonNegativeInt'];
  height: Scalars['NonNegativeInt'];
  phabletUp?: Maybe<HeaderPhabletUp>;
};

export type WebsiteHeader = {
  __typename?: 'WebsiteHeader';
  ID: Scalars['NonEmptyString'];
  items: Array<HeaderItem>;
  logo?: Maybe<HeaderLogo>;
  localeCode: LocaleCode;
};

export type UpdateWebsiteInput = {
  projectFullPath?: Maybe<Scalars['NonEmptyString']>;
  productionBranch?: Maybe<Scalars['NonEmptyString']>;
  previewUrl?: Maybe<Scalars['NonEmptyString']>;
  name?: Maybe<Scalars['NonEmptyString']>;
  state?: Maybe<WebsiteState>;
  enabledLocaleCodes?: Maybe<Array<LocaleCode>>;
  primaryDomain?: Maybe<Scalars['NonEmptyString']>;
};

export type WebsiteHeadersFilterInput = {
  localeCode?: Maybe<LocaleCode>;
};

export type WebsiteFootersFilterInput = {
  localeCode?: Maybe<LocaleCode>;
};

export enum FooterItemAlignment {
  Left = 'left',
  Right = 'right',
  Center = 'center'
}

export enum TypographyVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  Subtitle1 = 'subtitle1',
  Subtitle2 = 'subtitle2',
  Body1 = 'body1',
  Body2 = 'body2',
  Caption = 'caption',
  Button = 'button',
  Overline = 'overline'
}

export enum TypifiedIconModel {
  Facebook = 'facebook',
  Instagram = 'instagram',
  WhatsApp = 'whatsApp',
  LinkedIn = 'linkedIn',
  X = 'x',
  YouTube = 'youTube',
  Pinterest = 'pinterest',
  Reddit = 'reddit',
  Google = 'google',
  Apple = 'apple',
  Share = 'share',
  Map = 'map',
  MapPointer = 'mapPointer',
  Info = 'info',
  Clock = 'clock',
  PersonOnWheelchair = 'personOnWheelchair',
  ChildFriendly = 'childFriendly',
  Pets = 'pets',
  Language = 'language',
  World = 'world',
  SignLanguage = 'signLanguage',
  DirectionsCar = 'directionsCar',
  DirectionsBike = 'directionsBike',
  Ticket = 'ticket',
  SmokingRooms = 'smokingRooms',
  RssFeed = 'rssFeed',
  Blind = 'blind',
  PriceTag = 'priceTag',
  Copyright = 'copyright',
  Event = 'event',
  At = 'at',
  Email = 'email',
  Phone = 'phone',
  PersonWithHeadset = 'personWithHeadset',
  Lifeline = 'lifeline',
  Help = 'help',
  Shield = 'shield',
  OpenInNewTab = 'openInNewTab',
  Article = 'article',
  FileDownload = 'fileDownload',
  Cookie = 'cookie',
  Heart = 'heart',
  PaymentCard = 'paymentCard',
  CardGiftcard = 'cardGiftcard',
  Wallet = 'wallet',
  GreekBuilding = 'greekBuilding',
  ShoppingBag = 'shoppingBag',
  Storefront = 'storefront',
  LocalParking = 'localParking',
  SportsBar = 'sportsBar',
  Coffee = 'coffee',
  Calendar = 'calendar',
  TheaterComedy = 'theaterComedy',
  MusicNote = 'musicNote',
  Movie = 'movie',
  FamilyRestroom = 'familyRestroom',
  ColorLens = 'colorLens',
  Diversity = 'diversity'
}

export type FooterItem = {
  __typename?: 'FooterItem';
  label: Scalars['NonEmptyString'];
  typographyVariant?: Maybe<TypographyVariant>;
  link?: Maybe<Scalars['NonEmptyString']>;
  linkTarget?: Maybe<Scalars['NonEmptyString']>;
  icon?: Maybe<TypifiedIconModel>;
};

export type FooterItemGroups = {
  __typename?: 'FooterItemGroups';
  items: Array<FooterItem>;
  alignment: FooterItemAlignment;
};

export type WebsiteFooter = {
  __typename?: 'WebsiteFooter';
  ID: Scalars['NonEmptyString'];
  itemGroups: Array<FooterItemGroups>;
  localeCode: LocaleCode;
};

export type EventsFilterInput = {
  /** If "from" parameter isn't specified, current datetime is used instead */
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  state?: Maybe<EventState>;
  /** See [documentation](https://docs.google.com/document/d/1LykuQSM2pn4zOo35heV3G3Ec5ZgSy2IfXURRANZQEe4/edit?usp=sharing) to understand, when event is available to sell/reserve at point of sale */
  isAvailableAtPointOfSale?: Maybe<Scalars['Boolean']>;
  isAvailableOnEcommerce?: Maybe<Scalars['Boolean']>;
  hasText?: Maybe<Scalars['String']>;
  divisionId?: Maybe<Scalars['Int']>;
  venueId?: Maybe<Scalars['Int']>;
  typeCode?: Maybe<ShowTypeCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  genreCode?: Maybe<ShowGenreCode>;
  formatCode?: Maybe<ShowFormatCode>;
  soundMixCode?: Maybe<ShowSoundMixCode>;
  versionCode?: Maybe<ShowVersionCode>;
  showOnWebsiteAndApi?: Maybe<Scalars['Boolean']>;
  showId?: Maybe<Scalars['PositiveInt']>;
  eventCategoryId?: Maybe<Scalars['PositiveInt']>;
  marketingLabelId?: Maybe<Scalars['PositiveInt']>;
  costCenterId?: Maybe<Scalars['PositiveInt']>;
  businessPartnerIds?: Maybe<Array<Scalars['PositiveInt']>>;
  eventIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type PaginatedEventsSuccessResult = {
  __typename?: 'PaginatedEventsSuccessResult';
  items: Array<Event>;
  pagination: Pagination;
};

export enum EventState {
  Draft = 'draft',
  Published = 'published',
  Canceled = 'canceled',
  Deleted = 'deleted'
}

export enum EventExpirationType {
  LastFor = 'lastFor',
  ExpiresAt = 'expiresAt'
}

/** Used in database and in EventSeat model. On level of database, we do not differentiate between some states. */
export enum DbSeatState {
  Available = 'available',
  Hidden = 'hidden',
  Selected = 'selected',
  Disabled = 'disabled',
  Reserved = 'reserved',
  Cart = 'cart',
  PreSold = 'preSold',
  Sold = 'sold'
}

/**
 * It's a state used in interface between backend and frontend. We combine DbSeatState with information about current user, cart, reservation to split selected, reserved and cart states to
 * - selectedByMe
 * - selectedByOthers
 * - addedToMyCart
 * - addedToOtherCart
 * - reservedInMyCart
 * - inMyReservation
 * - inOtherReservation
 */
export enum ApiSeatState {
  Available = 'available',
  Hidden = 'hidden',
  SelectedByMe = 'selectedByMe',
  SelectedByOthers = 'selectedByOthers',
  AddedToMyCart = 'addedToMyCart',
  AddedToOtherCart = 'addedToOtherCart',
  InMyReservation = 'inMyReservation',
  InOtherReservation = 'inOtherReservation',
  /** This state should be used when seat state DbSeatState.Cart and myCartId equals item's cartId and item's reservationId matches one of the myReservationIds */
  ReservedInMyCart = 'reservedInMyCart',
  Disabled = 'disabled',
  PreSold = 'preSold',
  Sold = 'sold'
}

export enum EventSeatType {
  Seat = 'seat',
  Zone = 'zone'
}

export enum EventSeatUserType {
  Anonymous = 'anonymous',
  /** For both revolt client users and revolt administrator users */
  RevoltUser = 'revoltUser'
}

export type EventSeat = {
  __typename?: 'EventSeat';
  id: Scalars['Int'];
  event: Event;
  uuid: Scalars['String'];
  state: ApiSeatState;
  userType?: Maybe<EventSeatUserType>;
  stateChangedBy?: Maybe<Scalars['String']>;
  type: EventSeatType;
  label: Scalars['String'];
  row?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RawEventSeat = {
  __typename?: 'RawEventSeat';
  id: Scalars['PositiveInt'];
  eventId: Scalars['PositiveInt'];
  uuid: Scalars['NonEmptyString'];
  userType?: Maybe<EventSeatUserType>;
  state: ApiSeatState;
  stateBeforeSelected?: Maybe<ApiSeatState>;
  stateChangedBy?: Maybe<Scalars['String']>;
  type: EventSeatType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventSaleAndReservationFields = {
  __typename?: 'EventSaleAndReservationFields';
  saleStart: Scalars['DateTime'];
  saleEnd: Scalars['DateTime'];
  saleActive: Scalars['Boolean'];
  reservationStart: Scalars['DateTime'];
  reservationEnd: Scalars['DateTime'];
  reservationActive: Scalars['Boolean'];
  reservationExpirationType: EventExpirationType;
  reservationExpirationLastFor: Scalars['Int'];
  reservationExpirationExpiresAt: Scalars['DateTime'];
  isEnabledPaymentForReservation: Scalars['Boolean'];
};

export type EventSaleAndReservationFieldsInput = {
  saleStart: Scalars['DateTime'];
  saleEnd: Scalars['DateTime'];
  saleActive: Scalars['Boolean'];
  reservationStart: Scalars['DateTime'];
  reservationEnd: Scalars['DateTime'];
  reservationActive: Scalars['Boolean'];
  reservationExpirationType: EventExpirationType;
  reservationExpirationLastFor: Scalars['Int'];
  reservationExpirationExpiresAt: Scalars['DateTime'];
  isEnabledPaymentForReservation: Scalars['Boolean'];
};

export type EventSaleAndReservation = {
  __typename?: 'EventSaleAndReservation';
  pointOfSale: EventSaleAndReservationFields;
  online: EventSaleAndReservationFields;
};

export type EventSaleAndReservationInput = {
  pointOfSale: EventSaleAndReservationFieldsInput;
  online: EventSaleAndReservationFieldsInput;
  /**
   * This sets the maximum number of occupied seats compared on Retail
   * with number of occupied seats on both selling channels
   */
  maxNumberOfOccupiedSeatsOnRetail: Scalars['NonNegativeInt'];
  /**
   * This sets the maximum number of occupied seats compared on Ecommerce
   * with number of occupied seats on both selling channels
   */
  maxNumberOfOccupiedSeatsOnEcommerce: Scalars['NonNegativeInt'];
};

export type EventTicketType = {
  __typename?: 'EventTicketType';
  id: Scalars['Int'];
  eventPricingId: Scalars['Int'];
  ticketTypeId: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price: Scalars['NonNegativeFloat'];
  promotions: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  vatRate: Scalars['NonNegativeFloat'];
};

export type EventPricingToTicketType = {
  __typename?: 'EventPricingToTicketType';
  id: Scalars['Int'];
  eventPricingId: Scalars['Int'];
  ticketTypeId: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price: Scalars['NonNegativeFloat'];
  promotions: Scalars['Boolean'];
  vatRate: Scalars['NonNegativeFloat'];
};

export type EventPricing = {
  __typename?: 'EventPricing';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  startsAt: Scalars['DateTime'];
  /** Returns minimal price available in ticketTypes */
  minPrice: Scalars['NonNegativeFloat'];
  /** Returns maximal price available in ticketTypes */
  maxPrice: Scalars['NonNegativeFloat'];
  ticketTypes: Array<EventTicketType>;
};

export type EventTicketTypeInput = {
  ticketTypeId: Scalars['Int'];
  price: Scalars['NonNegativeFloat'];
  promotions: Scalars['Boolean'];
  vatRate?: Maybe<Scalars['NonNegativeFloat']>;
};

export type EventPricingInput = {
  startsAt?: Maybe<Scalars['DateTime']>;
  isStartsAtNow?: Maybe<Scalars['Boolean']>;
  ticketTypes: Array<EventTicketTypeInput>;
};

export type EnabledDiscountsFilterInput = {
  channel?: Maybe<SellingChannel>;
  discountApplications?: Maybe<Array<DiscountApplication>>;
  displayModes?: Maybe<Array<DiscountDisplayMode>>;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['Int'];
  showId: Scalars['Int'];
  venue: Venue;
  auditorium: Auditorium;
  auditoriumLayout: AuditoriumLayout;
  auditoriumLayoutPricing: AuditoriumLayoutPricing;
  auditoriumLayoutPricingId: Scalars['Int'];
  divisionId: Scalars['Int'];
  clientId: Scalars['PositiveInt'];
  division: Division;
  names: Translated;
  startsAt: Scalars['DateTime'];
  duration: Scalars['PositiveInt'];
  serviceTime: Scalars['NonNegativeInt'];
  endsAt: Scalars['DateTime'];
  state: EventState;
  show: ClientShow;
  moved: Scalars['Boolean'];
  formatCode?: Maybe<ShowFormatCode>;
  soundMixCode?: Maybe<ShowSoundMixCode>;
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  showOnWebsiteAndApi: Scalars['Boolean'];
  gateOpensAt: Scalars['DateTime'];
  gateClosedAt: Scalars['DateTime'];
  checkingOption: CheckingOption;
  organizerNote: Scalars['String'];
  ticketNote: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  salesAndReservation: EventSaleAndReservation;
  pricings: Array<EventPricing>;
  activePricing: EventPricing;
  reservedSeatsCount: Scalars['NonNegativeInt'];
  soldSeatsCount: Scalars['NonNegativeInt'];
  availableSeatsCount: Scalars['NonNegativeInt'];
  hiddenSeatsCount: Scalars['NonNegativeInt'];
  disabledSeatsCount: Scalars['NonNegativeInt'];
  inCartSeatsCount: Scalars['NonNegativeInt'];
  selectedSeatsCount: Scalars['NonNegativeInt'];
  allowedDiscountsSellingChannels: Array<SellingChannel>;
  /** Enabled discounts are discounts already applicable for specific selling channel */
  enabledDiscounts: Array<Discount>;
  /** Available discounts are discounts that will be applicable after client enables them. */
  availableDiscounts: Array<Discount>;
  ecommerceEventURL: Scalars['String'];
  passCodesCheckedInCount: Scalars['NonNegativeInt'];
  passCodesCheckedOutCount: Scalars['NonNegativeInt'];
  uniqueCheckedInCount: Scalars['NonNegativeInt'];
  maxNumberOfOccupiedSeatsOnRetail: Scalars['NonNegativeInt'];
  maxNumberOfOccupiedSeatsOnEcommerce: Scalars['NonNegativeInt'];
  costCenterId?: Maybe<Scalars['NonNegativeInt']>;
  costCenter?: Maybe<CostCenter>;
  eventCategoryId?: Maybe<Scalars['NonNegativeInt']>;
  eventCategory?: Maybe<EventCategory>;
  marketingLabelId?: Maybe<Scalars['NonNegativeInt']>;
  marketingLabel?: Maybe<MarketingLabel>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
  businessPartner?: Maybe<BusinessPartner>;
  feePercentageOfSoldTickets?: Maybe<Scalars['Float']>;
  feeFixedAmountPerSoldTicket?: Maybe<Scalars['Float']>;
  feeFixedAmountPerEvent?: Maybe<Scalars['Float']>;
  feeMinimumGuarantee?: Maybe<Scalars['Float']>;
  thirdPartyPurchaseURL?: Maybe<Scalars['Url']>;
};


export type EventEnabledDiscountsArgs = {
  discountsFilter?: Maybe<EnabledDiscountsFilterInput>;
};

export type CreateEventInput = {
  showId: Scalars['Int'];
  auditoriumLayoutPricingId: Scalars['Int'];
  divisionId: Scalars['Int'];
  names: TranslatedInput;
  startsAt: Scalars['DateTime'];
  duration: Scalars['PositiveInt'];
  serviceTime: Scalars['NonNegativeInt'];
  formatCode?: Maybe<ShowFormatCode>;
  soundMixCode?: Maybe<ShowSoundMixCode>;
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  ticketTypes: Array<CreateEventTicketTypeInput>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
  /** Value between 0.00 and 100.00 */
  feePercentageOfSoldTickets?: Maybe<Scalars['Float']>;
  /** Value between 0.00 and 1 000 000.00 */
  feeFixedAmountPerSoldTicket?: Maybe<Scalars['Float']>;
  /** Value between 0.00 and 1 000 000.00 */
  feeFixedAmountPerEvent?: Maybe<Scalars['Float']>;
  /** Value between 0.00 and 1 000 000.00 */
  feeMinimumGuarantee?: Maybe<Scalars['Float']>;
};

export type CreateEventTicketTypeInput = {
  ticketTypeId: Scalars['Int'];
  price: Scalars['NonNegativeFloat'];
  promotions: Scalars['Boolean'];
  vatRate?: Maybe<Scalars['NonNegativeFloat']>;
};

export type GeneralEventDataInput = {
  names: TranslatedInput;
  startsAt: Scalars['DateTime'];
  duration: Scalars['PositiveInt'];
  serviceTime: Scalars['NonNegativeInt'];
  formatCode?: Maybe<ShowFormatCode>;
  soundMixCode?: Maybe<ShowSoundMixCode>;
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
};

export enum CheckingOption {
  Entrance = 'entrance',
  EntranceExit = 'entranceExit'
}

export type ExtendedEventDataInput = {
  showOnWebsiteAndApi: Scalars['Boolean'];
  organizerNote: Scalars['String'];
  ticketNote: Scalars['String'];
  divisionId: Scalars['Int'];
  gateOpensAt: Scalars['DateTime'];
  gateClosedAt: Scalars['DateTime'];
  checkingOption: CheckingOption;
  /**
   * Null costCenterId will cause deletion of costCenter for event.
   * Omitted or undefined costCenterId will cause preserving of costCenter for event
   */
  costCenterId?: Maybe<Scalars['NonNegativeInt']>;
  /**
   * Null eventCategoryId will cause deletion of eventCategory for event.
   * Omitted or undefined eventCategoryId will cause preserving of eventCategory for event
   */
  eventCategoryId?: Maybe<Scalars['NonNegativeInt']>;
  /**
   * Null marketingLabelId will cause deletion of marketingLabel for event.
   * Omitted or undefined marketingLabelId will cause preserving of marketingLabel for event
   */
  marketingLabelId?: Maybe<Scalars['NonNegativeInt']>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
  /** Value between 0.00 and 100.00 */
  feePercentageOfSoldTickets?: Maybe<Scalars['Float']>;
  /** Value between 0.00 and 1 000 000.00 */
  feeFixedAmountPerSoldTicket?: Maybe<Scalars['Float']>;
  /** Value between 0.00 and 1 000 000.00 */
  feeFixedAmountPerEvent?: Maybe<Scalars['Float']>;
  /** Value between 0.00 and 1 000 000.00 */
  feeMinimumGuarantee?: Maybe<Scalars['Float']>;
  thirdPartyPurchaseURL?: Maybe<Scalars['Url']>;
};

export type CostCenter = {
  __typename?: 'CostCenter';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type CostCenterInput = {
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type EventCategory = {
  __typename?: 'EventCategory';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type EventCategoryInput = {
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type MarketingLabel = {
  __typename?: 'MarketingLabel';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type MarketingLabelInput = {
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type MarketingLabelsFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  isActive?: Maybe<Scalars['Boolean']>;
  marketingLabelIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type EventCategoriesFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  isActive?: Maybe<Scalars['Boolean']>;
  eventCategoriesIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type CostCentersFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  isActive?: Maybe<Scalars['Boolean']>;
  costCentersIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

/** [Documentation](https://docs.google.com/document/d/1v39adDV84UWTVGbrnXVXcrmKFsAVGL0eQ1i6Y20eXGo/edit?usp=sharing) */
export enum DiscountApplication {
  Selectable = 'selectable',
  Code = 'code',
  /** Not implemented */
  Automatic = 'automatic',
  Customer = 'customer'
}

/** [Documentation](https://docs.google.com/document/d/1v39adDV84UWTVGbrnXVXcrmKFsAVGL0eQ1i6Y20eXGo/edit?usp=sharing) */
export enum DiscountType {
  Percentage = 'percentage',
  FixedAmount = 'fixedAmount'
}

/** [Documentation](https://docs.google.com/document/d/1v39adDV84UWTVGbrnXVXcrmKFsAVGL0eQ1i6Y20eXGo/edit?usp=sharing) */
export enum DiscountState {
  Inactive = 'inactive',
  Active = 'active',
  Deleted = 'deleted'
}

/** [Documentation](https://docs.google.com/document/d/1v39adDV84UWTVGbrnXVXcrmKFsAVGL0eQ1i6Y20eXGo/edit?usp=sharing) */
export enum ApplicationRuleType {
  Event = 'event',
  Tour = 'tour',
  Cart = 'cart',
  Product = 'product'
}

/** [Documentation](https://docs.google.com/document/d/1v39adDV84UWTVGbrnXVXcrmKFsAVGL0eQ1i6Y20eXGo/edit?usp=sharing) */
export enum ConditionResource {
  ShowTypeCode = 'showTypeCode',
  FormatCode = 'formatCode',
  VersionCode = 'versionCode',
  SoundMixCode = 'soundMixCode',
  ShowAgeClassificationCode = 'showAgeClassificationCode',
  VenueId = 'venueId',
  AuditoriumId = 'auditoriumId',
  ShowId = 'showId',
  EventId = 'eventId',
  StartWeekDay = 'startWeekDay',
  StartTime = 'startTime',
  StartDate = 'startDate',
  MarketingLabelId = 'marketingLabelId',
  CostCenterId = 'costCenterId',
  EventCategoryId = 'eventCategoryId',
  ApplicableByUserId = 'applicableByUserId',
  DiscountApplicationTime = 'discountApplicationTime',
  DiscountApplicationDate = 'discountApplicationDate',
  TourId = 'tourId',
  TourTimeSlotId = 'tourTimeSlotId',
  AdmissionTypeId = 'admissionTypeId'
}

/** [Documentation](https://docs.google.com/document/d/1v39adDV84UWTVGbrnXVXcrmKFsAVGL0eQ1i6Y20eXGo/edit?usp=sharing) */
export enum ConditionOperator {
  IsOneOf = 'isOneOf',
  IsNotOneOf = 'isNotOneOf',
  IsGreaterOrEqual = 'isGreaterOrEqual',
  IsLowerOrEqual = 'isLowerOrEqual',
  IsEqual = 'isEqual',
  IsNotEqual = 'isNotEqual'
}

export enum DiscountDisplayMode {
  Hidden = 'hidden',
  Displayed = 'displayed'
}

export enum DiscountCodeRedemptionIntentState {
  Pending = 'pending',
  Redeemed = 'redeemed',
  RolledBack = 'rolledBack'
}

export type ConditionData = {
  __typename?: 'ConditionData';
  operator: ConditionOperator;
  /** value should be String!, Int!, [String!]! or [Int!]! */
  value?: Maybe<Scalars['JSON']>;
};

export type Condition = {
  __typename?: 'Condition';
  id: Scalars['Int'];
  applicationRuleId: Scalars['Int'];
  conditionData: ConditionData;
  resource: ConditionResource;
};

export type ApplicationRule = {
  __typename?: 'ApplicationRule';
  id: Scalars['Int'];
  discountId: Scalars['Int'];
  type: ApplicationRuleType;
  conditions: Array<Condition>;
};

export type Discount = {
  __typename?: 'Discount';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  internalDescription?: Maybe<Scalars['String']>;
  application: DiscountApplication;
  type: DiscountType;
  value: Scalars['NonNegativeFloat'];
  sellingChannels: Array<SellingChannel>;
  state: DiscountState;
  divisions: Array<Division>;
  applicationRules: Array<ApplicationRule>;
  applicationRulesCount: Scalars['NonNegativeInt'];
  maxUsageLimitPerOrder?: Maybe<Scalars['PositiveInt']>;
  displayMode: DiscountDisplayMode;
};

export type DiscountCodeRedemptionIntent = {
  __typename?: 'DiscountCodeRedemptionIntent';
  id: Scalars['PositiveInt'];
  itemId: Scalars['PositiveInt'];
  discountCodeId: Scalars['PositiveInt'];
  state: DiscountCodeRedemptionIntentState;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type DiscountCode = {
  __typename?: 'DiscountCode';
  id: Scalars['Int'];
  name: Scalars['String'];
  state: DiscountCodeState;
  description?: Maybe<Scalars['String']>;
  usageLimit?: Maybe<Scalars['NonNegativeInt']>;
  usageCount: Scalars['NonNegativeInt'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByName: Scalars['String'];
  discount: Discount;
  usageLimitPerOrder?: Maybe<Scalars['PositiveInt']>;
  redemptionIntentCount?: Maybe<Scalars['NonNegativeInt']>;
  remainingRedemptionCount?: Maybe<Scalars['NonNegativeInt']>;
  redemptionIntents: Array<DiscountCodeRedemptionIntent>;
};

export type PaginatedDiscountsSuccessResult = {
  __typename?: 'PaginatedDiscountsSuccessResult';
  items: Array<Discount>;
  pagination: Pagination;
};

export type DiscountCodeInput = {
  discountId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  usageLimit?: Maybe<Scalars['PositiveInt']>;
  /** Only date with rounded minutes, seconds and milliseconds is accepted */
  activationDate?: Maybe<Scalars['DateTime']>;
  /** Only date with rounded minutes, seconds and milliseconds is accepted */
  expirationDate?: Maybe<Scalars['DateTime']>;
  usageLimitPerOrder?: Maybe<Scalars['PositiveInt']>;
};

export type DiscountInput = {
  name: Scalars['String'];
  internalDescription?: Maybe<Scalars['String']>;
  application: DiscountApplication;
  type: DiscountType;
  value: Scalars['NonNegativeFloat'];
  sellingChannels: Array<SellingChannel>;
  divisionIds: Array<Scalars['Int']>;
  maxUsageLimitPerOrder?: Maybe<Scalars['PositiveInt']>;
  displayMode: DiscountDisplayMode;
};

export type UpdateDiscountInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  internalDescription?: Maybe<Scalars['String']>;
  sellingChannels: Array<SellingChannel>;
  state: DiscountState;
  divisionIds: Array<Scalars['Int']>;
  maxUsageLimitPerOrder?: Maybe<Scalars['PositiveInt']>;
  displayMode?: Maybe<DiscountDisplayMode>;
};

export type CreateApplicationRuleInput = {
  type: ApplicationRuleType;
  discountId: Scalars['Int'];
};

export type DiscountCodesFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  name?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  activationDateFrom?: Maybe<Scalars['Date']>;
  activationDateTo?: Maybe<Scalars['Date']>;
  expirationDateFrom?: Maybe<Scalars['Date']>;
  expirationDateTo?: Maybe<Scalars['Date']>;
  states?: Maybe<Array<DiscountCodeState>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
  usageCountFrom?: Maybe<Scalars['NonNegativeInt']>;
  usageCountTo?: Maybe<Scalars['NonNegativeInt']>;
};

export type DiscountsFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  states?: Maybe<Array<DiscountState>>;
  sellingChannels?: Maybe<Array<SellingChannel>>;
  types?: Maybe<Array<DiscountType>>;
  applications?: Maybe<Array<DiscountApplication>>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  discountCodeName?: Maybe<Scalars['NonEmptyString']>;
  displayModes?: Maybe<Array<DiscountDisplayMode>>;
};

export type CreateConditionInput = {
  applicationRuleId: Scalars['Int'];
  /**
   * Each resource requires specific values. See examples of values for specific resources:
   * ConditionResource.ShowTypeCode: [ShowTypeCode.Play]
   * ConditionResource.FormatCode: [ShowFormatCode.F2D]
   * ConditionResource.VersionCode: [ShowVersionCode.Subtitles]
   * ConditionResource.SoundMixCode: [ShowSoundMixCode.S71]
   * ConditionResource.ShowAgeClassificationCode: [ShowAgeClassificationCode.CzNoRestrictions]
   * ConditionResource.VenueId: [1]
   * ConditionResource.AuditoriumId: [1]
   * ConditionResource.ShowId: [1]
   * ConditionResource.EventId: [1]
   * ConditionResource.StartWeekDay: [WeekDay.Thu]
   * ConditionResource.StartTime: '21:00'
   * ConditionResource.StartDate: '2021-01-01'
   * ConditionResource.MarketingLabelId: [1]
   * ConditionResource.CostCenterId: [1]
   * ConditionResource.EventCategoryId: [1]
   * ConditionResource.ApplicableByUserId: [1]
   * ConditionResource.DiscountApplicationTime: '21:00'
   * ConditionResource.DiscountApplicationDate: '2021-01-01'
   * ConditionResource.TourId: [1]
   * ConditionResource.TourTimeSlotId: [1]
   * ConditionResource.AdmissionTypeId: [1]
   */
  value: Scalars['JSON'];
  /**
   * Each resource requires specific operators. See available operators for specific resources:
   * ConditionResource.ShowTypeCode: IsOneOf or IsNotOneOf
   * ConditionResource.FormatCode: IsOneOf or IsNotOneOf
   * ConditionResource.VersionCode: IsOneOf or IsNotOneOf
   * ConditionResource.SoundMixCode: IsOneOf or IsNotOneOf
   * ConditionResource.ShowAgeClassificationCode: IsOneOf or IsNotOneOf
   * ConditionResource.VenueId: IsOneOf or IsNotOneOf
   * ConditionResource.AuditoriumId: IsOneOf or IsNotOneOf
   * ConditionResource.ShowId: IsOneOf or IsNotOneOf
   * ConditionResource.EventId: IsOneOf or IsNotOneOf
   * ConditionResource.StartWeekDay: IsOneOf or IsNotOneOf
   * ConditionResource.StartTime: isEqual, isNotEqual, IsGreaterOrEqual or IsLowerOrEqual
   * ConditionResource.StartDate: isEqual, isNotEqual, IsGreaterOrEqual or IsLowerOrEqual
   * ConditionResource.MarketingLabelId: IsOneOf or IsNotOneOf
   * ConditionResource.CostCenterId: IsOneOf or IsNotOneOf
   * ConditionResource.EventCategoryId: IsOneOf or IsNotOneOf
   * ConditionResource.ApplicableByUserId: IsOneOf or IsNotOneOf
   * ConditionResource.DiscountApplicationTime: isEqual, isNotEqual, IsGreaterOrEqual or IsLowerOrEqual
   * ConditionResource.DiscountApplicationDate: isEqual, isNotEqual, IsGreaterOrEqual or IsLowerOrEqual
   * ConditionResource.TourId: IsOneOf or IsNotOneOf
   * ConditionResource.TourTimeSlotId: IsOneOf or IsNotOneOf
   * ConditionResource.AdmissionTypeId: IsOneOf or IsNotOneOf
   */
  operator: ConditionOperator;
  resource: ConditionResource;
};

export type UpdateConditionInput = {
  id: Scalars['Int'];
  /**
   * Each resource requires specific values. See examples of values for specific resources:
   * ConditionResource.ShowTypeCode: [ShowTypeCode.Play]
   * ConditionResource.FormatCode: [ShowFormatCode.F2D]
   * ConditionResource.VersionCode: [ShowVersionCode.Subtitles]
   * ConditionResource.SoundMixCode: [ShowSoundMixCode.S71]
   * ConditionResource.ShowAgeClassificationCode: [ShowAgeClassificationCode.CzNoRestrictions]
   * ConditionResource.VenueId: [1]
   * ConditionResource.AuditoriumId: [1]
   * ConditionResource.ShowId: [1]
   * ConditionResource.EventId: [1]
   * ConditionResource.StartWeekDay: [WeekDay.Thu]
   * ConditionResource.StartTime: '21:00'
   * ConditionResource.StartDate: '2021-01-01'
   * ConditionResource.MarketingLabelId: [1]
   * ConditionResource.CostCenterId: [1]
   * ConditionResource.EventCategoryId: [1]
   * ConditionResource.ApplicableByUserId: [1]
   * ConditionResource.DiscountApplicationTime: '21:00'
   * ConditionResource.DiscountApplicationDate: '2021-01-01'
   * ConditionResource.TourId: [1]
   * ConditionResource.TourTimeSlotId: [1]
   * ConditionResource.AdmissionTypeId: [1]
   */
  value: Scalars['JSON'];
  /**
   * Each resource requires specific operators. See available operators for specific resources:
   * ConditionResource.ShowTypeCode: IsOneOf or IsNotOneOf
   * ConditionResource.FormatCode: IsOneOf or IsNotOneOf
   * ConditionResource.VersionCode: IsOneOf or IsNotOneOf
   * ConditionResource.SoundMixCode: IsOneOf or IsNotOneOf
   * ConditionResource.ShowAgeClassificationCode: IsOneOf or IsNotOneOf
   * ConditionResource.VenueId: IsOneOf or IsNotOneOf
   * ConditionResource.AuditoriumId: IsOneOf or IsNotOneOf
   * ConditionResource.ShowId: IsOneOf or IsNotOneOf
   * ConditionResource.EventId: IsOneOf or IsNotOneOf
   * ConditionResource.StartWeekDay: IsOneOf or IsNotOneOf
   * ConditionResource.StartTime: isEqual, isNotEqual, IsGreaterOrEqual or IsLowerOrEqual
   * ConditionResource.StartDate: isEqual, isNotEqual, IsGreaterOrEqual or IsLowerOrEqual
   * ConditionResource.MarketingLabelId: IsOneOf or IsNotOneOf
   * ConditionResource.CostCenterId: IsOneOf or IsNotOneOf
   * ConditionResource.EventCategoryId: IsOneOf or IsNotOneOf
   * ConditionResource.ApplicableByUserId: IsOneOf or IsNotOneOf
   * ConditionResource.DiscountApplicationTime: isEqual, isNotEqual, IsGreaterOrEqual or IsLowerOrEqual
   * ConditionResource.DiscountApplicationDate: isEqual, isNotEqual, IsGreaterOrEqual or IsLowerOrEqual
   * ConditionResource.TourId: IsOneOf or IsNotOneOf
   * ConditionResource.TourTimeSlotId: IsOneOf or IsNotOneOf
   * ConditionResource.AdmissionTypeId: IsOneOf or IsNotOneOf
   */
  operator: ConditionOperator;
};

export enum CartState {
  Draft = 'draft',
  Sold = 'sold',
  Discarded = 'discarded',
  Expired = 'expired',
  Failed = 'failed',
  Reserved = 'reserved',
  Pending = 'pending'
}

export enum ItemType {
  Product = 'product',
  Ticket = 'ticket',
  Tour = 'tour'
}

export enum CheckoutOptions {
  Reservation = 'reservation',
  Sale = 'sale'
}

export enum Unit {
  Kilogram = 'kilogram',
  Gram = 'gram',
  Milligram = 'milligram',
  Liter = 'liter',
  Milliliter = 'milliliter',
  Centiliter = 'centiliter',
  Piece = 'piece'
}

export enum TicketItemCheckStatus {
  CheckedIn = 'checkedIn',
  CheckedOut = 'checkedOut',
  Unchecked = 'unchecked'
}

export enum TourItemCheckStatus {
  CheckedIn = 'checkedIn',
  CheckedOut = 'checkedOut',
  Unchecked = 'unchecked',
  PartiallyChecked = 'partiallyChecked'
}

export type PassCodeCheckActivityLog = {
  __typename?: 'PassCodeCheckActivityLog';
  direction: PassCodeCheckDirection;
  inputType: PassCodeCheckInputType;
  discountAuthorizationMode: DiscountAuthorizationMode;
  additionalInformation: PassCodeCheckAdditionalInformation;
  state: PassCodeCheckState;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  updatedByName: Scalars['String'];
};

export type PaginatedPassCodeCheckActivityLog = {
  __typename?: 'PaginatedPassCodeCheckActivityLog';
  items: Array<PassCodeCheckActivityLog>;
  pagination: Pagination;
};

export type TicketItem = {
  __typename?: 'TicketItem';
  id: Scalars['PositiveInt'];
  itemType: ItemType;
  relatedItemId?: Maybe<Scalars['PositiveInt']>;
  cartId: Scalars['PositiveInt'];
  reservation?: Maybe<Reservation>;
  sale?: Maybe<Sale>;
  claim?: Maybe<Claim>;
  divisionId: Scalars['PositiveInt'];
  division: Division;
  price: Scalars['Float'];
  priceBeforeDiscount: Scalars['NonNegativeFloat'];
  priceVatExcluded: Scalars['Float'];
  priceLevel: Scalars['String'];
  vatRate: Scalars['NonNegativeFloat'];
  vat: Scalars['Float'];
  eventPricingToTicketType: EventPricingToTicketType;
  eventSeat: EventSeat;
  appliedDiscounts: Array<AppliedDiscount>;
  passCode?: Maybe<Scalars['NonEmptyString']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  totalPassCodeChecksCount: Scalars['NonNegativeInt'];
  checkedInCount: Scalars['NonNegativeInt'];
  checkedOutCount: Scalars['NonNegativeInt'];
  checkStatus: TicketItemCheckStatus;
  passCodeCheckActivityLog: PaginatedPassCodeCheckActivityLog;
};


export type TicketItemPassCodeCheckActivityLogArgs = {
  paginationInput?: Maybe<PaginationInput>;
};

export type ProductItem = {
  __typename?: 'ProductItem';
  id: Scalars['PositiveInt'];
  itemType: ItemType;
  relatedItemId?: Maybe<Scalars['PositiveInt']>;
  productId: Scalars['PositiveInt'];
  warehouseId?: Maybe<Scalars['PositiveInt']>;
  product: Product;
  cartId: Scalars['PositiveInt'];
  sale?: Maybe<Sale>;
  claim?: Maybe<Claim>;
  divisionId: Scalars['PositiveInt'];
  division: Division;
  price: Scalars['Float'];
  priceBeforeDiscount: Scalars['NonNegativeFloat'];
  priceVatExcluded: Scalars['Float'];
  vatRate: Scalars['NonNegativeFloat'];
  vat: Scalars['Float'];
  name: Scalars['NonEmptyString'];
  unit: Unit;
  unitPrice: Scalars['Float'];
  unitPriceVatExcluded: Scalars['Float'];
  quantity: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
};

export enum TourItemState {
  InCart = 'inCart',
  Reserved = 'reserved',
  Sold = 'sold',
  Refunded = 'refunded',
  Unsuccessful = 'unsuccessful'
}

export type TourItem = {
  __typename?: 'TourItem';
  id: Scalars['PositiveInt'];
  itemType: ItemType;
  state: TourItemState;
  relatedItemId?: Maybe<Scalars['PositiveInt']>;
  cartId: Scalars['PositiveInt'];
  reservation?: Maybe<Reservation>;
  sale?: Maybe<Sale>;
  claim?: Maybe<Claim>;
  divisionId: Scalars['PositiveInt'];
  division: Division;
  tourTimeSlotId: Scalars['PositiveInt'];
  tourTimeSlot: TourTimeSlot;
  admissionTypeId: Scalars['PositiveInt'];
  admissionType: AdmissionType;
  admissionTypeAssignmentId: Scalars['PositiveInt'];
  admissionTypeAssignment: AdmissionTypeAssignment;
  name: Scalars['NonEmptyString'];
  attendeesCount: Scalars['NonNegativeInt'];
  price: Scalars['Float'];
  priceBeforeDiscount: Scalars['NonNegativeFloat'];
  priceVatExcluded: Scalars['Float'];
  vatRate: Scalars['NonNegativeFloat'];
  vat: Scalars['Float'];
  appliedDiscounts: Array<AppliedDiscount>;
  passCode?: Maybe<Scalars['NonEmptyString']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  totalPassCodeChecksCount: Scalars['NonNegativeInt'];
  checkedInCount: Scalars['NonNegativeInt'];
  checkedOutCount: Scalars['NonNegativeInt'];
  checkStatus: TourItemCheckStatus;
  passCodeCheckActivityLog: PaginatedPassCodeCheckActivityLog;
};


export type TourItemPassCodeCheckActivityLogArgs = {
  paginationInput?: Maybe<PaginationInput>;
};

export type ItemWithPassCode = TicketItem | TourItem;

export type Item = TicketItem | ProductItem | TourItem;

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['Int'];
  price: Scalars['Float'];
  state: CartState;
  clientId: Scalars['Int'];
  client: Client;
  sellingChannel: SellingChannel;
  reservations?: Maybe<Array<Reservation>>;
  sales?: Maybe<Array<Sale>>;
  /**
   * If cart checkout options equal to array with reservation and sale, cart can be reserved or purchased.
   * If cart checkout options equal to array with reservation, cart can be reserved.
   * If cart checkout options equal to array with sale, cart can be purchased.
   */
  checkoutOptions?: Maybe<Array<Maybe<CheckoutOptions>>>;
  items?: Maybe<Array<Item>>;
  lead?: Maybe<Lead>;
  uniqueLeads: Array<Lead>;
  payment?: Maybe<Payment>;
  paymentIntents: Array<PaymentIntent>;
  uuid?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  eCommercePaymentRedirectUrl?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  updatedBy: Scalars['String'];
  localeCode?: Maybe<LocaleCode>;
};

export type IncrementZoneTicketItemQuantitySuccessResult = {
  __typename?: 'IncrementZoneTicketItemQuantitySuccessResult';
  cart: Cart;
  newlyAddedZoneTicketItemsCount: Scalars['Int'];
};

export type IncrementZoneTicketItemQuantityResult = IncrementZoneTicketItemQuantitySuccessResult | ErrorResult;

export type PaymentIntentInput = {
  /** voucherId must be provided when payment method type is voucher and service provider is InHouseVoucher */
  voucherId?: Maybe<Scalars['PositiveInt']>;
  /**
   * voucherPinCode must be provided when payment method type is voucher, service provider is InHouseVoucher and
   * selling channel is Ecommerce
   */
  voucherPinCode?: Maybe<Scalars['NonEmptyString']>;
  /** isManagedByThirdPartyLocalDevice must be provided when payment is managed by local device */
  isManagedByThirdPartyLocalDevice?: Maybe<Scalars['Boolean']>;
  paymentMethodId: Scalars['Int'];
  denomination?: Maybe<Scalars['NonNegativeFloat']>;
  amount: Scalars['NonNegativeFloat'];
  count?: Maybe<Scalars['PositiveInt']>;
};

export type NarrowCart = {
  __typename?: 'NarrowCart';
  id: Scalars['Int'];
  price: Scalars['Float'];
  state: CartState;
  clientId: Scalars['Int'];
  client: Client;
  sellingChannel: SellingChannel;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdByName?: Maybe<Scalars['String']>;
  lead?: Maybe<Lead>;
  localeCode?: Maybe<LocaleCode>;
  itemsCount: Scalars['NonNegativeInt'];
  orderIds: Array<Scalars['Int']>;
  orderSequenceNumbers: Array<Scalars['String']>;
  eventNames: Array<Translated>;
  eventStartsAts: Array<Scalars['DateTime']>;
};

export type CartsFilterInput = {
  sellingChannel?: Maybe<SellingChannel>;
  cartStates?: Maybe<Array<Maybe<CartState>>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
  leadName?: Maybe<Scalars['String']>;
  leadEmail?: Maybe<Scalars['String']>;
  leadPhone?: Maybe<Scalars['String']>;
  leadNote?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['Int']>;
  saleId?: Maybe<Scalars['Int']>;
  reservationId?: Maybe<Scalars['Int']>;
  orderSequenceNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  startsAtFrom?: Maybe<Scalars['DateTime']>;
  startsAtTo?: Maybe<Scalars['DateTime']>;
  passCode?: Maybe<Scalars['String']>;
  thirdPartyPaymentId?: Maybe<Scalars['String']>;
  hasText?: Maybe<Scalars['String']>;
};

export type CartsSupportUserFilterInput = {
  sellingChannel?: Maybe<SellingChannel>;
  cartStates?: Maybe<Array<Maybe<CartState>>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
  leadName?: Maybe<Scalars['String']>;
  leadEmail?: Maybe<Scalars['String']>;
  leadPhone?: Maybe<Scalars['String']>;
  leadNote?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['Int']>;
  saleId?: Maybe<Scalars['Int']>;
  reservationId?: Maybe<Scalars['Int']>;
  orderSequenceNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  startsAtFrom?: Maybe<Scalars['DateTime']>;
  startsAtTo?: Maybe<Scalars['DateTime']>;
  passCode?: Maybe<Scalars['String']>;
  thirdPartyPaymentId?: Maybe<Scalars['String']>;
  hasText?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['PositiveInt']>;
};

export type PaginatedCartsSuccessResult = {
  __typename?: 'PaginatedCartsSuccessResult';
  items: Array<NarrowCart>;
  pagination: Pagination;
};

export type PaginatedTourItems = {
  __typename?: 'PaginatedTourItems';
  items: Array<TourItem>;
  pagination: Pagination;
};

export type TourItemsFilter = {
  showIds?: Maybe<Array<Scalars['PositiveInt']>>;
  tourIds?: Maybe<Array<Scalars['PositiveInt']>>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  tourTimeSlotIds?: Maybe<Array<Scalars['PositiveInt']>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createAtTo?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['PositiveInt']>;
  cartId?: Maybe<Scalars['PositiveInt']>;
  saleId?: Maybe<Scalars['PositiveInt']>;
  reservationId?: Maybe<Scalars['PositiveInt']>;
  claimId?: Maybe<Scalars['PositiveInt']>;
};

export type ItemFilter = {
  eventIds?: Maybe<Array<Scalars['PositiveInt']>>;
  tourIds?: Maybe<Array<Scalars['PositiveInt']>>;
  tourTimeSlotIds?: Maybe<Array<Scalars['PositiveInt']>>;
  venueIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type EventSeatsChange = {
  __typename?: 'EventSeatsChange';
  eventSeats: Scalars['JSON'];
};

export type Subscription = {
  __typename?: 'Subscription';
  eventSeatsChange?: Maybe<EventSeatsChange>;
};


export type SubscriptionEventSeatsChangeArgs = {
  eventId: Scalars['Int'];
  myCartId?: Maybe<Scalars['Int']>;
  myReservationIds?: Maybe<Array<Scalars['Int']>>;
  shouldCleanSelectedSeatsOnUnsubscribe?: Maybe<Scalars['Boolean']>;
};

export type AppliedDiscount = {
  __typename?: 'AppliedDiscount';
  discount: Discount;
  discountCode?: Maybe<DiscountCode>;
  discountedAmount?: Maybe<Scalars['NonNegativeFloat']>;
};

export enum TransactionType {
  Charge = 'charge',
  Refund = 'refund',
  Payout = 'payout',
  Deposit = 'deposit',
  Withdrawal = 'withdrawal'
}

export enum IntentState {
  Pending = 'pending',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Succeeded = 'succeeded'
}

export enum PaymentMethodType {
  Cash = 'cash',
  Card = 'card',
  WireTransfer = 'wireTransfer',
  Voucher = 'voucher',
  PaymentGateway = 'paymentGateway'
}

export enum RetailPaymentMethodType {
  Cash = 'cash',
  Card = 'card',
  WireTransfer = 'wireTransfer',
  Voucher = 'voucher'
}

export enum EcommercePaymentMethodType {
  PaymentGateway = 'paymentGateway',
  Voucher = 'voucher'
}

export enum PaymentMethodState {
  Active = 'active',
  Deleted = 'deleted'
}

export enum PaymentMethodGroupState {
  Active = 'active',
  Deleted = 'deleted'
}

export enum PaymentServiceProvider {
  Gopay = 'gopay',
  Besteron = 'besteron',
  StatnaPokladnica = 'statnaPokladnica',
  InHouseVoucher = 'inHouseVoucher'
}

export type StatnaPokladnicaConfigInput = {
  merchantId: Scalars['NonEmptyString'];
  privateKey: Scalars['PrivateRSAKey'];
  environment: PaymentGatewayEnvironment;
};

export enum BesteronPaymentInstrument {
  Agbaczpp = 'AGBACZPP',
  Bacxczpp = 'BACXCZPP',
  Brexczpp = 'BREXCZPP',
  Fiobczpp = 'FIOBCZPP',
  Gibaczpx = 'GIBACZPX',
  Kombczpp = 'KOMBCZPP',
  Rzbcczpp = 'RZBCCZPP',
  Gibaskbx = 'GIBASKBX',
  Pobnskba = 'POBNSKBA',
  Subaskbx = 'SUBASKBX',
  Tatrskbx = 'TATRSKBX',
  Uncrskbx = 'UNCRSKBX',
  Viamo = 'VIAMO',
  Card = 'CARD',
  Applepay = 'APPLEPAY',
  Googlepay = 'GOOGLEPAY'
}

export type BesteronConfigInput = {
  besteronId: Scalars['NonEmptyString'];
  paymentKey: Scalars['NonEmptyString'];
  apiKey: Scalars['NonEmptyString'];
  onBehalfOf?: Maybe<Scalars['PositiveInt']>;
  environment: PaymentGatewayEnvironment;
  paymentInstruments: Array<BesteronPaymentInstrument>;
};

export type PaymentServiceProviderConfigInput = {
  type: PaymentServiceProvider;
  goPayConfigInput?: Maybe<GoPayConfigInput>;
  besteronConfigInput?: Maybe<BesteronConfigInput>;
  statnaPokladnicaConfigInput?: Maybe<StatnaPokladnicaConfigInput>;
};

export type PaymentMethodFilter = {
  channel?: Maybe<SellingChannel>;
  state?: Maybe<PaymentMethodState>;
};

export type RetailPaymentMethodInput = {
  name: Scalars['String'];
  value: Scalars['NonNegativeFloat'];
  description?: Maybe<TranslatedInput>;
  currency: Currency;
  type: RetailPaymentMethodType;
  groupId?: Maybe<Scalars['Int']>;
  reducesBillTotal: Scalars['Boolean'];
  hasDenomination: Scalars['Boolean'];
  possibleCashdeskDisbursement: Scalars['Boolean'];
  isAvailableForRefunds: Scalars['Boolean'];
};

export type EcommercePaymentMethodInput = {
  name: Scalars['String'];
  value: Scalars['NonNegativeFloat'];
  description?: Maybe<TranslatedInput>;
  currency: Currency;
  type: EcommercePaymentMethodType;
  groupId?: Maybe<Scalars['Int']>;
  reducesBillTotal: Scalars['Boolean'];
  hasDenomination: Scalars['Boolean'];
  possibleCashdeskDisbursement: Scalars['Boolean'];
  isAvailableForRefunds: Scalars['Boolean'];
};

export type PaymentsFilterInput = {
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  channels?: Maybe<Array<SellingChannel>>;
  transactionTypes?: Maybe<Array<TransactionType>>;
  createdByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  clientId?: Maybe<Scalars['NonNegativeInt']>;
  id?: Maybe<Scalars['NonNegativeInt']>;
};

/**
 * Values of this enum must be capitalized and words must be separated by underscore
 * For more info see:
 * https://doc.gopay.com/#payment-instrument
 */
export enum GoPayPaymentInstrument {
  PaymentCard = 'PAYMENT_CARD',
  BankAccount = 'BANK_ACCOUNT',
  Gpay = 'GPAY',
  ApplePay = 'APPLE_PAY',
  Gopay = 'GOPAY',
  Paypal = 'PAYPAL',
  Mpayment = 'MPAYMENT',
  Prsms = 'PRSMS',
  Paysafecard = 'PAYSAFECARD',
  Bitcoin = 'BITCOIN',
  ClickToPay = 'CLICK_TO_PAY'
}

export enum PaymentGatewayEnvironment {
  Sandbox = 'sandbox',
  Production = 'production'
}

export type GoPayConfigInput = {
  goId: Scalars['BigInt'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  defaultPaymentInstrument?: Maybe<GoPayPaymentInstrument>;
  environment: PaymentGatewayEnvironment;
};

export type GoPayPaymentServiceProviderConfig = {
  __typename?: 'GoPayPaymentServiceProviderConfig';
  paymentServiceProvider: PaymentServiceProvider;
  environment: PaymentGatewayEnvironment;
  defaultPaymentInstrument?: Maybe<GoPayPaymentInstrument>;
};

export type StatnaPokladnicaPaymentServiceProviderConfig = {
  __typename?: 'StatnaPokladnicaPaymentServiceProviderConfig';
  paymentServiceProvider: PaymentServiceProvider;
  environment: PaymentGatewayEnvironment;
};

export type BesteronPaymentServiceProviderConfig = {
  __typename?: 'BesteronPaymentServiceProviderConfig';
  paymentServiceProvider: PaymentServiceProvider;
  environment: PaymentGatewayEnvironment;
  paymentInstruments: Array<BesteronPaymentInstrument>;
};

export type PaymentServiceProviderConfig = GoPayPaymentServiceProviderConfig | StatnaPokladnicaPaymentServiceProviderConfig | BesteronPaymentServiceProviderConfig;

export type PaymentMethodGroup = {
  __typename?: 'PaymentMethodGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['NonNegativeFloat'];
  description: Translated;
  currency: Currency;
  type: PaymentMethodType;
  state: PaymentMethodState;
  channel: SellingChannel;
  group?: Maybe<PaymentMethodGroup>;
  reducesBillTotal: Scalars['Boolean'];
  hasDenomination: Scalars['Boolean'];
  possibleCashdeskDisbursement: Scalars['Boolean'];
  isAvailableForRefunds: Scalars['Boolean'];
  serviceProvider?: Maybe<PaymentServiceProvider>;
  paymentServiceProviderConfig?: Maybe<PaymentServiceProviderConfig>;
  createdAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByName: Scalars['String'];
};

export type UpdatePaymentMethodInput = {
  groupId?: Maybe<Scalars['Int']>;
  isAvailableForRefunds: Scalars['Boolean'];
  description?: Maybe<TranslatedInput>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['Int'];
  cartId?: Maybe<Scalars['Int']>;
  cart?: Maybe<Cart>;
  claimId?: Maybe<Scalars['Int']>;
  claim?: Maybe<Claim>;
  warehouseDocument?: Maybe<WarehouseDocument>;
  amount: Scalars['NonNegativeFloat'];
  note?: Maybe<Scalars['String']>;
  currency: Currency;
  transactionType: TransactionType;
  channel: SellingChannel;
  createdAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  surplus?: Maybe<Scalars['Float']>;
  roundingVariance: Scalars['Float'];
  paymentIntents: Array<PaymentIntent>;
  refundIntents: Array<RefundIntent>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  id: Scalars['Int'];
  state: IntentState;
  paymentMethod: PaymentMethod;
  paymentMethodId: Scalars['PositiveInt'];
  payment?: Maybe<Payment>;
  cart: Cart;
  request?: Maybe<Scalars['JSONObject']>;
  response?: Maybe<Scalars['JSONObject']>;
  amount: Scalars['NonNegativeFloat'];
  retryAttempts?: Maybe<Scalars['PositiveInt']>;
  count?: Maybe<Scalars['PositiveInt']>;
  denomination?: Maybe<Scalars['NonNegativeFloat']>;
  thirdPartyPaymentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['String'];
  checkoutAttempt: Scalars['PositiveInt'];
  reasonForStateChange?: Maybe<Scalars['JSONObject']>;
};

export type RefundIntent = {
  __typename?: 'RefundIntent';
  id: Scalars['Int'];
  state: IntentState;
  paymentMethod: PaymentMethod;
  payment?: Maybe<Payment>;
  request?: Maybe<Scalars['JSONObject']>;
  response?: Maybe<Scalars['JSONObject']>;
  amount: Scalars['NonNegativeFloat'];
  retryAttempts?: Maybe<Scalars['PositiveInt']>;
  count?: Maybe<Scalars['PositiveInt']>;
  denomination?: Maybe<Scalars['NonNegativeFloat']>;
  thirdPartyPaymentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['String'];
  refundAttempt: Scalars['PositiveInt'];
  reasonForStateChange?: Maybe<Scalars['JSONObject']>;
  claim?: Maybe<Claim>;
};

export type PaymentMethodTypeAmount = {
  __typename?: 'PaymentMethodTypeAmount';
  cash?: Maybe<Scalars['Float']>;
  card?: Maybe<Scalars['Float']>;
  wireTransfer?: Maybe<Scalars['Float']>;
  voucher?: Maybe<Scalars['Float']>;
  paymentGateway?: Maybe<Scalars['Float']>;
};

export type PaymentItem = {
  __typename?: 'PaymentItem';
  createdAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  id: Scalars['NonNegativeInt'];
  channel: SellingChannel;
  amount: Scalars['Float'];
  surplus?: Maybe<Scalars['Float']>;
  paymentMethodTypeAmounts: PaymentMethodTypeAmount;
  note?: Maybe<Scalars['String']>;
  currency: Currency;
  clientId: Scalars['NonNegativeInt'];
  transactionNumSequence?: Maybe<Scalars['String']>;
  transactionType: TransactionType;
  cartId?: Maybe<Scalars['NonNegativeInt']>;
  claimId?: Maybe<Scalars['NonNegativeInt']>;
  paymentMethods: Array<PaymentMethod>;
};

export type PaymentsSummary = {
  __typename?: 'PaymentsSummary';
  amount: Scalars['Float'];
  surplus?: Maybe<Scalars['Float']>;
  paymentMethodTypeAmounts: PaymentMethodTypeAmount;
};

export type PaymentsResult = {
  __typename?: 'PaymentsResult';
  items: Array<PaymentItem>;
  pagination: Pagination;
  summary: PaymentsSummary;
};


export type PaymentsResultSummaryArgs = {
  filter?: Maybe<PaymentsFilterInput>;
};

export type PaymentInput = {
  count?: Maybe<Scalars['PositiveInt']>;
  amount: Scalars['NonNegativeFloat'];
  denomination?: Maybe<Scalars['NonNegativeFloat']>;
  paymentMethodId: Scalars['PositiveInt'];
};

export enum PaymentReportType {
  DailyCashierReport = 'dailyCashierReport',
  MonthlyECommerceReport = 'monthlyECommerceReport',
  MonthlyReport = 'monthlyReport'
}

export enum PaymentReportState {
  Draft = 'draft',
  Approved = 'approved',
  Closed = 'closed'
}

export type PaymentReportDataAggregatedByVatRate = {
  __typename?: 'PaymentReportDataAggregatedByVatRate';
  vatRate: Scalars['NonNegativeFloat'];
  revenueVat: Scalars['Float'];
  revenueVatIncluded: Scalars['Float'];
  revenueVatExcluded: Scalars['Float'];
  revenueItemQuantity: Scalars['Int'];
};

export type PaymentReportDataAggregatedByPaymentMethod = {
  __typename?: 'PaymentReportDataAggregatedByPaymentMethod';
  paymentMethodId: Scalars['PositiveInt'];
  paymentMethodName: Scalars['NonEmptyString'];
  intentsAmount: Scalars['Float'];
  intentsCount: Scalars['NonNegativeInt'];
};

export type PaymentReportDataAggregatedByTransactionType = {
  __typename?: 'PaymentReportDataAggregatedByTransactionType';
  transactionType: TransactionType;
  paymentsAmount: Scalars['Float'];
  paymentsCount: Scalars['NonNegativeInt'];
};

export type PaymentMethodDataAggregatedByTransactionType = {
  __typename?: 'PaymentMethodDataAggregatedByTransactionType';
  transactionType: TransactionType;
  intentsAmount: Scalars['Float'];
  intentsCount: Scalars['NonNegativeInt'];
};

export type PaymentReportDataAggregatedByPaymentMethodAndTransactionType = {
  __typename?: 'PaymentReportDataAggregatedByPaymentMethodAndTransactionType';
  paymentMethodId: Scalars['PositiveInt'];
  paymentMethodName: Scalars['NonEmptyString'];
  transactionTypes: Array<PaymentMethodDataAggregatedByTransactionType>;
  intentsAmount: Scalars['Float'];
  intentsCount: Scalars['NonNegativeInt'];
};

export type PaymentReportDataAggregatedByDivisionItem = {
  __typename?: 'PaymentReportDataAggregatedByDivisionItem';
  divisionId: Scalars['PositiveInt'];
  divisionName: Scalars['NonEmptyString'];
  saleSum: Scalars['NonNegativeFloat'];
  refundSum: Scalars['NonPositiveFloat'];
  saleItemQuantity: Scalars['NonNegativeInt'];
  refundItemQuantity: Scalars['NonPositiveInt'];
  revenueSum: Scalars['Float'];
  revenueItemQuantity: Scalars['Int'];
};

export type PaymentReportDataAggregatedByDivision = {
  __typename?: 'PaymentReportDataAggregatedByDivision';
  items: Array<PaymentReportDataAggregatedByDivisionItem>;
  totalSaleSum: Scalars['NonNegativeFloat'];
  totalRefundSum: Scalars['NonPositiveFloat'];
  totalSaleItemQuantity: Scalars['NonNegativeInt'];
  totalRefundItemQuantity: Scalars['NonPositiveInt'];
  totalRevenueSum: Scalars['Float'];
  totalRevenueItemQuantity: Scalars['Int'];
};

export type PaymentReport = {
  __typename?: 'PaymentReport';
  id: Scalars['PositiveInt'];
  type: PaymentReportType;
  state: PaymentReportState;
  clientId: Scalars['PositiveInt'];
  client: Client;
  cashierUserId?: Maybe<Scalars['PositiveInt']>;
  cashier?: Maybe<User>;
  note?: Maybe<Scalars['NonEmptyString']>;
  paymentsAmount: Scalars['Float'];
  paymentsSurplus: Scalars['Float'];
  paymentsCount: Scalars['NonNegativeInt'];
  revenueVatIncluded: Scalars['Float'];
  revenueVatExcluded: Scalars['Float'];
  revenueVat: Scalars['Float'];
  dataAggregatedByVatRate: Array<PaymentReportDataAggregatedByVatRate>;
  dataAggregatedByPaymentMethod: Array<PaymentReportDataAggregatedByPaymentMethod>;
  dataAggregatedByTransactionType: Array<PaymentReportDataAggregatedByTransactionType>;
  dataAggregatedByPaymentMethodAndTransactionType: Array<PaymentReportDataAggregatedByPaymentMethodAndTransactionType>;
  dataAggregatedByDivision?: Maybe<PaymentReportDataAggregatedByDivision>;
  reportFrom: Scalars['DateTime'];
  reportTo: Scalars['DateTime'];
  createdBy: User;
  createdAt: Scalars['DateTime'];
  updatedBy: User;
  updatedAt: Scalars['DateTime'];
  payments: Array<Payment>;
  externalFileUrl?: Maybe<Scalars['Url']>;
};

export type CreateDailyCashierPaymentReportInput = {
  note?: Maybe<Scalars['NonEmptyString']>;
  cashierUserId: Scalars['PositiveInt'];
  reportDate: Scalars['Date'];
};

export type PaymentReportsFilterInput = {
  id?: Maybe<Scalars['PositiveInt']>;
  type?: Maybe<PaymentReportType>;
  hasText?: Maybe<Scalars['String']>;
  /** Query result is filtered by cashierUserIds only in case, when type is set to "dailyCashierReport". Otherwise, this filter field is ignored. */
  cashierUserIds?: Maybe<Array<Scalars['PositiveInt']>>;
  reportFrom?: Maybe<Scalars['DateTime']>;
  reportTo?: Maybe<Scalars['DateTime']>;
  states?: Maybe<Array<PaymentReportState>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['PositiveInt']>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
};

export type PaginatedPaymentReportsSuccessResult = {
  __typename?: 'PaginatedPaymentReportsSuccessResult';
  items: Array<PaymentReport>;
  pagination: Pagination;
};

export type AvailableInputDataForDailyCashierReports = {
  __typename?: 'AvailableInputDataForDailyCashierReports';
  user: User;
  paymentDatesWithoutReport: Array<Scalars['Date']>;
};

export enum Month {
  January = 'january',
  February = 'february',
  March = 'march',
  April = 'april',
  May = 'may',
  June = 'june',
  July = 'july',
  August = 'august',
  September = 'september',
  October = 'october',
  November = 'november',
  December = 'december'
}

export type CreateMonthlyECommercePaymentReportInput = {
  note?: Maybe<Scalars['NonEmptyString']>;
  year: Scalars['PositiveInt'];
  month: Month;
};

export type PaymentPeriodWithoutMonthlyReport = {
  __typename?: 'PaymentPeriodWithoutMonthlyReport';
  year: Scalars['PositiveInt'];
  month: Month;
};

export type CreateMonthlyPaymentReportInput = {
  note?: Maybe<Scalars['NonEmptyString']>;
  year: Scalars['PositiveInt'];
  month: Month;
};

export type PaymentReportViewFilterInput = {
  reportDateTimeFrom: Scalars['DateTime'];
  reportDateTimeTo: Scalars['DateTime'];
  cashierUserIds?: Maybe<Array<Scalars['PositiveInt']>>;
  channel?: Maybe<SellingChannel>;
};

export type PaymentReportView = {
  __typename?: 'PaymentReportView';
  id: Scalars['ID'];
  client: Client;
  paymentsAmount: Scalars['Float'];
  paymentsSurplus: Scalars['Float'];
  paymentsCount: Scalars['NonNegativeInt'];
  revenueVatIncluded: Scalars['Float'];
  revenueVatExcluded: Scalars['Float'];
  revenueVat: Scalars['Float'];
  dataAggregatedByVatRate: Array<PaymentReportDataAggregatedByVatRate>;
  dataAggregatedByPaymentMethod: Array<PaymentReportDataAggregatedByPaymentMethod>;
  dataAggregatedByTransactionType: Array<PaymentReportDataAggregatedByTransactionType>;
  dataAggregatedByPaymentMethodAndTransactionType: Array<PaymentReportDataAggregatedByPaymentMethodAndTransactionType>;
  dataAggregatedByDivision?: Maybe<PaymentReportDataAggregatedByDivision>;
  reportFrom: Scalars['DateTime'];
  reportTo: Scalars['DateTime'];
  payments: Array<Payment>;
  createdBy: User;
  createdAt: Scalars['DateTime'];
};

export enum ReservationState {
  Active = 'active',
  Expired = 'expired',
  InCart = 'inCart',
  Canceled = 'canceled',
  Fulfilled = 'fulfilled'
}

export enum SaleState {
  Active = 'active'
}

export type Sale = {
  __typename?: 'Sale';
  id: Scalars['Int'];
  cart: Cart;
  event?: Maybe<Event>;
  division: Division;
  itemType: ItemType;
  price: Scalars['Float'];
  state?: Maybe<SaleState>;
  client: Client;
  lead?: Maybe<Lead>;
  venue?: Maybe<Venue>;
  tourTimeSlot?: Maybe<TourTimeSlot>;
  sellingChannel: SellingChannel;
  note?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Item>>;
  uuid: Scalars['String'];
  hash: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  createdByName: Scalars['String'];
  updatedByName: Scalars['String'];
};

export type Reservation = {
  __typename?: 'Reservation';
  id: Scalars['Int'];
  cart: Cart;
  event?: Maybe<Event>;
  division: Division;
  itemType: ItemType;
  tourTimeSlot?: Maybe<TourTimeSlot>;
  price: Scalars['Float'];
  lead?: Maybe<Lead>;
  state: ReservationState;
  client: Client;
  venue?: Maybe<Venue>;
  orderSequenceNumber: Scalars['String'];
  /** Reservation has a related sale when all ticket items from reservation have been paid for */
  relatedSale?: Maybe<Sale>;
  sellingChannel: SellingChannel;
  itemsCount: Scalars['NonNegativeInt'];
  note?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Item>>;
  uuid: Scalars['String'];
  hash: Scalars['String'];
  expireAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  createdByName: Scalars['String'];
  updatedByName: Scalars['String'];
};

export type AddZoneTicketItemToReservationSuccessResult = {
  __typename?: 'AddZoneTicketItemToReservationSuccessResult';
  reservation: Reservation;
  newlyAddedZoneTicketItemsCount: Scalars['Int'];
};

export type AddZoneTicketItemToReservationResult = AddZoneTicketItemToReservationSuccessResult | ErrorResult;

export type PaginatedReservationsSuccessResult = {
  __typename?: 'PaginatedReservationsSuccessResult';
  items: Array<Reservation>;
  pagination: Pagination;
};

export type PaginatedSalesSuccessResult = {
  __typename?: 'PaginatedSalesSuccessResult';
  items: Array<Sale>;
  pagination: Pagination;
};

export type CustomEmailTemplateDataInput = {
  customSubject?: Maybe<Scalars['String']>;
  customPreHeader?: Maybe<Scalars['String']>;
  customText?: Maybe<Scalars['String']>;
};

export type SaleInput = {
  id: Scalars['Int'];
  itemIds?: Maybe<Array<Scalars['Int']>>;
};

export type ReservationsFilterInput = {
  eventId?: Maybe<Scalars['PositiveInt']>;
  tourTimeSlotId?: Maybe<Scalars['PositiveInt']>;
  hasText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['PositiveInt']>;
  startsAtFrom?: Maybe<Scalars['DateTime']>;
  startsAtTo?: Maybe<Scalars['DateTime']>;
  expireFrom?: Maybe<Scalars['DateTime']>;
  expireTo?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  leadName?: Maybe<Scalars['String']>;
  leadEmail?: Maybe<Scalars['String']>;
  leadPhone?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  venueId?: Maybe<Scalars['Int']>;
  divisionId?: Maybe<Scalars['Int']>;
  channels?: Maybe<Array<SellingChannel>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
};

export type SaleFilterInput = {
  eventId?: Maybe<Scalars['PositiveInt']>;
  tourTimeSlotId?: Maybe<Scalars['PositiveInt']>;
  hasText?: Maybe<Scalars['NonEmptyString']>;
  id?: Maybe<Scalars['PositiveInt']>;
  startsAtFrom?: Maybe<Scalars['DateTime']>;
  startsAtTo?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['NonEmptyString']>;
  leadName?: Maybe<Scalars['NonEmptyString']>;
  leadEmail?: Maybe<Scalars['NonEmptyString']>;
  leadPhone?: Maybe<Scalars['NonEmptyString']>;
  note?: Maybe<Scalars['NonEmptyString']>;
  venueId?: Maybe<Scalars['PositiveInt']>;
  divisionId?: Maybe<Scalars['PositiveInt']>;
  channels?: Maybe<Array<SellingChannel>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
};

export enum LeadType {
  Cart = 'cart',
  Order = 'order',
  Claim = 'claim',
  Customer = 'customer'
}

export type LeadData = {
  __typename?: 'LeadData';
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyIdNumber?: Maybe<Scalars['String']>;
  VATId?: Maybe<Scalars['String']>;
  TAXId?: Maybe<Scalars['String']>;
  billingAddressStreet?: Maybe<Scalars['String']>;
  billingAddressTown?: Maybe<Scalars['String']>;
  billingPostalCode?: Maybe<Scalars['String']>;
  billingAddressCountry?: Maybe<ExistingCountryCode>;
  deliveryAddressee?: Maybe<Scalars['String']>;
  deliveryAddressStreet?: Maybe<Scalars['String']>;
  deliveryAddressTown?: Maybe<Scalars['String']>;
  deliveryPostalCode?: Maybe<Scalars['String']>;
  deliveryAddressCountry?: Maybe<ExistingCountryCode>;
  isPrivacyPolicyConsentGranted?: Maybe<Scalars['Boolean']>;
};

export type LeadDataInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  phone?: Maybe<Scalars['NonEmptyString']>;
  email?: Maybe<Scalars['NonEmptyString']>;
  note?: Maybe<Scalars['NonEmptyString']>;
  internalNote?: Maybe<Scalars['NonEmptyString']>;
  companyName?: Maybe<Scalars['NonEmptyString']>;
  companyIdNumber?: Maybe<Scalars['NonEmptyString']>;
  VATId?: Maybe<Scalars['NonEmptyString']>;
  TAXId?: Maybe<Scalars['NonEmptyString']>;
  billingAddressStreet?: Maybe<Scalars['NonEmptyString']>;
  billingAddressTown?: Maybe<Scalars['NonEmptyString']>;
  billingPostalCode?: Maybe<Scalars['NonEmptyString']>;
  billingAddressCountry?: Maybe<ExistingCountryCode>;
  deliveryAddressee?: Maybe<Scalars['NonEmptyString']>;
  deliveryAddressStreet?: Maybe<Scalars['NonEmptyString']>;
  deliveryAddressTown?: Maybe<Scalars['NonEmptyString']>;
  deliveryPostalCode?: Maybe<Scalars['NonEmptyString']>;
  deliveryAddressCountry?: Maybe<ExistingCountryCode>;
  isPrivacyPolicyConsentGranted?: Maybe<Scalars['Boolean']>;
};

export type Lead = {
  __typename?: 'Lead';
  id: Scalars['Int'];
  type: LeadType;
  data: LeadData;
};

export enum LeadEventOrigin {
  /** Filters out leads, that belongs to an event's sale */
  Sale = 'sale',
  /** Filters out leads, that belongs to an event's reservation */
  Reservation = 'reservation'
}

export enum LeadTourTimeSlotOrigin {
  /** Filters out leads, that belongs to an tour time slot's sale */
  Sale = 'sale',
  /** Filters out leads, that belongs to an tour time slot's reservation */
  Reservation = 'reservation'
}

export type LeadsEventFilterInput = {
  eventIds: Array<Scalars['PositiveInt']>;
  sellingChannel?: Maybe<SellingChannel>;
  leadEventOrigin?: Maybe<LeadEventOrigin>;
};

export type LeadsTourTimeSlotFilterInput = {
  tourTimeSlotIds: Array<Scalars['PositiveInt']>;
  sellingChannel?: Maybe<SellingChannel>;
  tourTimeSlotOrigin?: Maybe<LeadTourTimeSlotOrigin>;
};

/** Exactly one of fields cartId, claimId, saleId, reservationId, eventFilter in LeadsFilterInput must be defined, otherwise InvalidLeadsFilterInput error is thrown */
export type LeadsFilterInput = {
  cartId?: Maybe<Scalars['PositiveInt']>;
  claimId?: Maybe<Scalars['PositiveInt']>;
  saleId?: Maybe<Scalars['PositiveInt']>;
  reservationId?: Maybe<Scalars['PositiveInt']>;
  eventFilter?: Maybe<LeadsEventFilterInput>;
  tourTimeSlotFilter?: Maybe<LeadsTourTimeSlotFilterInput>;
  privacyPolicyConsentGranted?: Maybe<Scalars['Boolean']>;
};

export enum MessageType {
  Email = 'email',
  Sms = 'sms'
}

export enum MessageState {
  Sent = 'sent',
  Draft = 'draft',
  Failed = 'failed',
  Scheduled = 'scheduled',
  Pending = 'pending'
}

export type Message = {
  __typename?: 'Message';
  id: Scalars['PositiveInt'];
  type: MessageType;
  state: MessageState;
  body: Scalars['String'];
  internalNote: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  /**
   * In message with type email, it has been used as sender and replyTo name
   * In message with type sms, it has been used as sender name
   */
  senderName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: User;
  updatedBy: User;
  sendAt?: Maybe<Scalars['DateTime']>;
  /**
   * In message with type email, it has been used as replyTo.email
   * In message with type sms, it has been used as reply to phone number
   */
  replyTo?: Maybe<Scalars['String']>;
  /** Leads are message recipients */
  leads: Array<Lead>;
};

export type SendMessageInput = {
  leadIds: Array<Scalars['PositiveInt']>;
  type: MessageType;
  body: Scalars['String'];
  internalNote: Scalars['String'];
  /** In message with type email, this field is required. */
  subject?: Maybe<Scalars['String']>;
  /**
   * Fill senderName with a name.
   * In message with type email, it will be used as sender and replyTo name
   * In message with type sms, it will be used as sender name
   */
  senderName: Scalars['String'];
  /**
   * Fill replyTo with valid email/phone number.
   * In message with type email, it will be used as replyTo.email
   * In message with type sms, it will be used as reply to phone number
   */
  replyTo?: Maybe<Scalars['String']>;
};

export type MessagesFilterInput = {
  hasText?: Maybe<Scalars['String']>;
};

export type PaginatedMessagesSuccessResult = {
  __typename?: 'PaginatedMessagesSuccessResult';
  items: Array<Message>;
  pagination: Pagination;
};

export enum ClaimState {
  Draft = 'draft',
  Approved = 'approved',
  Deleted = 'deleted',
  Pending = 'pending',
  Refunded = 'refunded',
  Failed = 'failed'
}

export type Claim = {
  __typename?: 'Claim';
  id: Scalars['Int'];
  reason: Scalars['String'];
  itemType: ItemType;
  state: ClaimState;
  price: Scalars['NonNegativeFloat'];
  items?: Maybe<Array<Item>>;
  itemsCount: Scalars['NonNegativeInt'];
  createdAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByName: Scalars['String'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedByName?: Maybe<Scalars['String']>;
  refundedAt?: Maybe<Scalars['DateTime']>;
  refundedByName?: Maybe<Scalars['String']>;
  division: Division;
  sale: Sale;
  event?: Maybe<Event>;
  tourTimeSlotId?: Maybe<Scalars['PositiveInt']>;
  tourTimeSlot?: Maybe<TourTimeSlot>;
  lead?: Maybe<Lead>;
  payment?: Maybe<Payment>;
  refundIntents: Array<RefundIntent>;
  thirdPartyRefundNotifyUrl?: Maybe<Scalars['String']>;
};

export type ClaimsFilterInput = {
  hasText?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['Int']>;
  saleId?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<ClaimState>>>;
  /** Fields createdAtFrom and createdAtTo, should be mapped to draftedAt input on FE */
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
  /** Field createdByName, should be mapped to draftedByName input on FE */
  createdByName?: Maybe<Scalars['String']>;
  approvedByName?: Maybe<Scalars['String']>;
  refundedByName?: Maybe<Scalars['String']>;
  leadName?: Maybe<Scalars['String']>;
  leadEmail?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  eventStartsAtFrom?: Maybe<Scalars['DateTime']>;
  eventStartsAtTo?: Maybe<Scalars['DateTime']>;
  passCode?: Maybe<Scalars['String']>;
};

export type RefundIntentInput = {
  paymentMethodId: Scalars['Int'];
  denomination?: Maybe<Scalars['NonNegativeFloat']>;
  amount: Scalars['NonNegativeFloat'];
  count?: Maybe<Scalars['PositiveInt']>;
  /** isManagedByThirdPartyLocalDevice must be provided when refund is managed by local device */
  isManagedByThirdPartyLocalDevice?: Maybe<Scalars['Boolean']>;
};

export type VoucherInput = {
  campaignId: Scalars['PositiveInt'];
  /** If activationDate was not provided, voucher will be activated immediately */
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type RefundClaimByInHouseVoucherResponse = {
  __typename?: 'RefundClaimByInHouseVoucherResponse';
  claim: Claim;
  voucher: Voucher;
};

export type PaginatedClaims = {
  __typename?: 'PaginatedClaims';
  items: Array<Claim>;
  pagination: Pagination;
};

export enum PassCodeCheckDirection {
  In = 'in',
  Out = 'out'
}

export enum PassCodeCheckInputType {
  Scanned = 'scanned',
  Typed = 'typed'
}

export enum DiscountAuthorizationMode {
  DoNotAuthorize = 'doNotAuthorize',
  RetailOnly = 'retailOnly',
  ECommerceOnly = 'eCommerceOnly',
  AuthorizeAll = 'authorizeAll'
}

export enum PassCodeCheckState {
  Allowed = 'allowed',
  Pending = 'pending',
  Denied = 'denied'
}

export enum PassCodeCheckAdditionalInformation {
  GateIsNotOpen = 'gateIsNotOpen',
  TicketClaimed = 'ticketClaimed',
  AlreadyCheckedIn = 'alreadyCheckedIn',
  AlreadyCheckedOut = 'alreadyCheckedOut',
  CheckedOut = 'checkedOut',
  CheckedIn = 'checkedIn',
  DiscountAuthorizationPending = 'discountAuthorizationPending',
  UnauthorizedDiscount = 'unauthorizedDiscount',
  AuthorizedDiscount = 'authorizedDiscount',
  NotFoundDueToFilterVenueIds = 'notFoundDueToFilterVenueIds',
  NotFoundDueToFilterTourIds = 'notFoundDueToFilterTourIds',
  NotFoundDueToFilterTourTimeSlotIds = 'notFoundDueToFilterTourTimeSlotIds',
  NotFoundDueToFilterEventIds = 'notFoundDueToFilterEventIds',
  NotFoundDueToFilterArguments = 'notFoundDueToFilterArguments',
  EventNotPublished = 'eventNotPublished',
  TourTimeSlotNotPublished = 'tourTimeSlotNotPublished'
}

export type PassCodeCheck = {
  __typename?: 'PassCodeCheck';
  id: Scalars['PositiveInt'];
  itemId: Scalars['PositiveInt'];
  passCode: Scalars['String'];
  direction: PassCodeCheckDirection;
  inputType: PassCodeCheckInputType;
  discountAuthorizationMode: DiscountAuthorizationMode;
  state: PassCodeCheckState;
  additionalInformation: PassCodeCheckAdditionalInformation;
  ticketItem?: Maybe<TicketItem>;
  /** clientId may not be defined in some historic records */
  clientId?: Maybe<Scalars['PositiveInt']>;
  /** divisionId may not be defined in some historic records */
  divisionId?: Maybe<Scalars['PositiveInt']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdById: Scalars['PositiveInt'];
  updatedById: Scalars['PositiveInt'];
  createdBy: User;
  updatedBy: User;
  eventId?: Maybe<Scalars['PositiveInt']>;
  event?: Maybe<Event>;
  tourTimeSlotId?: Maybe<Scalars['PositiveInt']>;
  tourTimeSlot?: Maybe<TourTimeSlot>;
  tourItem?: Maybe<TourItem>;
  bulkCheckUUID?: Maybe<Scalars['NonEmptyString']>;
};

export type PaginatedPassCodeChecks = {
  __typename?: 'PaginatedPassCodeChecks';
  items: Array<PassCodeCheck>;
  pagination: Pagination;
};

export type PassCodeChecksFilter = {
  passCode?: Maybe<Scalars['String']>;
  states?: Maybe<Array<PassCodeCheckState>>;
  eventId?: Maybe<Scalars['PositiveInt']>;
  tourTimeSlotId?: Maybe<Scalars['PositiveInt']>;
  createdById?: Maybe<Scalars['PositiveInt']>;
  updatedById?: Maybe<Scalars['PositiveInt']>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  additionalInformation?: Maybe<Array<PassCodeCheckAdditionalInformation>>;
  direction?: Maybe<PassCodeCheckDirection>;
  inputType?: Maybe<PassCodeCheckInputType>;
  divisionId?: Maybe<Scalars['PositiveInt']>;
  itemId?: Maybe<Scalars['PositiveInt']>;
};

export enum TemplateFileType {
  Zpl = 'zpl',
  Pdf = 'pdf'
}

/** There is a database limitation, max templateType value length can be 30 chars */
export enum TemplateType {
  TicketPrinting = 'ticketPrinting',
  ClaimPrinting = 'claimPrinting',
  Receipt = 'receipt',
  DailyCashierReport = 'dailyCashierReport',
  MonthlyECommerceReport = 'monthlyECommerceReport',
  MonthlyReport = 'monthlyReport',
  IncomingWarehouseDocument = 'incomingWarehouseDocument',
  OutgoingWarehouseDocument = 'outgoingWarehouseDocument',
  InventoryCheck = 'inventoryCheck'
}

export enum TemplateState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum DataSetType {
  SalesPrinting = 'salesPrinting',
  Claim = 'claim',
  PaymentReport = 'paymentReport',
  WarehouseDocument = 'warehouseDocument',
  InventoryCheck = 'inventoryCheck'
}

export enum TemplateVersionState {
  Active = 'active',
  Inactive = 'inactive'
}

export type TemplateVersion = {
  __typename?: 'TemplateVersion';
  id: Scalars['PositiveInt'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  state: TemplateVersionState;
  templateId: Scalars['PositiveInt'];
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['PositiveInt'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  state: TemplateState;
  types: Array<TemplateType>;
  fileType: TemplateFileType;
  versionsCount: Scalars['Int'];
  clientsCount: Scalars['Int'];
  localeCode: LocaleCode;
  dataSetType: DataSetType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  updatedByName: Scalars['String'];
  activeVersion: TemplateVersion;
  /**
   * @description Valid search params string using format, height, width and landscape from [Puppeteer PdfOptions](https://pptr.dev/api/puppeteer.pdfoptions). It will be used for export of template into pdf.
   * @example "height=210mm&width=100mm"
   * @note if format is defined, width and height are ignored (puppeteer's default)
   */
  additionalSearchParams?: Maybe<Scalars['String']>;
  templateVersions: Array<TemplateVersion>;
};

export type TemplateAssignment = {
  __typename?: 'TemplateAssignment';
  id: Scalars['PositiveInt'];
  createdAt: Scalars['DateTime'];
  createdByName: Scalars['String'];
  template: Template;
};

export type TemplatesFilterInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<TemplateType>;
  fileType?: Maybe<TemplateFileType>;
  versionName?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  state?: Maybe<TemplateState>;
  clientId?: Maybe<Scalars['PositiveInt']>;
};

export type CreateTemplateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  types: Array<TemplateType>;
  fileType: TemplateFileType;
  localeCode: LocaleCode;
  dataSetType: DataSetType;
  /**
   * @description Create valid search params string using format, height, width and landscape from [Puppeteer PdfOptions](https://pptr.dev/api/puppeteer.pdfoptions) It will be used for export of template into pdf.
   * @example "height=210mm&width=100mm"
   * @note if format is defined, width and height are ignored (puppeteer's default)
   */
  additionalSearchParams?: Maybe<Scalars['String']>;
};

export type UpdateTemplateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  types: Array<TemplateType>;
  localeCode: LocaleCode;
  state: TemplateState;
  /**
   * @description Create valid search params string using format, height, width and landscape from [Puppeteer PdfOptions](https://pptr.dev/api/puppeteer.pdfoptions) It will be used for export of template into pdf.
   * @example "height=210mm&width=100mm"
   * @note if format is defined, width and height are ignored (puppeteer's default)
   */
  additionalSearchParams?: Maybe<Scalars['String']>;
};

export enum AdmissionTypeState {
  Active = 'active',
  Inactive = 'inactive',
  Draft = 'draft'
}

export enum TourState {
  Active = 'active',
  Inactive = 'inactive',
  Draft = 'draft'
}

export enum AccessZoneState {
  Active = 'active',
  Inactive = 'inactive'
}

export type AdmissionTypeInput = {
  name: Scalars['NonEmptyString'];
  abbreviation: Scalars['NonEmptyString'];
  capacityDecreaseCount: Scalars['NonNegativeInt'];
  startingQuantity: Scalars['PositiveInt'];
  color: Scalars['HexColorCode'];
  internalDescription?: Maybe<Scalars['NonEmptyString']>;
  icon?: Maybe<Scalars['NonEmptyString']>;
  eCommerceNames?: Maybe<ECommerceTranslatedInput>;
  eCommerceDescriptions?: Maybe<ECommerceTranslatedInput>;
};

export type UpdateDraftAdmissionTypeInput = {
  state: AdmissionTypeState;
  name: Scalars['NonEmptyString'];
  abbreviation: Scalars['NonEmptyString'];
  capacityDecreaseCount: Scalars['NonNegativeInt'];
  startingQuantity: Scalars['PositiveInt'];
  color: Scalars['HexColorCode'];
  internalDescription?: Maybe<Scalars['NonEmptyString']>;
  icon?: Maybe<Scalars['NonEmptyString']>;
  eCommerceNames?: Maybe<ECommerceTranslatedInput>;
  eCommerceDescriptions?: Maybe<ECommerceTranslatedInput>;
};

export type UpdateAdmissionTypeInput = {
  /** Only "active" and "inactive" values are accepted. In case of "draft", the error "INVALID_ADMISSION_TYPE_STATE" will be thrown. */
  state: AdmissionTypeState;
  name: Scalars['NonEmptyString'];
  abbreviation: Scalars['NonEmptyString'];
  color: Scalars['HexColorCode'];
  internalDescription?: Maybe<Scalars['NonEmptyString']>;
  icon?: Maybe<Scalars['NonEmptyString']>;
  eCommerceNames?: Maybe<ECommerceTranslatedInput>;
  eCommerceDescriptions?: Maybe<ECommerceTranslatedInput>;
};

export type TourInput = {
  name: Scalars['NonEmptyString'];
  internalNote?: Maybe<Scalars['NonEmptyString']>;
  showId: Scalars['PositiveInt'];
  divisionId: Scalars['PositiveInt'];
  duration: Scalars['PositiveInt'];
  retailAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceOrderAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  venueId?: Maybe<Scalars['PositiveInt']>;
  isECommerceSaleActive: Scalars['Boolean'];
  isECommerceReservationActive: Scalars['Boolean'];
  isRetailSaleActive: Scalars['Boolean'];
  isRetailReservationActive: Scalars['Boolean'];
  gateOpensType: DivisionGateOpens;
  gateOpensValue: Scalars['NonNegativeInt'];
  gateClosesType: DivisionGateCloses;
  gateClosesValue: Scalars['NonNegativeInt'];
  salesEndCashDeskValue: Scalars['NonNegativeInt'];
  salesEndCashDeskType: DivisionPosSalesEnd;
  salesEndWebValue: Scalars['NonNegativeInt'];
  salesEndWebType: DivisionOnlineSalesEnd;
  reservationEndCashDeskValue: Scalars['NonNegativeInt'];
  reservationEndCashDeskType: DivisionPosReservationEnd;
  reservationEndWebValue: Scalars['NonNegativeInt'];
  reservationEndWebType: DivisionOnlineReservationEnd;
  eCommerceCreateReservationEndValue: Scalars['NonNegativeInt'];
  eCommerceCreateReservationEndType: DivisionCreateOnlineReservationEnd;
  retailCreateReservationEndValue: Scalars['NonNegativeInt'];
  retailCreateReservationEndType: DivisionCreatePosReservationEnd;
};

export type AccessZoneInput = {
  name: Scalars['NonEmptyString'];
  description?: Maybe<Scalars['NonEmptyString']>;
};

export type UpdateAccessZoneInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  state?: Maybe<AccessZoneState>;
};

export type AccessZonesFilter = {
  hasText?: Maybe<Scalars['String']>;
  state?: Maybe<AccessZoneState>;
};

export type UpdateTourInput = {
  name: Scalars['NonEmptyString'];
  internalNote?: Maybe<Scalars['NonEmptyString']>;
  /** UpdateTourInput with state 'draft' is valid only for tours with state draft. For tours with other states, input with state 'draft' will throw an error InvalidTourState */
  state?: Maybe<TourState>;
  duration: Scalars['PositiveInt'];
  retailAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceOrderAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  venueId?: Maybe<Scalars['PositiveInt']>;
  isECommerceSaleActive?: Maybe<Scalars['Boolean']>;
  isECommerceReservationActive?: Maybe<Scalars['Boolean']>;
  isRetailSaleActive?: Maybe<Scalars['Boolean']>;
  isRetailReservationActive?: Maybe<Scalars['Boolean']>;
  gateOpensType?: Maybe<DivisionGateOpens>;
  gateOpensValue?: Maybe<Scalars['NonNegativeInt']>;
  gateClosesType?: Maybe<DivisionGateCloses>;
  gateClosesValue?: Maybe<Scalars['NonNegativeInt']>;
  salesEndCashDeskValue?: Maybe<Scalars['NonNegativeInt']>;
  salesEndCashDeskType?: Maybe<DivisionPosSalesEnd>;
  salesEndWebValue?: Maybe<Scalars['NonNegativeInt']>;
  salesEndWebType?: Maybe<DivisionOnlineSalesEnd>;
  reservationEndCashDeskValue?: Maybe<Scalars['NonNegativeInt']>;
  reservationEndCashDeskType?: Maybe<DivisionPosReservationEnd>;
  reservationEndWebValue?: Maybe<Scalars['NonNegativeInt']>;
  reservationEndWebType?: Maybe<DivisionOnlineReservationEnd>;
  eCommerceCreateReservationEndValue?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceCreateReservationEndType?: Maybe<DivisionCreateOnlineReservationEnd>;
  retailCreateReservationEndValue?: Maybe<Scalars['NonNegativeInt']>;
  retailCreateReservationEndType?: Maybe<DivisionCreatePosReservationEnd>;
};

export type AdmissionTypesFilter = {
  hasText?: Maybe<Scalars['String']>;
  states?: Maybe<Array<AdmissionTypeState>>;
  admissionTypeIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type ToursFilter = {
  hasText?: Maybe<Scalars['String']>;
  states?: Maybe<Array<TourState>>;
  id?: Maybe<Scalars['PositiveInt']>;
  showId?: Maybe<Scalars['PositiveInt']>;
  divisionId?: Maybe<Scalars['PositiveInt']>;
  tourIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type AdmissionType = {
  __typename?: 'AdmissionType';
  id: Scalars['PositiveInt'];
  name: Scalars['NonEmptyString'];
  abbreviation: Scalars['NonEmptyString'];
  capacityDecreaseCount: Scalars['NonNegativeInt'];
  startingQuantity: Scalars['PositiveInt'];
  color: Scalars['HexColorCode'];
  internalDescription?: Maybe<Scalars['NonEmptyString']>;
  icon?: Maybe<Scalars['NonEmptyString']>;
  eCommerceNames?: Maybe<ECommerceTranslated>;
  eCommerceDescriptions?: Maybe<ECommerceTranslated>;
  state: AdmissionTypeState;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  clientId: Scalars['PositiveInt'];
};

export type Tour = {
  __typename?: 'Tour';
  id: Scalars['PositiveInt'];
  name: Scalars['NonEmptyString'];
  internalNote?: Maybe<Scalars['NonEmptyString']>;
  showId: Scalars['PositiveInt'];
  divisionId: Scalars['PositiveInt'];
  division: Division;
  clientId: Scalars['PositiveInt'];
  show: ClientShow;
  state: TourState;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  timeSlots: PaginatedTourTimeSlots;
  /** Default value for tourTimeSlot */
  duration: Scalars['PositiveInt'];
  /** Default value for tourTimeSlot */
  retailAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  /** Default value for tourTimeSlot */
  eCommerceAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  /** Default value for tourTimeSlot */
  eCommerceOrderAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  /** Default value for tourTimeSlot */
  venueId?: Maybe<Scalars['PositiveInt']>;
  venue?: Maybe<Venue>;
  isECommerceSaleActive: Scalars['Boolean'];
  isECommerceReservationActive: Scalars['Boolean'];
  isRetailSaleActive: Scalars['Boolean'];
  isRetailReservationActive: Scalars['Boolean'];
  gateOpensType: DivisionGateOpens;
  gateOpensValue: Scalars['NonNegativeInt'];
  gateClosesType: DivisionGateCloses;
  gateClosesValue: Scalars['NonNegativeInt'];
  salesEndCashDeskValue: Scalars['NonNegativeInt'];
  salesEndCashDeskType: DivisionPosSalesEnd;
  salesEndWebValue: Scalars['NonNegativeInt'];
  salesEndWebType: DivisionOnlineSalesEnd;
  reservationEndCashDeskValue: Scalars['NonNegativeInt'];
  reservationEndCashDeskType: DivisionPosReservationEnd;
  reservationEndWebValue: Scalars['NonNegativeInt'];
  reservationEndWebType: DivisionOnlineReservationEnd;
  eCommerceCreateReservationEndValue: Scalars['NonNegativeInt'];
  eCommerceCreateReservationEndType: DivisionCreateOnlineReservationEnd;
  retailCreateReservationEndValue: Scalars['NonNegativeInt'];
  retailCreateReservationEndType: DivisionCreatePosReservationEnd;
};


export type TourTimeSlotsArgs = {
  filter?: Maybe<TimeSlotsFilter>;
  paginationInput: PaginationInput;
};

export type PaginatedAdmissionTypesSuccessResult = {
  __typename?: 'PaginatedAdmissionTypesSuccessResult';
  items: Array<AdmissionType>;
  pagination: Pagination;
};

export type PaginatedToursSuccessResult = {
  __typename?: 'PaginatedToursSuccessResult';
  items: Array<Tour>;
  pagination: Pagination;
};

export enum AdmissionRateState {
  Active = 'active',
  Inactive = 'inactive',
  Draft = 'draft'
}

export type AdmissionRateInput = {
  tourId: Scalars['PositiveInt'];
  name: Scalars['NonEmptyString'];
  abbreviation: Scalars['NonEmptyString'];
  color: Scalars['HexColorCode'];
  description?: Maybe<Scalars['NonEmptyString']>;
};

export type AdmissionTypeAssignment = {
  __typename?: 'AdmissionTypeAssignment';
  id: Scalars['PositiveInt'];
  clientId: Scalars['PositiveInt'];
  admissionRateId: Scalars['PositiveInt'];
  admissionTypeId: Scalars['PositiveInt'];
  admissionType: AdmissionType;
  retailPrice: Scalars['NonNegativeFloat'];
  retailVatRate: Scalars['NonNegativeFloat'];
  enabledOnRetail: Scalars['Boolean'];
  hasEnabledDiscountsOnRetail: Scalars['Boolean'];
  eCommercePrice: Scalars['NonNegativeFloat'];
  eCommerceVatRate: Scalars['NonNegativeFloat'];
  enabledOnECommerce: Scalars['Boolean'];
  hasEnabledDiscountsOnECommerce: Scalars['Boolean'];
  limitPerTimeSlot?: Maybe<Scalars['NonNegativeInt']>;
  triggerCheckNotification: Scalars['Boolean'];
  accessZones: Array<AccessZone>;
};

export type AdmissionRate = {
  __typename?: 'AdmissionRate';
  id: Scalars['PositiveInt'];
  tourId: Scalars['PositiveInt'];
  tour: Tour;
  clientId: Scalars['PositiveInt'];
  client: Client;
  name: Scalars['NonEmptyString'];
  abbreviation: Scalars['NonEmptyString'];
  color: Scalars['HexColorCode'];
  description?: Maybe<Scalars['NonEmptyString']>;
  state: AdmissionRateState;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  admissionTypesCount: Scalars['NonNegativeInt'];
  admissionTypesAssignments: Array<AdmissionTypeAssignment>;
};


export type AdmissionRateAdmissionTypesAssignmentsArgs = {
  state?: Maybe<AdmissionTypeState>;
  channel?: Maybe<SellingChannel>;
};

export type AccessZone = {
  __typename?: 'AccessZone';
  id: Scalars['PositiveInt'];
  clientId: Scalars['PositiveInt'];
  client: Client;
  name: Scalars['NonEmptyString'];
  description?: Maybe<Scalars['NonEmptyString']>;
  state: AccessZoneState;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type AdmissionRatesFilter = {
  hasText?: Maybe<Scalars['String']>;
  states?: Maybe<Array<AdmissionRateState>>;
  id?: Maybe<Scalars['PositiveInt']>;
  tourId?: Maybe<Scalars['PositiveInt']>;
};

export type PaginatedAdmissionRatesSuccessResult = {
  __typename?: 'PaginatedAdmissionRatesSuccessResult';
  items: Array<AdmissionRate>;
  pagination: Pagination;
};

export type UpdateAdmissionRateInput = {
  name: Scalars['NonEmptyString'];
  abbreviation: Scalars['NonEmptyString'];
  color: Scalars['HexColorCode'];
  description?: Maybe<Scalars['String']>;
  /** Only "active" and "inactive" values are accepted. In case of "draft", the error "INVALID_ADMISSION_RATE_TYPE_STATE" will be thrown. */
  state: AdmissionRateState;
};

export type DraftAdmissionRateAssignmentsInput = {
  /** Assignments without id will be created, assignments with id will be updated. Error will be thrown in case of not found assignment. */
  id?: Maybe<Scalars['PositiveInt']>;
  admissionTypeId: Scalars['PositiveInt'];
  retailPrice: Scalars['NonNegativeFloat'];
  retailVatRate: Scalars['NonNegativeFloat'];
  enabledOnRetail: Scalars['Boolean'];
  hasEnabledDiscountsOnRetail: Scalars['Boolean'];
  eCommercePrice: Scalars['NonNegativeFloat'];
  eCommerceVatRate: Scalars['NonNegativeFloat'];
  enabledOnECommerce: Scalars['Boolean'];
  hasEnabledDiscountsOnECommerce: Scalars['Boolean'];
  limitPerTimeSlot?: Maybe<Scalars['NonNegativeInt']>;
  triggerCheckNotification: Scalars['Boolean'];
};

export type AdmissionRateAssignmentsInput = {
  id: Scalars['PositiveInt'];
  enabledOnRetail: Scalars['Boolean'];
  hasEnabledDiscountsOnRetail: Scalars['Boolean'];
  enabledOnECommerce: Scalars['Boolean'];
  hasEnabledDiscountsOnECommerce: Scalars['Boolean'];
  limitPerTimeSlot?: Maybe<Scalars['NonNegativeInt']>;
  triggerCheckNotification: Scalars['Boolean'];
};

export enum TourTimeSlotState {
  Draft = 'draft',
  Published = 'published',
  Cancelled = 'cancelled'
}

export enum TicketValidityType {
  ValidOnlyForTimeSlot = 'validOnlyForTimeSlot',
  ValidAtSlotDatetimePlusNHours = 'validAtSlotDatetimePlusNHours',
  ValidAtSlotDayPlusNDays = 'validAtSlotDayPlusNDays'
}

export type TourTimeSlotInput = {
  state?: Maybe<TourTimeSlotState>;
  admissionRateId: Scalars['PositiveInt'];
  startsAt: Scalars['DateTime'];
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  guideId?: Maybe<Scalars['PositiveInt']>;
  showOnWebsiteAndApi: Scalars['Boolean'];
  ticketNote?: Maybe<Scalars['String']>;
  venueId: Scalars['PositiveInt'];
  retailAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceOrderAttendeesLimit: Scalars['NonNegativeInt'];
  costCenterId?: Maybe<Scalars['PositiveInt']>;
  marketingLabelId?: Maybe<Scalars['PositiveInt']>;
  eventCategoryId?: Maybe<Scalars['PositiveInt']>;
  isRetailSaleActive: Scalars['Boolean'];
  isRetailReservationActive: Scalars['Boolean'];
  isECommerceSaleActive: Scalars['Boolean'];
  isECommerceReservationActive: Scalars['Boolean'];
};

export type TourItemsStateCounts = {
  __typename?: 'TourItemsStateCounts';
  inCart: Scalars['NonNegativeInt'];
  reserved: Scalars['NonNegativeInt'];
  sold: Scalars['NonNegativeInt'];
};

export type TourTimeSlot = {
  __typename?: 'TourTimeSlot';
  id: Scalars['PositiveInt'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  state: TourTimeSlotState;
  names: Translated;
  clientId: Scalars['PositiveInt'];
  client: Client;
  tourId: Scalars['PositiveInt'];
  tour: Tour;
  admissionRateId: Scalars['PositiveInt'];
  admissionRate: AdmissionRate;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  duration: Scalars['PositiveInt'];
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  guideId?: Maybe<Scalars['PositiveInt']>;
  guide?: Maybe<User>;
  showOnWebsiteAndApi: Scalars['Boolean'];
  ticketNote?: Maybe<Scalars['String']>;
  costCenterId?: Maybe<Scalars['PositiveInt']>;
  marketingLabelId?: Maybe<Scalars['PositiveInt']>;
  eventCategoryId?: Maybe<Scalars['PositiveInt']>;
  costCenter?: Maybe<CostCenter>;
  marketingLabel?: Maybe<MarketingLabel>;
  eventCategory?: Maybe<EventCategory>;
  venueId: Scalars['PositiveInt'];
  venue: Venue;
  retailSaleStartsAt: Scalars['DateTime'];
  retailSaleEndsAt: Scalars['DateTime'];
  isRetailSaleActive: Scalars['Boolean'];
  retailReservationStartsAt: Scalars['DateTime'];
  retailReservationEndsAt: Scalars['DateTime'];
  isRetailReservationActive: Scalars['Boolean'];
  retailReservationExpirationType: DivisionPosReservationEnd;
  retailReservationExpirationLastFor?: Maybe<Scalars['Int']>;
  retailReservationExpirationExpiresAt: Scalars['DateTime'];
  eCommerceSaleStartsAt: Scalars['DateTime'];
  eCommerceSaleEndsAt: Scalars['DateTime'];
  isECommerceSaleActive: Scalars['Boolean'];
  eCommerceReservationStartsAt: Scalars['DateTime'];
  eCommerceReservationEndsAt: Scalars['DateTime'];
  isECommerceReservationActive: Scalars['Boolean'];
  eCommerceReservationExpirationType: DivisionOnlineReservationEnd;
  eCommerceReservationExpirationLastFor?: Maybe<Scalars['Int']>;
  eCommerceReservationExpirationExpiresAt: Scalars['DateTime'];
  retailAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceOrderAttendeesLimit: Scalars['NonNegativeInt'];
  uniqueCheckedInCount: Scalars['NonNegativeInt'];
  passCodesCheckedInCount: Scalars['NonNegativeInt'];
  passCodesCheckedOutCount: Scalars['NonNegativeInt'];
  attendeesCheckedInCount: Scalars['NonNegativeInt'];
  attendeesCheckedOutCount: Scalars['NonNegativeInt'];
  gateOpensAt: Scalars['DateTime'];
  gateClosesAt: Scalars['DateTime'];
  ticketValidityType: TicketValidityType;
  ticketValidityValue?: Maybe<Scalars['PositiveInt']>;
  tourItemsStateCounts: TourItemsStateCounts;
  enabledDiscounts: Array<Discount>;
};


export type TourTimeSlotEnabledDiscountsArgs = {
  discountsFilter?: Maybe<EnabledDiscountsFilterInput>;
};

export type TourTimeSlotSaleDataInput = {
  retailSaleStartsAt: Scalars['DateTime'];
  retailSaleEndsAt: Scalars['DateTime'];
  isRetailSaleActive: Scalars['Boolean'];
  retailReservationStartsAt: Scalars['DateTime'];
  retailReservationEndsAt: Scalars['DateTime'];
  isRetailReservationActive: Scalars['Boolean'];
  retailReservationExpirationType: DivisionPosReservationEnd;
  retailReservationExpirationLastFor?: Maybe<Scalars['Int']>;
  retailReservationExpirationExpiresAt: Scalars['DateTime'];
  eCommerceSaleStartsAt: Scalars['DateTime'];
  eCommerceSaleEndsAt: Scalars['DateTime'];
  isECommerceSaleActive: Scalars['Boolean'];
  eCommerceReservationStartsAt: Scalars['DateTime'];
  eCommerceReservationEndsAt: Scalars['DateTime'];
  isECommerceReservationActive: Scalars['Boolean'];
  eCommerceReservationExpirationType: DivisionOnlineReservationEnd;
  eCommerceReservationExpirationLastFor?: Maybe<Scalars['Int']>;
  eCommerceReservationExpirationExpiresAt: Scalars['DateTime'];
};

export type UpdateTourTimeSlotsInput = {
  /** Only published/cancelled TourTimeSlotStates are available */
  state?: Maybe<TourTimeSlotState>;
  names?: Maybe<TranslatedInput>;
  admissionRateId?: Maybe<Scalars['PositiveInt']>;
  /** Updates also endsAt cell in DB to startsAt+duration */
  duration?: Maybe<Scalars['PositiveInt']>;
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  guideId?: Maybe<Scalars['PositiveInt']>;
  showOnWebsiteAndApi?: Maybe<Scalars['Boolean']>;
  ticketNote?: Maybe<Scalars['String']>;
  costCenterId?: Maybe<Scalars['PositiveInt']>;
  marketingLabelId?: Maybe<Scalars['PositiveInt']>;
  eventCategoryId?: Maybe<Scalars['PositiveInt']>;
  venueId?: Maybe<Scalars['PositiveInt']>;
  isRetailSaleActive?: Maybe<Scalars['Boolean']>;
  isRetailReservationActive?: Maybe<Scalars['Boolean']>;
  isECommerceSaleActive?: Maybe<Scalars['Boolean']>;
  isECommerceReservationActive?: Maybe<Scalars['Boolean']>;
  retailAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceOrderAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  ticketValidityType?: Maybe<TicketValidityType>;
  ticketValidityValue?: Maybe<Scalars['PositiveInt']>;
};

export type CopyTourTimeSlotsInput = {
  tourId: Scalars['PositiveInt'];
  sourceDate: Scalars['Date'];
  destinationDateFrom: Scalars['Date'];
  destinationDateTo: Scalars['Date'];
  allowedWeekDays: Array<WeekDay>;
  state?: Maybe<TourTimeSlotState>;
};

export type CopyAdmissionRateInput = {
  admissionRateId: Scalars['PositiveInt'];
  admissionRateName: Scalars['NonEmptyString'];
  tourId: Scalars['PositiveInt'];
};

export type TimeSlotsFilter = {
  id?: Maybe<Scalars['PositiveInt']>;
  clientId?: Maybe<Scalars['PositiveInt']>;
  admissionRateId?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
  startsAtFrom?: Maybe<Scalars['DateTime']>;
  startsAtTo?: Maybe<Scalars['DateTime']>;
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  guideId?: Maybe<Scalars['PositiveInt']>;
  showOnWebsiteAndApi?: Maybe<Scalars['Boolean']>;
  state?: Maybe<TourTimeSlotState>;
  costCenterId?: Maybe<Scalars['PositiveInt']>;
  eventCategoryId?: Maybe<Scalars['PositiveInt']>;
  marketingLabelId?: Maybe<Scalars['PositiveInt']>;
  venueId?: Maybe<Scalars['PositiveInt']>;
  isAvailableOnRetail?: Maybe<Scalars['Boolean']>;
  isAvailableOnECommerce?: Maybe<Scalars['Boolean']>;
};

export type TourTimeSlotsFilter = {
  id?: Maybe<Scalars['PositiveInt']>;
  clientId?: Maybe<Scalars['PositiveInt']>;
  tourId?: Maybe<Scalars['PositiveInt']>;
  admissionRateId?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
  startsAtFrom?: Maybe<Scalars['DateTime']>;
  startsAtTo?: Maybe<Scalars['DateTime']>;
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  guideId?: Maybe<Scalars['PositiveInt']>;
  showOnWebsiteAndApi?: Maybe<Scalars['Boolean']>;
  state?: Maybe<TourTimeSlotState>;
  costCenterId?: Maybe<Scalars['PositiveInt']>;
  eventCategoryId?: Maybe<Scalars['PositiveInt']>;
  marketingLabelId?: Maybe<Scalars['PositiveInt']>;
  venueId?: Maybe<Scalars['PositiveInt']>;
  isAvailableOnRetail?: Maybe<Scalars['Boolean']>;
  isAvailableOnECommerce?: Maybe<Scalars['Boolean']>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  tourTimeSlotIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type PaginatedTourTimeSlots = {
  __typename?: 'PaginatedTourTimeSlots';
  items: Array<TourTimeSlot>;
  pagination: Pagination;
};

export type PaginatedAccessZones = {
  __typename?: 'PaginatedAccessZones';
  items: Array<AccessZone>;
  pagination: Pagination;
};

export enum ThirdPartyOriginState {
  Active = 'active',
  Inactive = 'inactive'
}

export type EventStatistics = {
  __typename?: 'EventStatistics';
  eventId: Scalars['PositiveInt'];
  names: Translated;
  state: EventState;
  startsAt: Scalars['DateTime'];
  venue: Venue;
  auditorium: Auditorium;
  division: Division;
  show: ClientShow;
  costCenter?: Maybe<CostCenter>;
  eventCategory?: Maybe<EventCategory>;
  marketingLabel?: Maybe<MarketingLabel>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
  formatCode?: Maybe<ShowFormatCode>;
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  soundMixCode?: Maybe<ShowSoundMixCode>;
  uniqueCheckedInCount: Scalars['NonNegativeInt'];
  passCodesCheckedInCount: Scalars['NonNegativeInt'];
  passCodesCheckedOutCount: Scalars['NonNegativeInt'];
  saleSumOnRetailChannel: Scalars['NonNegativeFloat'];
  saleSumOnEcommerceChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleTicketCountOnEcommerceChannel: Scalars['NonNegativeInt'];
  refundSumOnRetailChannel: Scalars['NonNegativeFloat'];
  refundSumOnEcommerceChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundTicketCountOnEcommerceChannel: Scalars['NonNegativeInt'];
  profit: Scalars['Float'];
  /**
   * This value can be a negative integer in a scenario when filter has been applied to eventsStatistics query resulting
   * in refundTicketCountOnRetail/Ecommerce channel to be greater than saleTicketCountOnRetail/Ecommerce channel
   */
  profitTicketCount: Scalars['Int'];
  reservedSeatsCount: Scalars['NonNegativeInt'];
  soldSeatsCount: Scalars['NonNegativeInt'];
  availableSeatsCount: Scalars['NonNegativeInt'];
  hiddenSeatsCount: Scalars['NonNegativeInt'];
  disabledSeatsCount: Scalars['NonNegativeInt'];
  inCartSeatsCount: Scalars['NonNegativeInt'];
  selectedSeatsCount: Scalars['NonNegativeInt'];
  auditoriumLayout: AuditoriumLayout;
  freeSoldTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  freeSoldTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  totalFreeSoldTicketCount: Scalars['NonNegativeInt'];
  discountedSoldTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  discountedSoldTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  totalDiscountedSoldTicketCount: Scalars['NonNegativeInt'];
};

export type AggregatedStatisticsByItemPrice = {
  __typename?: 'AggregatedStatisticsByItemPrice';
  id: Scalars['String'];
  discountName?: Maybe<Scalars['String']>;
  discountType?: Maybe<DiscountType>;
  discountValue?: Maybe<Scalars['NonNegativeFloat']>;
  ticketTypeName: Scalars['String'];
  itemPrice: Scalars['NonNegativeFloat'];
  itemPriceBeforeDiscount: Scalars['NonNegativeFloat'];
  saleSumOnRetailChannel: Scalars['NonNegativeFloat'];
  saleSumOnEcommerceChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleTicketCountOnEcommerceChannel: Scalars['NonNegativeInt'];
  refundSumOnRetailChannel: Scalars['NonNegativeFloat'];
  refundSumOnEcommerceChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundTicketCountOnEcommerceChannel: Scalars['NonNegativeInt'];
  profit: Scalars['Float'];
  profitTicketCount: Scalars['Int'];
};

export type AggregatedEventStatisticsByItemPrice = {
  __typename?: 'AggregatedEventStatisticsByItemPrice';
  eventId: Scalars['PositiveInt'];
  names: Translated;
  state: EventState;
  startsAt: Scalars['DateTime'];
  venue: Venue;
  auditorium: Auditorium;
  division: Division;
  show: ClientShow;
  costCenter?: Maybe<CostCenter>;
  eventCategory?: Maybe<EventCategory>;
  marketingLabel?: Maybe<MarketingLabel>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
  formatCode?: Maybe<ShowFormatCode>;
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  soundMixCode?: Maybe<ShowSoundMixCode>;
  uniqueCheckedInCount: Scalars['NonNegativeInt'];
  passCodesCheckedInCount: Scalars['NonNegativeInt'];
  passCodesCheckedOutCount: Scalars['NonNegativeInt'];
  saleSumOnRetailChannel: Scalars['NonNegativeFloat'];
  saleSumOnEcommerceChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleTicketCountOnEcommerceChannel: Scalars['NonNegativeInt'];
  refundSumOnRetailChannel: Scalars['NonNegativeFloat'];
  refundSumOnEcommerceChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundTicketCountOnEcommerceChannel: Scalars['NonNegativeInt'];
  profit: Scalars['Float'];
  /**
   * This value can be a negative integer in a scenario when filter has been applied to eventsStatistics query resulting
   * in refundTicketCountOnRetail/Ecommerce channel to be greater than saleTicketCountOnRetail/Ecommerce channel
   */
  profitTicketCount: Scalars['Int'];
  aggregatedStatistics: Array<AggregatedStatisticsByItemPrice>;
};

export type PaginatedEventsStatistics = {
  __typename?: 'PaginatedEventsStatistics';
  items: Array<EventStatistics>;
  pagination: Pagination;
};

export type EventsStatisticsFilterInput = {
  eventName?: Maybe<Scalars['String']>;
  eventStartsAtFrom?: Maybe<Scalars['DateTime']>;
  eventStartsAtTo?: Maybe<Scalars['DateTime']>;
  eventState?: Maybe<EventState>;
  clientId?: Maybe<Scalars['PositiveInt']>;
  divisionId?: Maybe<Scalars['PositiveInt']>;
  venueId?: Maybe<Scalars['PositiveInt']>;
  auditoriumId?: Maybe<Scalars['PositiveInt']>;
  showId?: Maybe<Scalars['PositiveInt']>;
  costCenterId?: Maybe<Scalars['PositiveInt']>;
  eventCategoryId?: Maybe<Scalars['PositiveInt']>;
  marketingLabelId?: Maybe<Scalars['PositiveInt']>;
  paymentCreatedById?: Maybe<Scalars['PositiveInt']>;
  paymentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  paymentDateTimeTo?: Maybe<Scalars['DateTime']>;
  businessPartnerIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type ProductStatistics = {
  __typename?: 'ProductStatistics';
  id: Scalars['NonEmptyString'];
  clientId: Scalars['PositiveInt'];
  productId: Scalars['PositiveInt'];
  divisionId: Scalars['PositiveInt'];
  productName: Scalars['NonEmptyString'];
  productState: ProductState;
  productInternalCode?: Maybe<Scalars['NonEmptyString']>;
  productTypeId: Scalars['PositiveInt'];
  productTypeName: Scalars['NonEmptyString'];
  unit: Unit;
  unitPriceVatExcluded: Scalars['NonNegativeFloat'];
  unitPriceVatIncluded: Scalars['NonNegativeFloat'];
  unitVat?: Maybe<Scalars['NonNegativeFloat']>;
  vatRate: Scalars['NonNegativeFloat'];
  revenueVatExcluded: Scalars['Float'];
  revenueVatIncluded: Scalars['Float'];
  revenueVat: Scalars['Float'];
  revenueItemQuantity: Scalars['Int'];
};

export type ProductsStatisticsTotals = {
  __typename?: 'ProductsStatisticsTotals';
  revenueVat: Scalars['Float'];
  revenueVatIncluded: Scalars['Float'];
  revenueVatExcluded: Scalars['Float'];
};

export type PaginatedProductsStatistics = {
  __typename?: 'PaginatedProductsStatistics';
  items: Array<ProductStatistics>;
  pagination: Pagination;
  totals: ProductsStatisticsTotals;
};

export type ProductsStatisticsFilterInput = {
  productName?: Maybe<Scalars['NonEmptyString']>;
  productId?: Maybe<Scalars['PositiveInt']>;
  clientId?: Maybe<Scalars['PositiveInt']>;
  productTypeIds?: Maybe<Array<Scalars['PositiveInt']>>;
  productStates?: Maybe<Array<ProductState>>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  sellingChannel?: Maybe<SellingChannel>;
  paymentCreatedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  paymentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  paymentDateTimeTo?: Maybe<Scalars['DateTime']>;
};

export type TourTimeSlotStatistics = {
  __typename?: 'TourTimeSlotStatistics';
  tourTimeSlotId: Scalars['PositiveInt'];
  state: TourTimeSlotState;
  names: Translated;
  startsAt: Scalars['DateTime'];
  versionCode?: Maybe<ShowVersionCode>;
  ageClassificationCode?: Maybe<ShowAgeClassificationCode>;
  tour: Tour;
  division: Division;
  venue: Venue;
  guide?: Maybe<User>;
  costCenter?: Maybe<CostCenter>;
  marketingLabel?: Maybe<MarketingLabel>;
  eventCategory?: Maybe<EventCategory>;
  saleSumOnRetailChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleAttendeesCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleSumOnECommerceChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  saleAttendeesCountOnECommerceChannel: Scalars['NonNegativeInt'];
  refundSumOnRetailChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundAttendeesCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundSumOnECommerceChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  refundAttendeesCountOnECommerceChannel: Scalars['NonNegativeInt'];
  revenue: Scalars['Float'];
  revenueTicketCount: Scalars['Int'];
  revenueAttendeesCount: Scalars['Int'];
  retailAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  eCommerceAttendeesLimit?: Maybe<Scalars['NonNegativeInt']>;
  tourItemsStateCounts: TourItemsStateCounts;
};

export type TourTimeSlotsStatisticsTotals = {
  __typename?: 'TourTimeSlotsStatisticsTotals';
  saleSumOnRetailChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleAttendeesCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleSumOnECommerceChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  saleAttendeesCountOnECommerceChannel: Scalars['NonNegativeInt'];
  refundSumOnRetailChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundAttendeesCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundSumOnECommerceChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  refundAttendeesCountOnECommerceChannel: Scalars['NonNegativeInt'];
  revenue: Scalars['Float'];
  revenueTicketCount: Scalars['Int'];
  revenueAttendeesCount: Scalars['Int'];
};

export type PaginatedTourTimeSlotsStatistics = {
  __typename?: 'PaginatedTourTimeSlotsStatistics';
  items: Array<TourTimeSlotStatistics>;
  totals: TourTimeSlotsStatisticsTotals;
  pagination: Pagination;
};

export type DiscountsStatistics = {
  __typename?: 'DiscountsStatistics';
  id: Scalars['PositiveInt'];
  application: DiscountApplication;
  type: DiscountType;
  value: Scalars['NonNegativeFloat'];
  name: Scalars['String'];
  discountApplicationCountTotal: Scalars['Int'];
  discountApplicationSaleCountECommerce: Scalars['NonNegativeInt'];
  discountApplicationSaleCountRetail: Scalars['NonNegativeInt'];
  discountApplicationRefundCountECommerce: Scalars['NonNegativeInt'];
  discountApplicationRefundCountRetail: Scalars['NonNegativeInt'];
  discountedPriceTotal: Scalars['Float'];
  discountedPriceSaleECommerce: Scalars['NonNegativeFloat'];
  discountedPriceSaleRetail: Scalars['NonNegativeFloat'];
  discountedPriceRefundECommerce: Scalars['NonNegativeFloat'];
  discountedPriceRefundRetail: Scalars['NonNegativeFloat'];
};

export type DiscountsStatisticsTotals = {
  __typename?: 'DiscountsStatisticsTotals';
  discountApplicationCountTotal: Scalars['Int'];
  discountApplicationSaleCountECommerce: Scalars['NonNegativeInt'];
  discountApplicationSaleCountRetail: Scalars['NonNegativeInt'];
  discountApplicationRefundCountECommerce: Scalars['NonNegativeInt'];
  discountApplicationRefundCountRetail: Scalars['NonNegativeInt'];
  discountedPriceTotal: Scalars['Float'];
  discountedPriceSaleECommerce: Scalars['NonNegativeFloat'];
  discountedPriceSaleRetail: Scalars['NonNegativeFloat'];
  discountedPriceRefundECommerce: Scalars['NonNegativeFloat'];
  discountedPriceRefundRetail: Scalars['NonNegativeFloat'];
};

export enum CombinedEventAndTourTimeSlotState {
  Draft = 'draft',
  Published = 'published',
  /** usage of "cancelled" either "canceled" will result in search in statistics for canceled events and cancelled tourTimeSlots */
  Cancelled = 'cancelled',
  /** usage of "cancelled" either "canceled" will result in search in statistics for canceled events and cancelled tourTimeSlots */
  Canceled = 'canceled',
  Deleted = 'deleted'
}

export type DiscountsStatisticsFilterInput = {
  paymentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  paymentDateTimeTo?: Maybe<Scalars['DateTime']>;
  paymentCreatedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  startsAtFrom?: Maybe<Scalars['DateTime']>;
  startsAtTo?: Maybe<Scalars['DateTime']>;
  /**
   * Use one of desired EventState's or TourTimeSlotState's
   * "draft", "published", "cancelled", "canceled", "deleted".
   */
  states?: Maybe<Array<CombinedEventAndTourTimeSlotState>>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  venueIds?: Maybe<Array<Scalars['PositiveInt']>>;
  showIds?: Maybe<Array<Scalars['PositiveInt']>>;
  costCenterIds?: Maybe<Array<Scalars['PositiveInt']>>;
  eventCategoryIds?: Maybe<Array<Scalars['PositiveInt']>>;
  marketingLabelIds?: Maybe<Array<Scalars['PositiveInt']>>;
  hasText?: Maybe<Scalars['NonEmptyString']>;
};

export type PaginatedDiscountsStatistics = {
  __typename?: 'PaginatedDiscountsStatistics';
  items: Array<DiscountsStatistics>;
  totals: DiscountsStatisticsTotals;
  pagination: Pagination;
};

export type TourTimeSlotsStatisticsFilterInput = {
  tourTimeSlotName?: Maybe<Scalars['NonEmptyString']>;
  startsAtFrom?: Maybe<Scalars['DateTime']>;
  startsAtTo?: Maybe<Scalars['DateTime']>;
  tourTimeSlotStates?: Maybe<Array<TourTimeSlotState>>;
  clientId?: Maybe<Scalars['PositiveInt']>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  venueIds?: Maybe<Array<Scalars['PositiveInt']>>;
  tourId?: Maybe<Scalars['PositiveInt']>;
  costCenterId?: Maybe<Scalars['PositiveInt']>;
  eventCategoryId?: Maybe<Scalars['PositiveInt']>;
  marketingLabelId?: Maybe<Scalars['PositiveInt']>;
  paymentCreatedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  paymentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  paymentDateTimeTo?: Maybe<Scalars['DateTime']>;
};

export type AdmissionTypeStatistics = {
  __typename?: 'AdmissionTypeStatistics';
  admissionTypeId: Scalars['PositiveInt'];
  name: Scalars['NonEmptyString'];
  startingQuantity: Scalars['PositiveInt'];
  capacityDecreaseCount: Scalars['NonNegativeInt'];
  saleSumOnRetailChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleAttendeesCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleSumOnECommerceChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  saleAttendeesCountOnECommerceChannel: Scalars['NonNegativeInt'];
  refundSumOnRetailChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundAttendeesCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundSumOnECommerceChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  refundAttendeesCountOnECommerceChannel: Scalars['NonNegativeInt'];
  saleSum: Scalars['NonNegativeFloat'];
  saleTicketCount: Scalars['NonNegativeInt'];
  saleAttendeesCount: Scalars['NonNegativeInt'];
  refundSum: Scalars['NonNegativeFloat'];
  refundTicketCount: Scalars['NonNegativeInt'];
  refundAttendeesCount: Scalars['NonNegativeInt'];
  revenue: Scalars['Float'];
  revenueTicketCount: Scalars['Int'];
  revenueAttendeesCount: Scalars['Int'];
};

export type AdmissionTypesStatisticsTotals = {
  __typename?: 'AdmissionTypesStatisticsTotals';
  saleSumOnRetailChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleAttendeesCountOnRetailChannel: Scalars['NonNegativeInt'];
  saleSumOnECommerceChannel: Scalars['NonNegativeFloat'];
  saleTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  saleAttendeesCountOnECommerceChannel: Scalars['NonNegativeInt'];
  refundSumOnRetailChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundAttendeesCountOnRetailChannel: Scalars['NonNegativeInt'];
  refundSumOnECommerceChannel: Scalars['NonNegativeFloat'];
  refundTicketCountOnECommerceChannel: Scalars['NonNegativeInt'];
  refundAttendeesCountOnECommerceChannel: Scalars['NonNegativeInt'];
  saleSum: Scalars['NonNegativeFloat'];
  saleTicketCount: Scalars['NonNegativeInt'];
  saleAttendeesCount: Scalars['NonNegativeInt'];
  refundSum: Scalars['NonNegativeFloat'];
  refundTicketCount: Scalars['NonNegativeInt'];
  refundAttendeesCount: Scalars['NonNegativeInt'];
  revenue: Scalars['Float'];
  revenueTicketCount: Scalars['Int'];
  revenueAttendeesCount: Scalars['Int'];
};

export type PaginatedAdmissionTypesStatistics = {
  __typename?: 'PaginatedAdmissionTypesStatistics';
  items: Array<AdmissionTypeStatistics>;
  totals: AdmissionTypesStatisticsTotals;
  pagination: Pagination;
};

export type AdmissionTypesStatisticsFilterInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  tourId?: Maybe<Scalars['PositiveInt']>;
  tourTimeSlotId?: Maybe<Scalars['PositiveInt']>;
  clientId?: Maybe<Scalars['PositiveInt']>;
  costCenterId?: Maybe<Scalars['PositiveInt']>;
  eventCategoryId?: Maybe<Scalars['PositiveInt']>;
  marketingLabelId?: Maybe<Scalars['PositiveInt']>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  venueIds?: Maybe<Array<Scalars['PositiveInt']>>;
  timeSlotStartsAtFrom?: Maybe<Scalars['DateTime']>;
  timeSlotStartsAtTo?: Maybe<Scalars['DateTime']>;
  paymentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  paymentDateTimeTo?: Maybe<Scalars['DateTime']>;
  paymentCreatedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  tourTimeSlotStates?: Maybe<Array<TourTimeSlotState>>;
  tourAccessZoneIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type ShowStatistics = {
  __typename?: 'ShowStatistics';
  showId: Scalars['PositiveInt'];
  showTitles: Array<ShowTranslation>;
  firstEventStartsAt: Scalars['DateTime'];
  lastEventStartsAt: Scalars['DateTime'];
  eventsCount: Scalars['NonNegativeInt'];
  profit: Scalars['Float'];
  profitTicketCount: Scalars['Int'];
  totalDiscountedSoldTicketCount: Scalars['NonNegativeInt'];
  totalFreeSoldTicketCount: Scalars['NonNegativeInt'];
};

export type ShowsStatisticsTotals = {
  __typename?: 'ShowsStatisticsTotals';
  eventsCount: Scalars['NonNegativeInt'];
  profit: Scalars['Float'];
  profitTicketCount: Scalars['Int'];
  totalDiscountedSoldTicketCount: Scalars['NonNegativeInt'];
  totalFreeSoldTicketCount: Scalars['NonNegativeInt'];
};

export type ShowsStatisticsFilterInput = {
  showIds?: Maybe<Array<Scalars['PositiveInt']>>;
  showTypeCodes?: Maybe<Array<ShowTypeCode>>;
  showReleaseDateFrom?: Maybe<Scalars['Date']>;
  showReleaseDateTo?: Maybe<Scalars['Date']>;
  hasText?: Maybe<Scalars['NonEmptyString']>;
  eventName?: Maybe<Scalars['NonEmptyString']>;
  eventStartsAtFrom?: Maybe<Scalars['DateTime']>;
  eventStartsAtTo?: Maybe<Scalars['DateTime']>;
  eventStates?: Maybe<Array<EventState>>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  venueIds?: Maybe<Array<Scalars['PositiveInt']>>;
  auditoriumIds?: Maybe<Array<Scalars['PositiveInt']>>;
  costCenterIds?: Maybe<Array<Scalars['PositiveInt']>>;
  eventCategoryIds?: Maybe<Array<Scalars['PositiveInt']>>;
  marketingLabelIds?: Maybe<Array<Scalars['PositiveInt']>>;
  paymentCreatedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  paymentDateTimeFrom?: Maybe<Scalars['DateTime']>;
  paymentDateTimeTo?: Maybe<Scalars['DateTime']>;
  businessPartnerIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type PaginatedShowsStatistics = {
  __typename?: 'PaginatedShowsStatistics';
  items: Array<ShowStatistics>;
  totals: ShowsStatisticsTotals;
  pagination: Pagination;
};

export enum ProductTypeState {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted'
}

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['PositiveInt'];
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  state: ProductTypeState;
  client: Client;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export enum ProductState {
  Active = 'active',
  Inactive = 'inactive',
  Disabled = 'disabled',
  Deleted = 'deleted'
}

export type ProductAvailability = {
  __typename?: 'ProductAvailability';
  id: Scalars['PositiveInt'];
  division: Division;
  isAvailableOnRetailChannel: Scalars['Boolean'];
  isAvailableOnECommerceChannel: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type ProductPricing = {
  __typename?: 'ProductPricing';
  id: Scalars['PositiveInt'];
  startsAt: Scalars['DateTime'];
  retailPrice: Scalars['NonNegativeFloat'];
  retailVatRate: Scalars['NonNegativeFloat'];
  eCommercePrice: Scalars['NonNegativeFloat'];
  eCommerceVatRate: Scalars['NonNegativeFloat'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
};

export type AggregatedProductPricingByDivision = {
  __typename?: 'AggregatedProductPricingByDivision';
  division: Division;
  activePricing?: Maybe<ProductPricing>;
  futurePricing?: Maybe<ProductPricing>;
  allPricings: Array<ProductPricing>;
};

export enum ProductMode {
  Product = 'product',
  WarehouseProduct = 'warehouseProduct',
  WarehouseRecipe = 'warehouseRecipe'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['PositiveInt'];
  name: Scalars['String'];
  eCommerceNames?: Maybe<Translated>;
  eCommerceDescriptions?: Maybe<Translated>;
  receiptName?: Maybe<Scalars['String']>;
  internalDescription?: Maybe<Scalars['String']>;
  internalCode?: Maybe<Scalars['String']>;
  priceLookupCode?: Maybe<Scalars['PositiveInt']>;
  unit: Unit;
  mode: ProductMode;
  state: ProductState;
  coverImageURL?: Maybe<Scalars['String']>;
  client: Client;
  productType: ProductType;
  productAvailability: Array<ProductAvailability>;
  productBarcodes: Array<ProductBarcode>;
  pricings: Array<AggregatedProductPricingByDivision>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  productGroups?: Maybe<Array<ProductGroup>>;
  warehouseProducts: Array<WarehouseProduct>;
};


export type ProductWarehouseProductsArgs = {
  warehouseIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type ProductBarcode = {
  __typename?: 'ProductBarcode';
  id: Scalars['PositiveInt'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  product: Product;
  createdAt: Scalars['DateTime'];
  createdBy: User;
};

export type CreateProductTypeInput = {
  name: Scalars['String'];
  abbreviation: Scalars['String'];
};

export type CreateProductInput = {
  name: Scalars['String'];
  eCommerceNames?: Maybe<TranslatedInput>;
  eCommerceDescriptions?: Maybe<TranslatedInput>;
  receiptName?: Maybe<Scalars['String']>;
  internalDescription?: Maybe<Scalars['String']>;
  internalCode?: Maybe<Scalars['String']>;
  priceLookupCode?: Maybe<Scalars['PositiveInt']>;
  unit: Unit;
  mode: ProductMode;
  productTypeId: Scalars['PositiveInt'];
};

export type UpdateProductInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  eCommerceNames?: Maybe<TranslatedInput>;
  eCommerceDescriptions?: Maybe<TranslatedInput>;
  receiptName?: Maybe<Scalars['String']>;
  internalDescription?: Maybe<Scalars['String']>;
  internalCode?: Maybe<Scalars['String']>;
  priceLookupCode?: Maybe<Scalars['PositiveInt']>;
  productTypeId?: Maybe<Scalars['PositiveInt']>;
  state?: Maybe<ProductState>;
  mode?: Maybe<ProductMode>;
};

export type ProductAvailabilityFilterInput = {
  divisionId: Scalars['PositiveInt'];
  sellingChannel: SellingChannel;
};

export type ProductsFilterInput = {
  productId?: Maybe<Scalars['PositiveInt']>;
  internalCode?: Maybe<Scalars['String']>;
  productTypeId?: Maybe<Scalars['PositiveInt']>;
  state?: Maybe<ProductState>;
  priceLookupCode?: Maybe<Scalars['PositiveInt']>;
  hasText?: Maybe<Scalars['String']>;
  divisionId?: Maybe<Scalars['PositiveInt']>;
  sellingChannel?: Maybe<SellingChannel>;
  /** availability filters out products with existing pricing for given division and sellingChannel */
  availability?: Maybe<ProductAvailabilityFilterInput>;
  isTrackingStockEnabled?: Maybe<Scalars['Boolean']>;
  warehouseIds?: Maybe<Array<Scalars['PositiveInt']>>;
  mode?: Maybe<ProductMode>;
};

export type ProductAvailabilityInput = {
  divisionId: Scalars['PositiveInt'];
  isAvailableOnRetailChannel: Scalars['Boolean'];
  isAvailableOnECommerceChannel: Scalars['Boolean'];
};

export type ProductPricingInput = {
  divisionId: Scalars['PositiveInt'];
  /** Defaults to current DateTime if not provided */
  startsAt?: Maybe<Scalars['DateTime']>;
  retailPrice: Scalars['NonNegativeFloat'];
  retailVatRate: Scalars['NonNegativeFloat'];
  eCommercePrice: Scalars['NonNegativeFloat'];
  eCommerceVatRate: Scalars['NonNegativeFloat'];
};

export type UpdateProductTypeInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  abbreviation?: Maybe<Scalars['NonEmptyString']>;
  state?: Maybe<ProductTypeState>;
};

export type CreateProductBarcodeInput = {
  code: Scalars['NonEmptyString'];
  productId: Scalars['PositiveInt'];
  description?: Maybe<Scalars['String']>;
};

export enum WarehouseState {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted'
}

export type Warehouse = {
  __typename?: 'Warehouse';
  id: Scalars['PositiveInt'];
  name: Scalars['NonEmptyString'];
  code?: Maybe<Scalars['NonEmptyString']>;
  state: WarehouseState;
  client: Client;
  division: Division;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: User;
  updatedBy: User;
};

export type CreateWarehouseInput = {
  name: Scalars['NonEmptyString'];
  code?: Maybe<Scalars['NonEmptyString']>;
  divisionId: Scalars['PositiveInt'];
};

export type UpdateWarehouseInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  code?: Maybe<Scalars['NonEmptyString']>;
  state?: Maybe<WarehouseState>;
};

export enum ProductGroupState {
  Active = 'active',
  Inactive = 'inactive'
}

export type CreateProductGroupInput = {
  name: Scalars['NonEmptyString'];
  color: Scalars['HexColorCode'];
  icon?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  internalDescription?: Maybe<Scalars['NonEmptyString']>;
  coverImageUrl?: Maybe<Scalars['NonEmptyString']>;
};

export type ProductGroupAvailability = {
  __typename?: 'ProductGroupAvailability';
  id: Scalars['PositiveInt'];
  division: Division;
  isAvailableOnRetailChannel: Scalars['Boolean'];
  isAvailableOnECommerceChannel: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type ProductGroupProductsFilterInput = {
  divisionId?: Maybe<Scalars['PositiveInt']>;
  state?: Maybe<ProductState>;
  isAvailableOnRetailChannel?: Maybe<Scalars['Boolean']>;
  isAvailableOnECommerceChannel?: Maybe<Scalars['Boolean']>;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  id: Scalars['PositiveInt'];
  name: Scalars['NonEmptyString'];
  state: ProductGroupState;
  color: Scalars['HexColorCode'];
  icon?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  internalDescription?: Maybe<Scalars['NonEmptyString']>;
  coverImageUrl?: Maybe<Scalars['NonEmptyString']>;
  client: Client;
  availability: Array<ProductGroupAvailability>;
  createdBy: User;
  updatedBy: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  products?: Maybe<Array<Product>>;
};


export type ProductGroupProductsArgs = {
  productsFilter?: Maybe<ProductGroupProductsFilterInput>;
};

export type UpdateProductGroupInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  icon?: Maybe<Scalars['NonEmptyString']>;
  state?: Maybe<ProductGroupState>;
  color?: Maybe<Scalars['HexColorCode']>;
  description?: Maybe<Scalars['NonEmptyString']>;
  internalDescription?: Maybe<Scalars['NonEmptyString']>;
};

export type ProductGroupAvailabilityInput = {
  divisionId: Scalars['PositiveInt'];
  isAvailableOnRetailChannel: Scalars['Boolean'];
  isAvailableOnECommerceChannel: Scalars['Boolean'];
};

export enum WarehouseDocumentType {
  Incoming = 'incoming',
  Outgoing = 'outgoing'
}

export enum WarehouseDocumentSource {
  InventoryCheck = 'inventoryCheck',
  Shop = 'shop',
  Stock = 'stock',
  Transfer = 'transfer',
  StockWriteOff = 'stockWriteOff'
}

export enum WarehouseDocumentState {
  Draft = 'draft',
  Issued = 'issued'
}

export type WarehouseDocumentItem = {
  __typename?: 'WarehouseDocumentItem';
  id: Scalars['PositiveInt'];
  note?: Maybe<Scalars['NonEmptyString']>;
  quantity: Scalars['PositiveFloat'];
  unitPriceVatExcluded: Scalars['NonNegativeFloat'];
  unitPriceVatIncluded: Scalars['NonNegativeFloat'];
  unitVat: Scalars['NonNegativeFloat'];
  vatRate: Scalars['NonNegativeFloat'];
  priceVatExcluded: Scalars['NonNegativeFloat'];
  priceVatIncluded: Scalars['NonNegativeFloat'];
  vat: Scalars['NonNegativeFloat'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  warehouseProduct: WarehouseProduct;
  warehouseDocument: WarehouseDocument;
};

export type WarehouseDocument = {
  __typename?: 'WarehouseDocument';
  id: Scalars['PositiveInt'];
  type: WarehouseDocumentType;
  source: WarehouseDocumentSource;
  state: WarehouseDocumentState;
  thirdPartyDocumentId?: Maybe<Scalars['NonEmptyString']>;
  note?: Maybe<Scalars['NonEmptyString']>;
  sumPriceVatIncluded: Scalars['Float'];
  sumPriceVatExcluded: Scalars['Float'];
  sumVat: Scalars['Float'];
  deliveryDate: Scalars['Date'];
  client: Client;
  division: Division;
  warehouse: Warehouse;
  businessPartner?: Maybe<BusinessPartner>;
  createdBy: User;
  createdAt: Scalars['DateTime'];
  updatedBy: User;
  updatedAt: Scalars['DateTime'];
  items: Array<WarehouseDocumentItem>;
  payment?: Maybe<Payment>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  issuedBy?: Maybe<User>;
};

export type CreateWarehouseDocumentInput = {
  warehouseId: Scalars['PositiveInt'];
  type: WarehouseDocumentType;
  source: WarehouseDocumentSource;
  thirdPartyDocumentId?: Maybe<Scalars['NonEmptyString']>;
  note?: Maybe<Scalars['NonEmptyString']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
};

export type UpdateDraftWarehouseDocumentItemInput = {
  note?: Maybe<Scalars['NonEmptyString']>;
  vatRate?: Maybe<Scalars['NonNegativeFloat']>;
  quantity?: Maybe<Scalars['PositiveFloat']>;
  unitPriceVatExcluded?: Maybe<Scalars['NonNegativeFloat']>;
};

export type UpdateDraftWarehouseDocumentItem = {
  id: Scalars['PositiveInt'];
  input: UpdateDraftWarehouseDocumentItemInput;
};

export type UpdateDraftWarehouseDocumentInput = {
  deliveryDate?: Maybe<Scalars['Date']>;
  note?: Maybe<Scalars['NonEmptyString']>;
  thirdPartyDocumentId?: Maybe<Scalars['NonEmptyString']>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
  items?: Maybe<Array<UpdateDraftWarehouseDocumentItem>>;
};

export enum BusinessPartnerState {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted'
}

export enum BusinessPartnerType {
  Supplier = 'supplier',
  Client = 'client',
  Partner = 'partner'
}

export enum BusinessPartnerCategory {
  Retailer = 'retailer',
  Wholesaler = 'wholesaler',
  Distributor = 'distributor',
  Affiliate = 'affiliate',
  Licensing = 'licensing',
  Outsourcing = 'outsourcing',
  Franchise = 'franchise',
  Shareholder = 'shareholder',
  Government = 'government',
  Municipal = 'municipal',
  Local = 'local',
  Regional = 'regional',
  NonProfit = 'nonProfit',
  Community = 'community',
  Consultant = 'consultant',
  Advisor = 'advisor',
  Regulatory = 'regulatory',
  MovieDistributor = 'movie_distributor',
  Agency = 'agency'
}

export type BusinessPartnerBillingAddress = {
  __typename?: 'BusinessPartnerBillingAddress';
  street?: Maybe<Scalars['NonEmptyString']>;
  town?: Maybe<Scalars['NonEmptyString']>;
  postalCode?: Maybe<Scalars['NonEmptyString']>;
  country?: Maybe<ExistingCountryCode>;
};

export type BusinessPartnerDeliveryAddress = {
  __typename?: 'BusinessPartnerDeliveryAddress';
  addressee?: Maybe<Scalars['NonEmptyString']>;
  street?: Maybe<Scalars['NonEmptyString']>;
  town?: Maybe<Scalars['NonEmptyString']>;
  postalCode?: Maybe<Scalars['NonEmptyString']>;
  country?: Maybe<ExistingCountryCode>;
};

export type BusinessPartnerBillingAddressInput = {
  street?: Maybe<Scalars['NonEmptyString']>;
  town?: Maybe<Scalars['NonEmptyString']>;
  postalCode?: Maybe<Scalars['NonEmptyString']>;
  country?: Maybe<ExistingCountryCode>;
};

export type BusinessPartnerDeliveryAddressInput = {
  addressee?: Maybe<Scalars['NonEmptyString']>;
  street?: Maybe<Scalars['NonEmptyString']>;
  town?: Maybe<Scalars['NonEmptyString']>;
  postalCode?: Maybe<Scalars['NonEmptyString']>;
  country?: Maybe<ExistingCountryCode>;
};

export type CreateBusinessPartnerInput = {
  companyName: Scalars['NonEmptyString'];
  types: Array<BusinessPartnerType>;
  categories?: Maybe<Array<BusinessPartnerCategory>>;
  companyIdNumber?: Maybe<Scalars['NonEmptyString']>;
  taxId?: Maybe<Scalars['NonEmptyString']>;
  vatId?: Maybe<Scalars['NonEmptyString']>;
  VATRegistered?: Maybe<ClientVatRegistered>;
  customId?: Maybe<Scalars['NonEmptyString']>;
  phone?: Maybe<Scalars['NonEmptyString']>;
  email?: Maybe<Scalars['NonEmptyString']>;
  website?: Maybe<Scalars['NonEmptyString']>;
  note?: Maybe<Scalars['NonEmptyString']>;
  internalNote?: Maybe<Scalars['NonEmptyString']>;
  billingAddress?: Maybe<BusinessPartnerBillingAddressInput>;
  deliveryAddress?: Maybe<BusinessPartnerDeliveryAddressInput>;
};

export type BusinessPartner = {
  __typename?: 'BusinessPartner';
  id: Scalars['PositiveInt'];
  client: Client;
  companyName: Scalars['NonEmptyString'];
  state: BusinessPartnerState;
  types: Array<BusinessPartnerType>;
  categories?: Maybe<Array<BusinessPartnerCategory>>;
  companyIdNumber?: Maybe<Scalars['NonEmptyString']>;
  taxId?: Maybe<Scalars['NonEmptyString']>;
  vatId?: Maybe<Scalars['NonEmptyString']>;
  VATRegistered?: Maybe<ClientVatRegistered>;
  customId?: Maybe<Scalars['NonEmptyString']>;
  phone?: Maybe<Scalars['NonEmptyString']>;
  email?: Maybe<Scalars['NonEmptyString']>;
  website?: Maybe<Scalars['NonEmptyString']>;
  note?: Maybe<Scalars['NonEmptyString']>;
  internalNote?: Maybe<Scalars['NonEmptyString']>;
  billingAddress?: Maybe<BusinessPartnerBillingAddress>;
  deliveryAddress?: Maybe<BusinessPartnerDeliveryAddress>;
  createdBy: User;
  createdAt: Scalars['DateTime'];
  updatedBy: User;
  updatedAt: Scalars['DateTime'];
};

export type PaginatedBusinessPartnersSuccessResult = {
  __typename?: 'PaginatedBusinessPartnersSuccessResult';
  items: Array<BusinessPartner>;
  pagination: Pagination;
};

export type BusinessPartnersFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  companyName?: Maybe<Scalars['NonEmptyString']>;
  states?: Maybe<Array<BusinessPartnerState>>;
  type?: Maybe<BusinessPartnerType>;
  categories?: Maybe<Array<BusinessPartnerCategory>>;
  companyIdNumber?: Maybe<Scalars['NonEmptyString']>;
  id?: Maybe<Scalars['PositiveInt']>;
};

export type UpdateBusinessPartnerInput = {
  companyName?: Maybe<Scalars['NonEmptyString']>;
  state?: Maybe<BusinessPartnerState>;
  types?: Maybe<Array<BusinessPartnerType>>;
  categories?: Maybe<Array<BusinessPartnerCategory>>;
  companyIdNumber?: Maybe<Scalars['NonEmptyString']>;
  taxId?: Maybe<Scalars['NonEmptyString']>;
  vatId?: Maybe<Scalars['NonEmptyString']>;
  VATRegistered?: Maybe<ClientVatRegistered>;
  customId?: Maybe<Scalars['NonEmptyString']>;
  phone?: Maybe<Scalars['NonEmptyString']>;
  email?: Maybe<Scalars['NonEmptyString']>;
  website?: Maybe<Scalars['NonEmptyString']>;
  note?: Maybe<Scalars['NonEmptyString']>;
  internalNote?: Maybe<Scalars['NonEmptyString']>;
  billingAddress?: Maybe<BusinessPartnerBillingAddressInput>;
  deliveryAddress?: Maybe<BusinessPartnerDeliveryAddressInput>;
};

export type WarehouseProduct = {
  __typename?: 'WarehouseProduct';
  id: Scalars['PositiveInt'];
  client: Client;
  division: Division;
  warehouse: Warehouse;
  product: Product;
  isNegativeStockEnabled: Scalars['Boolean'];
  stock: Scalars['Float'];
  minStockLevel?: Maybe<Scalars['PositiveFloat']>;
  optimalStockLevel?: Maybe<Scalars['PositiveFloat']>;
  averagePriceVatIncluded: Scalars['NonNegativeFloat'];
  averagePriceVatExcluded: Scalars['NonNegativeFloat'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type CreateWarehouseProductInput = {
  productId: Scalars['PositiveInt'];
  warehouseId: Scalars['PositiveInt'];
  isNegativeStockEnabled?: Maybe<Scalars['Boolean']>;
  minStockLevel?: Maybe<Scalars['PositiveFloat']>;
  optimalStockLevel?: Maybe<Scalars['PositiveFloat']>;
};

export type AddItemToWarehouseDocumentInput = {
  warehouseProductId: Scalars['PositiveInt'];
  warehouseDocumentId: Scalars['PositiveInt'];
  note?: Maybe<Scalars['NonEmptyString']>;
  vatRate: Scalars['NonNegativeFloat'];
  quantity: Scalars['PositiveFloat'];
  unitPriceVatExcluded: Scalars['NonNegativeFloat'];
};

export type UpdateWarehouseProductInput = {
  minStockLevel?: Maybe<Scalars['PositiveFloat']>;
  optimalStockLevel?: Maybe<Scalars['PositiveFloat']>;
  isNegativeStockEnabled?: Maybe<Scalars['Boolean']>;
};

export enum InventoryCheckStockLevel {
  Zero = 'zero',
  Negative = 'negative',
  Positive = 'positive'
}

export enum InventoryCheckType {
  Full = 'full',
  Controlling = 'controlling'
}

export type CreateInventoryCheckInput = {
  type: InventoryCheckType;
  stockLevels: Array<InventoryCheckStockLevel>;
  warehouseId: Scalars['PositiveInt'];
  note?: Maybe<Scalars['NonEmptyString']>;
  /** Defaults to current DateTime if not provided */
  inventoryCheckedTill?: Maybe<Scalars['DateTime']>;
  productIds?: Maybe<Array<Scalars['PositiveInt']>>;
  productGroupIds?: Maybe<Array<Scalars['PositiveInt']>>;
  productTypeIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export enum InventoryCheckState {
  Draft = 'draft',
  Completed = 'completed'
}

export type InventoryCheckProduct = {
  __typename?: 'InventoryCheckProduct';
  id: Scalars['PositiveInt'];
  note?: Maybe<Scalars['NonEmptyString']>;
  expectedStock: Scalars['Float'];
  realStock?: Maybe<Scalars['Float']>;
  stockVariance: Scalars['Float'];
  averagePriceVatIncluded: Scalars['NonNegativeFloat'];
  averagePriceVatExcluded: Scalars['NonNegativeFloat'];
  warehouseProduct: WarehouseProduct;
  inventoryCheck: InventoryCheck;
  createdBy: User;
  createdAt: Scalars['DateTime'];
  updatedBy: User;
  updatedAt: Scalars['DateTime'];
};

export type InventoryCheck = {
  __typename?: 'InventoryCheck';
  id: Scalars['PositiveInt'];
  type: InventoryCheckType;
  state: InventoryCheckState;
  note?: Maybe<Scalars['NonEmptyString']>;
  inventoryVarianceVatIncluded: Scalars['Float'];
  inventoryVarianceVatExcluded: Scalars['Float'];
  client: Client;
  division: Division;
  warehouse: Warehouse;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedBy?: Maybe<User>;
  inventoryCheckedTill: Scalars['DateTime'];
  incomingWarehouseDocument?: Maybe<WarehouseDocument>;
  outgoingWarehouseDocument?: Maybe<WarehouseDocument>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
  inventoryCheckProducts: Array<InventoryCheckProduct>;
};

export type UpdateInventoryCheckProductInput = {
  inventoryCheckId: Scalars['PositiveInt'];
  inventoryCheckProductId: Scalars['PositiveInt'];
  note?: Maybe<Scalars['NonEmptyString']>;
  realStock?: Maybe<Scalars['Float']>;
};

export type UpdateInventoryCheckInput = {
  note?: Maybe<Scalars['NonEmptyString']>;
  type?: Maybe<InventoryCheckType>;
};

export type PaginatedProductsSuccessResult = {
  __typename?: 'PaginatedProductsSuccessResult';
  items: Array<Product>;
  pagination: Pagination;
};

export type WarehousesFilterInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  states?: Maybe<Array<WarehouseState>>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type PaginatedWarehousesSuccessResult = {
  __typename?: 'PaginatedWarehousesSuccessResult';
  items: Array<Warehouse>;
  pagination: Pagination;
};

export type ProductGroupsFilterInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  state?: Maybe<ProductGroupState>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  isAvailableOnRetailChannel?: Maybe<Scalars['Boolean']>;
  isAvailableOnECommerceChannel?: Maybe<Scalars['Boolean']>;
};

export type WarehouseDocumentsFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  businessPartnerId?: Maybe<Scalars['PositiveInt']>;
  warehouseDocumentId?: Maybe<Scalars['PositiveInt']>;
  type?: Maybe<WarehouseDocumentType>;
  sources?: Maybe<Array<WarehouseDocumentSource>>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  warehouseIds?: Maybe<Array<Scalars['PositiveInt']>>;
  state?: Maybe<WarehouseDocumentState>;
  thirdPartyDocumentId?: Maybe<Scalars['NonEmptyString']>;
  note?: Maybe<Scalars['NonEmptyString']>;
  deliveryDateFrom?: Maybe<Scalars['Date']>;
  deliveryDateTo?: Maybe<Scalars['Date']>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  createdByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
  updatedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  paymentIds?: Maybe<Array<Scalars['PositiveInt']>>;
  issuedAtFrom?: Maybe<Scalars['DateTime']>;
  issuedAtTo?: Maybe<Scalars['DateTime']>;
  issuedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type PaginatedWarehouseDocumentsSuccessResult = {
  __typename?: 'PaginatedWarehouseDocumentsSuccessResult';
  items: Array<WarehouseDocument>;
  pagination: Pagination;
};

export type WarehouseProductsFilterInput = {
  productName?: Maybe<Scalars['NonEmptyString']>;
  warehouseIds?: Maybe<Array<Scalars['PositiveInt']>>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  isNegativeStockEnabled?: Maybe<Scalars['Boolean']>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  createdByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
  updatedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  isStockLevelBelowMinStockLevel?: Maybe<Scalars['Boolean']>;
  isStockLevelBelowOptimalStockLevel?: Maybe<Scalars['Boolean']>;
  productStates?: Maybe<Array<ProductState>>;
  productInternalCode?: Maybe<Scalars['NonEmptyString']>;
  productTypeIds?: Maybe<Array<Scalars['PositiveInt']>>;
  productBarcode?: Maybe<Scalars['NonEmptyString']>;
  productGroupIds?: Maybe<Array<Scalars['PositiveInt']>>;
  hasText?: Maybe<Scalars['NonEmptyString']>;
  isStockLevelOutOfStock?: Maybe<Scalars['Boolean']>;
  isStockLevelNegative?: Maybe<Scalars['Boolean']>;
};

export type PaginatedWarehouseProductsSuccessResult = {
  __typename?: 'PaginatedWarehouseProductsSuccessResult';
  items: Array<WarehouseProduct>;
  pagination: Pagination;
};

export type WarehouseDocumentItemsFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  warehouseProductIds?: Maybe<Array<Scalars['PositiveInt']>>;
  productIds?: Maybe<Array<Scalars['PositiveInt']>>;
  productStates?: Maybe<Array<ProductState>>;
  productTypeIds?: Maybe<Array<Scalars['PositiveInt']>>;
  warehouseDocumentDivisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  warehouseDocumentWarehouseIds?: Maybe<Array<Scalars['PositiveInt']>>;
  warehouseDocumentBusinessPartnerIds?: Maybe<Array<Scalars['PositiveInt']>>;
  warehouseDocumentDeliveryDateFrom?: Maybe<Scalars['Date']>;
  warehouseDocumentDeliveryDateTo?: Maybe<Scalars['Date']>;
  warehouseDocumentTypes?: Maybe<Array<WarehouseDocumentType>>;
  warehouseDocumentSources?: Maybe<Array<WarehouseDocumentSource>>;
  warehouseDocumentStates?: Maybe<Array<WarehouseDocumentState>>;
  warehouseDocumentCreatedAtFrom?: Maybe<Scalars['DateTime']>;
  warehouseDocumentCreatedAtTo?: Maybe<Scalars['DateTime']>;
  warehouseDocumentUpdatedAtFrom?: Maybe<Scalars['DateTime']>;
  warehouseDocumentUpdatedAtTo?: Maybe<Scalars['DateTime']>;
};

export type PaginatedWarehouseDocumentItemsSuccessResult = {
  __typename?: 'PaginatedWarehouseDocumentItemsSuccessResult';
  items: Array<WarehouseDocumentItem>;
  pagination: Pagination;
};

export type InventoryChecksFilterInput = {
  hasText?: Maybe<Scalars['NonEmptyString']>;
  types?: Maybe<Array<InventoryCheckType>>;
  states?: Maybe<Array<InventoryCheckState>>;
  note?: Maybe<Scalars['NonEmptyString']>;
  divisionIds?: Maybe<Array<Scalars['PositiveInt']>>;
  warehouseIds?: Maybe<Array<Scalars['PositiveInt']>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  createdByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
  updatedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
  inventoryCheckedTillFrom?: Maybe<Scalars['DateTime']>;
  inventoryCheckedTillTo?: Maybe<Scalars['DateTime']>;
  completedAtFrom?: Maybe<Scalars['DateTime']>;
  completedAtTo?: Maybe<Scalars['DateTime']>;
  completedByIds?: Maybe<Array<Scalars['PositiveInt']>>;
};

export type PaginatedInventoryChecksSuccessResult = {
  __typename?: 'PaginatedInventoryChecksSuccessResult';
  items: Array<InventoryCheck>;
  pagination: Pagination;
};

export enum VoucherCampaignState {
  Active = 'active',
  Inactive = 'inactive',
  Invalid = 'invalid'
}

export enum VoucherState {
  Active = 'active',
  Inactive = 'inactive',
  Invalid = 'invalid'
}

export enum VoucherCampaignPeriodType {
  Day = 'day',
  Hour = 'hour'
}

export enum VoucherTransactionIntentState {
  Pending = 'pending',
  Failed = 'failed',
  Expired = 'expired',
  Canceled = 'canceled',
  Succeeded = 'succeeded'
}

export enum VoucherTransactionIntentType {
  Credit = 'credit',
  Debit = 'debit'
}

export enum VoucherTransactionIntentReason {
  Charge = 'charge',
  Refund = 'refund',
  TopUp = 'topUp',
  Reward = 'reward',
  Activation = 'activation',
  Expiration = 'expiration',
  Other = 'other',
  Import = 'import',
  Export = 'export'
}

export type VoucherCampaignDuration = {
  __typename?: 'VoucherCampaignDuration';
  value: Scalars['PositiveInt'];
  period: VoucherCampaignPeriodType;
};

export type VoucherCampaignDurationInput = {
  value: Scalars['PositiveInt'];
  period: VoucherCampaignPeriodType;
};

export type VoucherCampaign = {
  __typename?: 'VoucherCampaign';
  id: Scalars['PositiveInt'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  state: VoucherCampaignState;
  redemptionLimit?: Maybe<Scalars['NonNegativeInt']>;
  rechargeLimit?: Maybe<Scalars['NonNegativeInt']>;
  initialValidity?: Maybe<Scalars['String']>;
  initialBalance?: Maybe<Scalars['NonNegativeFloat']>;
  canCreateNewVouchers: Scalars['Boolean'];
  retailPaymentMethodId?: Maybe<Scalars['PositiveInt']>;
  ecommercePaymentMethodId?: Maybe<Scalars['PositiveInt']>;
  clientId: Scalars['PositiveInt'];
  client: Client;
  retailPaymentMethod?: Maybe<PaymentMethod>;
  ecommercePaymentMethod?: Maybe<PaymentMethod>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type CreateVoucherCampaignInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  redemptionLimit?: Maybe<Scalars['NonNegativeInt']>;
  rechargeLimit?: Maybe<Scalars['NonNegativeInt']>;
  initialValidity?: Maybe<VoucherCampaignDurationInput>;
  initialBalance?: Maybe<Scalars['NonNegativeFloat']>;
  retailPaymentMethodId?: Maybe<Scalars['PositiveInt']>;
  ecommercePaymentMethodId?: Maybe<Scalars['PositiveInt']>;
};

export type VouchersFilterInput = {
  state?: Maybe<Array<VoucherState>>;
  code?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['PositiveInt']>;
  activationDateFrom?: Maybe<Scalars['DateTime']>;
  activationDateTo?: Maybe<Scalars['DateTime']>;
  activatedAtFrom?: Maybe<Scalars['DateTime']>;
  activatedAtTo?: Maybe<Scalars['DateTime']>;
  expirationDateFrom?: Maybe<Scalars['DateTime']>;
  expirationDateTo?: Maybe<Scalars['DateTime']>;
  expiredAtFrom?: Maybe<Scalars['DateTime']>;
  expiredAtTo?: Maybe<Scalars['DateTime']>;
};

export type VoucherCampaignPaymentMethodFilterInput = {
  hasPaymentMethod?: Maybe<Scalars['Boolean']>;
  isAvailableForRefunds?: Maybe<Scalars['Boolean']>;
  hasDenomination?: Maybe<Scalars['Boolean']>;
};

export type VoucherCampaignsFilterInput = {
  state?: Maybe<Array<VoucherCampaignState>>;
  canCreateNewVouchers?: Maybe<Scalars['Boolean']>;
  hasInitialBalance?: Maybe<Scalars['Boolean']>;
  voucherCampaignRetailPaymentMethodFilter?: Maybe<VoucherCampaignPaymentMethodFilterInput>;
  voucherCampaignEcommercePaymentMethodFilter?: Maybe<VoucherCampaignPaymentMethodFilterInput>;
};

export type PaginatedVoucherCampaignsSuccessResult = {
  __typename?: 'PaginatedVoucherCampaignsSuccessResult';
  items: Array<VoucherCampaign>;
  pagination: Pagination;
};

export type VouchersTotals = {
  __typename?: 'VouchersTotals';
  totalBalance: Scalars['NonNegativeFloat'];
  totalValue: Scalars['NonNegativeFloat'];
  activeVouchersCount: Scalars['NonNegativeInt'];
  inactiveVouchersCount: Scalars['NonNegativeInt'];
  invalidVouchersCount: Scalars['NonNegativeInt'];
  totalRedemptionsCount: Scalars['NonNegativeInt'];
  totalTopUpsCount: Scalars['NonNegativeInt'];
};

export type PaginatedVouchersSuccessResult = {
  __typename?: 'PaginatedVouchersSuccessResult';
  items: Array<Voucher>;
  pagination: Pagination;
  totals: VouchersTotals;
};

export type VoucherTransactionIntentsFilterInput = {
  voucherId?: Maybe<Scalars['PositiveInt']>;
  campaignId?: Maybe<Scalars['PositiveInt']>;
  voucherCode?: Maybe<Scalars['NonEmptyString']>;
  type?: Maybe<VoucherTransactionIntentType>;
  states?: Maybe<Array<VoucherTransactionIntentState>>;
  reasons?: Maybe<Array<VoucherTransactionIntentReason>>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  createdByUserIds?: Maybe<Array<Scalars['PositiveInt']>>;
  channel?: Maybe<SellingChannel>;
};

export type Voucher = {
  __typename?: 'Voucher';
  id: Scalars['PositiveInt'];
  client: Client;
  code: Scalars['String'];
  state: VoucherState;
  value: Scalars['NonNegativeFloat'];
  balance: Scalars['NonNegativeFloat'];
  campaign: VoucherCampaign;
  pinCode: Scalars['String'];
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  activatedAt?: Maybe<Scalars['DateTime']>;
  activatedBy?: Maybe<User>;
  redemptionsCount: Scalars['NonNegativeInt'];
  topUpsCount: Scalars['NonNegativeInt'];
  transactionIntents: Array<VoucherTransactionIntent>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};


export type VoucherTransactionIntentsArgs = {
  filter?: Maybe<VoucherTransactionIntentFilterInput>;
};

export type VoucherTransactionIntent = {
  __typename?: 'VoucherTransactionIntent';
  id: Scalars['PositiveInt'];
  note?: Maybe<Scalars['NonEmptyString']>;
  voucher: Voucher;
  voucherId: Scalars['PositiveInt'];
  paymentIntentId?: Maybe<Scalars['PositiveInt']>;
  refundIntentId?: Maybe<Scalars['PositiveInt']>;
  paymentIntent?: Maybe<PaymentIntent>;
  refundIntent?: Maybe<RefundIntent>;
  state: VoucherTransactionIntentState;
  amount: Scalars['NonNegativeFloat'];
  type: VoucherTransactionIntentType;
  reason: VoucherTransactionIntentReason;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type VoucherTransactionIntentsTotals = {
  __typename?: 'VoucherTransactionIntentsTotals';
  amount: Scalars['Float'];
};

export type PaginatedVoucherTransactionIntentsSuccessResult = {
  __typename?: 'PaginatedVoucherTransactionIntentsSuccessResult';
  items: Array<VoucherTransactionIntent>;
  totals: VoucherTransactionIntentsTotals;
  pagination: Pagination;
};

export type VoucherTransactionIntentFilterInput = {
  state?: Maybe<VoucherTransactionIntentState>;
  type?: Maybe<VoucherTransactionIntentType>;
  reason?: Maybe<VoucherTransactionIntentReason>;
};

export type CreateVouchersInput = {
  /** Currently we support creation of maximum 500 vouchers */
  count: Scalars['PositiveInt'];
  campaignId: Scalars['PositiveInt'];
  balance?: Maybe<Scalars['NonNegativeFloat']>;
  activationDate?: Maybe<Scalars['DateTime']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type CreditVoucherInput = {
  amount: Scalars['PositiveFloat'];
  /** Only "topUp", "reward", "import" and "other" reasons are valid for creditVoucher mutation */
  reason: VoucherTransactionIntentReason;
  note?: Maybe<Scalars['NonEmptyString']>;
};

export enum CustomerState {
  Invited = 'invited',
  Active = 'active',
  Disabled = 'disabled',
  Anonymized = 'anonymized'
}

export type LoyaltyScore = {
  __typename?: 'LoyaltyScore';
  totalPurchaseAmount: Scalars['NonNegativeFloat'];
  soldCartsCount: Scalars['NonNegativeInt'];
  reservedCartsCount: Scalars['NonNegativeInt'];
  salesCount: Scalars['NonNegativeInt'];
  reservationsCount: Scalars['NonNegativeInt'];
  totalTicketsCount: Scalars['NonNegativeInt'];
  paidTicketsCount: Scalars['NonNegativeInt'];
  freeTicketsCount: Scalars['NonNegativeInt'];
  updatedAt: Scalars['DateTime'];
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['PositiveInt'];
  clientId: Scalars['PositiveInt'];
  username: Scalars['NonEmptyString'];
  loyaltyId: Scalars['NonEmptyString'];
  state: CustomerState;
  leadId: Scalars['PositiveInt'];
  privacyPolicyConsentGrantedAt?: Maybe<Scalars['DateTime']>;
  lead: Lead;
  client: Client;
  sales: Array<Sale>;
  reservations: Array<Reservation>;
  customerGroups: Array<CustomerGroup>;
  createdBy: User;
  updatedBy: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  loyaltyScore: LoyaltyScore;
  loyaltyLevelExpirationDate?: Maybe<Scalars['Date']>;
};


export type CustomerReservationsArgs = {
  states?: Maybe<Array<ReservationState>>;
};

export type CustomerInput = {
  username: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
  clientId: Scalars['PositiveInt'];
  privacyPolicyConsentGranted?: Maybe<Scalars['Boolean']>;
  leadData?: Maybe<LeadDataInput>;
};

export type UpdateSignedInCustomerInput = {
  username?: Maybe<Scalars['NonEmptyString']>;
  privacyPolicyConsentGranted?: Maybe<Scalars['Boolean']>;
  leadData?: Maybe<LeadDataInput>;
};

export enum LoyaltyLevelStatus {
  Active = 'active',
  Expired = 'expired',
  Unspecified = 'unspecified'
}

export type CustomersFilterInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyIdNumber?: Maybe<Scalars['String']>;
  /** Searches by name, email and companyName */
  hasText?: Maybe<Scalars['String']>;
  states?: Maybe<Array<CustomerState>>;
  privacyPolicyConsentGranted?: Maybe<Scalars['Boolean']>;
  privacyPolicyConsentGrantedTo?: Maybe<Scalars['DateTime']>;
  privacyPolicyConsentGrantedFrom?: Maybe<Scalars['DateTime']>;
  clientId?: Maybe<Scalars['PositiveInt']>;
  loyaltyId?: Maybe<Scalars['NonEmptyString']>;
  username?: Maybe<Scalars['NonEmptyString']>;
  loyaltyScoreUpdatedAtFrom?: Maybe<Scalars['DateTime']>;
  loyaltyScoreUpdatedAtTo?: Maybe<Scalars['DateTime']>;
  loyaltyScoreTotalPurchaseAmountFrom?: Maybe<Scalars['NonNegativeFloat']>;
  loyaltyScoreTotalPurchaseAmountTo?: Maybe<Scalars['NonNegativeFloat']>;
  loyaltyScorePaidTicketsCountFrom?: Maybe<Scalars['NonNegativeInt']>;
  loyaltyScorePaidTicketsCountTo?: Maybe<Scalars['NonNegativeInt']>;
  customerGroupIds?: Maybe<Array<Scalars['PositiveInt']>>;
  loyaltyLevelExpirationDateFrom?: Maybe<Scalars['DateTime']>;
  loyaltyLevelExpirationDateTo?: Maybe<Scalars['DateTime']>;
  loyaltyLevelStatus?: Maybe<LoyaltyLevelStatus>;
  createdAtFrom?: Maybe<Scalars['DateTime']>;
  createdAtTo?: Maybe<Scalars['DateTime']>;
  updatedAtFrom?: Maybe<Scalars['DateTime']>;
  updatedAtTo?: Maybe<Scalars['DateTime']>;
};

export type PaginatedCustomersSuccessResult = {
  __typename?: 'PaginatedCustomersSuccessResult';
  items: Array<Customer>;
  pagination: Pagination;
};

export type CreateCustomerGroupInput = {
  name: Scalars['NonEmptyString'];
  description?: Maybe<Scalars['String']>;
};

export type UpdateCustomerGroupInput = {
  name?: Maybe<Scalars['NonEmptyString']>;
  description?: Maybe<Scalars['String']>;
};

export type CustomerGroup = {
  __typename?: 'CustomerGroup';
  id: Scalars['PositiveInt'];
  name: Scalars['NonEmptyString'];
  description?: Maybe<Scalars['String']>;
  clientId: Scalars['PositiveInt'];
  client: Client;
  customers: Array<Customer>;
};

export type UpdateCustomerInput = {
  username?: Maybe<Scalars['NonEmptyString']>;
  isPrivacyPolicyConsentGranted?: Maybe<Scalars['Boolean']>;
  leadData?: Maybe<LeadDataInput>;
  customerGroupIds?: Maybe<Array<Scalars['PositiveInt']>>;
  loyaltyLevelExpirationDate?: Maybe<Scalars['Date']>;
};

export type CreateCustomerInput = {
  /** Minimal required username length is 6 characters. */
  username: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
  loyaltyId?: Maybe<Scalars['NonEmptyString']>;
  isPrivacyPolicyConsentGranted?: Maybe<Scalars['Boolean']>;
  leadData?: Maybe<LeadDataInput>;
  loyaltyLevelExpirationDate?: Maybe<Scalars['Date']>;
};

export enum DataGridTableColumnAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum DataExportStatisticsType {
  Payments = 'payments',
  PaymentsExtended = 'paymentsExtended'
}

export enum DataGridTableColumnType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  DateTime = 'dateTime',
  Boolean = 'boolean'
}

export type DataExportStatisticsFilterInput = {
  paymentStatisticsFilter?: Maybe<PaymentsFilterInput>;
  paymentReportsStatisticsFilter?: Maybe<PaymentReportsFilterInput>;
};

export type DataGridTableColumn = {
  align?: Maybe<DataGridTableColumnAlign>;
  headerName?: Maybe<Scalars['String']>;
  field: Scalars['NonEmptyString'];
  type: DataGridTableColumnType;
  headerAlign?: Maybe<DataGridTableColumnAlign>;
  groupParents?: Maybe<Array<Scalars['NonEmptyString']>>;
  hasSummary?: Maybe<Scalars['Boolean']>;
  merge?: Maybe<Scalars['Boolean']>;
  numberOfDecimalPlaces?: Maybe<Scalars['PositiveInt']>;
};

export type DataGridTableColumnSortModel = {
  field: Scalars['NonEmptyString'];
  sort: SortDirection;
};

export type DataGridTableData = {
  columns: Array<DataGridTableColumn>;
  sortModel?: Maybe<Array<DataGridTableColumnSortModel>>;
  mergeByColumn?: Maybe<Scalars['NonEmptyString']>;
};

export type LeadUserFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'isLead'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'code'>
  )> }
);

export type LoginLeadMutationVariables = Exact<{ [key: string]: never; }>;


export type LoginLeadMutation = (
  { __typename?: 'Mutation' }
  & { loginLead: (
    { __typename?: 'LoginLeadResponse' }
    & Pick<LoginLeadResponse, 'eSid'>
    & { lead: (
      { __typename?: 'User' }
      & LeadUserFieldsFragment
    ) }
  ) }
);

export type GetLeadQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLeadQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & LeadUserFieldsFragment
  )> }
);

export type ReserveMutationVariables = Exact<{
  input?: Maybe<LeadDataInput>;
  cartId: Scalars['Int'];
  sendEmail?: Maybe<Scalars['Boolean']>;
  localeCode?: Maybe<ECommerceLocaleCode>;
}>;


export type ReserveMutation = (
  { __typename?: 'Mutation' }
  & { reserve: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'uuid' | 'hash'>
    & BasicCartFieldsFragment
  ) }
);

export type CheckoutMutationVariables = Exact<{
  input?: Maybe<LeadDataInput>;
  paymentIntentInputs: Array<PaymentIntentInput>;
  cartId: Scalars['Int'];
  sendEmail?: Maybe<Scalars['Boolean']>;
  localeCode?: Maybe<ECommerceLocaleCode>;
}>;


export type CheckoutMutation = (
  { __typename?: 'Mutation' }
  & { checkout: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'eCommercePaymentRedirectUrl' | 'uuid' | 'hash'>
    & BasicCartFieldsFragment
  ) }
);

export type AddDiscountToCartTicketItemsMutationVariables = Exact<{
  cartId: Scalars['Int'];
  itemIds: Array<Scalars['Int']>;
  discountId: Scalars['Int'];
  discountCodeId?: Maybe<Scalars['Int']>;
  customerLoyaltyId?: Maybe<Scalars['NonEmptyString']>;
}>;


export type AddDiscountToCartTicketItemsMutation = (
  { __typename?: 'Mutation' }
  & { addDiscountToCartTicketItems: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type RemoveDiscountFromItemMutationVariables = Exact<{
  cartId: Scalars['Int'];
  itemId: Scalars['Int'];
  discountId: Scalars['Int'];
}>;


export type RemoveDiscountFromItemMutation = (
  { __typename?: 'Mutation' }
  & { removeDiscountFromItem: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type GetEnabledDiscountsQueryVariables = Exact<{
  eventId: Scalars['Int'];
  discountsFilter?: Maybe<EnabledDiscountsFilterInput>;
}>;


export type GetEnabledDiscountsQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & { enabledDiscounts: Array<(
      { __typename?: 'Discount' }
      & EnabledDiscountFieldsFragment
    )> }
  ) }
);

export type TourTimeSlotEnabledDiscountsQueryVariables = Exact<{
  id: Scalars['PositiveInt'];
  discountsFilter?: Maybe<EnabledDiscountsFilterInput>;
}>;


export type TourTimeSlotEnabledDiscountsQuery = (
  { __typename?: 'Query' }
  & { tourTimeSlot: (
    { __typename?: 'TourTimeSlot' }
    & { enabledDiscounts: Array<(
      { __typename?: 'Discount' }
      & EnabledDiscountFieldsFragment
    )> }
  ) }
);

export type CheckDiscountCodeForCartQueryVariables = Exact<{
  cartId: Scalars['Int'];
  eventId: Scalars['Int'];
  codeName: Scalars['String'];
}>;


export type CheckDiscountCodeForCartQuery = (
  { __typename?: 'Query' }
  & { checkDiscountCodeForCart: (
    { __typename?: 'DiscountCode' }
    & Pick<DiscountCode, 'id' | 'name' | 'state' | 'description' | 'usageLimit' | 'usageCount' | 'usageLimitPerOrder' | 'activationDate' | 'expirationDate' | 'remainingRedemptionCount'>
    & { discount: (
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'name' | 'value' | 'type' | 'maxUsageLimitPerOrder'>
    ) }
  ) }
);

export type VoucherFieldsFragment = (
  { __typename?: 'Voucher' }
  & Pick<Voucher, 'id' | 'balance' | 'state' | 'activatedAt' | 'code' | 'expirationDate' | 'redemptionsCount'>
  & { campaign: (
    { __typename?: 'VoucherCampaign' }
    & Pick<VoucherCampaign, 'id' | 'name' | 'state' | 'redemptionLimit'>
    & { ecommercePaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'id' | 'state'>
    )> }
  ) }
);

export type GetVoucherByCodeQueryVariables = Exact<{
  code: Scalars['NonEmptyString'];
  pinCode: Scalars['NonEmptyString'];
  cartId: Scalars['PositiveInt'];
}>;


export type GetVoucherByCodeQuery = (
  { __typename?: 'Query' }
  & { voucherByCodeOnECommerceChannel: (
    { __typename?: 'Voucher' }
    & VoucherFieldsFragment
  ) }
);

export type RemoveTourItemFromCartMutationVariables = Exact<{
  itemId: Scalars['PositiveInt'];
  cartId: Scalars['PositiveInt'];
}>;


export type RemoveTourItemFromCartMutation = (
  { __typename?: 'Mutation' }
  & { removeTourItemFromCart: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type AddDiscountToTourItemsMutationVariables = Exact<{
  cartId: Scalars['PositiveInt'];
  itemIds: Array<Scalars['PositiveInt']>;
  discountId: Scalars['PositiveInt'];
  discountCodeId?: Maybe<Scalars['PositiveInt']>;
  customerLoyaltyId?: Maybe<Scalars['NonEmptyString']>;
}>;


export type AddDiscountToTourItemsMutation = (
  { __typename?: 'Mutation' }
  & { addDiscountToTourItems: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type CheckDiscountCodeForTourItemsQueryVariables = Exact<{
  cartId: Scalars['PositiveInt'];
  tourTimeSlotId: Scalars['PositiveInt'];
  discountCodeName: Scalars['NonEmptyString'];
}>;


export type CheckDiscountCodeForTourItemsQuery = (
  { __typename?: 'Query' }
  & { checkDiscountCodeForTourItems: (
    { __typename?: 'DiscountCode' }
    & Pick<DiscountCode, 'id' | 'name' | 'state' | 'description' | 'usageLimit' | 'usageCount' | 'usageLimitPerOrder' | 'activationDate' | 'expirationDate' | 'remainingRedemptionCount'>
    & { discount: (
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'name' | 'value' | 'type' | 'maxUsageLimitPerOrder'>
    ) }
  ) }
);

export type CartSummaryTicketItemFieldsFragment = (
  { __typename?: 'TicketItem' }
  & Pick<TicketItem, 'id' | 'price' | 'priceBeforeDiscount'>
  & { appliedDiscounts: Array<(
    { __typename?: 'AppliedDiscount' }
    & { discount: (
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'name'>
    ) }
  )>, eventSeat: (
    { __typename?: 'EventSeat' }
    & Pick<EventSeat, 'id' | 'uuid' | 'label' | 'row' | 'section' | 'floor' | 'type'>
  ) }
);

export type GetCartSummaryQueryVariables = Exact<{
  uuid: Scalars['String'];
  hash: Scalars['String'];
}>;


export type GetCartSummaryQuery = (
  { __typename?: 'Query' }
  & { cartSummary: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'id' | 'state' | 'price' | 'uuid' | 'hash'>
    & { sales?: Maybe<Array<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'id' | 'price'>
      & { event?: Maybe<(
        { __typename?: 'Event' }
        & EventDetailFieldsFragment
      )>, tourTimeSlot?: Maybe<(
        { __typename?: 'TourTimeSlot' }
        & TourTimeSlotFieldsFragment
      )>, items?: Maybe<Array<(
        { __typename?: 'TicketItem' }
        & CartSummaryTicketItemFieldsFragment
      ) | { __typename?: 'ProductItem' } | (
        { __typename?: 'TourItem' }
        & TourItemFieldsFragment
      )>> }
    )>>, reservations?: Maybe<Array<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id' | 'price' | 'state' | 'uuid' | 'hash' | 'updatedAt' | 'expireAt'>
      & { event?: Maybe<(
        { __typename?: 'Event' }
        & EventDetailFieldsFragment
      )>, items?: Maybe<Array<(
        { __typename?: 'TicketItem' }
        & CartSummaryTicketItemFieldsFragment
      ) | { __typename?: 'ProductItem' } | (
        { __typename?: 'TourItem' }
        & TourItemFieldsFragment
      )>>, tourTimeSlot?: Maybe<(
        { __typename?: 'TourTimeSlot' }
        & TourTimeSlotFieldsFragment
      )>, relatedSale?: Maybe<(
        { __typename?: 'Sale' }
        & Pick<Sale, 'id'>
        & { items?: Maybe<Array<(
          { __typename?: 'TicketItem' }
          & Pick<TicketItem, 'id'>
        ) | (
          { __typename?: 'ProductItem' }
          & Pick<ProductItem, 'id'>
        ) | (
          { __typename?: 'TourItem' }
          & Pick<TourItem, 'id'>
        )>> }
      )> }
    )>>, lead?: Maybe<(
      { __typename?: 'Lead' }
      & Pick<Lead, 'id'>
      & { data: (
        { __typename?: 'LeadData' }
        & LeadDataFieldsFragment
      ) }
    )>, items?: Maybe<Array<(
      { __typename?: 'TicketItem' }
      & { eventSeat: (
        { __typename?: 'EventSeat' }
        & Pick<EventSeat, 'id'>
        & { event: (
          { __typename?: 'Event' }
          & EventDetailFieldsFragment
        ) }
      ) }
      & CartSummaryTicketItemFieldsFragment
    ) | { __typename?: 'ProductItem' } | (
      { __typename?: 'TourItem' }
      & TourItemFieldsFragment
    )>>, client: (
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'currency'>
    ) }
    & BasicCartFieldsFragment
  ) }
);

export type CancelReservationMutationVariables = Exact<{
  reservationId: Scalars['Int'];
  reservationEcommerceIdentifiers?: Maybe<EcommerceIdentifiers>;
}>;


export type CancelReservationMutation = (
  { __typename?: 'Mutation' }
  & { cancelReservation: (
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
  ) }
);

export type AddReservationToCartMutationVariables = Exact<{
  reservationId: Scalars['Int'];
  cartId?: Maybe<Scalars['Int']>;
  reservationEcommerceIdentifiers?: Maybe<EcommerceIdentifiers>;
}>;


export type AddReservationToCartMutation = (
  { __typename?: 'Mutation' }
  & { addReservationToCart: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type GetBackendConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBackendConfigQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supportEmail'>
);

export type SignUpCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type SignUpCustomerMutation = (
  { __typename?: 'Mutation' }
  & { signUpCustomer: (
    { __typename?: 'Customer' }
    & CustomerFieldsFragment
  ) }
);

export type ForgotCustomerPasswordMutationVariables = Exact<{
  username: Scalars['NonEmptyString'];
  clientId: Scalars['PositiveInt'];
}>;


export type ForgotCustomerPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotCustomerPassword'>
);

export type SignInCustomerMutationVariables = Exact<{
  username: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
  clientId: Scalars['PositiveInt'];
}>;


export type SignInCustomerMutation = (
  { __typename?: 'Mutation' }
  & { signInCustomer: (
    { __typename?: 'Customer' }
    & CustomerFieldsFragment
  ) }
);

export type GetEventDetailQueryVariables = Exact<{
  id: Scalars['Int'];
  discountsFilter?: Maybe<EnabledDiscountsFilterInput>;
}>;


export type GetEventDetailQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & { venue: (
      { __typename?: 'Venue' }
      & VenueFieldsFragment
    ), enabledDiscounts: Array<(
      { __typename?: 'Discount' }
      & EnabledDiscountFieldsFragment
    )> }
    & EventDetailFieldsFragment
    & SalesAndReservationFieldsFragment
    & ShowFieldsFragment
  ) }
);

export type IncrementZoneTicketItemQuantitySuccessResultFieldsFragment = (
  { __typename?: 'IncrementZoneTicketItemQuantitySuccessResult' }
  & Pick<IncrementZoneTicketItemQuantitySuccessResult, 'newlyAddedZoneTicketItemsCount'>
  & { cart: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type IncrementZoneTicketItemQuantityMutationVariables = Exact<{
  cartId?: Maybe<Scalars['Int']>;
  eventId: Scalars['Int'];
  eventSeatUUID: Scalars['String'];
  increment?: Maybe<Scalars['NonNegativeInt']>;
}>;


export type IncrementZoneTicketItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { incrementZoneTicketItemQuantity: (
    { __typename?: 'IncrementZoneTicketItemQuantitySuccessResult' }
    & IncrementZoneTicketItemQuantitySuccessResultFieldsFragment
  ) | (
    { __typename?: 'ErrorResult' }
    & ErrorResultFieldsFragment
  ) }
);

export type AddSeatingTicketItemToCartMutationVariables = Exact<{
  cartId?: Maybe<Scalars['Int']>;
  eventId: Scalars['Int'];
  eventSeatUUID: Scalars['String'];
}>;


export type AddSeatingTicketItemToCartMutation = (
  { __typename?: 'Mutation' }
  & { addSeatingTicketItemToCart: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type DecrementZoneTicketItemQuantityMutationVariables = Exact<{
  cartId: Scalars['Int'];
  eventId: Scalars['Int'];
  eventSeatUUID: Scalars['String'];
  decrement: Scalars['NonNegativeInt'];
}>;


export type DecrementZoneTicketItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { decrementZoneTicketItemQuantity: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type GetEventSeatsQueryVariables = Exact<{
  eventId: Scalars['Int'];
  myCartId?: Maybe<Scalars['Int']>;
}>;


export type GetEventSeatsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'eventSeats'>
);

export type OnEventSeatsChangeSubscriptionVariables = Exact<{
  eventId: Scalars['Int'];
  myCartId?: Maybe<Scalars['Int']>;
}>;


export type OnEventSeatsChangeSubscription = (
  { __typename?: 'Subscription' }
  & { eventSeatsChange?: Maybe<(
    { __typename?: 'EventSeatsChange' }
    & Pick<EventSeatsChange, 'eventSeats'>
  )> }
);

export type GetEventStatsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetEventStatsQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'reservedSeatsCount' | 'soldSeatsCount' | 'availableSeatsCount'>
    & { auditoriumLayout: (
      { __typename?: 'AuditoriumLayout' }
      & Pick<AuditoriumLayout, 'id' | 'capacity'>
    ) }
  ) }
);

export type GetEventOverviewQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetEventOverviewQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & { venue: (
      { __typename?: 'Venue' }
      & VenueFieldsFragment
    ) }
    & EventDetailFieldsFragment
    & ShowFieldsFragment
  ) }
);

export type PrimaryImageFieldsFragment = (
  { __typename?: 'ClientShowImage' }
  & Pick<ClientShowImage, 'id'>
  & { thumbnails: Array<(
    { __typename?: 'ShowImageThumbnail' }
    & Pick<ShowImageThumbnail, 'id' | 'size' | 'url'>
  )> }
);

export type EventListItemFieldsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'startsAt' | 'endsAt' | 'formatCode' | 'soundMixCode' | 'versionCode' | 'ecommerceEventURL' | 'ageClassificationCode' | 'availableSeatsCount'>
  & { names: (
    { __typename?: 'Translated' }
    & TranslatedFieldsFragment
  ), venue: (
    { __typename?: 'Venue' }
    & Pick<Venue, 'id' | 'name'>
  ), show: (
    { __typename?: 'ClientShow' }
    & Pick<ClientShow, 'id' | 'contentDescriptorCodes'>
    & { translations: Array<(
      { __typename?: 'ShowTranslation' }
      & Pick<ShowTranslation, 'localeCode' | 'tagline'>
    )>, photoPrimaryImage?: Maybe<(
      { __typename?: 'ClientShowImage' }
      & PrimaryImageFieldsFragment
    )>, posterPrimaryImage?: Maybe<(
      { __typename?: 'ClientShowImage' }
      & PrimaryImageFieldsFragment
    )> }
  ), auditoriumLayout: (
    { __typename?: 'AuditoriumLayout' }
    & Pick<AuditoriumLayout, 'id' | 'capacity'>
  ) }
);

export type GetEventsListDataQueryVariables = Exact<{
  filter: EventsFilterInput;
  paginationInput: PaginationInput;
  clientId: Scalars['Int'];
}>;


export type GetEventsListDataQuery = (
  { __typename?: 'Query' }
  & { client: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'localeCodes'>
  ), paginatedEventsOnEcommerce: (
    { __typename?: 'PaginatedEventsSuccessResult' }
    & { items: Array<(
      { __typename?: 'Event' }
      & EventListItemFieldsFragment
    )>, pagination: (
      { __typename?: 'Pagination' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type TranslatedFieldsFragment = (
  { __typename?: 'Translated' }
  & Pick<Translated, 'en' | 'sk' | 'cs' | 'hu'>
);

export type ECommerceTranslatedFieldsFragment = (
  { __typename?: 'ECommerceTranslated' }
  & Pick<ECommerceTranslated, 'en' | 'sk' | 'cs' | 'hu'>
);

export type PaginationFieldsFragment = (
  { __typename?: 'Pagination' }
  & Pick<Pagination, 'hasMore' | 'offset' | 'limit'>
);

export type EnabledDiscountFieldsFragment = (
  { __typename?: 'Discount' }
  & Pick<Discount, 'id' | 'name' | 'value' | 'application' | 'type' | 'maxUsageLimitPerOrder'>
);

export type LeadOptionFieldsFragment = (
  { __typename?: 'LeadOption' }
  & Pick<LeadOption, 'id' | 'field' | 'inputStatus' | 'helperText'>
);

export type UserFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'firstName' | 'lastName'>
);

export type LeadDataFieldsFragment = (
  { __typename?: 'LeadData' }
  & Pick<LeadData, 'name' | 'phone' | 'email' | 'note' | 'internalNote' | 'companyName' | 'companyIdNumber' | 'VATId' | 'TAXId' | 'billingAddressStreet' | 'billingAddressTown' | 'billingPostalCode' | 'billingAddressCountry' | 'deliveryAddressee' | 'deliveryAddressStreet' | 'deliveryAddressTown' | 'deliveryPostalCode' | 'deliveryAddressCountry' | 'isPrivacyPolicyConsentGranted'>
);

export type ClientDetailFieldsFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'id' | 'currency' | 'name' | 'countryCode' | 'termsOfServiceUrl' | 'marketingInformationUrl' | 'appProviderB2CTermsOfServiceUrl'>
  & { eCommercePaymentMethods: Array<(
    { __typename?: 'PaymentMethod' }
    & Pick<PaymentMethod, 'id' | 'name' | 'serviceProvider'>
    & { description: (
      { __typename?: 'Translated' }
      & TranslatedFieldsFragment
    ), paymentServiceProviderConfig?: Maybe<(
      { __typename?: 'GoPayPaymentServiceProviderConfig' }
      & Pick<GoPayPaymentServiceProviderConfig, 'paymentServiceProvider' | 'defaultPaymentInstrument'>
    ) | { __typename?: 'StatnaPokladnicaPaymentServiceProviderConfig' } | (
      { __typename?: 'BesteronPaymentServiceProviderConfig' }
      & Pick<BesteronPaymentServiceProviderConfig, 'paymentServiceProvider' | 'paymentInstruments'>
    )> }
  )>, eCommerceLeadOptions: Array<(
    { __typename?: 'LeadOption' }
    & LeadOptionFieldsFragment
  )>, eCommerceReservationLeadOptions: Array<(
    { __typename?: 'LeadOption' }
    & LeadOptionFieldsFragment
  )> }
);

export type EventDetailFieldsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'state' | 'startsAt' | 'endsAt' | 'updatedAt' | 'duration' | 'formatCode' | 'soundMixCode' | 'versionCode' | 'ecommerceEventURL' | 'ageClassificationCode' | 'availableSeatsCount'>
  & { division: (
    { __typename?: 'Division' }
    & Pick<Division, 'id'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'currency' | 'localeCodes'>
    ) }
  ), names: (
    { __typename?: 'Translated' }
    & TranslatedFieldsFragment
  ), auditorium: (
    { __typename?: 'Auditorium' }
    & Pick<Auditorium, 'id' | 'name'>
  ), auditoriumLayout: (
    { __typename?: 'AuditoriumLayout' }
    & Pick<AuditoriumLayout, 'capacity' | 'type' | 'id' | 'layout'>
  ), auditoriumLayoutPricing: (
    { __typename?: 'AuditoriumLayoutPricing' }
    & Pick<AuditoriumLayoutPricing, 'id' | 'pricing' | 'maxTicketsPerOrder'>
  ), venue: (
    { __typename?: 'Venue' }
    & Pick<Venue, 'id' | 'name'>
  ), activePricing: (
    { __typename?: 'EventPricing' }
    & Pick<EventPricing, 'id'>
    & { ticketTypes: Array<(
      { __typename?: 'EventTicketType' }
      & Pick<EventTicketType, 'id' | 'name' | 'price' | 'color' | 'ticketTypeId' | 'description'>
    )> }
  ), salesAndReservation: (
    { __typename?: 'EventSaleAndReservation' }
    & { pointOfSale: (
      { __typename?: 'EventSaleAndReservationFields' }
      & Pick<EventSaleAndReservationFields, 'saleStart' | 'saleEnd' | 'saleActive' | 'reservationStart' | 'reservationEnd' | 'reservationActive' | 'isEnabledPaymentForReservation'>
    ), online: (
      { __typename?: 'EventSaleAndReservationFields' }
      & Pick<EventSaleAndReservationFields, 'saleStart' | 'saleEnd' | 'saleActive' | 'reservationStart' | 'reservationEnd' | 'reservationActive' | 'isEnabledPaymentForReservation'>
    ) }
  ) }
);

export type SalesAndReservationFieldsFragment = (
  { __typename?: 'Event' }
  & { salesAndReservation: (
    { __typename?: 'EventSaleAndReservation' }
    & { pointOfSale: (
      { __typename?: 'EventSaleAndReservationFields' }
      & Pick<EventSaleAndReservationFields, 'saleStart' | 'saleEnd' | 'saleActive' | 'reservationStart' | 'reservationEnd' | 'reservationActive'>
    ), online: (
      { __typename?: 'EventSaleAndReservationFields' }
      & Pick<EventSaleAndReservationFields, 'saleStart' | 'saleEnd' | 'saleActive' | 'reservationStart' | 'reservationEnd' | 'reservationActive'>
    ) }
  ) }
);

export type VenueFieldsFragment = (
  { __typename?: 'Venue' }
  & Pick<Venue, 'id' | 'name'>
  & { address: (
    { __typename?: 'Address' }
    & Pick<Address, 'complex' | 'street' | 'postalCode' | 'town'>
  ) }
);

export type ShowFieldsFragment = (
  { __typename?: 'Event' }
  & { show: (
    { __typename?: 'ClientShow' }
    & Pick<ClientShow, 'id' | 'duration' | 'genreCodes' | 'typeCode' | 'globalReleaseDate' | 'productionYear' | 'contentDescriptorCodes'>
    & { primaryImage?: Maybe<(
      { __typename?: 'ClientShowImage' }
      & Pick<ClientShowImage, 'id' | 'url'>
      & { thumbnails: Array<(
        { __typename?: 'ShowImageThumbnail' }
        & Pick<ShowImageThumbnail, 'id' | 'url'>
      )> }
    )>, translations: Array<(
      { __typename?: 'ShowTranslation' }
      & Pick<ShowTranslation, 'localeCode' | 'description' | 'tagline'>
    )>, images: Array<(
      { __typename?: 'ClientShowImage' }
      & Pick<ClientShowImage, 'id' | 'url' | 'type'>
      & { thumbnails: Array<(
        { __typename?: 'ShowImageThumbnail' }
        & Pick<ShowImageThumbnail, 'id' | 'url' | 'type'>
      )> }
    )>, crew: Array<(
      { __typename?: 'ShowCrewByRole' }
      & Pick<ShowCrewByRole, 'crewRoleCode'>
      & { persons: Array<(
        { __typename?: 'ShowCrewPerson' }
        & Pick<ShowCrewPerson, 'name' | 'description'>
      )> }
    )> }
  ) }
);

export type TicketItemFieldsFragment = (
  { __typename?: 'TicketItem' }
  & Pick<TicketItem, 'id' | 'price' | 'priceBeforeDiscount'>
  & { appliedDiscounts: Array<(
    { __typename?: 'AppliedDiscount' }
    & { discountCode?: Maybe<(
      { __typename?: 'DiscountCode' }
      & Pick<DiscountCode, 'id'>
    )>, discount: (
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'name'>
    ) }
  )>, eventPricingToTicketType: (
    { __typename?: 'EventPricingToTicketType' }
    & Pick<EventPricingToTicketType, 'name' | 'promotions'>
  ), reservation?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
  )>, eventSeat: (
    { __typename?: 'EventSeat' }
    & Pick<EventSeat, 'id' | 'uuid' | 'label' | 'row' | 'section' | 'floor' | 'type'>
    & { event: (
      { __typename?: 'Event' }
      & { enabledDiscounts: Array<(
        { __typename?: 'Discount' }
        & EnabledDiscountFieldsFragment
      )> }
      & EventDetailFieldsFragment
    ) }
  ) }
);

export type TourItemsStateCountsFieldsFragment = (
  { __typename?: 'TourItemsStateCounts' }
  & Pick<TourItemsStateCounts, 'inCart' | 'reserved' | 'sold'>
);

export type ProductItemFieldsFragment = (
  { __typename?: 'ProductItem' }
  & Pick<ProductItem, 'id'>
);

export type TourTimeSlotFieldsFragment = (
  { __typename?: 'TourTimeSlot' }
  & Pick<TourTimeSlot, 'id' | 'duration' | 'startsAt' | 'endsAt' | 'versionCode' | 'ageClassificationCode' | 'eCommerceAttendeesLimit' | 'retailAttendeesLimit'>
  & { names: (
    { __typename?: 'Translated' }
    & TranslatedFieldsFragment
  ), venue: (
    { __typename?: 'Venue' }
    & Pick<Venue, 'id' | 'name'>
  ), client: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'currency' | 'localeCodes'>
  ), guide?: Maybe<(
    { __typename?: 'User' }
    & UserFieldsFragment
  )>, tourItemsStateCounts: (
    { __typename?: 'TourItemsStateCounts' }
    & TourItemsStateCountsFieldsFragment
  ) }
);

export type TourItemFieldsFragment = (
  { __typename?: 'TourItem' }
  & Pick<TourItem, 'id' | 'name' | 'price' | 'priceBeforeDiscount' | 'tourTimeSlotId' | 'admissionTypeAssignmentId'>
  & { appliedDiscounts: Array<(
    { __typename?: 'AppliedDiscount' }
    & { discountCode?: Maybe<(
      { __typename?: 'DiscountCode' }
      & Pick<DiscountCode, 'id'>
    )>, discount: (
      { __typename?: 'Discount' }
      & Pick<Discount, 'id' | 'name'>
    ) }
  )>, admissionTypeAssignment: (
    { __typename?: 'AdmissionTypeAssignment' }
    & Pick<AdmissionTypeAssignment, 'id' | 'hasEnabledDiscountsOnECommerce'>
  ), tourTimeSlot: (
    { __typename?: 'TourTimeSlot' }
    & { enabledDiscounts: Array<(
      { __typename?: 'Discount' }
      & EnabledDiscountFieldsFragment
    )> }
    & TourTimeSlotFieldsFragment
  ), division: (
    { __typename?: 'Division' }
    & Pick<Division, 'id'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'localeCodes' | 'currency'>
    ) }
  ) }
);

export type ErrorResultFieldsFragment = (
  { __typename?: 'ErrorResult' }
  & Pick<ErrorResult, 'errorCode'>
);

export type BasicCartFieldsFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id' | 'state' | 'price' | 'expiresAt' | 'clientId'>
  & { paymentIntents: Array<(
    { __typename?: 'PaymentIntent' }
    & Pick<PaymentIntent, 'id' | 'state'>
  )>, uniqueLeads: Array<(
    { __typename?: 'Lead' }
    & Pick<Lead, 'id'>
    & { data: (
      { __typename?: 'LeadData' }
      & LeadDataFieldsFragment
    ) }
  )>, items?: Maybe<Array<(
    { __typename?: 'TicketItem' }
    & TicketItemFieldsFragment
  ) | (
    { __typename?: 'ProductItem' }
    & ProductItemFieldsFragment
  ) | (
    { __typename?: 'TourItem' }
    & TourItemFieldsFragment
  )>> }
);

export type CustomerFieldsFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'id' | 'clientId' | 'username' | 'state' | 'leadId' | 'loyaltyId' | 'privacyPolicyConsentGrantedAt' | 'createdAt'>
  & { lead: (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id'>
    & { data: (
      { __typename?: 'LeadData' }
      & LeadDataFieldsFragment
    ) }
  ), client: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'localeCodes' | 'currency'>
  ) }
);

export type GetCurrentCartQueryVariables = Exact<{
  cartId: Scalars['Int'];
}>;


export type GetCurrentCartQuery = (
  { __typename?: 'Query' }
  & { cart: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'checkoutOptions'>
    & { client: (
      { __typename?: 'Client' }
      & ClientDetailFieldsFragment
    ), lead?: Maybe<(
      { __typename?: 'Lead' }
      & Pick<Lead, 'id'>
      & { data: (
        { __typename?: 'LeadData' }
        & LeadDataFieldsFragment
      ) }
    )> }
    & BasicCartFieldsFragment
  ) }
);

export type RemoveTicketItemFromCartMutationVariables = Exact<{
  itemId: Scalars['Int'];
  cartId: Scalars['Int'];
}>;


export type RemoveTicketItemFromCartMutation = (
  { __typename?: 'Mutation' }
  & { removeTicketItemFromCart: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'checkoutOptions'>
    & BasicCartFieldsFragment
  ) }
);

export type SignOutCustomerMutationVariables = Exact<{ [key: string]: never; }>;


export type SignOutCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signOutCustomer'>
);

export type RecoverCustomerPasswordMutationVariables = Exact<{
  token: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
}>;


export type RecoverCustomerPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recoverCustomerPassword'>
);

export type ChangePasswordForSignedInCustomerMutationVariables = Exact<{
  password: Scalars['NonEmptyString'];
}>;


export type ChangePasswordForSignedInCustomerMutation = (
  { __typename?: 'Mutation' }
  & { changePasswordForSignedInCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
  ) }
);

export type UpdateSignedInCustomerMutationVariables = Exact<{
  input: UpdateSignedInCustomerInput;
}>;


export type UpdateSignedInCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateSignedInCustomer: (
    { __typename?: 'Customer' }
    & CustomerFieldsFragment
  ) }
);

export type CustomerPurchaseHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerPurchaseHistoryQuery = (
  { __typename?: 'Query' }
  & { signedInCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id'>
    & { sales: Array<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'id' | 'price' | 'itemType'>
      & { event?: Maybe<(
        { __typename?: 'Event' }
        & EventDetailFieldsFragment
      )>, tourTimeSlot?: Maybe<(
        { __typename?: 'TourTimeSlot' }
        & Pick<TourTimeSlot, 'id' | 'startsAt'>
        & { names: (
          { __typename?: 'Translated' }
          & TranslatedFieldsFragment
        ), client: (
          { __typename?: 'Client' }
          & Pick<Client, 'id' | 'localeCodes'>
        ) }
      )>, items?: Maybe<Array<(
        { __typename?: 'TicketItem' }
        & TicketItemFieldsFragment
      ) | (
        { __typename?: 'ProductItem' }
        & ProductItemFieldsFragment
      ) | (
        { __typename?: 'TourItem' }
        & TourItemFieldsFragment
      )>> }
    )>, reservations: Array<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id' | 'price' | 'state' | 'updatedAt' | 'expireAt' | 'uuid' | 'hash' | 'itemType'>
      & { tourTimeSlot?: Maybe<(
        { __typename?: 'TourTimeSlot' }
        & Pick<TourTimeSlot, 'id' | 'startsAt'>
        & { names: (
          { __typename?: 'Translated' }
          & TranslatedFieldsFragment
        ), client: (
          { __typename?: 'Client' }
          & Pick<Client, 'id' | 'localeCodes'>
        ) }
      )>, event?: Maybe<(
        { __typename?: 'Event' }
        & EventDetailFieldsFragment
      )>, items?: Maybe<Array<(
        { __typename?: 'TicketItem' }
        & TicketItemFieldsFragment
      ) | (
        { __typename?: 'ProductItem' }
        & ProductItemFieldsFragment
      ) | (
        { __typename?: 'TourItem' }
        & TourItemFieldsFragment
      )>> }
    )> }
  ) }
);

export type NotifyUserReturnedFromPaymentGatewayMutationVariables = Exact<{
  paymentServiceProvider: PaymentServiceProvider;
  uuid: Scalars['String'];
  hash: Scalars['String'];
  paymentIntentId: Scalars['PositiveInt'];
}>;


export type NotifyUserReturnedFromPaymentGatewayMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'notifyUserReturnedFromPaymentGateway'>
);

export type TourTimeSlotBaseFieldsFragment = (
  { __typename?: 'TourTimeSlot' }
  & Pick<TourTimeSlot, 'id' | 'versionCode' | 'ageClassificationCode' | 'startsAt' | 'endsAt'>
  & { names: (
    { __typename?: 'Translated' }
    & TranslatedFieldsFragment
  ), tour: (
    { __typename?: 'Tour' }
    & Pick<Tour, 'id' | 'name'>
  ), venue: (
    { __typename?: 'Venue' }
    & VenueFieldsFragment
  ), client: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'localeCodes' | 'currency'>
  ) }
);

export type TourTimeSlotSaleFieldsFragment = (
  { __typename?: 'TourTimeSlot' }
  & Pick<TourTimeSlot, 'eCommerceSaleStartsAt' | 'eCommerceSaleEndsAt' | 'eCommerceReservationStartsAt' | 'eCommerceReservationEndsAt' | 'eCommerceAttendeesLimit' | 'eCommerceOrderAttendeesLimit' | 'isECommerceSaleActive' | 'isECommerceReservationActive' | 'isRetailSaleActive' | 'isRetailReservationActive' | 'state'>
);

export type GetTourTimeSlotDetailQueryVariables = Exact<{
  id: Scalars['PositiveInt'];
}>;


export type GetTourTimeSlotDetailQuery = (
  { __typename?: 'Query' }
  & { tourTimeSlot: (
    { __typename?: 'TourTimeSlot' }
    & Pick<TourTimeSlot, 'id'>
    & { admissionRate: (
      { __typename?: 'AdmissionRate' }
      & Pick<AdmissionRate, 'id'>
      & { admissionTypesAssignments: Array<(
        { __typename?: 'AdmissionTypeAssignment' }
        & Pick<AdmissionTypeAssignment, 'id' | 'enabledOnECommerce' | 'eCommercePrice'>
        & { admissionType: (
          { __typename?: 'AdmissionType' }
          & Pick<AdmissionType, 'id' | 'startingQuantity' | 'name'>
          & { eCommerceNames?: Maybe<(
            { __typename?: 'ECommerceTranslated' }
            & ECommerceTranslatedFieldsFragment
          )>, eCommerceDescriptions?: Maybe<(
            { __typename?: 'ECommerceTranslated' }
            & ECommerceTranslatedFieldsFragment
          )> }
        ) }
      )> }
    ) }
    & TourTimeSlotBaseFieldsFragment
    & TourTimeSlotSaleFieldsFragment
  ) }
);

export type IncrementTourItemQuantityMutationVariables = Exact<{
  cartId?: Maybe<Scalars['PositiveInt']>;
  tourTimeSlotId: Scalars['PositiveInt'];
  admissionTypeAssignmentId: Scalars['PositiveInt'];
  increment: Scalars['PositiveInt'];
}>;


export type IncrementTourItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { incrementTourItemQuantity: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type DecrementTourItemQuantityMutationVariables = Exact<{
  cartId: Scalars['PositiveInt'];
  tourTimeSlotId: Scalars['PositiveInt'];
  admissionTypeAssignmentId: Scalars['PositiveInt'];
  decrement: Scalars['PositiveInt'];
}>;


export type DecrementTourItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { decrementTourItemQuantity: (
    { __typename?: 'Cart' }
    & BasicCartFieldsFragment
  ) }
);

export type GetTourTimeSlotsListDataQueryVariables = Exact<{
  filter: TourTimeSlotsFilter;
  paginationInput: PaginationInput;
}>;


export type GetTourTimeSlotsListDataQuery = (
  { __typename?: 'Query' }
  & { tourTimeSlots: (
    { __typename?: 'PaginatedTourTimeSlots' }
    & { items: Array<(
      { __typename?: 'TourTimeSlot' }
      & TourTimeSlotFieldsFragment
    )>, pagination: (
      { __typename?: 'Pagination' }
      & PaginationFieldsFragment
    ) }
  ) }
);

export type GetToursTimeSlotsListDataQueryVariables = Exact<{
  filter?: Maybe<TourTimeSlotsFilter>;
  paginationInput: PaginationInput;
}>;


export type GetToursTimeSlotsListDataQuery = (
  { __typename?: 'Query' }
  & { tourTimeSlots: (
    { __typename?: 'PaginatedTourTimeSlots' }
    & { items: Array<(
      { __typename?: 'TourTimeSlot' }
      & TourTimeSlotFieldsFragment
    )>, pagination: (
      { __typename?: 'Pagination' }
      & PaginationFieldsFragment
    ) }
  ) }
);
